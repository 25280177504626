import { CountrySelect } from '../../../shared/CountryMultiselect/CountrySelect';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  TimePicker,
  Tooltip,
  Switch,
} from 'antd';
import styles from './OrdersForm.module.css';
import React, { useContext, useEffect, useState } from 'react';
import timezones from 'timezones-list';
import { fromApiToClient } from '../../../utils/dateFilters';
import { AutocompleteSelect } from '../../../shared/AutocompleteSelect/AutocompleteSelect';
import api from '../../../api/api';
import dayjs from 'dayjs';
import {
  hasPermissions,
  usePermissions,
} from '../../../hooks/usePermissionsAllowed';
import { CurrentUserContext } from '../../../providers/AuthProvider';
import { FormFieldRestricter } from '../../../shared/FormFieldRestricter/FormFieldRestricter';

const { TextArea } = Input;
export const OrdersForm = ({ order, onFinish }) => {
  const [form] = Form.useForm();
  const role = usePermissions();
  const user = useContext(CurrentUserContext);
  const [isActive, setIsActive] = useState(order ? order.isActive : true);
  const [isAllowDuplicates, setIsAllowDuplicates] = useState(
    order ? order.isAllowDuplicates : true
  );
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [selectedBuyers, setSelectedBuyers] = useState([]);
  const [callStatusGroup, setCallStatusGroup] = useState();
  const [selectedBizdev, setSelectedBizdev] = useState();
  const [selectedAffiliate, setSelectedAffiliate] = useState();
  const [selectedCountryBox, setSelectedCountryBox] = useState();
  const [selectedFullstack, setSelectedFullstack] = useState();
  const [twoSides, setTwoSides] = useState(true);
  const [startedAt, setStartedAt] = useState();
  const [endedAt, setEndedAt] = useState();
  const [countries, setCountries] = useState([]);
  const [scheduleError, setScheduleError] = useState(false);
  const [timezone, setTimezone] = useState(
    order ? JSON.parse(order.callCenterSchedule).timezone : undefined
  );
  const [timezonesList, setTimezonesList] = useState([]);
  const [days, setDays] = useState(
    order
      ? JSON.parse(order.callCenterSchedule).days
      : [
          { label: 'Понеділок' },
          { label: 'Вівторок' },
          { label: 'Середа' },
          { label: 'Четвер' },
          { label: "П'ятниця" },
          { label: 'Субота' },
          { label: 'Неділя' },
        ]
  );
  const affiliatesTeam = [
    'AFFILIATE',
    'AFFILIATE_TEAM_LEAD',
    'AFFILIATE_SUPPORT',
  ];
  const bizdevTeam = ['BIZDEV', 'BIZDEV_TEAM_LEAD', 'BIZDEV_SUPPORT'];
  const fullstackTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  const allWorkers = affiliatesTeam.concat(bizdevTeam).concat(fullstackTeam);

  useEffect(() => {
    if (!order) form.setFieldValue('isActive', true);
    api.get(`/timezones`).then((res) => {
      setTimezonesList(res.data);
    });
  }, []);

  useEffect(() => {
    if (order) {
      form.setFieldsValue(order);
      if (order.integration) {
        form.setFieldValue('integration', {
          label: order.integration?.name,
          value: order.integration?.id,
          key: order.integration?.id,
        });
        setSelectedIntegration({
          label: order.integration?.name,
          value: order.integration?.id,
          key: order.integration?.id,
        });
      }
      if (order.buyers) {
        setSelectedBuyers(
          order.buyers.map((b) => ({ label: b.name, value: b.id, key: b.id }))
        );
        form.setFieldValue(
          'buyers',
          order.buyers.map((b) => ({ label: b.name, value: b.id, key: b.id }))
        );
      }
      if (order.countryBox) {
        setSelectedCountryBox({
          value: order.countryBox?.id,
          label: order.countryBox?.name,
        });
        form.setFieldValue('countryBox', {
          value: order.countryBox?.id,
          label: order.countryBox?.name,
        });
      }
      if (order.countries) {
        setCountries(JSON.parse(order.countries));
        form.setFieldValue('countries', JSON.parse(order.countries));
      }
      if (order.callStatusGroup) {
        form.setFieldValue('callStatusGroup', {
          label: order.callStatusGroup?.name,
          value: order.callStatusGroup?.id,
          key: order.callStatusGroup?.id,
        });
        setCallStatusGroup({
          label: order.callStatusGroup?.name,
          value: order.callStatusGroup?.id,
          key: order.callStatusGroup?.id,
        });
      }
      if (order.customer) {
        form.setFieldValue('customer', {
          label: order.customer?.name,
          value: order.customer?.id,
          key: order.customer?.id,
        });
        setSelectedCustomer({
          label: order.customer?.name,
          value: order.customer?.id,
          key: order.customer?.id,
        });
      }
      if (order.bizdev) {
        setSelectedBizdev({
          value: order.bizdev?.id,
          label: order.bizdev?.nickname,
          key: order.bizdev?.id,
        });
        form.setFieldValue('bizdev', {
          value: order.bizdev?.id,
          label: order.bizdev?.nickname,
          key: order.bizdev?.id,
        });
      }
      if (order.affiliate) {
        setSelectedAffiliate({
          value: order.affiliate?.id,
          label: order.affiliate?.nickname,
          key: order.affiliate?.id,
        });
        form.setFieldValue('affiliate', {
          value: order.affiliate?.id,
          label: order.affiliate?.nickname,
          key: order.affiliate?.id,
        });
      }
      if (
        order.affiliate &&
        order.bizdev &&
        order.affiliate?.id === order.bizdev?.id
      ) {
        setSelectedFullstack({
          value: order.affiliate?.id,
          label: order.affiliate?.nickname,
          key: order.affiliate?.id,
        });
        form.setFieldValue('fullstack', {
          value: order.affiliate?.id,
          label: order.affiliate?.nickname,
          key: order.affiliate?.id,
        });
      }
      if (order.createdAt) {
        form.setFieldValue(
          'createdAt',
          fromApiToClient(order.createdAt, user.timezone)
        );
      }
      if (order.updatedAt) {
        form.setFieldValue(
          'updatedAt',
          fromApiToClient(order.updatedAt, user.timezone)
        );
      }
      if (order.startedAt) {
        setStartedAt(dayjs(order.startedAt));
        form.setFieldValue(
          'startedAt',
          order.startedAt ? dayjs(new Date(order.startedAt)) : ''
        );
      }
      if (order.endedAt) {
        setEndedAt(dayjs(order.endedAt));
        form.setFieldValue(
          'endedAt',
          order.endedAt ? dayjs(new Date(order.endedAt)) : ''
        );
      }
      if (order.paymentForm)
        form.setFieldValue('paymentForm', order.paymentForm);
      if (order.paymentType)
        form.setFieldValue('paymentType', order.paymentType);
      setIsActive(order.isActive);
      setIsAllowDuplicates(order.isAllowDuplicates);
      setDays(JSON.parse(order.callCenterSchedule).days);
      setTimezone(JSON.parse(order.callCenterSchedule).timezone);
    }
  }, [order]);

  const currentUser = useContext(CurrentUserContext);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.role === 'AFFILIATE') {
        setSelectedAffiliate({
          value: currentUser.id,
          label: currentUser.nickname,
          key: currentUser.id,
        });
        form.setFieldValue('affiliate', {
          value: currentUser.id,
          label: currentUser.nickname,
          key: currentUser.id,
        });
      }
      if (currentUser.role === 'BIZDEV') {
        setSelectedAffiliate({
          value: currentUser.id,
          label: currentUser.nickname,
          key: currentUser.id,
        });
        form.setFieldValue('bizdev', {
          value: currentUser.id,
          label: currentUser.nickname,
          key: currentUser.id,
        });
      }
      if (currentUser.role === 'FULLSTACK') {
        setSelectedFullstack({
          value: currentUser.id,
          label: currentUser.nickname,
          key: currentUser.id,
        });
        form.setFieldValue('fullstack', {
          value: currentUser.id,
          label: currentUser.nickname,
          key: currentUser.id,
        });
      }
      if (
        (order?.affiliate?.id === order?.bizdev?.id &&
          order?.affiliate?.id.length &&
          order?.bizdev?.id.length) ||
        (order?.affiliate?.id === order?.bizdev?.id &&
          fullstackTeam.includes(role))
      ) {
        setTwoSides(false);
      }
    }
  }, [currentUser]);

  const setSwitchTwoSides = () => {
    setTwoSides(!twoSides);
  };

  const onActiveChange = (e) => {
    setIsActive(e.target.checked);
  };

  const onAllowDuplicatesChange = (e) => {
    setIsAllowDuplicates(e.target.checked);
  };

  const onCountryChange = (value) => {
    setCountries(value);
    form.setFieldValue('countries', value);
  };

  const fetchCustomers = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/customer?filter.name=$ilike:${name}&filter.isActive=$eq:true`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchCustomers = async (name) => {
    const res = await api.get(`/customer?limit10&filter.isActive=$eq:true`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const fetchIntegrations = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/integration?filter.name=$ilike:${name}&filter.customer.id=$eq:${selectedCustomer.value}&filter.isActive=$eq:true${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchIntegrations = async () => {
    const url = selectedCustomer?.value
      ? `/integration?limit=10&filter.customer.id=$eq:${selectedCustomer.value}&filter.isActive=$eq:true`
      : `/integration?limit=10`;
    const res = await api.get(url);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const fetchBuyers = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/buyer?filter.name=$ilike:${name}&filter.isActive=$eq:true${idFilter}`
    );
    return res.data.data.map((b) => ({
      label: `#${b.id} ${b.name}`,
      value: b.id,
    }));
  };

  const initialFetchBuyers = async () => {
    const res = await api.get(`/buyer?limit=10&filter.isActive=$eq:true`);
    return res.data.data.map((b) => ({
      label: `#${b.id} ${b.name}`,
      value: b.id,
    }));
  };

  const fetchCallStatusGroup = async (name) => {
    const res = await api.get(
      `/call-status-group?filter.name=$ilike:${name}&filter.isActive=$eq:true`
    );
    return res.data.data.map((c) => ({
      label: c.name,
      value: c.id,
    }));
  };

  const initialFetchCallStatusGroup = async () => {
    const res = await api.get(
      `/call-status-group?limit=10&filter.isActive=$eq:true`
    );
    return res.data.data.map((c) => ({
      label: c.name,
      value: c.id,
    }));
  };

  const fetchCountryBox = async (name) => {
    const res = await api.get(
      `/country-box?filter.isActive=$eq:true&filter.name=$ilike:${name}`
    );
    return res.data.data.map((c) => ({
      label: c.name,
      value: c.id,
    }));
  };

  const initialFetchCountryBox = async () => {
    const res = await api.get(`/country-box?filter.isActive=$eq:true`);
    return res.data.data.map((c) => ({
      label: c.name,
      value: c.id,
    }));
  };

  const fetchBizdevs = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:BIZDEV&filter.role=$or:$eq:FULLSTACK&filter.name=$ilike:${name}${idFilter}`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: `#${u.id} ${u.name}`,
        value: u.id,
      }));
  };

  const initialFetchBizdevs = async () => {
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:BIZDEV&filter.role=$or:$eq:FULLSTACK`
    );
    return res.data.data.map((u) => ({
      label: `#${u.id} ${u.name}`,
      value: u.id,
    }));
  };

  const fetchAffiliates = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:AFFILIATE&filter.role=$or:$eq:FULLSTACK&filter.nickname=$ilike:${name}${idFilter}`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: `#${u.id} ${u.name}`,
        value: u.id,
      }));
  };

  const initialFetchAffiliates = async () => {
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:AFFILIATE&filter.role=$or:$eq:FULLSTACK`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: `#${u.id} ${u.name}`,
        value: u.id,
      }));
  };

  const fetchFullstaks = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:FULLSTACK&filter.nickname=$ilike:${name}`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: `#${u.id} ${u.name}`,
        value: u.id,
      }));
  };

  const initialFetchFullstacks = async () => {
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:FULLSTACK`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: `#${u.id} ${u.name}`,
        value: u.id,
      }));
  };

  if (!user) return 'Loading';

  return (
    <Form
      form={form}
      onFinish={() => {
        if (
          days.find((d) => d.isActive && d.from !== 0 && !d.to) &&
          timezone?.length
        ) {
          setScheduleError(true);
          return;
        }
        onFinish({
          ...form.getFieldsValue(),
          isActive: order ? isActive : true,
          isAllowDuplicates: order ? isAllowDuplicates : true,
          leadPrice: +form.getFieldValue('leadPrice'),
          cap: +form.getFieldValue('cap') || null,
          dailyCap: +form.getFieldValue('dailyCap') || null,
          countries: JSON.stringify(countries) || null,
          startedAt: startedAt?.$d || null,
          endedAt: endedAt?.$d || null,
          customerId: form.getFieldValue('customer')?.value || null,
          countryBoxId: form.getFieldValue('countryBox')?.value || null,
          integrationId: form.getFieldValue('integration')?.value || null,
          buyers: form.getFieldValue('buyers')?.map((b) => b.value) || [],
          callStatusGroupId:
            form.getFieldValue('callStatusGroup')?.value || null,
          affiliateId: selectedAffiliate?.value || undefined,
          bizdevId: selectedBizdev?.value || undefined,
          fullstackId: selectedFullstack?.value || undefined,
          twoSides: twoSides,

          callCenterSchedule: JSON.stringify({
            timezone,
            days: days.map((d) => {
              if (d.isActive) {
                return {
                  label: d.label,
                  from: d.from,
                  to: d.to,
                  isActive: d.isActive,
                };
              }
              return {
                label: d.label,
                isActive: false,
              };
            }),
          }),
        });
      }}
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
    >
      {hasPermissions(role, fullstackTeam) && !twoSides && (
        <Form.Item
          name="fullstack"
          label="Fullstack"
          rules={[
            {
              required: !twoSides,
            },
          ]}
        >
          <AutocompleteSelect
            className={styles.input}
            value={selectedBizdev}
            placeholder="Fullstack"
            initialFetch={initialFetchFullstacks}
            fetch={fetchFullstaks}
            onChange={setSelectedFullstack}
            disabled={
              !hasPermissions(role, [
                'FULLSTACK_TEAM_LEAD',
                'FULLSTACK_SUPPORT',
              ])
            }
          />
        </Form.Item>
      )}
      {twoSides && (
        <Form.Item
          name="affiliate"
          label="Аффілейт"
          rules={[
            {
              required: !selectedBizdev && twoSides,
            },
          ]}
        >
          <AutocompleteSelect
            className={styles.input}
            value={selectedAffiliate}
            placeholder="Аффілейт"
            initialFetch={initialFetchAffiliates}
            fetch={fetchAffiliates}
            onChange={setSelectedAffiliate}
            disabled={
              !hasPermissions(
                role,
                bizdevTeam.concat(
                  ['AFFILIATE_TEAM_LEAD', 'AFFILIATE_SUPPORT'].concat(
                    fullstackTeam
                  )
                )
              )
            }
          />
        </Form.Item>
      )}
      {twoSides && (
        <Form.Item
          name="bizdev"
          label="Біздев"
          rules={[
            {
              required: !selectedAffiliate && twoSides,
            },
          ]}
        >
          <AutocompleteSelect
            className={styles.input}
            value={selectedBizdev}
            placeholder="Біздев"
            initialFetch={initialFetchBizdevs}
            fetch={fetchBizdevs}
            onChange={setSelectedBizdev}
            disabled={
              !hasPermissions(
                role,
                affiliatesTeam
                  .concat(['BIZDEV_TEAM_LEAD', 'BIZDEV_SUPPORT'])
                  .concat(fullstackTeam)
              )
            }
          />
        </Form.Item>
      )}
      {hasPermissions(role, fullstackTeam.concat('SUPER_ADMIN')) && (
        <div className={styles.switchWraper}>
          <Switch
            defaultChecked={
              role === 'FULLSTACK' &&
              (!order ||
                (order?.affiliate?.id === order?.bizdev?.id &&
                  order?.affiliate?.id !== undefined &&
                  order?.bizdev?.id !== undefined))
                ? false
                : twoSides
            }
            onChange={setSwitchTwoSides}
          />
          <span className={styles.switchLabel}>Аффілейт + біздев</span>
        </div>
      )}
      <Divider />
      <Form.Item
        name="name"
        label="Назва"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input
          disabled={!hasPermissions(role, allWorkers)}
          placeholder="Назва"
          rootClassName={styles.input}
        />
      </Form.Item>
      <Form.Item
        name="customer"
        label="Замовник"
        rules={[
          {
            required: bizdevTeam.includes(role) || !twoSides,
          },
        ]}
      >
        <AutocompleteSelect
          className={styles.input}
          value={selectedCustomer}
          disabled={!hasPermissions(role, bizdevTeam.concat(fullstackTeam))}
          placeholder="Замовник"
          initialFetch={initialFetchCustomers}
          fetch={fetchCustomers}
          onChange={setSelectedCustomer}
        />
      </Form.Item>
      <Form.Item
        name="integration"
        label="Інтеграція"
        rules={[
          {
            required: bizdevTeam.includes(role) || !twoSides,
          },
        ]}
      >
        <AutocompleteSelect
          allowClear
          disabled={
            !selectedCustomer ||
            !hasPermissions(role, bizdevTeam.concat(fullstackTeam))
          }
          className={styles.input}
          value={selectedIntegration}
          placeholder="Інтеграція"
          initialFetch={initialFetchIntegrations}
          fetch={fetchIntegrations}
          onChange={setSelectedIntegration}
        />
      </Form.Item>
      <Form.Item
        name="buyers"
        label="Баєра"
        rules={[
          {
            required: affiliatesTeam.includes(role) || !twoSides,
          },
        ]}
      >
        <AutocompleteSelect
          className={styles.input}
          value={selectedBuyers}
          placeholder="Баєра"
          mode="multiple"
          initialFetch={initialFetchBuyers}
          fetch={fetchBuyers}
          onChange={setSelectedBuyers}
          disabled={!hasPermissions(role, affiliatesTeam.concat(fullstackTeam))}
        />
      </Form.Item>
      <Form.Item
        name="callStatusGroup"
        label="Група call статусів"
        rules={[
          {
            required: affiliatesTeam.includes(role) || !twoSides,
          },
        ]}
      >
        <AutocompleteSelect
          className={styles.input}
          disabled={!hasPermissions(role, affiliatesTeam.concat(fullstackTeam))}
          value={callStatusGroup}
          placeholder="Група call статусів"
          initialFetch={initialFetchCallStatusGroup}
          fetch={fetchCallStatusGroup}
          onChange={setCallStatusGroup}
        />
      </Form.Item>
      <Form.Item name="startedAt" label="Дата початку">
        <Tooltip title="Дата вказується включно. Використовується таймзона коллцентра">
          <DatePicker
            defaultValue={
              order && order.startedAt ? dayjs.tz(order.startedAt, 'UTC') : ''
            }
            disabled={!hasPermissions(role, bizdevTeam.concat(fullstackTeam))}
            onChange={setStartedAt}
            style={{ width: '350px' }}
            className={styles.input}
          />
        </Tooltip>
      </Form.Item>
      <Form.Item name="endedAt" label="Дата виконання">
        <Tooltip title="Дата вказується включно. Використовується таймзона коллцентра">
          <DatePicker
            defaultValue={
              order && order.endedAt ? dayjs.tz(order.endedAt, 'UTC') : ''
            }
            disabled={!hasPermissions(role, bizdevTeam.concat(fullstackTeam))}
            onChange={setEndedAt}
            style={{ width: '350px' }}
            rootClassName={styles.input}
          />
        </Tooltip>
      </Form.Item>
      <Form.Item
        name="leadPrice"
        label="Ціна ліда"
        rules={[
          {
            required: affiliatesTeam.includes(role) || !twoSides,
          },
        ]}
      >
        <Input
          placeholder="Ціна ліда"
          type="number"
          disabled={!hasPermissions(role, affiliatesTeam.concat(fullstackTeam))}
          rootClassName={styles.input}
        />
      </Form.Item>
      <Form.Item
        name="countryBox"
        label="Country Box"
        rules={[
          {
            required:
              countries.length === 0 &&
              (bizdevTeam.includes(role) || !twoSides),
          },
        ]}
      >
        <AutocompleteSelect
          className={styles.input}
          value={selectedCountryBox}
          placeholder="Country Box"
          initialFetch={initialFetchCountryBox}
          fetch={fetchCountryBox}
          onChange={setSelectedCountryBox}
          disabled={
            !hasPermissions(role, bizdevTeam.concat(fullstackTeam)) ||
            countries.length !== 0
          }
          allowClear
        />
      </Form.Item>
      <Form.Item
        name="countries"
        label="Країни"
        rules={[
          {
            required:
              !selectedCountryBox && (bizdevTeam.includes(role) || !twoSides),
          },
        ]}
      >
        <CountrySelect
          mode="multiple"
          onChange={onCountryChange}
          className={styles.input}
          disabled={
            !hasPermissions(role, bizdevTeam.concat(fullstackTeam)) ||
            selectedCountryBox?.value
          }
        />
      </Form.Item>
      <Form.Item
        name="paymentForm"
        label="Форма оплати"
        rules={[
          {
            required: bizdevTeam.includes(role) || !twoSides,
          },
        ]}
      >
        <Select
          options={[
            { value: 'PREPAY', label: 'PREPAY' },
            { value: 'POSTPAY', label: 'POSTPAY' },
          ]}
          className={styles.input}
          disabled={!hasPermissions(role, bizdevTeam.concat(fullstackTeam))}
        />
      </Form.Item>
      <Form.Item
        name="paymentType"
        label="Тип оплати"
        rules={[
          {
            required: bizdevTeam.includes(role) || !twoSides,
          },
        ]}
      >
        <Select
          options={[
            { value: 'CPA', label: 'CPA' },
            { value: 'CPL', label: 'CPL' },
          ]}
          className={styles.input}
          disabled={!hasPermissions(role, bizdevTeam.concat(fullstackTeam))}
        />
      </Form.Item>
      <Form.Item name="comment" label="Коментар">
        <TextArea
          rows={4}
          placeholder="Коментар"
          className={styles.input}
          style={{ resize: 'none' }}
          disabled={!hasPermissions(role, allWorkers)}
        />
      </Form.Item>
      <Tooltip
        name="dailyCap"
        title="Залиште пустим, або вкажіть 0, щоб залишити без обмеження"
      >
        <Form.Item name="dailyCap" label="Денний кап">
          <Input
            placeholder="Денний кап"
            type="number"
            rootClassName={styles.input}
            disabled={!hasPermissions(role, bizdevTeam.concat(fullstackTeam))}
          />
        </Form.Item>
      </Tooltip>
      <Tooltip
        name="cap"
        title="Залиште пустим, або вкажіть 0, щоб залишити без обмеження"
      >
        <Form.Item name="cap" label="Кап">
          <Input
            placeholder="Кап"
            type="number"
            rootClassName={styles.input}
            disabled={!hasPermissions(role, bizdevTeam.concat(fullstackTeam))}
          />
        </Form.Item>
      </Tooltip>
      {order && (
        <Form.Item name="createdAt" label="Створено">
          <Input disabled placeholder="Створено" rootClassName={styles.input} />
        </Form.Item>
      )}
      {order && (
        <Form.Item name="updatedAt" label="Оновлено">
          <Input disabled placeholder="Оновлено" rootClassName={styles.input} />
        </Form.Item>
      )}
      <Form.Item
        name="isActive"
        label="Активнe"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Checkbox
          onClick={onActiveChange}
          defaultChecked={order ? order.isActive : true}
          rootClassName={styles.input}
          disabled={
            !hasPermissions(
              role,
              affiliatesTeam.concat(fullstackTeam).concat(bizdevTeam)
            )
          }
        />
      </Form.Item>
      <Form.Item name="isAllowDuplicate" label="Дозволити дублікати">
        <Checkbox
          onClick={onAllowDuplicatesChange}
          defaultChecked={order ? order.isAllowDuplicates : true}
          rootClassName={styles.input}
          disabled={!hasPermissions(role, affiliatesTeam.concat(fullstackTeam))}
        />
      </Form.Item>

      <Divider />

      <div name="callCenterSchedule" className={styles.schedule}>
        <div className={styles.timezone}>
          <Select
            disabled={!hasPermissions(role, bizdevTeam.concat(fullstackTeam))}
            placeholder="Таймзона"
            style={{ width: 200 }}
            showSearch
            defaultValue={timezone}
            onChange={setTimezone}
            options={timezonesList.map((t) => ({
              value: t.timezone,
              label: t.name,
              key: t.timezone,
            }))}
          />
        </div>
        <div className={styles.scheduleContainer}>
          <div className={styles.scheduleDays}>
            {days.map((day) => {
              return (
                <ScheduleItem
                  disabled={
                    !hasPermissions(role, bizdevTeam.concat(fullstackTeam))
                  }
                  order={order}
                  day={day}
                  onPeriodChange={(e) => {
                    setDays(
                      days.map((d) => {
                        if (d.label === day.label) {
                          return {
                            ...d,
                            to: { h: e[1].$H, m: e[1].$m },
                            from: { h: e[0].$H, m: e[0].$m },
                          };
                        }
                        return d;
                      })
                    );
                  }}
                  onActive={(checked, day, date) => {
                    setDays(
                      days.map((d) => {
                        if (d.label === day.label) {
                          return {
                            ...d,
                            to: { h: date[1].$H, m: date[1].$m },
                            from: { h: date[0].$H, m: date[0].$m },
                            isActive: checked,
                          };
                        }
                        return d;
                      })
                    );
                  }}
                  isActive={day.isActive}
                />
              );
            })}
          </div>
        </div>
        {scheduleError && (
          <div className={styles.scheduleErrorMsg}>Будь ласка, вкажіть час</div>
        )}
      </div>

      {hasPermissions(role, allWorkers) && (
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button type="primary" htmlType="submit">
            Зберегти
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};

const ScheduleItem = ({
  day,
  onPeriodChange,
  onActive,
  isActive,
  disabled,
}) => {
  return (
    <Row key={day.label} className={styles.scheduleItem}>
      <Col offset={8} span={1}>
        {day.label}
      </Col>
      <Col offset={1}>
        <TimePicker.RangePicker
          changeOnBlur
          defaultValue={[
            day.from
              ? dayjs()
                  .minute(day?.from?.m ?? 0)
                  .hour(day?.from?.h ?? 0)
              : dayjs().hour(0).minute(0),
            day.to
              ? dayjs()
                  .minute(day?.to?.m ?? 0)
                  .hour(day?.to?.h ?? 0)
              : dayjs().hour(23).minute(59),
          ]}
          format={'HH:mm'}
          allowClear={false}
          disabled={disabled || !isActive}
          className={styles.timePicker}
          onChange={onPeriodChange}
        />
      </Col>
      <Col>
        <Checkbox
          disabled={disabled}
          defaultChecked={isActive}
          onChange={(e) =>
            onActive(e.target.checked, day, [
              day.from
                ? dayjs()
                    .minute(day?.from?.m ?? 0)
                    .hour(day?.from?.h ?? 0)
                : dayjs().hour(0).minute(0),
              day.to
                ? dayjs()
                    .minute(day?.to?.m ?? 0)
                    .hour(day?.to?.h ?? 0)
                : dayjs().hour(23).minute(59),
            ])
          }
        />
      </Col>
    </Row>
  );
};
