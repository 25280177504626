import api from '../../../../api/api';

export const initialFetchBuyers = async () => {
  const res = await api.get(`/buyer?limit=10&filter.isActive=$eq:true`);
  return res.data.data.map((c) => ({
    label: `#${c.id} ${c.name}`,
    value: c.id,
  }));
};

export const fetchBuyers = async (name) => {
  const idFilter =
    !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
  const res = await api.get(
    `/buyer?filter.name=$ilike:${name}&filter.isActive=$eq:true${idFilter}`
  );
  return res.data.data.map((c) => ({
    label: `#${c.id} ${c.name}`,
    value: c.id,
  }));
};
