import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { PermissionsForm } from '../PermissionsForm/PermissionsForm';

export const EditPermissions = () => {
  const { message } = App.useApp();
  const [permissions, setPermissions] = useState();
  const { id } = useParams();
  usePermissionsAllowed(['SUPER_ADMIN']);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    api.get(`/permissions/${id}`).then((res) => {
      setPermissions({
        ...res.data,
      });
      setIsLoading(false);
    });
  }, []);

  const onFinish = (updatedPermissions) => {
    delete updatedPermissions.updatedAt;
    delete updatedPermissions.createdAt;
    api
      .put(`/permissions/${id}`, {
        ...updatedPermissions,
      })
      .then((_) => {
        navigate(-1);
        message.success('Права оновлено');
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  if (isLoading) return 'loading';

  return <PermissionsForm onFinish={onFinish} permissions={permissions} />;
};
