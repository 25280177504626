import { Tag } from 'antd';
import { translateStatusDetail } from '../../utils/translateStatus.js';
import React from 'react';

export const StatusDetailTag = ({ status, style }) => {
  const statuses = {
    success: 'green',
    partiallySuccessful: 'orange',
    ongoing: 'yellow',
    deleted: 'red',
    error: 'red',
  };
  return (
    <Tag style={style} color={statuses[status]}>
      {translateStatusDetail(status)}
    </Tag>
  );
};
