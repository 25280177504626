import { Button, Form, Input, message, Modal, Select } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import api from '../../../api/api';
import styles from '../../../pages/Users/UsersForm/UsersForm.module.css';
import { CurrentUserContext } from '../../../providers/AuthProvider';
export const ChangeProfileModal = ({ isModalOpen, handleCancel }) => {
  const [form] = Form.useForm();
  const [timezonesList, setTimezonesList] = useState([]);
  const [timezone, setTimezone] = useState();
  const user = useContext(CurrentUserContext);
  useEffect(() => {
    if (user) {
      form.setFieldsValue(user);
    }
    if (isModalOpen) {
      api.get(`/timezones`).then((res) => {
        setTimezonesList(res.data);
      });
    }
  }, [user, isModalOpen]);

  if (!user) return '';
  const onUpdateUser = (data) => {
    api
      .put(`/user/update/${user.id}`, {
        timezone: data.timezone,
        telegram: data.telegram,
      })
      .then((_) => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
        message.success('Користувача оновлено');
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };
  return (
    <Modal
      title="Змінити профіль"
      open={isModalOpen}
      footer={null}
      onCancel={handleCancel}
    >
      <Form
        wrapperCol={{ span: 12, offset: 1 }}
        labelCol={{ span: 7, offset: 1 }}
        form={form}
        onFinish={onUpdateUser}
      >
        <Form.Item
          name="telegram"
          label="Telegram"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Telegram" rootClassName={styles.input} />
        </Form.Item>

        <Form.Item
          name="timezone"
          label="Часовий пояс"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Таймзона"
            style={{ maxWidth: 350 }}
            showSearch
            defaultValue={timezone}
            onChange={setTimezone}
            options={timezonesList.map((t) => ({
              value: t.timezone,
              label: t.name,
              key: t.timezone,
            }))}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button type="primary" htmlType="submit">
            Змінити
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
