import React, { useContext, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { Link } from 'react-router-dom';
import { usePermissionsAllowed } from '../../hooks/usePermissionsAllowed';
import { fromApiToClient } from '../../utils/dateFilters';
import api from '../../api/api';
import { inputToFilter } from '../../utils/inputToFilter';
import { AppTable } from '../../shared/Table/AppTable';
import { ExtendableLinkTableCell } from '../../shared/ExtendableTableCell/ExtendableTableCell';
import { ExtendableTableCellText } from '../../shared/ExtendableTableCell/ExtendableTableCell';
import { CurrentUserContext } from '../../providers/AuthProvider';

export const Logs = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filterValues, setFilterValues] = useState({});
  usePermissionsAllowed(['SUPER_ADMIN']);
  const user = useContext(CurrentUserContext);
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    if (user) {
      setColumns([
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Дані ліда',
          dataIndex: 'leadData',
          key: 'leadData',
          render: (leadData) => (
            <ExtendableTableCellText count={1} data={leadData} />
          ),
        },
        {
          title: 'Лід',
          dataIndex: 'lead',
          key: 'lead',
          render: (lead) =>
            lead?.id ? <Link to={`/leads/${lead.id}`}>{lead.id}</Link> : '',
        },
        {
          title: 'Замовлення',
          dataIndex: 'orders',
          key: 'orders',
          render: (orders) => {
            return Array.isArray(orders) ? (
              <ExtendableLinkTableCell
                count={1}
                data={JSON.parse(orders).map((o) => ({
                  label: `${o.order} - ${o.error}`,
                  url: `/orders/${o.order}`,
                }))}
              />
            ) : (
              orders
            );
          },
        },
        {
          title: 'Створено',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (date) => fromApiToClient(date, user.timezone),
        },
      ]);
    }
  }, [user]);
  useEffect(() => {
    api.get('/log?page=1&limit=10').then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
    });
  }, []);

  useEffect(() => {
    const url = inputToFilter(filterValues, currentPage);
    api.get(`/log?${url}`).then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
      window.scrollTo(0, 0);
    });
  }, [filterValues, currentPage]);

  const onFiltersChange = (name, value) => {
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  };

  const onTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  return (
    <div>
      <AppTable
        filters={GetFilters({ onFiltersChange })}
        columns={columns}
        pagination={{
          total,
          current: currentPage,
          pageSize: 10,
        }}
        onChange={onTableChange}
        onColumnsChange={setColumns}
        data={data}
        dataPrefix="logs"
      />
    </div>
  );
};

const GetFilters = ({ onFiltersChange }) => {
  const user = useContext(CurrentUserContext);
  const onValueChange = (name, value) => {
    onFiltersChange(name, value);
  };
  return [
    {
      label: 'Від',
      type: 'datepicker',
      onChange: (e) => {
        const date = e
          ?.set('hour', 0)
          .set('minute', 0)
          .set('second', 0)
          .tz(user.timezone);
        onFiltersChange('createdAt', `$gte:${date?.toISOString() || ''}`);
      },
      style: {
        width: '130px',
      },
    },
    {
      label: 'До',
      type: 'datepicker',
      onChange: (e) => {
        const date = e
          ?.set('hour', 23)
          .set('minute', 23)
          .set('second', 59)
          .tz(user.timezone);
        onFiltersChange('createdAt', `$lte:${date?.toISOString() || ''}`);
      },
      style: {
        width: '130px',
      },
    },
    {
      label: 'Дані ліда',
      onChange: debounce(
        (e) => onValueChange('leadData', `$ilike:${e.target.value}`),
        300
      ),
    },
  ];
};
