import {
  App,
  Button,
  Checkbox,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  Progress,
  Upload,
} from 'antd';
import React, { useContext, useState } from 'react';
import api from '../../../api/api';
import styles from '../../InjectLeads/InjectLeadsForm/InjectForm.module.css';
import { translateStatus } from '../../../utils/translateStatus';
import { InboxOutlined } from '@ant-design/icons';
import { csvImportExample } from '../../../utils/csvImportExample';
import dayjs from 'dayjs';
import { CurrentUserContext } from '../../../providers/AuthProvider';

const { Dragger } = Upload;

export const ImportLeadsModal = ({ isModalOpen, handleCancel, onSuccess }) => {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const [isWithAutologin, setIsWithAutologin] = useState(true);
  const [isAutoPickProxy, setIsAutoPickProxy] = useState(true);
  const [selectedFile, setSelectedFile] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [startAt, setStartAt] = useState(dayjs());
  const user = useContext(CurrentUserContext);

  const onWithAutologinChange = (e) => {
    setIsWithAutologin(e.target.checked);
  };

  const onAutoPickProxyChange = (e) => {
    setIsAutoPickProxy(e.target.checked);
  };

  const onFileUpload = (e) => {
    // console.log(e);
  };

  const onImport = () => {
    const data = new FormData();
    data.append('file', selectedFile.fileList[0].originFileObj);
    data.append('min', form.getFieldValue('min'));
    data.append('max', form.getFieldValue('max'));
    data.append('isWithAutoLogin', isWithAutologin);
    const date = startAt?.tz(user.timezone);
    data.append('startAt', date.toISOString());
    setIsLoading(true);
    api
      .post('/lead/import', data, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((res) => {
        if (res.data.sessionId) {
          onSuccess(res.data.sessionId);
          setIsLoading(false);
          return;
        }

        setIsLoading(false);
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'import_with_errors.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
        message.error(
          'Файл містить валідаційні помилки. Будь ласка, виправте і завантажте заново'
        );
      })
      .catch((e) => {
        setIsLoading(false);
        message.error(e.response.data?.message);
      });
  };

  const exampleCsv = () => {
    const url = window.URL.createObjectURL(new Blob([csvImportExample]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'import_example.csv');
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  if (!user) return '';

  return (
    <Modal
      title="Імпорт лідів"
      open={isModalOpen}
      footer={null}
      onCancel={handleCancel}
    >
      <div
        style={{ width: '100%', display: 'flex', flexDirection: 'row-reverse' }}
      >
        <Button style={{ width: 115 }} type="link" onClick={exampleCsv}>
          Шаблон файлу
        </Button>
      </div>
      <Form
        wrapperCol={{ span: 12, offset: 1 }}
        labelCol={{ span: 7, offset: 1 }}
        form={form}
        onFinish={onImport}
      >
        <Form.Item
          name="file"
          style={{ marginTop: 15 }}
          wrapperCol={{ span: 24, offset: 1 }}
          labelCol={{ span: 12, offset: 1 }}
          rules={[
            {
              validator: (rule, value, callback, source, options) => {
                if (!selectedFile) callback('Будь ласка, виберіть файл');
                return callback();
              },
            },
          ]}
        >
          <Dragger
            name="file"
            onChange={(e) => setSelectedFile(e)}
            beforeUpload={() => false}
            multiple={false}
            maxCount={1}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Клікніть або перетягніть файл для завантаження
            </p>
          </Dragger>
        </Form.Item>
        {/* <Form.Item
          name="startAt"
          label="Початок"
          style={{ marginTop: 15 }}
          rules={[
            {
              validator: (rule, value, callback) => {
                if (!startAt) callback('Будь ласка, виберіть час початку');
                return callback();
              },
            },
          ]}
        >
          <DatePicker
            defaultValue={dayjs().tz(user.timezone)}
            showTime
            onChange={setStartAt}
            placeholder="Початок"
            changeOnBlur
            allowClear={false}
          />
        </Form.Item> */}

        <Form.Item
          name="startAt"
          label="Початок"
          style={{ marginTop: 15 }}
          rules={[
            {
              validator: (rule, value, callback) => {
                if (!startAt) callback('Будь ласка, виберіть час початку');
                return callback();
              },
            },
          ]}
        >
          <DatePicker
            defaultValue={dayjs().tz(user.timezone)}
            showTime
            onChange={setStartAt}
            placeholder="Початок"
            changeOnBlur
            allowClear={false}
          />
        </Form.Item>
        <Form.Item
          name="min"
          label="Мінімальний інтервал (хв)"
          style={{ marginTop: 15 }}
          wrapperCol={{ span: 12, offset: 1 }}
          labelCol={{ span: 12, offset: 1 }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber placeholder="0" min={0} max={60} />
        </Form.Item>
        <Form.Item
          name="max"
          label="Максимальний інтервал (хв)"
          style={{ marginTop: 15 }}
          wrapperCol={{ span: 12, offset: 1 }}
          labelCol={{ span: 12, offset: 1 }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber placeholder="60" min={0} max={60} />
        </Form.Item>
        <Form.Item wrapperCol={{ span: 24, offset: 1 }} name="isWithAutologin">
          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              gap: 10,
              justifyContent: 'center',
            }}
          >
            <div>Симулювати автологін</div>
            <Checkbox
              onClick={onWithAutologinChange}
              defaultChecked={true}
              rootClassName={styles.input}
            />
          </div>
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 10 }}>
          <Button type="primary" htmlType="submit" disabled={isLoading}>
            Відправити
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
