import { App, Button, Form, Input, Modal } from 'antd';
import React from 'react';
import api from '../../../api/api';
import { useNavigate, useParams } from 'react-router-dom';

export const CloneCallStatusGroupModal = ({ isOpen, onCancel }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const { message } = App.useApp();

  const onApiCall = () => {
    api
      .post(`/call-status-group/copy/${id}`, {
        name: form.getFieldValue('name'),
      })
      .then((res) => {
        message.success('Call статус групу клоновано');
        navigate(`/call-status-groups/${res.data}`);
        onCancel();
      });
  };

  return (
    <Modal
      title="Скопіювати групу Call статусів"
      open={isOpen}
      footer={null}
      onCancel={onCancel}
    >
      <Form
        wrapperCol={{ span: 12, offset: 1 }}
        labelCol={{ span: 7, offset: 1 }}
        form={form}
        onFinish={onApiCall}
      >
        <Form.Item
          name="name"
          label="Нова назва"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Нова назва" />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button type="primary" htmlType="submit">
            Зберегти
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
