import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { App, Divider, Tabs } from 'antd';
import api from '../../../api/api';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { BoxForm } from '../BoxForm/BoxForm';
import { BoxOrders } from '../BoxOrders/BoxOrders';

export const EditBox = () => {
  const { message } = App.useApp();
  const [box, setBox] = useState();
  const { id } = useParams();
  const affiliatesTeam = [
    'AFFILIATE',
    'AFFILIATE_TEAM_LEAD',
    'AFFILIATE_SUPPORT',
  ];
  const fullstackTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  usePermissionsAllowed(affiliatesTeam.concat(fullstackTeam).concat('ANALYST'));
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    api.get(`/box/${id}`).then((res) => {
      setBox({
        ...res.data,
      });
      setIsLoading(false);
    });
  }, []);

  const onFinish = (updatedBox) => {
    delete updatedBox.updatedAt;
    api
      .put(`/box/${id}`, {
        ...updatedBox,
      })
      .then((_) => {
        navigate(-1);
        message.success('Бокс оновлено');
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  const tabs = [
    {
      key: '1',
      label: `Бокс`,
      children: <BoxForm onFinish={onFinish} box={box} />,
    },
    {
      key: '2',
      label: 'Замовлення',
      children: <BoxOrders box={box} />,
    },
  ];

  if (isLoading) return 'loading';

  return (
    <div>
      <BoxForm onFinish={onFinish} box={box} />
      <Divider orientation="center">Замовлення</Divider>
      <BoxOrders box={box} />
    </div>
  );
};
