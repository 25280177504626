import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { RegwayForm } from '../RegwayForm/RegwayForm';

export const EditRegway = () => {
  const { message } = App.useApp();
  const [regway, setRegway] = useState();
  const { id } = useParams();
  usePermissionsAllowed(['SUPPORT', 'TEAM_LEAD']);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    api.get(`/domain-purchase/regway/${id}`).then((res) => {
      setRegway({
        ...res.data,
      });
      setIsLoading(false);
    });
  }, []);

  const onFinish = (updatedRegway) => {
    delete updatedRegway.updatedAt;
    delete updatedRegway.createdAt;
    api
      .put(`/domain-purchase/regway/${id}`, {
        ...updatedRegway,
      })
      .then((_) => {
        navigate(-1);
        message.success('Regway оновлено');
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  if (isLoading) return 'loading';

  return <RegwayForm onFinish={onFinish} regway={regway} />;
};
