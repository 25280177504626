import { useContext, useEffect, useState } from 'react';
import { CurrentUserContext } from '../../../providers/AuthProvider';
import { useSearchParams } from 'react-router-dom';
import { fetchBuyers, initialFetchBuyers } from './api/getBuyers';
import { initialFetchCustomers, fetchCustomers } from './api/getCustomers';
import debounce from 'lodash.debounce';
import {
  AFFILIATE,
  AFFILIATE_SUPPORT,
  AFFILIATE_TEAM_LEAD,
  BIZDEV,
  BIZDEV_SUPPORT,
  BIZDEV_TEAM_LEAD,
} from '../../../utils/roles';

export const GetFilters = ({ onFiltersChange, onFiltersSet, querystr }) => {
  const currentUser = useContext(CurrentUserContext);
  const user = useContext(CurrentUserContext);
  const [buyers, setBuyers] = useState(
    querystr?.hasOwnProperty('buyers') ? querystr.buyers[0] : []
  );
  const [customers, setCustomers] = useState(
    querystr?.hasOwnProperty('customer') ? querystr.customer[0] : []
  );
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (querystr) {
      onFiltersSet(querystr);
    }
  }, []);

  const affiliatesTeam = [AFFILIATE, AFFILIATE_TEAM_LEAD, AFFILIATE_SUPPORT];
  const bizdevsTeam = [BIZDEV, BIZDEV_TEAM_LEAD, BIZDEV_SUPPORT];

  return [
    {
      label: 'ID',
      dataIndex: 'id',
      type: 'number',
      defaultValue: querystr?.hasOwnProperty('id') ? querystr.id[0] : '',
      onChange: debounce((e) => {
        onFiltersChange('id', `$eq:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            id: [e.target.value, 'id', `$eq:${e.target.value}`],
          }),
        });
      }, 300),
      style: {
        width: '80px',
      },
    },
    {
      label: 'Назва',
      dataIndex: 'name',
      defaultValue: querystr?.hasOwnProperty('name') ? querystr.name[0] : '',
      onChange: debounce((e) => {
        onFiltersChange('name', `$ilike:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            name: [e.target.value, 'name', `$ilike:${e.target.value}`],
          }),
        });
      }, 300),
    },
    {
      label: 'Замовник',
      dataIndex: 'customer',
      type: 'multiselect',
      onChange: (e) => {
        setCustomers(e);
        e.forEach((d) => {
          delete d.disabled;
          delete d.title;
          delete d.key;
        });
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            customer: [
              e,
              'customer.id',
              `$in:${e.map((u) => u.value).join(',')}`,
            ],
          }),
        });
        onFiltersChange(
          'customer.id',
          `$in:${e.map((o) => o.value).join(',')}`
        );
      },
      fetch: fetchCustomers,
      initialFetch: initialFetchCustomers,
      value: customers,
      style: {
        width: '130px',
      },
      hide: currentUser ? affiliatesTeam.includes(currentUser.role) : true,
    },
    {
      label: 'Баєр',
      dataIndex: 'buyers',
      type: 'multiselect',
      onChange: (e) => {
        setBuyers(e);
        e.forEach((d) => {
          delete d.disabled;
          delete d.title;
          delete d.key;
        });
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            buyers: [e, 'buyers.id', `$in:${e.map((u) => u.value).join(',')}`],
          }),
        });
        onFiltersChange('buyers.id', `$in:${e.map((u) => u.value).join(',')}`);
      },
      fetch: fetchBuyers,
      initialFetch: initialFetchBuyers,
      value: buyers,
      style: {
        width: '130px',
      },
      hide: currentUser ? bizdevsTeam.includes(currentUser.role) : true,
    },
    {
      label: 'Тип оплати',
      dataIndex: 'paymentType',
      value: querystr?.hasOwnProperty('paymentType') && querystr.paymentType[0],
      type: 'select',
      onChange: (e) => {
        onFiltersChange('paymentType', `${e?.length ? `$eq:${e}` : '$ilike:'}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            paymentType: [
              e,
              'paymentType',
              `${e?.length ? `$eq:${e}` : '$ilike:'}`,
            ],
          }),
        });
      },
      options: [
        { value: 'CPA', label: 'CPA' },
        { value: 'CPL', label: 'CPL' },
      ],
      style: {
        width: '130px',
      },
    },
    {
      label: 'Форма оплати',
      dataIndex: 'paymentForm',
      value: querystr?.hasOwnProperty('paymentForm') && querystr.paymentForm[0],
      type: 'select',
      onChange: (e) => {
        onFiltersChange('paymentForm', `${e?.length ? `$eq:${e}` : '$ilike:'}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            paymentForm: [
              e,
              'paymentForm',
              `${e?.length ? `$eq:${e}` : '$ilike:'}`,
            ],
          }),
        });
      },
      options: [
        { value: 'PREPAY', label: 'PREPAY' },
        { value: 'POSTPAY', label: 'POSTPAY' },
      ],
      style: {
        width: '130px',
      },
    },
    {
      label: 'Від',
      dataIndex: 'startedAt',
      type: 'datepicker',
      value:
        querystr?.hasOwnProperty('createdAtFrom') && querystr.createdAtFrom[0],
      onChange: (e) => {
        const date = e
          ?.set('hour', 0)
          .set('minute', 0)
          .set('second', 0)
          .tz(user.timezone);
        onFiltersChange('createdAt', `$gte:${date?.toISOString() || ''}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            createdAtFrom: [
              date?.toISOString(),
              'createdAt',
              `$gte:${date?.toISOString() || ''}`,
            ],
          }),
        });
      },
      style: {
        width: '130px',
      },
    },
    {
      label: 'До',
      dataIndex: 'endedAt',
      type: 'datepicker',
      value: querystr?.hasOwnProperty('createdAtTo') && querystr.createdAtTo[0],
      onChange: (e) => {
        const date = e
          ?.set('hour', 23)
          .set('minute', 23)
          .set('second', 59)
          .tz(user.timezone);
        onFiltersChange('createdAt', `$lte:${date?.toISOString() || ''}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            createdAtTo: [
              date?.toISOString(),
              'createdAt',
              `$lte:${date?.toISOString() || ''}`,
            ],
          }),
        });
      },
      style: {
        width: '130px',
      },
    },
    {
      label: 'Стан',
      dataIndex: 'isActive',
      type: 'checkbox',
      defaultChecked: querystr?.hasOwnProperty('isActive')
        ? querystr.isActive[0]
        : true,
      onChange: (e) => {
        if (e.target.checked) {
          onFiltersChange('isActive', `$eq:${e.target.checked}`);
        } else {
          onFiltersChange('isActive', `$ilike:`);
        }
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            isActive: [
              e.target.checked,
              'isActive',
              e.target.checked ? `$eq:${e.target.checked}` : '$ilike:',
            ],
          }),
        });
      },
    },
  ];
};
