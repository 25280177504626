import { Button, Checkbox, Form, Input } from 'antd';
import styles from './CustomersForm.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import { CountrySelect } from '../../../shared/CountryMultiselect/CountrySelect';
import {
  hasPermissions,
  usePermissions,
} from '../../../hooks/usePermissionsAllowed';
import api from '../../../api/api';
import { CurrentUserContext } from '../../../providers/AuthProvider';
import { AutocompleteSelect } from '../../../shared/AutocompleteSelect/AutocompleteSelect';

export const CustomersForm = ({ customer, onFinish }) => {
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState();
  const [countries, setCountries] = useState();
  const [selectedBizdev, setSelectedBizdev] = useState();
  const role = usePermissions();
  const currentUser = useContext(CurrentUserContext);
  const user = useContext(CurrentUserContext);
  const bizdevsTeam = ['BIZDEV', 'BIZDEV_TEAM_LEAD', 'BIZDEV_SUPPORT'];
  const fullstackTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];

  useEffect(() => {
    if (customer) {
      form.setFieldsValue(customer);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(customer.createdAt, user?.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(customer.updatedAt, user?.timezone)
      );
      if (customer.bizdev) {
        setSelectedBizdev({
          value: customer.bizdev.id,
          label: customer.bizdev.nickname,
          key: customer.bizdev.id,
        });
        form.setFieldValue('bizdev', {
          value: customer.bizdev.id,
          label: customer.bizdev.nickname,
          key: customer.bizdev.id,
        });
      }
      setIsActive(customer.isActive);
    }
  }, [customer]);

  useEffect(() => {
    if (currentUser) {
      if (['BIZDEV', 'FULLSTACK'].includes(currentUser.role)) {
        setSelectedBizdev({
          value: currentUser.id,
          label: currentUser.nickname,
          key: currentUser.id,
        });
        form.setFieldValue('bizdev', {
          value: currentUser.id,
          label: currentUser.nickname,
          key: currentUser.id,
        });
      }
    }
  }, [currentUser]);

  const onActiveChange = (e) => {
    setIsActive(e.target.checked);
  };

  const onCountryChange = (value) => {
    setCountries(value);
    form.setFieldValue('countries', value);
  };

  const fetchBizdevs = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:BIZDEV&filter.role=$or:$eq:FULLSTACK&filter.name=$ilike:${name}${idFilter}`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: `#${u.id} ${u.name}`,
        value: u.id,
      }));
  };

  const initialFetchBizdevs = async (name) => {
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:BIZDEV&filter.role=$or:$eq:FULLSTACK`
    );
    return res.data.data.map((u) => ({
      label: `#${u.id} ${u.name}`,
      value: u.id,
    }));
  };

  return (
    <Form
      form={form}
      onFinish={() => {
        onFinish({
          name: form.getFieldValue('name'),
          bizdevId: selectedBizdev?.value || undefined,
          country: countries,
          isActive,
        });
      }}
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
    >
      <Form.Item
        name="name"
        label="Ім'я"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input
          disabled={!hasPermissions(role, bizdevsTeam.concat(fullstackTeam))}
          placeholder="Ім'я"
          rootClassName={styles.input}
        />
      </Form.Item>
      <Form.Item
        name="country"
        label="Країна"
        rules={[
          {
            required: true,
            max: 2,
          },
        ]}
      >
        <CountrySelect
          disabled={!hasPermissions(role, bizdevsTeam.concat(fullstackTeam))}
          onChange={onCountryChange}
          className={styles.input}
        />
      </Form.Item>
      <Form.Item
        name="bizdev"
        label="Біздев"
        rules={[
          {
            required: [
              'BIZDEV_TEAM_LAED',
              'BIZDEV_SUPPORT',
              'FULLSTACK_TEAM_LEAD',
              'FULLSTACK_SUPPORT',
            ].includes(currentUser?.role),
          },
        ]}
      >
        <AutocompleteSelect
          className={styles.input}
          value={selectedBizdev}
          placeholder="Біздев"
          initialFetch={initialFetchBizdevs}
          fetch={fetchBizdevs}
          onChange={setSelectedBizdev}
          disabled={
            !hasPermissions(role, [
              'BIZDEV_TEAM_LEAD',
              'BIZDEV_SUPPORT',
              'FULLSTACK_TEAM_LEAD',
              'FULLSTACK_SUPPORT',
            ])
          }
        />
      </Form.Item>
      <Form.Item name="isActive" label="Активний">
        <Checkbox
          disabled={!hasPermissions(role, bizdevsTeam.concat(fullstackTeam))}
          onClick={onActiveChange}
          defaultChecked={customer?.isActive}
          rootClassName={styles.input}
        />
      </Form.Item>
      {hasPermissions(role, bizdevsTeam.concat(fullstackTeam)) && (
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button type="primary" htmlType="submit">
            Зберегти
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};
