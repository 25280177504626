import { Tag } from 'antd';
import React from 'react';

export const CallStatusCategoryTag = ({ callStatusCategory, style }) => {
  return (
    <Tag style={style}>
      {callStatusCategory.parentCategory
        ? `${callStatusCategory.parentCategory.name} - ${callStatusCategory.name}`
        : callStatusCategory.childrenCategories?.length
        ? `${callStatusCategory.name} (без підкатегорії)`
        : callStatusCategory.name}
    </Tag>
  );
};
