import React, { useContext, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { ThemeContext } from '../../../providers/ThemeProvider';
import { AppTable } from '../../../shared/Table/AppTable';
import api from '../../../api/api';
import {
  inputToFilter,
  inputToFilterExport,
  sorterValue,
} from '../../../utils/inputToFilter';
import { Link, useSearchParams } from 'react-router-dom';
import {
  fromApiToClient,
  fromApiToClientTimePlusShift,
  checkIsTimeInInterval,
} from '../../../utils/dateFilters';
import {
  cellBackgroundColor,
  colorList,
} from '../../../utils/cellBackgroundColor';
import {
  hasPermissions,
  usePermissions,
} from '../../../hooks/usePermissionsAllowed';
import { PermissionalLink } from '../../../shared/PermissionalLink/PermissionalLink';
import {
  LockOutlined,
  CheckOutlined,
  RedoOutlined,
  HistoryOutlined,
  MinusOutlined,
  StopOutlined,
} from '@ant-design/icons';
import {
  App,
  Button,
  Pagination,
  Row,
  Spin,
  Tooltip,
  Progress,
  message,
  Dropdown,
} from 'antd';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';
import { StatusTag } from '../../../shared/StatusTag/StatusTag';
import { ChangeStatusModal } from './ChangeStatusModal';
import { ResendLeadsModal } from './ResendLeadsModal';
import { InjectLeadsModal } from './InjectLeadsModal';
import { CurrentUserContext } from '../../../providers/AuthProvider';
import { TooltipList } from '../../../shared/TooltipList/TooltipList';
import { ImportLeadsModal } from './ImportLeadsModal';
import { socket } from '../../../api/socket';
import { ExtendableTextTableCell } from '../../../shared/ExtendableTableCell/ExtendableTableCell';
import { CallStatusCategoryTag } from '../../../shared/CallStatusCategoryTag/CallStatusCategoryTag';
import FraudIPTag from '../../../shared/FraudIPCell/FraudIPTag';
import { DetailsModalFrod } from './DetailsModalFrod';
import {
  AFFILIATE,
  AFFILIATE_SUPPORT,
  AFFILIATE_TEAM_LEAD,
  ANALYST,
  BIZDEV,
  BIZDEV_SUPPORT,
  BIZDEV_TEAM_LEAD,
  FULLSTACK,
  FULLSTACK_SUPPORT,
  FULLSTACK_TEAM_LEAD,
  SUPER_ADMIN,
} from '../../../utils/roles';

ExtendableTextTableCell.propTypes = {};
export const Lids = (props) => {
  const [searchParams] = useSearchParams();
  const [isExportLoading, setIsExportLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(
    JSON.parse(localStorage.getItem('pageSize')) ?? 10
  );
  const [total, setTotal] = useState(0);
  const [filterValues, setFilterValues] = useState({});
  const [isStatusChecked, setIsStatusChecked] = useState(false);
  const [selectedLeads, setSelectedLeads] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isColoredOfCells, setIsColoredOfCells] = useState(false);
  const [isResendModalOpen, setIsResendModalOpen] = useState(false);
  const [isInjectModalOpen, setIsInjectModalOpen] = useState(false);
  const [filterIds, setFiltersIds] = useState([]);
  const [leadsStatusUpdating, setIsLeadsStatusUpdating] = useState(false);
  const [updatedLeads, setUpdatedLeads] = useState([]);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const role = usePermissions();
  const { isDarkTheme } = useContext(ThemeContext);
  const url = searchParams.get('filters');
  const [sorter, setSorter] = useState('');
  const [setableLeadsList, setSetableLeadsList] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState('{}');

  const affiliatesTeam = [AFFILIATE, AFFILIATE_TEAM_LEAD, AFFILIATE_SUPPORT];
  const bizdevsTeam = [BIZDEV, BIZDEV_TEAM_LEAD, BIZDEV_SUPPORT];
  const fullstackTeam = [FULLSTACK, FULLSTACK_TEAM_LEAD, FULLSTACK_SUPPORT];
  const user = useContext(CurrentUserContext);
  const querystr = JSON.parse(url) ? JSON.parse(url) : null;
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const leadList = { green: [], yellow: [], blue: [], purple: [] };
    for (const lead of data) {
      if (!['error', 'unsigned', 'test'].includes(lead.status)) {
        if (
          (!lead.useFakeCallStatus &&
            checkIsTimeInInterval(lead.lastCallStatusAt, 30, 5)) ||
          (!lead.useFakeIsDeposit &&
            checkIsTimeInInterval(lead.depositAt, 30, 5))
        )
          leadList.yellow.push(lead.id);
        else if (
          (!lead.useFakeCallStatus &&
            checkIsTimeInInterval(lead.lastCallStatusAt, 30)) ||
          (!lead.useFakeIsDeposit && checkIsTimeInInterval(lead.depositAt, 30))
        )
          leadList.green.push(lead.id);
        else if (lead.useFakeCallStatus || lead.useFakeIsDeposit)
          leadList.blue.push(lead.id);
        else if (!lead.callStatus) leadList.purple.push(lead.id);
      }
    }
    setSetableLeadsList(leadList);
  }, [data]);

  useEffect(() => {
    if (user) {
      const allowedFilters = JSON.parse(user.permissions.columns).leads;
      socket.on('update-import-progress', (msg) => {
        if (user.id == msg.userId) onProgressUpdate(msg.perc, msg.sessionId);
      });
      setColumns([
        {
          title: <span className="dragHandler">ID</span>,
          dataIndex: 'id',
          key: 'id',
          sorter: true,
          render(id) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(id, setableLeadsList),
                },
              },
              children: <Link to={`/leads/${id}`}>{id}</Link>,
            };
          },
        },
        {
          title: <span className="dragHandler">Ім'я</span>,
          dataIndex: 'name',
          key: 'name',
          sorter: true,
          render(name, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: (
                <ExtendableTextTableCell data={name} maxFullStringLength={25} />
              ),
            };
          },
        },
        {
          title: <span className="dragHandler">Прізвище</span>,
          dataIndex: 'lastName',
          key: 'lastName',
          sorter: true,
          render(lastName, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: (
                <ExtendableTextTableCell
                  data={lastName}
                  maxFullStringLength={25}
                />
              ),
            };
          },
        },
        {
          title: <span className="dragHandler">Телефон</span>,
          dataIndex: 'phone',
          key: 'phone',
          sorter: true,
          render(phone, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: <>{phone}</>,
            };
          },
        },
        {
          title: <span className="dragHandler">Email</span>,
          dataIndex: 'email',
          key: 'email',
          sorter: true,
          render(email, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: <>{email}</>,
            };
          },
        },
        {
          title: <span className="dragHandler">Ціна</span>,
          dataIndex: 'price',
          key: 'price',
          sorter: true,
          render(price, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: <>{price}</>,
            };
          },
        },
        {
          title: <span className="dragHandler">Бокс</span>,
          dataIndex: 'box',
          key: 'box',
          sorter: true,
          render(box, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: (
                <PermissionalLink
                  label={box && `#${lead?.box?.id} ${box.name}`}
                  url={`/boxes/${lead?.box?.id}`}
                  permissions={affiliatesTeam
                    .concat(fullstackTeam)
                    .concat('ANALYST')}
                />
              ),
            };
          },
        },
        {
          title: <span className="dragHandler">Замовлення</span>,
          dataIndex: 'order',
          key: 'order',
          sorter: true,
          render(order, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: (
                <Link to={`/orders/${lead.order?.id}`}>
                  {order && `#${order.id} ${order.name}`}
                </Link>
              ),
            };
          },
        },
        {
          title: <span className="dragHandler">Замовник</span>,
          dataIndex: 'order',
          key: 'customer',
          sorter: true,
          render(order, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: (
                <PermissionalLink
                  label={
                    order?.customer &&
                    `#${order.customer.id} ${order.customer.name}`
                  }
                  url={`/customers/${lead.order?.customer?.id}`}
                  permissions={bizdevsTeam
                    .concat(fullstackTeam)
                    .concat(['ANALYST', 'SUPER_ADMIN'])}
                />
              ),
            };
          },
        },
        {
          title: <span className="dragHandler">Баєр</span>,
          dataIndex: 'buyer',
          key: 'buyer',
          sorter: true,
          render(buyer, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: (
                <PermissionalLink
                  label={buyer && `#${buyer.id} ${buyer.name}`}
                  url={`/buyers/${lead.buyer?.id}`}
                  permissions={affiliatesTeam
                    .concat(fullstackTeam)
                    .concat(['SUPER_ADMIN', 'ANALYST'])}
                />
              ),
            };
          },
        },
        {
          title: <span className="dragHandler">Аффілейт</span>,
          dataIndex: 'order',
          key: 'affiliate',
          sorter: true,
          render(order, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: order?.affiliate ? (
                <PermissionalLink
                  label={`#${order.affiliate.id} ${order.affiliate.nickname}`}
                  url={`/user/${lead.order?.bizdev?.id}`}
                  permissions={[
                    BIZDEV_TEAM_LEAD,
                    FULLSTACK_TEAM_LEAD,
                    SUPER_ADMIN,
                  ]}
                />
              ) : lead.box?.affiliate ? (
                <PermissionalLink
                  label={`#${lead.box.affiliate.id} ${lead.box.affiliate.nickname}`}
                  url={`/user/${lead.box.affiliate.id}`}
                  permissions={[
                    BIZDEV_TEAM_LEAD,
                    FULLSTACK_TEAM_LEAD,
                    SUPER_ADMIN,
                  ]}
                />
              ) : lead.buyer?.affiliate ? (
                <PermissionalLink
                  label={`#${lead.buyer.affiliate.id} ${lead.buyer.affiliate.nickname}`}
                  url={`/user/${lead.buyer.affiliate.id}`}
                  permissions={[
                    BIZDEV_TEAM_LEAD,
                    FULLSTACK_TEAM_LEAD,
                    SUPER_ADMIN,
                  ]}
                />
              ) : (
                ''
              ),
            };
          },
        },
        {
          title: <span className="dragHandler">Біздев</span>,
          dataIndex: 'order',
          key: 'bizdev',
          sorter: true,
          render(order, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: (
                <PermissionalLink
                  label={
                    order?.bizdev &&
                    `#${order.bizdev.id} ${order.bizdev.nickname}`
                  }
                  url={`/user/${lead.order?.bizdev?.id}`}
                  permissions={[
                    BIZDEV_TEAM_LEAD,
                    FULLSTACK_TEAM_LEAD,
                    SUPER_ADMIN,
                  ]}
                />
              ),
            };
          },
        },
        {
          title: <span className="dragHandler">IP</span>,
          dataIndex: 'ip',
          key: 'ip',
          sorter: true,
          render(ip, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: <>{ip}</>,
            };
          },
        },
        {
          title: <span className="dragHandler">User Agent</span>,
          dataIndex: 'userAgent',
          key: 'userAgent',
          hide: true,
          sorter: true,
          render(userAgent, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: <ExtendableTextTableCell data={userAgent} />,
            };
          },
        },
        {
          title: <span className="dragHandler">Країна</span>,
          dataIndex: 'country',
          key: 'country',
          sorter: true,
          render(country, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: <>{country}</>,
            };
          },
        },
        {
          title: <span className="dragHandler">Мова</span>,
          dataIndex: 'lang',
          key: 'lang',
          sorter: true,
          render(lang, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: <>{lang}</>,
            };
          },
        },
        {
          title: <span className="dragHandler">Статус</span>,
          dataIndex: 'status',
          key: 'status',
          sorter: true,
          render(status, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: <StatusTag status={status} />,
            };
          },
        },
        {
          title: <span className="dragHandler">Call статус</span>,
          dataIndex: 'callStatus',
          key: 'callStatus',
          sorter: true,
          render(callStatus, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: (
                <CallStatusHistoryCell
                  callStatus={callStatus}
                  callStatusHistory={lead.callStatusHistory}
                />
              ),
            };
          },
        },
        {
          title: <span className="dragHandler">Поточний Call статус</span>,
          dataIndex: 'currentCallStatus',
          key: 'currentCallStatus',
          sorter: true,
          render(currentCallStatus, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: (
                <CurrentCallStatusCell
                  currentCallStatus={currentCallStatus}
                  useFakeCallStatus={lead.useFakeCallStatus}
                  nextCallStatus={lead.nextCallStatus}
                  nextCallStatusTime={lead.nextCallStatusTime}
                  timezone={user.timezone}
                />
              ),
            };
          },
        },
        {
          title: <span className="dragHandler">Автологін</span>,
          dataIndex: 'isAutoLogin',
          key: 'isAutoLogin',
          sorter: true,
          render(isAutoLogin, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: isAutoLogin && <CheckOutlined />,
            };
          },
        },
        {
          title: <span className="dragHandler">Автологін дата</span>,
          dataIndex: 'autoLoginAt',
          key: 'autoLoginAt',
          sorter: true,
          render(date, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: fromApiToClient(date, user.timezone),
            };
          },
        },
        {
          title: <span className="dragHandler">Автологін Ip</span>,
          dataIndex: 'autologinIp',
          sorter: true,
          key: 'autologinIp',
          render(autologinIp, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: <>{autologinIp}</>,
            };
          },
        },
        {
          title: <span className="dragHandler">Фрод IP</span>,
          dataIndex: 'frodIp',
          key: 'frodIp',
          sorter: true,
          render: (frodIP, lead) => {
            if (!frodIP) {
              return {
                props: {
                  style: {
                    backgroundColor:
                      isColoredOfCells &&
                      cellBackgroundColor(lead.id, setableLeadsList),
                  },
                },
                children: <></>,
              };
            }
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: (
                <FraudIPTag
                  checkIP={JSON.parse(frodIP)}
                  setIsModalOpen={() => setIsOpen(true)}
                  setSelectedData={() => setSelectedData(frodIP)}
                />
              ),
            };
          },
        },
        {
          title: <span className="dragHandler">Інджект</span>,
          dataIndex: 'isInjected',
          key: 'isInjected',
          sorter: true,
          render(isInjected, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: isInjected && <CheckOutlined />,
            };
          },
        },
        {
          title: <span className="dragHandler">Заінджевктив</span>,
          dataIndex: 'injected_by',
          key: 'injected_by',
          sorter: true,
          render(user, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: (
                <PermissionalLink
                  label={lead?.user?.nickname}
                  url={`/users/${lead?.user?.id}`}
                  permissions={['SUPER_ADMIN']}
                />
              ),
            };
          },
        },
        {
          title: <span className="dragHandler">Зовнішній ID</span>,
          dataIndex: 'externalId',
          key: 'externalId',
          sorter: true,
          render(externalId, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                  minWidth: 200,
                },
              },
              children: <>{externalId}</>,
            };
          },
        },
        {
          title: <span className="dragHandler">Депозит</span>,
          dataIndex: 'isDeposit',
          key: 'isDeposit',
          sorter: true,
          render(isDeposit, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: isDeposit && <CheckOutlined />,
            };
          },
        },
        {
          title: <span className="dragHandler">Поточний депозит</span>,
          dataIndex: 'currentIsDeposit',
          key: 'currentIsDeposit',
          sorter: true,
          render(currentIsDeposit, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: (
                <CurrentIsDepositCell
                  currentIsDeposit={currentIsDeposit}
                  useFakeIsDeposit={lead.useFakeIsDeposit}
                  depositAt={lead.depositAt}
                  timezone={user.timezone}
                />
              ),
            };
          },
        },
        {
          title: <span className="dragHandler">Депозит дата</span>,
          dataIndex: 'depositAt',
          key: 'depositAt',
          sorter: true,
          render(date, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                  minWidth: 140,
                },
              },
              children: <>{fromApiToClient(date, user.timezone)}</>,
            };
          },
        },
        {
          title: <span className="dragHandler">sub1</span>,
          dataIndex: 'sub1',
          key: 'sub1',
          sorter: true,
          render(sub1, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: <ExtendableTextTableCell data={sub1} />,
            };
          },
        },
        {
          title: <span className="dragHandler">sub2</span>,
          dataIndex: 'sub2',
          key: 'sub2',
          sorter: true,
          render(sub2, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: <ExtendableTextTableCell data={sub2} />,
            };
          },
        },
        {
          title: <span className="dragHandler">sub3</span>,
          dataIndex: 'sub3',
          key: 'sub3',
          sorter: true,
          render(sub3, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: <ExtendableTextTableCell data={sub3} />,
            };
          },
        },
        {
          title: <span className="dragHandler">sub4</span>,
          dataIndex: 'sub4',
          key: 'sub4',
          sorter: true,
          render(sub4, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: <ExtendableTextTableCell data={sub4} />,
            };
          },
        },
        {
          title: <span className="dragHandler">sub5</span>,
          dataIndex: 'sub5',
          key: 'sub5',
          sorter: true,
          render(sub5, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: <ExtendableTextTableCell data={sub5} />,
            };
          },
        },
        {
          title: <span className="dragHandler">sub6</span>,
          dataIndex: 'sub6',
          key: 'sub6',
          sorter: true,
          render(sub6, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: <ExtendableTextTableCell data={sub6} />,
            };
          },
        },
        {
          title: <span className="dragHandler">sub7</span>,
          dataIndex: 'sub7',
          key: 'sub7',
          sorter: true,
          render(sub7, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: <ExtendableTextTableCell data={sub7} />,
            };
          },
        },
        {
          title: <span className="dragHandler">sub8</span>,
          dataIndex: 'sub8',
          key: 'sub8',
          sorter: true,
          render(sub8, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: <ExtendableTextTableCell data={sub8} />,
            };
          },
        },
        {
          title: <span className="dragHandler">Помилка</span>,
          dataIndex: 'unsignedDetails',
          key: 'unsignedDetails',
          sorter: true,
          hide: true,
          render(unsignedDetails, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                },
              },
              children: <ExtendableTextTableCell data={unsignedDetails} />,
            };
          },
        },
        {
          title: <span className="dragHandler">Відправлено</span>,
          dataIndex: 'sentAt',
          key: 'sentAt',
          sorter: true,
          render(date, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                  minWidth: 140,
                },
              },
              children: <>{fromApiToClient(date, user.timezone)}</>,
            };
          },
        },
        {
          title: <span className="dragHandler">Створено</span>,
          dataIndex: 'createdAt',
          key: 'createdAt',
          sorter: true,
          render(date, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                  minWidth: 140,
                },
              },
              children: <>{fromApiToClient(date, user.timezone)}</>,
            };
          },
        },
        {
          title: <span className="dragHandler">Оновлено</span>,
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          sorter: true,
          render(date, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                  minWidth: 140,
                },
              },
              children: <>{fromApiToClient(date, user.timezone)}</>,
            };
          },
        },
        {
          title: <span className="dragHandler">uuid</span>,
          dataIndex: 'uuid',
          key: 'uuid',
          width: 100,
          sorter: true,
          render(uuid, lead) {
            return {
              props: {
                style: {
                  backgroundColor:
                    isColoredOfCells &&
                    cellBackgroundColor(lead.id, setableLeadsList),
                  minWidth: 200,
                },
              },
              children: <>{uuid}</>,
            };
          },
        },
        {
          title: <span className="dragHandler"></span>,
          dataIndex: '',
          key: 'actions',
          render: (_, obj) => {
            if (
              allowedFilters?.includes('status') &&
              allowedFilters?.includes('callStatus')
            ) {
              return (
                <Dropdown.Button menu={menuProps(obj)}>
                  <LeadActionCell
                    lead={obj}
                    onLoadFinish={() => setIsStatusChecked(true)}
                    leadsStatusUpdating={obj?.isStatusLoading}
                    selectedLeads={selectedLeads}
                  />
                </Dropdown.Button>
              );
            } else if (allowedFilters?.includes('status')) {
              return (
                <Dropdown menu={menuProps(obj)}>
                  <Button>...</Button>
                </Dropdown>
              );
            } else if (allowedFilters?.includes('callStatus')) {
              <LeadActionCell
                lead={obj}
                onLoadFinish={() => setIsStatusChecked(true)}
                leadsStatusUpdating={obj?.isStatusLoading}
                selectedLeads={selectedLeads}
              />;
            }
          },
        },
      ]);
    }
  }, [user, isColoredOfCells, setableLeadsList]);

  useDidUpdateEffect(() => {
    localStorage.setItem('pageSize', JSON.stringify(pageSize));
    if (JSON.parse(url).id) {
      setFiltersIds(JSON.parse(url).id[0]);
    }
  }, [url]);

  useEffect(() => {
    const ids = updatedLeads;
    setData(
      data.map((d) => ({
        ...d,
        isStatusLoading:
          leadsStatusUpdating && ids.find((sl) => sl?.id === d?.id),
      }))
    );
  }, [leadsStatusUpdating]);

  useEffect(() => {
    let preparedQuery = '';
    if (!url) {
      preparedQuery = '';
    }

    for (let i in querystr) {
      preparedQuery += `filter.${querystr[i][1]}=$and:${querystr[i][2]}&`;
    }

    api
      .get('/lead?' + preparedQuery + `page=1&limit=${pageSize}`)
      .then((res) => {
        setData(res.data.data);
        setTotal(res.data.meta.totalItems);
      });
  }, []);

  useDidUpdateEffect(() => {
    getData(filterValues, currentPage, pageSize);
  }, [filterValues, currentPage, pageSize]);

  useEffect(() => {
    if (isStatusChecked) {
      getData(filterValues, currentPage);
      setIsStatusChecked(false);
    }
  }, [isStatusChecked]);

  const onFiltersChange = (name, value) => {
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  };
  const onFiltersSet = (filters) => {
    let object = {};
    for (let i in filters) {
      object[filters[i][1]] = filters[i][2];
    }
    setFilterValues({
      ...object,
    });
  };

  const onTableChange = (pagination, _, sorter) => {
    setCurrentPage(pagination);
    if (sorter?.columnKey === 'bizdev') {
      setSorter({ key: `order.bizdev.nickname`, order: sorter?.order });
      return;
    }
    if (sorter?.columnKey === 'customer') {
      setSorter({ key: `order.customer.name`, order: sorter?.order });
      return;
    }
    if (sorter?.columnKey === 'affiliate') {
      setSorter({ key: `buyer.affiliate.nickname`, order: sorter?.order });
      return;
    }
    if (sorter?.columnKey === 'injected_by') {
      setSorter({ key: `injectedBy.nickname`, order: sorter?.order });
      return;
    }

    if (
      ['order', 'user', 'box', 'buyer', 'customer'].includes(sorter?.columnKey)
    ) {
      setSorter({ key: `${sorter?.columnKey}.name`, order: sorter?.order });
      return;
    }
    setSorter({ key: sorter?.columnKey, order: sorter?.order });
  };

  const getData = (filterValues, currentPage, pageSize) => {
    if (typeof currentPage !== 'number') {
      currentPage = 1;
      setCurrentPage(1);
    }
    const sort = sorterValue(sorter);
    const url = inputToFilter(filterValues, currentPage, pageSize);
    api.get(`/lead?${url}${sort}`).then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
      window.scrollTo(0, 0);
    });
  };

  const handleMenuClick = (e) => {
    const lead = e.item.props.value;
    api
      .put(`/lead/${lead.id}`, {
        isStatusLocked: true,
        status: 'test',
      })
      .then(() => {
        message.success('Лід оновлено');
        getData(filterValues, currentPage, pageSize);
      });
  };
  const items = (lead) => {
    return [
      {
        label: 'Помітити як тест',
        key: '1',
        value: lead,
        icon: <LockOutlined />,
      },
    ];
  };
  const menuProps = (lead) => {
    return {
      items: items(lead),
      onClick: handleMenuClick,
    };
  };

  const onCsvExport = (cols) => {
    const url = inputToFilterExport(filterValues);
    setIsExportLoading(true);
    api
      .get(`/lead/export?${url}&columns=${cols.join(',')}&limit=0`)
      .then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'leads.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
        setIsExportLoading(false);
      })
      .catch((res) => {
        message.error(res?.response?.data?.message);
      });
  };

  const changeIsColoredOfCellValue = () => {
    setIsColoredOfCells(!isColoredOfCells);
  };

  const rowSelection = {
    selectedRowKeys: selectedLeads.map((l) => l.id),
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedLeads(selectedRows);
    },
  };

  const onShowSizeChange = (current, newPageSize) => {
    setPageSize(newPageSize);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
  };

  const handleResendModalCancel = () => {
    setIsResendModalOpen(false);
  };

  const handleInjectModalCancel = () => {
    setIsInjectModalOpen(false);
  };

  const handleImportModalCancel = () => {
    setIsImportModalOpen(false);
  };

  const onImportSuccess = (sessionId) => {
    onProgressUpdate(0, sessionId);
    setIsImportModalOpen(false);
  };

  const onResendUpdateSuccess = () => {
    setIsModalOpen(false);
    setIsResendModalOpen(false);
    setIsInjectModalOpen(false);
    // getData(filterValues, currentPage, pageSize);
    setSelectedLeads([]);
  };

  const checkStatuses = ({ selectedLeads, onLoadFinish, message }) => {
    if (selectedLeads.length) {
      const leads = selectedLeads
        .filter(
          (l) =>
            l.status !== 'error' &&
            l.order?.integration_id &&
            l.externalId?.length
        )
        .map((l) => l.id);
      setUpdatedLeads(selectedLeads);
      setIsLeadsStatusUpdating(true);
      if (leads.length) {
        api
          .post('/lead/checkStatuses', {
            leads: leads,
          })
          .then((res) => {
            if (res) {
              setIsLeadsStatusUpdating(false);
              setSelectedLeads([]);
              setUpdatedLeads([]);
              onLoadFinish();
              message.success(
                'Статус ' +
                  res.data[0].leads.sort((a, b) => a - b).join(', ') +
                  ' лідів оновлено'
              );
            }
          });
      }
    }
  };

  const onLoadFinish = () => {
    setIsStatusChecked(true);
  };

  const onProgressUpdate = (progress, sessionId) => {
    const stopImport = () => {
      api
        .delete(`/lead/import/${sessionId}`)
        .then((res) => {
          message.success('Імпорт зупинено');
          messageApi.destroy(sessionId);
        })
        .catch((e) => {
          message.error(e.response.data.message);
        });
    };

    if (progress >= 100) {
      setTimeout(() => {
        messageApi.destroy(sessionId);
      }, 2000);
    }
    messageApi.open({
      content: (
        <span>
          <Progress
            format={(progress) => progress < 100}
            percent={progress}
            status={progress >= 100 ? 'success' : 'active'}
          />
        </span>
      ),
      key: sessionId,
      duration: 0,
      style: {
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '15px',
        padding: 0,
      },
    });
  };

  const TableFooter = ({ paginationProps, selectedLeads }) => {
    const { message } = App.useApp();
    const affiliatesTeam = [AFFILIATE, AFFILIATE_TEAM_LEAD, AFFILIATE_SUPPORT];
    const fullstackTeam = [FULLSTACK, FULLSTACK_TEAM_LEAD, FULLSTACK_SUPPORT];
    return (
      <Row
        style={{
          padding: '16px 16px',
          borderRadius: '0 0 8px 8px',
          background: isDarkTheme ? '#272b34' : '#ffffff',
        }}
        justify="space-between"
      >
        <Row>
          {!!selectedLeads.length && (
            <Row>
              <div>
                {hasPermissions(role, affiliatesTeam.concat(fullstackTeam)) && (
                  <Button type="primary" onClick={() => setIsModalOpen(true)}>
                    Змінити статус
                  </Button>
                )}
              </div>
              <div>
                {hasPermissions(role, affiliatesTeam.concat(fullstackTeam)) && (
                  <Button
                    style={{ marginLeft: 15 }}
                    type="primary"
                    onClick={() => setIsResendModalOpen(true)}
                  >
                    Перевідправити ліди
                  </Button>
                )}
                {(user?.isCanInject || role === 'SUPER_ADMIN') && (
                  <Button
                    style={{ marginLeft: 15 }}
                    type="primary"
                    onClick={() => setIsInjectModalOpen(true)}
                  >
                    Заінджектити ліди
                  </Button>
                )}
                <Button
                  style={{ marginLeft: 15 }}
                  type="primary"
                  onClick={() =>
                    checkStatuses({ selectedLeads, onLoadFinish, message })
                  }
                >
                  Оновити статуси
                </Button>
              </div>
            </Row>
          )}
        </Row>

        <ChangeStatusModal
          isModalOpen={isModalOpen}
          leads={selectedLeads}
          handleCancel={handleModalCancel}
          onSuccess={onResendUpdateSuccess}
        />
        <ResendLeadsModal
          isModalOpen={isResendModalOpen}
          leads={selectedLeads}
          handleCancel={handleResendModalCancel}
          onSuccess={onResendUpdateSuccess}
        />
        <InjectLeadsModal
          isModalOpen={isInjectModalOpen}
          leads={selectedLeads}
          handleCancel={handleInjectModalCancel}
          onSuccess={onResendUpdateSuccess}
        />
        <ImportLeadsModal
          isModalOpen={isImportModalOpen}
          handleCancel={handleImportModalCancel}
          onSuccess={onImportSuccess}
        />
        <Pagination {...paginationProps} />
      </Row>
    );
  };

  return (
    <div>
      <DetailsModalFrod
        data={selectedData}
        isModalOpen={isOpen}
        handleCancel={() => setIsOpen(false)}
      />
      {contextHolder}
      <AppTable
        daraggable
        filters={GetFilters({
          onFiltersChange,
          onFiltersSet,
          querystr,
          filterIds,
        })}
        columns={columns}
        pagination={false}
        onChange={onTableChange}
        onColumnsChange={setColumns}
        data={data}
        dataPrefix="leads"
        allowChangingColumns
        allowChangingFilters
        onCsvExport={onCsvExport}
        onCsvImport={() => setIsImportModalOpen(true)}
        isColoredOfCells={isColoredOfCells}
        changeIsColoredOfCellValue={changeIsColoredOfCellValue}
        isExportDisabled={isExportLoading}
        querystr={querystr}
        legendColorList={colorList}
        footer={() => (
          <TableFooter
            paginationProps={{
              total,
              pageSizeOptions: [10, 50, 100, 200],
              current: currentPage,
              pageSize,
              onChange: onTableChange,
              onShowSizeChange,
              showSizeChanger: true,
              showTotal: (total) =>
                `Всього ${total} лідів ${
                  selectedLeads.length
                    ? '/ Вибрано: ' + selectedLeads.length
                    : ''
                }`,
            }}
            selectedLeads={selectedLeads}
          />
        )}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
      />
    </div>
  );
};

const GetFilters = ({ onFiltersChange, onFiltersSet, querystr, filterIds }) => {
  const role = usePermissions();
  const user = useContext(CurrentUserContext);
  const [orders, setOrders] = useState(
    querystr?.hasOwnProperty('orders') ? querystr.orders[0] : []
  );
  const [buyers, setBuyers] = useState(
    querystr?.hasOwnProperty('buyers') ? querystr.buyers[0] : []
  );
  const [boxes, setBoxes] = useState(
    querystr?.hasOwnProperty('boxes') ? querystr.boxes[0] : []
  );
  const [ids, setIds] = useState(
    querystr?.hasOwnProperty('id') ? querystr.id[0] : []
  );
  const [customers, setCustomers] = useState(
    querystr?.hasOwnProperty('order.customer')
      ? querystr['order.customer'][0]
      : []
  );
  const [affiliates, setAffiliates] = useState(
    querystr?.hasOwnProperty('affiliates') ? querystr.affiliates[0] : []
  );
  const [bizdevs, setBizdevs] = useState(
    querystr?.hasOwnProperty('bizdevs') ? querystr.bizdevs[0] : []
  );
  const [lang, setLang] = useState(
    querystr?.hasOwnProperty('lang') ? querystr.lang[0] : []
  );
  const [status, setStatus] = useState([]);
  const [callStatusCategory, setCallStatusCategory] = useState([]);
  const [country, setCountry] = useState(
    querystr?.hasOwnProperty('country') ? querystr.country[0] : []
  );

  const [approved, setApproved] = useState(
    querystr?.hasOwnProperty('approvedDeposit')
      ? querystr.approvedDeposit[0]
      : []
  );

  useEffect(() => {
    if (querystr?.hasOwnProperty('status') && !status.length) {
      let elements = [];
      for (const st of querystr.status[0]) {
        elements.push({
          value: st?.value,
          label: (
            <StatusTag
              status={st?.label.props.status}
              style={st?.label.props.style}
            />
          ),
        });
      }
      setStatus(elements);
    }
    if (
      querystr?.hasOwnProperty('callStatusCategory') &&
      !callStatusCategory.length
    ) {
      let elements = [];
      for (const csc of querystr.callStatusCategory[0]) {
        elements.push({
          value: csc?.value,
          label: (
            <CallStatusCategoryTag
              callStatusCategory={csc?.label.props.callStatusCategory}
              style={csc?.label.props.style}
            />
          ),
        });
      }
      setCallStatusCategory(elements);
    }
  }, []);

  const [, setSearchParams] = useSearchParams();
  const affiliatesTeam = [AFFILIATE, AFFILIATE_TEAM_LEAD, AFFILIATE_SUPPORT];
  const bizdevsTeam = [BIZDEV, BIZDEV_TEAM_LEAD, BIZDEV_SUPPORT];
  const fullstackTeam = [FULLSTACK, FULLSTACK_TEAM_LEAD, FULLSTACK_SUPPORT];

  const initialFetchStatus = async () => {
    return [
      {
        value: 'notDistributed',
        label: (
          <StatusTag
            status="notDistributed"
            style={{
              height: 20,
              lineHeight: '20px',
              marginTop: 2,
              marginBottom: 2,
            }}
          />
        ),
      },
      {
        value: 'pending',
        label: (
          <StatusTag
            status="pending"
            style={{
              height: 20,
              lineHeight: '16px',
              marginTop: 2,
              marginBottom: 2,
            }}
          />
        ),
      },
      {
        value: 'approved',
        label: (
          <StatusTag
            status="approved"
            style={{
              height: 20,
              lineHeight: '16px',
              marginTop: 2,
              marginBottom: 2,
            }}
          />
        ),
      },
      {
        value: 'unsigned',
        label: (
          <StatusTag
            status="unsigned"
            style={{
              height: 20,
              lineHeight: '16px',
              marginTop: 2,
              marginBottom: 2,
            }}
          />
        ),
      },
      {
        value: 'rejected',
        label: (
          <StatusTag
            status="rejected"
            style={{
              height: 20,
              lineHeight: '16px',
              marginTop: 2,
              marginBottom: 2,
            }}
          />
        ),
      },
      {
        value: 'error',
        label: (
          <StatusTag
            status="error"
            style={{
              height: 20,
              lineHeight: '16px',
              marginTop: 2,
              marginBottom: 2,
            }}
          />
        ),
      },
      {
        value: 'test',
        label: (
          <StatusTag
            status="test"
            style={{
              height: 20,
              lineHeight: '16px',
              marginTop: 2,
              marginBottom: 2,
            }}
          />
        ),
      },
      {
        value: 'fraud',
        label: (
          <StatusTag
            status="fraud"
            style={{
              height: 20,
              lineHeight: '16px',
              marginTop: 2,
              marginBottom: 2,
            }}
          />
        ),
      },
    ];
  };
  const initialFetchApproveds = async () => {
    return [
      {
        value: 'pending',
        label: (
          <StatusTag
            status="pending"
            style={{
              height: 20,
              lineHeight: '16px',
              marginTop: 2,
              marginBottom: 2,
            }}
          />
        ),
      },
      {
        value: 'approved',
        label: (
          <StatusTag
            status="approved"
            style={{
              height: 20,
              lineHeight: '16px',
              marginTop: 2,
              marginBottom: 2,
            }}
          />
        ),
      },
      {
        value: 'reject',
        label: (
          <StatusTag
            status="reject"
            style={{
              height: 20,
              lineHeight: '16px',
              marginTop: 2,
              marginBottom: 2,
            }}
          />
        ),
      },
    ];
  };

  const initialFetchCallStatusCategories = async () => {
    const res = await api.get(`/call-status-category/all`);
    return res.data
      .sort((a, b) =>
        a.parentCategory
          ? a.parentCategory.name.localeCompare(
              b.parentCategory ? b.parentCategory.name : b.name
            )
          : a.name.localeCompare(
              b.parentCategory ? b.parentCategory.name : b.name
            )
      )
      .map((c) => ({
        label: (
          <CallStatusCategoryTag
            callStatusCategory={c}
            style={{
              height: 20,
              lineHeight: '16px',
              marginTop: 2,
              marginBottom: 2,
              borderColor: c.color,
            }}
          />
        ),
        value: c.id,
      }));
  };

  useEffect(() => {
    if (querystr) {
      onFiltersSet(querystr);
    }
  }, []);

  useDidUpdateEffect(() => {
    setIds(filterIds);
  }, [filterIds]);

  const fetchOrders = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(`/order?filter.name=$ilike:${name}${idFilter}`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchOrders = async () => {
    const res = await api.get(`/order?limit=10`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const fetchCustomers = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/customer?filter.name=$ilike:${name}${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchCustomers = async () => {
    const res = await api.get(`/customer?limit=10`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const fetchAffiliates = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:AFFILIATE&filter.role=$or:$eq:FULLSTACK&filter.nickname=$ilike:${name}${idFilter}`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: `#${u.id} ${u.name}`,
        value: u.id,
      }));
  };

  const initialFetchAffiliates = async (name) => {
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:AFFILIATE&filter.role=$or:$eq:FULLSTACK`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: `#${u.id} ${u.name}`,
        value: u.id,
      }));
  };

  const fetchBizdevs = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/user?filter.role=$eq:BIZDEV&filter.role=$or:$eq:FULLSTACK&filter.nickname=$ilike:${name}${idFilter}`
    );
    return res.data.data.map((u) => ({
      label: `#${u.id} ${u.name}`,
      value: u.id,
    }));
  };

  const initialFetchBizdevs = async () => {
    const res = await api.get(
      `/user?limit=10&filter.role=$eq:BIZDEV&filter.role=$or:$eq:FULLSTACK`
    );
    return res.data.data.map((u) => ({
      label: `#${u.id} ${u.name}`,
      value: u.id,
    }));
  };

  const initialFetchBuyers = async () => {
    const res = await api.get(`/buyer?limit=10&filter.isActive=$eq:true`);
    return res.data.data.map((b) => ({
      label: `#${b.id} ${b.name}`,
      value: b.id,
    }));
  };

  const fetchBuyers = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(`/buyer?filter.name=$ilike:${name}${idFilter}`);
    return res.data.data.map((b) => ({
      label: `#${b.id} ${b.name}`,
      value: b.id,
    }));
  };

  const fetchBoxes = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(`/box?filter.name=$ilike:${name}${idFilter}`);
    return res.data.data.map((b) => ({
      label: `#${b.id} ${b.name}`,
      value: b.id,
    }));
  };

  const initialFetchBoxes = async () => {
    const res = await api.get(`/box?limit=10`);
    return res.data.data.map((b) => ({
      label: `#${b.id} ${b.name}`,
      value: b.id,
    }));
  };

  const onValueChange = (name, value) => {
    onFiltersChange(name, value);
  };

  return [
    {
      label: 'ID',
      type: 'select',
      dataIndex: 'id',
      mode: 'tags',
      value: ids,
      defaultValue: querystr?.hasOwnProperty('id') ? querystr.id[0] : [],
      onChange: (e) => {
        setIds(e);
        onValueChange('id', `$in:${e.join(',')}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            id: [e, 'id', `$in:${e.join(',')}`],
          }),
        });
      },
      style: {
        width: '100px',
      },
    },
    {
      label: 'Email',
      dataIndex: 'email',
      defaultValue: querystr?.hasOwnProperty('email') ? querystr.email[0] : '',
      onChange: debounce((e) => {
        onValueChange('email', `$ilike:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            email: [e.target.value, 'email', `$ilike:${e.target.value}`],
          }),
        });
      }, 300),
    },
    {
      label: 'Телефон',
      dataIndex: 'phone',
      defaultValue: querystr?.hasOwnProperty('phone') ? querystr.phone[0] : '',
      onChange: debounce((e) => {
        onValueChange('phone', `$ilike:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            phone: [e.target.value, 'phone', `$ilike:${e.target.value}`],
          }),
        });
      }, 300),
    },
    {
      label: 'Країна',
      dataIndex: 'country',
      type: 'countrySelect',
      onChange: (e) => {
        setCountry(e.data);
        if (e?.data.length !== 0 || country.length !== 0) {
          onValueChange(
            'country',
            `${
              e.data?.length
                ? e.isSelected
                  ? `$in:${e.data}`
                  : `$not:$in:${e.data}`
                : '$ilike:'
            }`
          );
          setSearchParams({
            filters: JSON.stringify({
              ...querystr,
              country: e.isSelected
                ? [
                    e.data,
                    'country',
                    `${e.data?.length ? `$in:${e.data}` : '$ilike:'}`,
                  ]
                : [
                    e.data,
                    'country',
                    `${e.data?.length ? `$not:$in:${e.data}` : '$ilike:'}`,
                  ],
            }),
          });
        }
      },
      value: country,
      style: {
        width: '120px',
      },
    },
    {
      label: 'Мова',
      dataIndex: 'lang',
      type: 'countrySelect',
      onChange: (e) => {
        setLang(e.data);
        if (e?.data.length !== 0 || lang.length !== 0) {
          onValueChange(
            'lang',
            `${
              e.data?.length
                ? e.isSelected
                  ? `$in:${e.data}`
                  : `$not:$in:${e.data}`
                : '$ilike:'
            }`
          );
          setSearchParams({
            filters: JSON.stringify({
              ...querystr,
              lang: e.isSelected
                ? [
                    e.data,
                    'lang',
                    `${e.data?.length ? `$in:${e.data}` : '$ilike:'}`,
                  ]
                : [
                    e.data,
                    'lang',
                    `${e.data?.length ? `$not:$in:${e.data}` : '$ilike:'}`,
                  ],
            }),
          });
        }
      },
      value: lang,
      style: {
        width: '120px',
      },
    },
    {
      label: 'IP',
      dataIndex: 'ip',
      defaultValue: querystr?.hasOwnProperty('ip') ? querystr.ip[0] : '',
      onChange: debounce((e) => {
        onValueChange('IP', `$ilike:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            ip: [e.target.value, 'ip', `$ilike:${e.target.value}`],
          }),
        });
      }, 300),
    },
    {
      label: 'Статус',
      dataIndex: 'status',
      type: 'multiselectFilter',
      onChange: (e) => {
        setStatus(e.data);
        e.data.forEach((d) => {
          delete d.disabled;
          delete d.title;
          delete d.key;
        });
        if (e?.data.length !== 0 || status.length !== 0) {
          setSearchParams({
            filters: JSON.stringify({
              ...querystr,
              status: e.isSelected
                ? [
                    e.data,
                    'status',
                    e.data.length !== 0
                      ? `$eq:$in:${e.data.map((u) => u.value).join(',')}`
                      : `$ilike:`,
                  ]
                : [
                    e.data,
                    'status',
                    e.data.length !== 0
                      ? `$not:$eq:$in:${e.data.map((u) => u.value).join(',')}`
                      : `$ilike:`,
                  ],
            }),
          });
          onFiltersChange(
            'status',
            e.data.length !== 0
              ? e.isSelected
                ? `$eq:$in:${e.data.map((o) => o.value).join(',')}`
                : `$not:$eq:$in:${e.data.map((o) => o.value).join(',')}`
              : `$ilike:`
          );
        }
      },
      fetch: initialFetchStatus,
      initialFetch: initialFetchStatus,
      value: status,
      style: {
        width: '130px',
      },
    },
    {
      label: 'Call статус',
      dataIndex: 'callStatus',
      type: 'multiselectFilter',
      onChange: (e) => {
        setCallStatusCategory(e.data);
        e.data.forEach((d) => {
          delete d.disabled;
          delete d.title;
          delete d.key;
        });
        if (e?.data.length !== 0 || callStatusCategory.length !== 0) {
          setSearchParams({
            filters: JSON.stringify({
              ...querystr,
              callStatusCategory: e.isSelected
                ? [
                    e.data,
                    'callStatusObj.callStatusCategory.id',
                    `${
                      e.data?.length
                        ? `$eq:$in:${e.data.map((u) => u.value).join(',')}`
                        : '$ilike:'
                    }`,
                  ]
                : [
                    e.data,
                    'callStatusObj.callStatusCategory.id',
                    `${
                      e.data?.length
                        ? `$not:$eq:$in:${e.data.map((u) => u.value).join(',')}`
                        : '$ilike:'
                    }`,
                  ],
            }),
          });
          onFiltersChange(
            'callStatusObj.callStatusCategory.id',
            e.isSelected
              ? `${
                  e.data?.length
                    ? `$eq:$in:${e.data.map((u) => u.value).join(',')}`
                    : '$ilike:'
                }`
              : `${
                  e.data?.length
                    ? `$not:$eq:$in:${e.data.map((u) => u.value).join(',')}`
                    : '$ilike:'
                }`
          );
        }
      },
      fetch: initialFetchCallStatusCategories,
      initialFetch: initialFetchCallStatusCategories,
      value: callStatusCategory,
      style: {
        width: '130px',
      },
    },
    {
      label: 'Бокси',
      dataIndex: 'box_id',
      type: 'multiselectFilter',
      onChange: (e) => {
        setBoxes(e.data);
        e.data.forEach((d) => {
          delete d.disabled;
          delete d.title;
          delete d.key;
        });
        if (e?.data.length !== 0 || boxes.length !== 0) {
          setSearchParams({
            filters: JSON.stringify({
              ...querystr,
              boxes: e.isSelected
                ? [
                    e.data,
                    'box.id',
                    e.data.length !== 0
                      ? `$in:${e.data.map((u) => u.value).join(',')}`
                      : `$ilike:`,
                  ]
                : [
                    e.data,
                    'box.id',
                    e.data.length !== 0
                      ? `$not:$in:${e.data.map((u) => u.value).join(',')}`
                      : `$ilike:`,
                  ],
            }),
          });
          onFiltersChange(
            'box.id',
            `$in:${e.data.map((o) => o.value).join(',')}`
          );
        }
      },
      fetch: fetchBoxes,
      initialFetch: initialFetchBoxes,
      value: boxes,
      style: {
        width: '130px',
      },
    },
    {
      label: 'Замовлення',
      dataIndex: 'order_id',
      type: 'multiselectFilter',
      onChange: (e) => {
        setOrders(e.data);
        e.data.forEach((d) => {
          delete d.disabled;
          delete d.title;
          delete d.key;
        });
        if (e?.data.length !== 0 || orders.length !== 0) {
          setSearchParams({
            filters: JSON.stringify({
              ...querystr,
              orders: e.isSelected
                ? [
                    e.data,
                    'order.id',
                    e.data?.length !== 0
                      ? `$in:${e.data?.map((u) => u.value).join(',')}`
                      : '$ilike:',
                    !e.isSelected,
                  ]
                : [
                    e.data,
                    'order.id',
                    e.data?.length !== 0
                      ? `$not:$in:${e.data?.map((u) => u.value).join(',')}`
                      : '$ilike',
                    !e.isSelected,
                  ],
            }),
          });
          onFiltersChange(
            'order.id',
            `$in:${e.data.map((o) => o.value).join(',')}`
          );
        }
      },
      fetch: fetchOrders,
      initialFetch: initialFetchOrders,
      value: orders,
      style: {
        width: '130px',
      },
    },
    {
      label: 'Замовник',
      dataIndex: 'order_id',
      type: 'multiselectFilter',
      onChange: (e) => {
        setCustomers(e.data);
        e.data.forEach((d) => {
          delete d.disabled;
          delete d.title;
          delete d.key;
        });
        if (e?.data.length !== 0 || customers.length !== 0) {
          setSearchParams({
            filters: JSON.stringify({
              ...querystr,
              'order.customer': e.isSelected
                ? [
                    e.data,
                    'order.customer.id',
                    e.data.length !== 0
                      ? `$in:${e.data.map((u) => u.value).join(',')}`
                      : '$ilike:',
                  ]
                : [
                    e.data,
                    'order.customer.id',
                    e.data.length !== 0
                      ? `$not:$in:${e.data.map((u) => u.value).join(',')}`
                      : '$ilike',
                  ],
            }),
          });
          onFiltersChange(
            'order.customer.id',
            `$in:${e.data.map((o) => o.value).join(',')}`
          );
        }
      },
      fetch: fetchCustomers,
      initialFetch: initialFetchCustomers,
      value: customers,
      style: {
        width: '130px',
      },
      permissions: bizdevsTeam.concat(fullstackTeam).concat(['ANALYST']),
    },
    {
      label: 'Баєр',
      dataIndex: 'buyer_id',
      type: 'multiselect',
      onChange: (e) => {
        setBuyers(e);
        e.forEach((d) => {
          delete d.disabled;
          delete d.title;
          delete d.key;
        });
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            buyers: [e, 'buyer.id', `$in:${e.map((u) => u.value).join(',')}`],
          }),
        });
        onFiltersChange('buyer.id', `$in:${e.map((u) => u.value).join(',')}`);
      },
      fetch: fetchBuyers,
      initialFetch: initialFetchBuyers,
      value: buyers,
      style: {
        width: '130px',
      },
      permissions: affiliatesTeam.concat(fullstackTeam).concat(['ANALYST']),
    },
    {
      label: 'Аффілейт',
      dataIndex: 'buyer_id',
      type: 'multiselect',
      onChange: (e) => {
        setAffiliates(e);
        e.forEach((d) => {
          delete d.disabled;
          delete d.title;
          delete d.key;
        });
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            'buyer.affiliate': [
              e,
              'buyer.affiliate.id',
              `$in:${e.map((u) => u.value).join(',')}`,
            ],
          }),
        });
        onFiltersChange(
          'buyer.affiliate.id',
          `$in:${e.map((u) => u.value).join(',')}`
        );
      },
      fetch: fetchAffiliates,
      initialFetch: initialFetchAffiliates,
      value: affiliates,
      style: {
        width: '130px',
      },
      permissions: [AFFILIATE_SUPPORT, AFFILIATE_TEAM_LEAD, ANALYST].concat(
        fullstackTeam
      ),
    },
    {
      label: 'Біздев',
      dataIndex: 'order_id',
      type: 'multiselect',
      onChange: (e) => {
        setBizdevs(e);
        e.forEach((d) => {
          delete d.disabled;
          delete d.title;
          delete d.key;
        });
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            'order.bizdev': [
              e,
              'order.bizdev.id',
              `$in:${e.map((u) => u.value).join(',')}`,
            ],
          }),
        });
        onFiltersChange(
          'order.bizdev.id',
          `$in:${e.map((u) => u.value).join(',')}`
        );
      },
      fetch: fetchBizdevs,
      initialFetch: initialFetchBizdevs,
      value: bizdevs,
      style: {
        width: '130px',
      },
      permissions: [BIZDEV_SUPPORT, BIZDEV_TEAM_LEAD, ANALYST].concat(
        fullstackTeam
      ),
    },
    {
      label: 'Інжект',
      type: 'checkbox',
      dataIndex: 'isInjected',
      thirdLine: true,
      defaultChecked:
        querystr?.hasOwnProperty('isInjected') && querystr.isInjected[0],
      onChange: (e) => {
        if (e.target.checked) {
          onFiltersChange('isInjected', `$eq:${e.target.checked}`);
        } else {
          onFiltersChange('isInjected', `$ilike:`);
        }
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            isActive: [
              e.target.checked,
              'isInjected',
              e.target.checked ? `$eq:${e.target.checked}` : '$ilike:',
            ],
          }),
        });
      },
    },
    {
      label: 'Депозит',
      dataIndex: 'isDeposit',
      type: 'checkbox',
      thirdLine: true,
      defaultChecked:
        querystr?.hasOwnProperty('isDeposit') && querystr.isDeposit[0],
      onChange: (e) => {
        if (e.target.checked) {
          onFiltersChange('isDeposit', `$eq:${e.target.checked}`);
        } else {
          onFiltersChange('isDeposit', `$ilike:`);
        }
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            isDeposit: [
              e.target.checked,
              'isDeposit',
              e.target.checked ? `$eq:${e.target.checked}` : '$ilike:',
            ],
          }),
        });
      },
    },
    {
      label: 'Доступні для модифікації',
      type: 'checkbox',
      dataIndex: 'id',
      thirdLine: true,
      defaultChecked:
        querystr?.hasOwnProperty('isAbleToModify') &&
        querystr.isAbleToModify[0],
      onChange: (e) => {
        if (e.target.checked) {
          onFiltersChange('isAbleToModify', `$eq:${e.target.checked}`);
        } else {
          onFiltersChange('isAbleToModify', `$ilike:`);
        }
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            isAbleToModify: [
              e.target.checked,
              'isAbleToModify',
              e.target.checked ? `$eq:${e.target.checked}` : '$ilike:',
            ],
          }),
        });
      },
    },
    {
      label: 'Створено від',
      dataIndex: 'createdAt',
      type: 'datepicker',
      value:
        querystr?.hasOwnProperty('createdAtFrom') && querystr.createdAtFrom[0],
      secondLine: true,
      onChange: (e) => {
        const date = e
          ?.set('hour', 0)
          .set('minute', 0)
          .set('second', 0)
          .tz(user.timezone);
        onFiltersChange('createdAtFrom', `$gte:${date?.toISOString() || ''}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            createdAtFrom: [
              date?.toISOString(),
              'createdAtFrom',
              `$gte:${date?.toISOString() || ''}`,
            ],
          }),
        });
      },
      style: {
        width: '130px',
      },
    },
    {
      label: 'Створено до',
      dataIndex: 'createdAt',
      type: 'datepicker',
      value: querystr?.hasOwnProperty('createdAtTo') && querystr.createdAtTo[0],
      secondLine: true,
      onChange: (e) => {
        const date = e
          ?.set('hour', 23)
          .set('minute', 23)
          .set('second', 59)
          .tz(user.timezone);
        onFiltersChange('createdAtTo', `$lte:${date?.toISOString() || ''}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            createdAtTo: [
              date?.toISOString(),
              'createdAtTo',
              `$lte:${date?.toISOString() || ''}`,
            ],
          }),
        });
      },
      style: {
        width: '130px',
      },
    },
    {
      label: 'Відправлено від',
      dataIndex: 'sentAt',
      type: 'datepicker',
      value: querystr?.hasOwnProperty('sentAtFrom') && querystr.sentAtFrom[0],
      secondLine: true,
      onChange: (e) => {
        const date = e?.set('hour', 0).set('minute', 0).set('second', 0);
        onFiltersChange('sentAtFrom', `$gte:${date?.toISOString() || ''}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            sentAtFrom: [
              date?.toISOString(),
              'sentAtFrom',
              `$gte:${date?.toISOString() || ''}`,
            ],
          }),
        });
      },
      style: {
        width: '130px',
      },
    },
    {
      label: 'Відправлено до',
      dataIndex: 'sentAt',
      type: 'datepicker',
      value: querystr?.hasOwnProperty('sentAtTo') && querystr.sentAtTo[0],
      secondLine: true,
      onChange: (e) => {
        const date = e?.set('hour', 23).set('minute', 23).set('second', 59);
        onFiltersChange('sentAtTo', `$lte:${date?.toISOString() || ''}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            sentAtTo: [
              date?.toISOString(),
              'sentAtTo',
              `$lte:${date?.toISOString() || ''}`,
            ],
          }),
        });
      },
      style: {
        width: '130px',
      },
    },
  ];
};

const LeadActionCell = ({ lead, onLoadFinish, leadsStatusUpdating }) => {
  const [loading, setIsLoading] = useState(false);
  const { message } = App.useApp();

  const handleStatusCheck = (id) => {
    setIsLoading(true);
    api.post(`/lead/checkStatus/${id}`).then((res) => {
      setIsLoading(false);
      onLoadFinish();
      message.success('Статус оновлено');
    });
  };

  if (
    lead.status === 'error' ||
    !lead.order?.integration_id ||
    !lead.externalId?.length
  )
    return <StopOutlined />;

  return loading || leadsStatusUpdating ? (
    <Spin size="small" />
  ) : (
    <Tooltip title="Оновити call статус">
      <RedoOutlined onClick={() => handleStatusCheck(lead.id)} />
    </Tooltip>
  );
};

const CallStatusHistoryCell = ({ callStatus, callStatusHistory }) => {
  if (!callStatusHistory || !callStatus) return '';
  let history = JSON.parse(callStatusHistory);
  const data = history.map(
    (h) => `${fromApiToClient(h.dateTime)} | ${h.callStatus}`
  );
  return (
    <div
      style={{
        width: 160,
        display: 'flex',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {callStatus}
      <TooltipList data={data} icon={<HistoryOutlined />} />
    </div>
  );
};

const CurrentCallStatusCell = ({
  currentCallStatus,
  useFakeCallStatus,
  nextCallStatus,
  nextCallStatusTime,
  timezone,
}) => {
  const style = {
    width: '140px',
  };
  return useFakeCallStatus ? (
    <div style={style}>
      <ExtendableTextTableCell
        data={currentCallStatus}
        stringLength={30}
        maxFullStringLength={100}
      />{' '}
      <i>(fake)</i>
    </div>
  ) : nextCallStatus ? (
    <div style={style}>
      <div>
        <ExtendableTextTableCell
          data={currentCallStatus}
          stringLength={30}
          maxFullStringLength={100}
        />
      </div>
      <br></br>
      <div>
        <i>
          Наступний:
          <br></br>
          <>
            <ExtendableTextTableCell
              data={nextCallStatus}
              stringLength={30}
              maxFullStringLength={100}
            />{' '}
            <span>
              [{fromApiToClientTimePlusShift(nextCallStatusTime, timezone)}]
            </span>
          </>
        </i>
      </div>
    </div>
  ) : (
    <div style={style}>
      <ExtendableTextTableCell
        data={currentCallStatus}
        stringLength={30}
        maxFullStringLength={100}
      />
    </div>
  );
};

const CurrentIsDepositCell = ({
  currentIsDeposit,
  useFakeIsDeposit,
  depositAt,
  timezone,
}) => {
  const style = {
    // width: '150px',
  };
  return useFakeIsDeposit ? (
    <div style={style}>
      {currentIsDeposit ? (
        <CheckOutlined />
      ) : (
        <>
          <MinusOutlined /> <i>(fake)</i>
        </>
      )}
    </div>
  ) : depositAt && !currentIsDeposit ? (
    <div style={style}>
      <div>
        <MinusOutlined />
        <i>Буде проставлено:</i> [
        <i>{fromApiToClientTimePlusShift(depositAt, timezone)}]</i>
      </div>
    </div>
  ) : (
    <div style={style}>{currentIsDeposit && <CheckOutlined />}</div>
  );
};
