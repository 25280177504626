import { useNavigate } from 'react-router-dom';
import api from '../../../api/api';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { BuyersForm } from '../BuyersForm/BuyersForm';

export const CreateBuyer = () => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const affiliatesTeam = [
    'AFFILIATE',
    'AFFILIATE_TEAM_LEAD',
    'AFFILIATE_SUPPORT',
  ];
  const fullstackTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  usePermissionsAllowed(affiliatesTeam.concat(fullstackTeam));

  const onFinish = (buyer) => {
    api
      .post('/buyer', {
        ...buyer,
      })
      .then((_) => {
        navigate('/buyers');
        message.success('Баєра додано');
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  return (
    <>
      <BuyersForm onFinish={onFinish} type={'create'} />
    </>
  );
};
