import React, { useEffect, useState } from 'react';
import { Form, Input } from 'antd';

export const EditableCellBox = ({
  name,
  value,
  onUpdate,
  isUpdate,
  boxId,
  onChange,
}) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [form] = Form.useForm();
  const handleDoubleClick = (e) => {
    if (e.detail === 2) {
      setIsEditMode(true);
    }
  };

  const handleEsc = (evt) => {
    evt = evt || window.event;
    let isEscape;
    if ('key' in evt) {
      isEscape = evt.key === 'Escape' || evt.key === 'Esc';
    } else {
      isEscape = evt.keyCode === 27;
    }
    if (isEscape && isEditMode) {
      setIsEditMode(false);
    }
  };

  document.addEventListener('keydown', handleEsc);

  useEffect(() => () => document.removeEventListener('keydown', handleEsc), []);

  const onFinish = () => {
    onUpdate({
      ...form.getFieldsValue(),
    });
    setIsEditMode(false);
  };
  if ((!isEditMode && !isUpdate.update) || boxId !== isUpdate.id)
    return <span onClick={handleDoubleClick}>{value}</span>;

  return (
    <Form form={form} onFinish={onFinish}>
      <Form.Item style={{ margin: '0px' }} name={name}>
        <Input
          type="number"
          ref={(input) => input}
          defaultValue={value}
          style={{ width: 100 }}
          onChange={(e) => onChange(e.target.value)}
        />
      </Form.Item>
    </Form>
  );
};
