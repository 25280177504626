import { App } from 'antd';
import { useNavigate } from 'react-router-dom';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import api from '../../../api/api';
import { IntegrationsDetails } from '../IntegrationsDetails/IntegrationsDetails';

export const CreateIntegration = () => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const bizdevTeam = ['BIZDEV', 'BIZDEV_TEAM_LEAD', 'BIZDEV_SUPPORT'];
  const fullstackTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  usePermissionsAllowed(bizdevTeam.concat(fullstackTeam));

  const onFinish = (integration) => {
    api
      .post('/integration', {
        ...integration,
      })
      .then((res) => {
        message.success('Інтеграцію додано');
        navigate(`/integrations`);
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  return <IntegrationsDetails onFinish={onFinish} />;
};
