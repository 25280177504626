import { Button, Checkbox, Form, Select } from 'antd';
import styles from './DomainsPurchase.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { AutocompleteSelect } from '../../shared/AutocompleteSelect/AutocompleteSelect';
import api from '../../api/api';
import { fromApiToClient } from '../../utils/dateFilters';
import { DomainPurchaseConfirmModal } from './DomainPurchaseConfirmModal';
import { usePermissionsAllowed } from '../../hooks/usePermissionsAllowed';
import { useNavigate } from 'react-router-dom';
import { CurrentUserContext } from '../../providers/AuthProvider';

export const DomainsPurchase = ({ customer, onFinish }) => {
  const [form] = Form.useForm();
  const [fixDomain, setFixDomain] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState();
  const [selectedServers, setSelectedServers] = useState();
  const [selectedRegways, setSelectedRegways] = useState();
  const [selectedCloudflares, setSelectedCloudflares] = useState();
  const [domains, setDomains] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isPurchaseLoading, setIsPurchaseLoading] = useState(false);
  const [priceRes, setPriceRes] = useState();
  const navigate = useNavigate();

  usePermissionsAllowed(['SUPPORT', 'TEAM_LEAD']);
  const user = useContext(CurrentUserContext);

  const initialFetchUsers = async () => {
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=MEDIA_BUYER`
    );
    return res.data.data.map((c) => ({
      label: c.nickname,
      value: c.id,
    }));
  };

  const fetchUsers = async (name) => {
    const res = await api.get(
      `/user?filter.nickname=$ilike:${name}&filter.isActive=$eq:true&filter.role=MEDIA_BUYER`
    );
    return res.data.data.map((c) => ({
      label: c.nickname,
      value: c.id,
    }));
  };

  const initialFetchRegways = async () => {
    const res = await api.get(
      `/domain-purchase/regway?limit=10&filter.isActive=$eq:true`
    );
    return res.data.data.map((c) => ({
      label: c.name,
      value: c.id,
    }));
  };

  const fetchRegways = async (name) => {
    const res = await api.get(
      `/domain-purchase/regway?limit=10&filter.isActive=$eq:true`
    );
    return res.data.data.map((c) => ({
      label: c.name,
      value: c.id,
    }));
  };

  const initialFetchServers = async () => {
    const res = await api.get(
      `/domain-purchase/server?limit=10&filter.isActive=$eq:true`
    );
    return res.data.data.map((c) => ({
      label: c.name,
      value: c.id,
    }));
  };

  const fetchServers = async (name) => {
    const res = await api.get(
      `/domain-purchase/server?limit=10&filter.isActive=$eq:true`
    );
    return res.data.data.map((c) => ({
      label: c.name,
      value: c.id,
    }));
  };

  const initialFetchCloudflares = async () => {
    const res = await api.get(
      `/domain-purchase/cloudflare?limit=10&filter.isActive=$eq:true`
    );
    return res.data.data.map((c) => ({
      label: c.name,
      value: c.id,
    }));
  };

  const fetchCloudflares = async (name) => {
    const res = await api.get(
      `/domain-purchase/cloudflare?limit=10&filter.isActive=$eq:true`
    );
    return res.data.data.map((c) => ({
      label: c.name,
      value: c.id,
    }));
  };

  useEffect(() => {
    if (customer) {
      form.setFieldsValue(customer);
      form.setFieldValue('createdAt', fromApiToClient(customer.createdAt));
      form.setFieldValue('updatedAt', fromApiToClient(customer.updatedAt));
    }
  }, [customer]);

  const onActiveChange = (e) => {
    setFixDomain(e.target.checked);
  };

  const checkPrice = () => {
    setIsLoading(true);
    api.post('/domain-purchase/domain/price', { domains }).then((res) => {
      setIsLoading(false);
      setIsModalOpen(true);
      setPriceRes(res.data.domains);
    });
  };

  const onPurchase = (selectedDomains) => {
    setIsPurchaseLoading(true);
    api
      .post('/domain-purchase/domain', {
        domains: selectedDomains.map((d) => d.domain),
        regwayId: selectedRegways.value,
        cloudflareId: selectedCloudflares.value,
        serverId: selectedServers.value,
        buyerId: selectedUsers.value,
        fixDomain: fixDomain,
        userId: user.id,
      })
      .then((_) => {
        setIsModalOpen(false);
        setIsPurchaseLoading(false);
        navigate('/domains');
      });
  };

  return (
    <Form
      form={form}
      onFinish={() => {
        checkPrice();
      }}
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
    >
      <Form.Item
        name="users"
        label="Баєр"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <AutocompleteSelect
          className={styles.input}
          value={selectedUsers}
          placeholder="Баєр"
          initialFetch={initialFetchUsers}
          fetch={fetchUsers}
          onChange={setSelectedUsers}
        />
      </Form.Item>
      <Form.Item
        name="domains"
        label="Домени"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          mode="tags"
          placeholder="Домени"
          className={styles.input}
          tokenSeparators={['\n', '\r\n', '\r']}
          onChange={setDomains}
        />
      </Form.Item>
      <Form.Item
        name="servers"
        label="Сервер"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <AutocompleteSelect
          className={styles.input}
          value={selectedServers}
          placeholder="Сервер"
          initialFetch={initialFetchServers}
          fetch={fetchServers}
          onChange={setSelectedServers}
        />
      </Form.Item>
      <Form.Item
        name="cloudflare"
        label="Cloudflare"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <AutocompleteSelect
          className={styles.input}
          value={selectedCloudflares}
          placeholder="Cloudflare"
          initialFetch={initialFetchCloudflares}
          fetch={fetchCloudflares}
          onChange={setSelectedCloudflares}
        />
      </Form.Item>
      <Form.Item
        name="regway"
        label="Regway"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <AutocompleteSelect
          className={styles.input}
          value={selectedRegways}
          placeholder="Regway"
          initialFetch={initialFetchRegways}
          fetch={fetchRegways}
          onChange={setSelectedRegways}
        />
      </Form.Item>
      <Form.Item
        name="isActive"
        label="Якщо домен не доступний - купити схожий"
      >
        <Checkbox
          onClick={onActiveChange}
          defaultChecked={customer?.isActive}
          rootClassName={styles.input}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 11 }}>
        <Button loading={isLoading} type="primary" htmlType="submit">
          Перевірити
        </Button>
      </Form.Item>

      <DomainPurchaseConfirmModal
        priceRes={priceRes}
        isModalOpen={isModalOpen}
        handleCancel={() => setIsModalOpen(false)}
        onSuccess={onPurchase}
        isLoading={isPurchaseLoading}
        isFixDomains={fixDomain}
      />
    </Form>
  );
};
