import { Modal, Input } from 'antd';
import React from 'react';

const { TextArea } = Input;

export const DetailsModalFrod = ({ data, isModalOpen, handleCancel }) => {
  return (
    <Modal
      title="Деталі"
      open={isModalOpen}
      footer={null}
      onCancel={handleCancel}
    >
      <TextArea
        rows={30}
        value={JSON.stringify(JSON.parse(data), undefined, 2)}
        disabled
      />
    </Modal>
  );
};
