import { Select } from 'antd';
import CountryList from 'country-list-with-dial-code-and-flag';
import { useEffect, useState } from 'react';

const { Option } = Select;

export const CountrySelect = ({ values, onChange, mode, ...props }) => {
  const countries = CountryList.getAll();

  return (
    <Select
      mode={mode}
      placeholder="Країни"
      onChange={onChange}
      optionLabelProp="label"
      defaultValue={values}
      showSearch
      filterOption={(inputValue, option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.value.toLowerCase().includes(inputValue.toLowerCase())
      }
      {...props}
    >
      {countries.map((c, index) => (
        <Option key={index} value={c.code} label={c.name}>
          <div>
            <span role="img" aria-label={c.name}>
              {c.flag}&nbsp;
            </span>
            {c.name}&nbsp;({c.code.trim()})
          </div>
        </Option>
      ))}
    </Select>
  );
};
