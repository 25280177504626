import React, { useEffect, useState } from 'react';
import {
  App,
  Button,
  Form,
  Input,
  Modal,
  Progress,
  Table,
  Tooltip,
} from 'antd';
import { Link } from 'react-router-dom';
import api from '../../../api/api';
import styles from '../BoxForm/BoxForm.module.css';
import { AutocompleteSelect } from '../../../shared/AutocompleteSelect/AutocompleteSelect';
import { EditableCellBox } from '../../../shared/EditableCell/EditableCellBox';
import dayjs from 'dayjs';
import {
  hasPermissions,
  usePermissions,
} from '../../../hooks/usePermissionsAllowed';
import {
  CaretLeftOutlined,
  DeleteOutlined,
  EditOutlined,
  PauseOutlined,
  PlayCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons';

export const BoxOrders = ({ box }) => {
  const { message } = App.useApp();
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [value, setValue] = useState({});
  const [isUpdate, setIsUpdate] = useState({ update: false, id: null });
  const [boxOrders, setBoxOrders] = useState([]);
  const role = usePermissions();

  const affiliatesTeam = [
    'AFFILIATE',
    'AFFILIATE_TEAM_LEAD',
    'AFFILIATE_SUPPORT',
  ];
  const fullstacksTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];

  const getBoxOrders = () => {
    api.get(`/box-order/${box.id}`).then((res) => {
      setBoxOrders(res.data);
      setSelectedOrders(res.data.map((ob) => ob.order));
    });
  };

  useEffect(() => {
    getBoxOrders();
  }, []);

  const handleUpdate = (id, obj) => {
    api.put(`/box-order/${id}`, obj).then((_) => {
      message.success('Замовлення оновлено');
      getBoxOrders();
    });
  };

  const handleDelete = (id) => {
    api.delete(`/box-order/${id}`).then((_) => {
      message.success('Замовлення видлено з бокса');
      getBoxOrders();
    });
  };

  const handlePause = (id, pause) => {
    api.put(`/box-order/${id}`, pause).then((_) => {
      message.success(
        pause.pause ? 'Замовлення не активне' : 'Замовлення активне'
      );
      getBoxOrders();
    });
  };

  const columns = [
    {
      title: 'Назва замовлення',
      dataIndex: 'order',
      key: 'order',
      render: (order) =>
        order.isCurrentUserCantModify ? (
          <>
            {order.isActive
              ? `#${order.id} ${order.name}`
              : `#${order.id} ${order.name} (Неактивне)`}
          </>
        ) : (
          <Link to={`/orders/${order.id}`}>
            {order.isActive
              ? `#${order.id} ${order.name}`
              : `#${order.id} ${order.name} (Неактивне)`}
          </Link>
        ),
    },
    {
      title: 'Пріорітет',
      dataIndex: 'priority',
      key: 'priority',
      render: (priority, fullObj) => {
        return (
          <EditableCellBox
            value={priority}
            boxId={fullObj.id}
            isUpdate={isUpdate}
            onChange={(e) => {
              setValue({
                ...value,
                priority: +e,
              });
            }}
            name="priority"
            onUpdate={() => {
              handleUpdate(fullObj.id, value);
            }}
          />
        );
      },
    },
    {
      title: 'Вага',
      dataIndex: 'weight',
      key: 'weight',
      render: (weight, fullObj) => {
        return (
          <EditableCellBox
            value={weight}
            boxId={fullObj.id}
            isUpdate={isUpdate}
            onChange={(e) => {
              setValue({
                ...value,
                weight: +e,
              });
            }}
            name="weight"
            onUpdate={() => {
              handleUpdate(fullObj.id, value);
            }}
          />
        );
      },
    },
    {
      title: 'Денний кап',
      dataIndex: 'dailyCap',
      align: 'center',
      key: 'dailyCap',
      render: (dailyCap, bo) => {
        let percent = 0;
        let leadsForFormat = 0;
        const cc = JSON.parse(bo.order?.callCenterSchedule);
        if (cc?.timezone && dailyCap && dailyCap > 0) {
          const startOfDay = dayjs.tz(
            dayjs().startOf('day'),
            cc.timezone.split(' ')[0]
          );
          const now = dayjs();
          const leads = bo.order.leads.filter(
            (l) =>
              dayjs(l.createdAt).isBetween(startOfDay, now) &&
              !['error', 'unsigned', 'test'].includes(l.status) &&
              l.box_id == box.id
          );
          leadsForFormat = leads?.length;
          percent = (100 * leadsForFormat) / dailyCap;
        }
        return (dailyCap && dailyCap > 0) || isUpdate.update ? (
          <div
            style={{
              width: 130,
              margin: '0px auto',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Progress
              percent={percent}
              size="small"
              format={() => (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span style={{ fontSize: '15px' }}>{leadsForFormat}/</span>
                  <span style={{ fontSize: '15px' }}>
                    <EditableCellBox
                      isUpdate={isUpdate}
                      value={dailyCap}
                      boxId={bo.id}
                      onChange={(e) => {
                        setValue({
                          ...value,
                          dailyCap: +e,
                        });
                      }}
                      name="dailyCap"
                      onUpdate={() => {
                        handleUpdate(bo.id, value);
                      }}
                    />
                  </span>
                </div>
              )}
            />
          </div>
        ) : (
          ''
        );
      },
    },
    {
      render: (_, obj) => (
        <DeleteOutlined
          style={{
            color: 'red',
            fontSize: 20,
            display: 'flex',
            justifyContent: 'center',
          }}
          onClick={() => handleDelete(obj.id)}
        />
      ),
    },
    {
      render: (_, obj) => {
        if (!obj.pause) {
          return (
            <PauseOutlined
              style={{
                color: 'orange',
                fontSize: 20,
                display: 'flex',
                justifyContent: 'center',
              }}
              onClick={() => handlePause(obj.id, { pause: !obj.pause })}
            />
          );
        } else {
          return (
            <PlayCircleOutlined
              style={{
                color: 'green',
                fontSize: 20,
                display: 'flex',
                justifyContent: 'center',
              }}
              onClick={() => handlePause(obj.id, { pause: !obj.pause })}
            />
          );
        }
      },
    },
    {
      render: (_, obj) => (
        <EditOutlined
          style={{
            color: 'blue',
            fontSize: 20,
            display: 'flex',
            justifyContent: 'center',
          }}
          onClick={() => setIsUpdate({ update: !isUpdate.update, id: obj.id })}
        />
      ),
    },
  ];

  const fetchOrders = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/order?filter.name=$ilike:${name}&filter.isActive=$eq:true&filter.buyers.id=$eq:${
        box.buyerId
      }${
        selectedOrders.length
          ? `&filter.id=$not:$in:${selectedOrders
              .map((o) => o.id.toString())
              .join(',')}`
          : ''
      }${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchOrders = async (name) => {
    const res = await api.get(
      `/order?limit=10${
        selectedOrders.length
          ? `&filter.id=$not:$in:${selectedOrders
              .map((o) => o.id.toString())
              .join(',')}`
          : ''
      }&filter.isActive=$eq:true&filter.buyers.id=$eq:${box.buyerId}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const onItemAdd = () => {
    api
      .post('/box-order', {
        orderId: form.getFieldValue('order').value,
        boxId: +box.id,
        priority: +form.getFieldValue('priority'),
        weight: +form.getFieldValue('weight'),
        dailyCap: +form.getFieldValue('dailyCap'),
      })
      .then((_) => {
        message.success('Бокс додано');
        setIsModalOpen(false);
        form.resetFields();
        getBoxOrders();
      });
  };

  return (
    <div>
      {hasPermissions(role, affiliatesTeam.concat(fullstacksTeam)) && (
        <div className={styles.ordersTableButtonContainer}>
          <Button
            disabled={!box.id}
            className={styles.addButton}
            type="primary"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <PlusOutlined />
          </Button>
        </div>
      )}
      <Table dataSource={boxOrders} columns={columns} />
      <Modal
        title="Додати замовлення до бокса"
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <Form
          wrapperCol={{ span: 12, offset: 1 }}
          labelCol={{ span: 7, offset: 1 }}
          form={form}
          onFinish={onItemAdd}
        >
          <Form.Item
            name="order"
            label="Замовлення"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <AutocompleteSelect
              className={styles.input}
              value={selectedOrders}
              placeholder="Замовлення"
              initialFetch={initialFetchOrders}
              fetch={fetchOrders}
              onChange={setSelectedOrders}
            />
          </Form.Item>
          <Form.Item
            name="priority"
            label="Пріорітет"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            name="weight"
            label="Вага"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Tooltip title="Залиште пустим, або вкажіть 0, щоб залишити без обмеження">
            <Form.Item name="dailyCap" label="Денний кап">
              <Input
                placeholder="Денний кап"
                type="number"
                rootClassName={styles.input}
              />
            </Form.Item>
          </Tooltip>
          <Form.Item wrapperCol={{ offset: 11 }}>
            <Button type="primary" htmlType="submit">
              Створити
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
