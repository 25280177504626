import { useNavigate } from 'react-router-dom';
import api from '../../../api/api';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { OrdersForm } from '../OrderForm/OrdersForm';

export const CreateOrder = () => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const affiliatesTeam = [
    'AFFILIATE',
    'AFFILIATE_TEAM_LEAD',
    'AFFILIATE_SUPPORT',
  ];
  const bizdevsTeam = ['BIZDEV', 'BIZDEV_TEAM_LEAD', 'BIZDEV_SUPPORT'];
  const fullstackTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  usePermissionsAllowed(
    affiliatesTeam.concat(bizdevsTeam).concat(fullstackTeam)
  );

  const onFinish = (order) => {
    api
      .post('/order', {
        ...order,
      })
      .then((_) => {
        navigate('/orders');
        message.success('Замовлення додано');
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  return (
    <>
      <OrdersForm onFinish={onFinish} />
    </>
  );
};
