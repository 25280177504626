import { App, Button, Checkbox, Form, Input, Select } from 'antd';
import styles from './InjectForm.module.css';
import React, { useContext, useEffect, useState } from 'react';
import api from '../../../api/api';
import { useNavigate } from 'react-router-dom';
import { AutocompleteSelect } from '../../../shared/AutocompleteSelect/AutocompleteSelect';
import { CountrySelect } from '../../../shared/CountryMultiselect/CountrySelect';
import { getRandom } from 'random-useragent';
import { usePermissions } from '../../../hooks/usePermissionsAllowed';
import { CurrentUserContext } from '../../../providers/AuthProvider';

const { Option } = Select;

export const InjectForm = () => {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const { TextArea } = Input;
  const [values] = useState();
  const navigate = useNavigate();
  // const [selectedUser, setSelectedUser] = useState();
  const [selectedBuyer, setSelectedBuyer] = useState();
  const [selectedOrder, setSelectedOrder] = useState();
  const [loadings, setLoadings] = useState(false);
  const [protocol, setProtocol] = useState('http://');

  const onFinish = (infoLeads) => {
    api
      .post(`/lead/inject`, {
        ...infoLeads,
      })
      .then((_) => {
        navigate('/leads');
        message.success('Лід відправлено');
        setLoadings(false);
      })
      .catch((e) => {
        e.response.data.message[0].error
          ? message.error(e.response.data.message[0].error)
          : message.error(e.response.data.message) ||
            message.error(e.response.statusText);
        setLoadings(false);
      });
  };
  useEffect(() => {
    onGenerateUserAgent();
  }, []);

  const onGenerateUserAgent = () => {
    const mobileAndTabletBrowsersNames = [
      'Mobile Safari',
      'Android Browser',
      'IEMobile',
      'NetFront',
      'Mobile Opera',
      'Samsung Browser',
    ];
    const browserNames = [
      'IE',
      'Chrome',
      'Google Chrome',
      'Safari',
      'Firefox',
      'Yandex',
      'Opera',
      'Chromium',
    ];
    const mobileOsNames = ['Windows Phone OS', 'Android', 'iOS', 'ChromeOS'];
    const osNames = ['Linux', 'Windows', 'Debian', 'Mac OS'];
    const mobileDeviceVendors = [
      'htc',
      'Apple',
      'Samsung',
      'Windows Phone',
      'Nokia',
      'Xiaomi',
      'ASUS',
      'Google',
      'Honor',
      'Huawei',
      'Meizu',
      'OPPO',
      'OnePlus',
      'Pixel',
      'Redmi',
      'Razer',
    ];
    const checkWindowsVersion = (os, osVersion) =>
      os === 'Windows' && Number(osVersion) >= 7;
    const checkAndroidOsVersion = (os, osVersion) =>
      os === 'Android' && parseFloat(osVersion) >= 4.4;

    const userAgent = getRandom(function (ua) {
      if (!ua.deviceType)
        return (
          browserNames.includes(ua.browserName) &&
          osNames.includes(ua.osName) &&
          checkWindowsVersion(ua.osName, ua.osVersion)
        );
      return (
        mobileAndTabletBrowsersNames.includes(ua.browserName) &&
        mobileOsNames.includes(ua.osName) &&
        mobileDeviceVendors.includes(ua.deviceVendor) &&
        checkAndroidOsVersion(ua.osName, ua.osVersion)
      );
    });
    form.setFieldValue('userAgent', userAgent);
  };

  const fetchProxy = async () => {
    const res = await api.get(`/lead/proxy/`);
    return res.data;
  };

  const onCountryChange = (value) => {
    form.setFieldValue('countries', value);
  };

  const fetchBuyersByOrder = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/buyer?limit=10&filter.isActive=$eq:true&filter.name=$ilike:${name}&filter.orders.id=$eq:${selectedOrder?.value}`
    );
    if (res.length !== 0)
      return res.data.data.map((b) => ({
        label: b.name,
        value: b.id,
      }));
  };

  const initialFetchBuyersByOrder = async () => {
    const res = await api.get(
      `/buyer?limit=10&filter.isActive=$eq:true&filter.orders.id=$eq:${selectedOrder?.value}`
    );
    if (res.length !== 0)
      return res.data.data.map((b) => ({
        label: b.name,
        value: b.id,
      }));
  };

  const initialFetchOrders = async (name) => {
    const res = await api.get(`/order?limit10&filter.isActive=$and:$eq:true&`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const fetchOrders = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/order?filter.name=$ilike:${name}&filter.isActive=$eq:true`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  useEffect(() => {
    setSelectedBuyer(null);
    form.setFieldValue('buyer', {
      label: undefined,
      value: undefined,
    });
  }, [selectedOrder]);

  const selectBefore = (
    <Select defaultValue="http://" onChange={setProtocol}>
      <Option value="http://">http://</Option>
      <Option value="https://">https://</Option>
    </Select>
  );

  return (
    <Form
      form={form}
      onFinish={() => {
        setLoadings(true);
        onFinish({
          ...form.getFieldsValue(),
          orderId: selectedOrder?.value,
          buyerId: selectedBuyer?.value,
          userAgent: form.getFieldsValue().userAgent,
        });
      }}
      labelCol={{ span: 11 }}
      wrapperCol={{ span: 14 }}
    >
      <Form.Item
        name="name"
        label="Ім'я"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Ім'я" rootClassName={styles.input} />
      </Form.Item>
      <Form.Item
        name="lastName"
        label="Прізвище"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Прізвище" rootClassName={styles.input} />
      </Form.Item>
      <Form.Item
        name="phone"
        label="Телефон"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Телефон" rootClassName={styles.input} />
      </Form.Item>
      <Form.Item
        name="email"
        label="Email"
        rules={[
          {
            required: true,
            type: 'email',
          },
        ]}
      >
        <Input placeholder="email" rootClassName={styles.input} />
      </Form.Item>
      <Form.Item
        name="ip"
        label="Ip"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Ip" rootClassName={styles.input} />
      </Form.Item>

      <Form.Item
        name="country"
        label="Країна"
        rules={[
          {
            required: true,
            max: 2,
          },
        ]}
      >
        <CountrySelect onChange={onCountryChange} className={styles.input} />
      </Form.Item>

      <Form.Item
        name="lang"
        label="Мова"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <CountrySelect onChange={onCountryChange} className={styles.input} />
      </Form.Item>
      <Form.Item
        name="orderId"
        label="Замовлення"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <AutocompleteSelect
          className={styles.input}
          value={selectedOrder}
          placeholder="Замовлення"
          initialFetch={initialFetchOrders}
          fetch={fetchOrders}
          onChange={setSelectedOrder}
        />
      </Form.Item>
      <Form.Item
        name="buyerId"
        label="Баєр"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <AutocompleteSelect
          className={styles.input}
          value={selectedBuyer}
          placeholder="Баєр"
          initialFetch={initialFetchBuyersByOrder}
          fetch={fetchBuyersByOrder}
          onChange={setSelectedBuyer}
          disabled={!selectedOrder}
        />
      </Form.Item>
      <Form.Item
        name="userAgent"
        label="User agent"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <TextArea
          rows={4}
          value={values}
          placeholder="User agent"
          name="userAgent"
          className={styles.input}
          style={{ resize: 'none' }}
        />
      </Form.Item>
      <Form.Item wrapperCol={{ offset: 12 }}>
        <Button type="primary" onClick={onGenerateUserAgent}>
          Генерація
        </Button>
      </Form.Item>

      <Form.Item name="sub1" label="sub1">
        <Input placeholder="sub1" rootClassName={styles.input} />
      </Form.Item>
      <Form.Item name="sub2" label="sub2">
        <Input placeholder="sub2" rootClassName={styles.input} />
      </Form.Item>
      <Form.Item name="sub3" label="sub3">
        <Input placeholder="sub5" rootClassName={styles.input} />
      </Form.Item>
      <Form.Item name="sub4" label="sub4">
        <Input placeholder="sub4" rootClassName={styles.input} />
      </Form.Item>
      <Form.Item name="sub5" label="sub5">
        <Input placeholder="sub1" rootClassName={styles.input} />
      </Form.Item>
      <Form.Item name="sub6" label="sub6">
        <Input placeholder="sub7" rootClassName={styles.input} />
      </Form.Item>
      <Form.Item name="sub7" label="sub7">
        <Input placeholder="sub7" rootClassName={styles.input} />
      </Form.Item>
      <Form.Item name="sub8" label="sub8">
        <Input placeholder="sub8" rootClassName={styles.input} />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 12 }}>
        <Button type="primary" htmlType="submit" loading={loadings}>
          Відправити
        </Button>
      </Form.Item>
    </Form>
  );
};
