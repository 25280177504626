import { useNavigate } from 'react-router-dom';
import api from '../../../api/api';
import { App } from 'antd';
import { RegwayForm } from '../RegwayForm/RegwayForm';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';

export const CreateRegway = () => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  usePermissionsAllowed(['SUPPORT', 'TEAM_LEAD']);

  const onFinish = (server) => {
    api
      .post('/domain-purchase/regway', {
        ...server,
      })
      .then((res) => {
        message.success('Regway додано');
        navigate(`/regway`);
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  return <RegwayForm onFinish={onFinish} />;
};
