import React, { useContext, useEffect, useState } from 'react';
import api from '../../api/api';
import { inputToFilter } from '../../utils/inputToFilter';
import { Link, useSearchParams } from 'react-router-dom';
import { fromApiToClient } from '../../utils/dateFilters';
import { useDidUpdateEffect } from '../../hooks/useDidUpdateEffect';
import { translateType } from '../../utils/translateStatus';
import { StatusDetailTag } from '../../shared/StatusDetailTag/StatusDetailTag';
import { socket } from '../../api/socket';
import { CurrentUserContext } from '../../providers/AuthProvider';
import { Button, message, Progress, Table, Tooltip } from 'antd';
import { CloseCircleOutlined, DeleteOutlined } from '@ant-design/icons';
import { DetailModal } from './DetailModal';

export const Tasks = (props) => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [countPage, setCountPage] = useState(10);
  const url = searchParams.get('filters');
  const querystr = JSON.parse(url) ? JSON.parse(url) : null;
  const [isInjectModalOpen, setIsInjectModalOpen] = useState(false);
  const [isTaskDetail, setIsTaskDetail] = useState([]);
  const [filterValues, setFilterValues] = useState({
    isActive: !!querystr?.length ? '$ilike:' : '$eq:true',
  });
  const user = useContext(CurrentUserContext);
  const handleInjectModalCancel = () => {
    setIsInjectModalOpen(false);
  };
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    if (user) {
      setColumns([
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          show: true,
        },
        {
          title: 'Тип',
          dataIndex: 'type',
          key: 'type',
          render: (type) => translateType(type),
        },
        {
          title: 'Прогрес',
          dataIndex: '',
          key: '',
          render: (_, task) => {
            if (task.type === 'purchase') return '';
            return (
              <Progress
                percent={task.perc}
                status={task.perc >= 100 ? 'success' : 'active'}
              />
            );
          },
        },
        {
          title: 'Загальна кількість',
          dataIndex: 'count',
          key: 'count',
        },
        {
          title: 'Статус',
          dataIndex: 'status',
          key: 'status',
          render: (status) => {
            return <StatusDetailTag status={status} />;
          },
        },
        {
          title: 'Дата початку',
          dataIndex: 'dateStart',
          key: 'dateStart',
          render: (dateStart) => fromApiToClient(dateStart, user.timezone),
        },
        {
          title: 'Дата кінця',
          dataIndex: 'dateEnd',
          key: 'dateEnd',
          render: (dateEnd) => fromApiToClient(dateEnd, user.timezone),
        },
        {
          title: '',
          dataIndex: '',
          key: 'actions',
          render: (_, obj) => {
            return <ActionLogs obj={obj} />;
          },
        },
        {
          title: '',
          dataIndex: '',
          key: 'actions',
          render: (_, obj) => {
            return (
              <Button
                style={{ marginLeft: 15 }}
                type="primary"
                onClick={() => {
                  setIsInjectModalOpen(true);
                  setIsTaskDetail(obj);
                }}
              >
                Журнал
              </Button>
            );
          },
        },
        {
          title: 'Користувач',
          dataIndex: 'userId',
          key: 'userId',
          render: (userId, obj) => (
            <Link to={`/users/${userId}`}>{obj.user.nickname}</Link>
          ),
        },
      ]);
      const events = [
        'task-progress',
        'update-import-progress',
        'update-resend-progress',
        'update-import-batch-progress',
        'update-progress',
      ];
      for (const event of events) {
        socket.on(event, (msg) => {
          setColumns([
            {
              title: 'ID',
              dataIndex: 'id',
              key: 'id',
              show: true,
            },
            {
              title: 'Тип',
              dataIndex: 'type',
              key: 'type',
              render: (type) => translateType(type),
            },
            {
              title: 'Прогрес',
              dataIndex: '',
              key: '',
              render: (_, task) => {
                if (task.type === 'purchase') return '';
                if (task.id === msg.sessionId) {
                  return (
                    <span>
                      <Progress
                        percent={msg.perc}
                        status={msg.perc >= 100 ? 'success' : 'active'}
                      />
                    </span>
                  );
                } else {
                  return (
                    <Progress
                      percent={task.perc}
                      status={task.perc >= 100 ? 'success' : 'active'}
                    />
                  );
                }
              },
            },
            {
              title: 'Загальна кількість',
              dataIndex: 'count',
              key: 'count',
            },
            {
              title: 'Статус',
              dataIndex: 'status',
              key: 'status',
              render: (status) => {
                return <StatusDetailTag status={status} />;
              },
            },
            {
              title: 'Дата початку',
              dataIndex: 'dateStart',
              key: 'dateStart',
              render: (dateStart) => fromApiToClient(dateStart, user.timezone),
            },
            {
              title: 'Дата кінця',
              dataIndex: 'dateEnd',
              key: 'dateEnd',
              render: (dateEnd) => fromApiToClient(dateEnd, user.timezone),
            },
            {
              title: '',
              dataIndex: '',
              key: 'actions',
              render: (_, obj) => {
                return <ActionLogs obj={obj} />;
              },
            },
            {
              title: '',
              dataIndex: '',
              key: 'actions',
              render: (_, obj) => {
                return (
                  <Button
                    style={{ marginLeft: 15 }}
                    type="primary"
                    onClick={() => {
                      setIsInjectModalOpen(true);
                      setIsTaskDetail(obj);
                    }}
                  >
                    Журнал
                  </Button>
                );
              },
            },
            {
              title: 'Користувач',
              dataIndex: 'userId',
              key: 'userId',
              render: (userId, obj) => (
                <Link to={`/users/${userId}`}>{obj.user.nickname}</Link>
              ),
            },
          ]);
        });
      }
    }
  }, [user]);
  const ActionLogs = ({ obj }) => {
    const [task, setTask] = useState({ ...obj });
    const handlePauseClick = (id, isActive) => {
      setTask({
        ...task,
        isActive: !isActive,
      });
      api.put(`/task/remove/${id}`, { type: task.type }).then(() => {
        message.success('Чергу видалено');
      });
    };
    return task.isActive && !['error', 'success'].includes(task.status) ? (
      <Tooltip title="Видалити чергу">
        <DeleteOutlined
          onClick={() => handlePauseClick(task.id, task.isActive)}
        />
      </Tooltip>
    ) : (
      <Tooltip title="Черга видалена">
        <CloseCircleOutlined />
      </Tooltip>
    );
  };

  useEffect(() => {
    let res = '';
    if (!url) {
      res = 'filter.isActive=$and:$eq:true&';
    }
    for (let i in querystr) {
      res += `filter.${querystr[i][1]}=$and:${querystr[i][2]}&`;
    }
    api.get('/task?' + res + 'page=1&limit=10').then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
    });
  }, []);

  useDidUpdateEffect(() => {
    const url = inputToFilter(filterValues, currentPage, countPage);
    api.get(`/task?${url}`).then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
      window.scrollTo(0, 0);
    });
  }, [filterValues, currentPage, countPage]);
  const onFiltersChange = (name, value) => {
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  };

  const onFiltersSet = (filters) => {
    let object = {};
    for (let i in filters) {
      object[filters[i][1]] = filters[i][2];
    }
    setFilterValues({
      ...object,
    });
  };
  const onTableChange = (pagination) => {
    setCountPage(pagination.pageSize);
    setCurrentPage(pagination.current);
  };
  return (
    <div>
      <DetailModal
        task={isTaskDetail}
        isModalOpen={isInjectModalOpen}
        handleCancel={handleInjectModalCancel}
      />
      <Table
        columns={columns}
        pagination={{
          total,
          current: currentPage,
          pageSize: countPage,
          showSizeChanger: true,
        }}
        onChange={onTableChange}
        onColumnsChange={setColumns}
        dataSource={data}
        dataPrefix="task"
        querystr={querystr}
      />
    </div>
  );
};
