import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { ServersForm } from '../ServersForm/ServersForm';

export const EditServers = () => {
  const { message } = App.useApp();
  const [server, setServer] = useState();
  const { id } = useParams();
  usePermissionsAllowed(['SUPPORT', 'TEAM_LEAD']);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    api.get(`/domain-purchase/server/${id}`).then((res) => {
      setServer({
        ...res.data,
      });
      setIsLoading(false);
    });
  }, []);

  const onFinish = (updatedServer) => {
    delete updatedServer.updatedAt;
    delete updatedServer.createdAt;
    api
      .put(`/domain-purchase/server/${id}`, {
        ...updatedServer,
      })
      .then((_) => {
        navigate(-1);
        message.success('Сервер оновлено');
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  if (isLoading) return 'loading';

  return <ServersForm onFinish={onFinish} server={server} />;
};
