import React, { useContext, useEffect, useState } from 'react';

import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { statuses } from '../../../utils/statusOptions';
import {
  App,
  DatePicker,
  Divider,
  FloatButton,
  Form,
  Select,
  Spin,
  Table,
} from 'antd';
import dayjs from 'dayjs';
import api from '../../../api/api';
import {
  fromApiToClientDate,
  fromApiToClientMonth,
} from '../../../utils/dateFilters';
import { ReportChart } from './ReportChart';
import { StatusTag } from '../../../shared/StatusTag/StatusTag';
import { TagsSelector } from '../../../shared/TagsSelector/TagsSelector';
import { AutocompleteSelect } from '../../../shared/AutocompleteSelect/AutocompleteSelect';
import { CountrySelect } from '../../../shared/CountryMultiselect/CountrySelect';
import { CurrentUserContext } from '../../../providers/AuthProvider';
import { EditableCell } from '../../../shared/EditableCell/EditableCell';
import { round } from 'lodash/math';

export const Reports = () => {
  const translateGrouping = (g) => {
    if (g === 'box') return 'Бокс';
    if (g === 'day') return 'День';
    if (g === 'month') return 'Місяць';
    if (g === 'customer') return 'Замовник';
    if (g === 'country') return 'Країна';
    if (g === 'app_order') return 'Замовлення';
    if (g === 'buyer') return 'Баєр';
    if (g === 'affiliate') return 'Аффілейт';
    if (g === 'bizdev') return 'Біздев';
  };

  const translateCols = (g) => {
    if (g === 'leads') return 'Ліди';
    if (g === 'price') return 'Дохід';
    if (g === 'percent') return 'Проценти';
  };

  const { message } = App.useApp();
  const [isLoading, setIsLoading] = useState(false);
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [isLocalstorage, setIsLocalstorage] = useState(false);
  const [groupings, setGroupings] = useState([
    { value: 'month', label: 'Місяць' },
  ]);
  const [groupingsChart, setGroupingsChart] = useState([
    { value: 'day', label: 'Дні' },
  ]);
  const [data, setData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [subCols, setSubCols] = useState(['leads']);
  const [filters, setFilters] = useState({
    from: dayjs().subtract(14, 'days').startOf('day'),
    to: dayjs().endOf('day'),
  });

  const currentUser = useContext(CurrentUserContext);

  const affiliatesTeam = [
    'AFFILIATE',
    'AFFILIATE_TEAM_LEAD',
    'AFFILIATE_SUPPORT',
  ];
  const bizdevsTeam = ['BIZDEV', 'BIZDEV_TEAM_LEAD', 'BIZDEV_SUPPORT'];

  const [columns, setColumns] = useState([
    ...groupings.map((g) => ({
      title: g.label,
      dataIndex: g.value,
      key: g.value,
    })),
    {
      title: 'Всього',
      key: 'total',
      align: 'center',
      children: [
        {
          title: 'Ліди',
          dataIndex: 'totalLeads',
          key: 'totalLeads',
          sorter: (a, b) => a.totalLeads - b.totalLeads,
          align: 'center',
        },
      ],
    },
    ...statuses
      .filter((s) => s !== 'unsigned' && s !== 'test' && s !== 'error')
      .map((s) => ({
        title: <StatusTag status={s} />,
        dataIndex: 'statusData',
        key: 'statusData',
        align: 'center',
        children: [
          {
            title: 'Ліди',
            key: `leads${s}`,
            sorter: (a, b) => {
              const statusA =
                a.statusData.find((sd) => sd.status === s)?.leads || 0;
              const statusB =
                b.statusData.find((sd) => sd.status === s)?.leads || 0;
              return statusA - statusB;
            },
            align: 'center',
            render: (_, obj) => {
              const updateValue = (e, oldValue) => {
                localStorage.removeItem('data');
                let keyUser = '';
                let keyStatus = '';
                let keyValue = '';
                for (const eKey in e) {
                  keyValue = e[eKey];
                  keyUser = eKey.split('/')[1];
                  keyStatus = eKey.split('/')[0];
                }
                let allLeads = 0;
                let allLeadBefore = 0;
                oldValue.statusData
                  .filter((y) => y.status === keyStatus)
                  .map((y) => {
                    allLeadBefore = y.leads;
                  });
                data
                  .filter((e) => {
                    let flag = false;
                    for (let i = 0; i < groupings.length; i++) {
                      if (
                        e[groupings[i]?.value] ||
                        e[groupings[i]?.value] == null
                      ) {
                        if (
                          e[groupings[i]?.value] ===
                          oldValue[groupings[i]?.value]
                        ) {
                          flag = true;
                        } else {
                          flag = false;
                          break;
                        }
                      }
                    }
                    return flag;
                  })
                  .map((e) => {
                    e.statusData
                      .filter((y) => y.status === keyStatus)
                      .map((y) => {
                        y.leads = +keyValue;
                      });
                    e.statusData.map((r) => {
                      allLeads += r.leads;
                    });
                    e.totalLeads = +allLeads;
                  });
                data.map((e) => {
                  e.statusData.map((v) => {
                    const percent = (v.leads * 100) / e.totalLeads;
                    v.percent = round(percent, 2);
                  });
                });
                if (allLeadBefore - +keyValue > 0) {
                  let totalSum = allLeadBefore - +keyValue;
                  while (totalSum > 0) {
                    reportData
                      .filter((e) => {
                        if (
                          groupingsChart?.[0]?.value &&
                          oldValue?.[groupingsChart[0].value]
                        ) {
                          return (
                            e[groupingsChart[0].value] ===
                            oldValue[groupingsChart[0].value]
                          );
                        } else {
                          return true;
                        }
                      })
                      .forEach((e) => {
                        e.statusData
                          .filter((e) => e.status === keyStatus)
                          .map((y) => {
                            if (totalSum > 0 && y.leads > 0) {
                              y.leads--;
                              totalSum--;
                            }
                          });
                      });
                  }
                } else {
                  let totalSum = +keyValue - allLeadBefore;
                  while (totalSum > 0) {
                    reportData
                      .filter((e) => {
                        if (oldValue?.[groupingsChart[0].value] === null) {
                          totalSum = 0;
                          return false;
                        }
                        if (
                          groupingsChart?.[0]?.value &&
                          oldValue?.[groupingsChart[0].value]
                        ) {
                          return (
                            e[groupingsChart[0].value] ===
                            oldValue[groupingsChart[0].value]
                          );
                        } else {
                          return true;
                        }
                      })
                      .forEach((e) => {
                        e.statusData
                          .filter((e) => e.status === keyStatus)
                          .map((y) => {
                            if (totalSum > 0) {
                              y.leads++;
                              totalSum--;
                            }
                          });
                      });
                  }
                }
                setData([...data]);
                localStorage.setItem(
                  'data',
                  JSON.stringify({
                    data: data,
                    reportData: reportData,
                    groupings: groupings,
                    filters: filters,
                    groupingsChart: groupingsChart,
                    subCols: subCols,
                  })
                );
              };
              return (
                <EditableCell
                  name={`${s}/${obj.app_user}`}
                  onUpdate={(val) => updateValue(val)}
                  value={obj.statusData.find((sd) => sd.status === s)?.leads}
                />
              );
            },
          },
        ],
      })),
  ]);

  useEffect(() => {
    setColumns([
      ...groupings.map((g) => ({
        title: g.label,
        dataIndex: g.value,
        key: g.value,
        render: (val, obj) => {
          if (g.value === 'day') {
            return fromApiToClientDate(obj.day);
          }
          if (g.value === 'month') {
            return fromApiToClientMonth(obj.month);
          }
          return val;
        },
      })),
      {
        title: 'Всього',
        key: 'total',
        align: 'center',
        children: subCols.map((col) => {
          return {
            title: translateCols(col),
            dataIndex: `total${col.charAt(0).toUpperCase() + col.slice(1)}`,
            key: `total${col.charAt(0).toUpperCase() + col.slice(1)}`,
            align: 'center',
            render: (val, obj) =>
              col === 'price'
                ? `$${obj.totalPrice}`
                : obj[`total${col.charAt(0).toUpperCase() + col.slice(1)}`],
          };
        }),
      },
      ...statuses
        .filter((s) => s !== 'unsigned' && s !== 'test' && s !== 'error')
        .map((s) => ({
          title: <StatusTag status={s} />,
          dataIndex: 'statusData',
          key: 'statusData',
          align: 'center',
          children: subCols.map((col) => ({
            title: translateCols(col),
            key: col,
            align: 'center',
            sorter: (a, b) => {
              const statusA =
                a.statusData.find((sd) => sd.status === s)?.leads || 0;
              const statusB =
                b.statusData.find((sd) => sd.status === s)?.leads || 0;
              return statusA - statusB;
            },
            render: (_, obj) => {
              if (col === 'percent') {
                const data = obj.statusData.find((sd) => sd.status === s)?.[
                  col
                ];
                return data ? `${data}%` : '';
              }
              if (col === 'price') {
                const data = obj.statusData.find((sd) => sd.status === s)?.[
                  col
                ];
                return data ? `$${data}` : '';
              }
              const updateValue = (e, oldValue) => {
                localStorage.removeItem('data');
                let keyUser = '';
                let keyStatus = '';
                let keyValue = '';
                for (const eKey in e) {
                  keyValue = e[eKey];
                  keyUser = eKey.split('/')[1];
                  keyStatus = eKey.split('/')[0];
                }
                let allLeads = 0;
                let allLeadBefore = 0;
                oldValue.statusData
                  .filter((y) => y.status === keyStatus)
                  .map((y) => {
                    allLeadBefore = y.leads;
                  });
                data
                  .filter((e) => {
                    let flag = false;
                    for (let i = 0; i < groupings.length; i++) {
                      if (
                        e[groupings[i]?.value] ||
                        e[groupings[i]?.value] == null
                      ) {
                        if (
                          e[groupings[i]?.value] ===
                          oldValue[groupings[i]?.value]
                        ) {
                          flag = true;
                        } else {
                          flag = false;
                          break;
                        }
                      }
                    }
                    return flag;
                  })
                  .map((e) => {
                    e.statusData
                      .filter((y) => y.status === keyStatus)
                      .map((y) => {
                        y.leads = +keyValue;
                      });
                    e.statusData.map((r) => {
                      allLeads += r.leads;
                    });
                    e.totalLeads = +allLeads;
                  });
                data.map((e) => {
                  e.statusData.map((v) => {
                    const percent = (v.leads * 100) / e.totalLeads;
                    v.percent = round(percent, 2);
                  });
                });
                if (allLeadBefore - +keyValue > 0) {
                  let totalSum = allLeadBefore - +keyValue;
                  while (totalSum > 0) {
                    reportData
                      .filter((e) => {
                        if (
                          groupingsChart?.[0]?.value &&
                          oldValue?.[groupingsChart[0].value]
                        ) {
                          return (
                            e[groupingsChart[0].value] ===
                            oldValue[groupingsChart[0].value]
                          );
                        } else {
                          return true;
                        }
                      })
                      .forEach((e) => {
                        e.statusData
                          .filter((e) => e.status === keyStatus)
                          .map((y) => {
                            if (totalSum > 0 && y.leads > 0) {
                              y.leads--;
                              totalSum--;
                            }
                          });
                      });
                  }
                } else {
                  let totalSum = +keyValue - allLeadBefore;
                  while (totalSum > 0) {
                    reportData
                      .filter((e) => {
                        if (oldValue?.[groupingsChart[0].value] === null) {
                          totalSum = 0;
                          return false;
                        }
                        if (
                          groupingsChart?.[0]?.value &&
                          oldValue?.[groupingsChart[0].value]
                        ) {
                          return (
                            e[groupingsChart[0].value] ===
                            oldValue[groupingsChart[0].value]
                          );
                        } else {
                          return true;
                        }
                      })
                      .forEach((e) => {
                        e.statusData
                          .filter((e) => e.status === keyStatus)
                          .map((y) => {
                            if (totalSum > 0) {
                              y.leads++;
                              totalSum--;
                            }
                          });
                      });
                  }
                }
                setData([...data]);
                localStorage.setItem(
                  'data',
                  JSON.stringify({
                    data: data,
                    reportData: reportData,
                    groupings: groupings,
                    filters: filters,
                    groupingsChart: groupingsChart,
                    subCols: subCols,
                  })
                );
              };
              return (
                <EditableCell
                  name={`${s}/${obj.app_user}`}
                  onUpdate={(val) => updateValue(val, obj)}
                  value={obj.statusData.find((sd) => sd.status === s)?.leads}
                />
              );
            },
          })),
        })),
    ]);
  }, [reportData, data, groupings, groupingsChart, filters, subCols]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('data'));
    if (!data) {
      getReport();
    }
  }, [groupings, filters]);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('data'));
    if (data) {
      setIsLocalstorage(true);
      if (data?.data) {
        setData([...data?.data]);
      }
      if (data?.subCols) {
        setSubCols([...data?.subCols]);
      }
      if (data?.groupings) {
        setGroupings([...data?.groupings]);
      }
      if (data?.filters) {
        if (data?.filters?.from) {
          data.filters.from = dayjs(data?.filters?.from);
        }
        if (data?.filters?.to) {
          data.filters.to = dayjs(data?.filters?.to);
        }
        setFilters(data?.filters);
      }
    }
    if (data?.reportData) {
      setGroupingsChart([...data?.groupingsChart]);
      setReportData([...data?.reportData]);
    }
  }, []);

  useEffect(() => {
    const data = JSON.parse(localStorage.getItem('data'));
    if (!data?.reportData) {
      getReportChartData();
    }
  }, [filters, groupingsChart]);

  const getReport = () => {
    setIsLoading(true);
    let df = {};
    for (const ffKey in filters) {
      if (filters[ffKey]?.value) {
        df[ffKey] = filters[ffKey].value;
      } else {
        df[ffKey] = filters[ffKey];
      }
    }
    api
      .get(
        `/report?groupings=${groupings
          .map((g) => g?.value)
          .join(',')}&filters=${JSON.stringify(df)}`
      )
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((e) => {
        message.error(e.response.data?.message);
      });
  };

  const getReportChartData = () => {
    setIsReportLoading(true);
    let df = {};
    for (const ffKey in filters) {
      if (filters[ffKey]?.value) {
        df[ffKey] = filters[ffKey].value;
      } else {
        df[ffKey] = filters[ffKey];
      }
    }
    api
      .get(
        `/report/chart?groupings=${
          groupingsChart[0]?.value
        }&filters=${JSON.stringify(df)}`
      )
      .then((res) => {
        setReportData(res.data);
        setIsReportLoading(false);
      })
      .catch((e) => {
        message.error(e.response.data?.message);
      });
  };

  const fetchOrders = async (name) => {
    const res = await api.get(`/order?filter.name=$ilike:${name}`);
    return res.data.data.map((c) => ({
      label: c.name,
      value: c.id,
    }));
  };

  const initialFetchOrders = async () => {
    const res = await api.get(`/order?limit=10`);
    return res.data.data.map((c) => ({
      label: c.name,
      value: c.id,
    }));
  };

  const fetchBoxes = async (name) => {
    const res = await api.get(`/box?filter.name=$ilike:${name}`);
    return res.data.data.map((c) => ({
      label: c.name,
      value: c.id,
    }));
  };

  const initialFetchBoxes = async () => {
    const res = await api.get(`/box?limit=10`);
    return res.data.data.map((c) => ({
      label: c.name,
      value: c.id,
    }));
  };

  const fetchBuyers = async (name) => {
    const res = await api.get(
      `/buyer?limit=10&filter.isActive=$eq:true&filter.name=$ilike:${name}`
    );
    if (res.length !== 0)
      return res.data.data.map((b) => ({
        label: b.name,
        value: b.id,
      }));
  };

  const initialFetchBuyers = async () => {
    const res = await api.get(`/buyer?limit=10&filter.isActive=$eq:true`);
    if (res.length !== 0)
      return res.data.data.map((b) => ({
        label: b.name,
        value: b.id,
      }));
  };

  const fetchAffiliates = async (name) => {
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:AFFILIATE&filter.role=$or:$eq:FULLSTACK&filter.nickname=$ilike:${name}`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: u.name,
        value: u.id,
      }));
  };

  const initialFetchAffiliates = async (name) => {
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:AFFILIATE&filter.role=$or:$eq:FULLSTACK`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: u.name,
        value: u.id,
      }));
  };

  const fetchBizdevs = async (name) => {
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:BIZDEV&filter.role=$or:$eq:FULLSTACK&filter.name=$ilike:${name}`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: u.name,
        value: u.id,
      }));
  };

  const initialFetchBizdevs = async (name) => {
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:BIZDEV&filter.role=$or:$eq:FULLSTACK`
    );
    return res.data.data.map((u) => ({
      label: u.name,
      value: u.id,
    }));
  };

  const user = useContext(CurrentUserContext);
  if (!user) return;
  const allowedColumnsFromUser = JSON.parse(user.permissions.columns).leads;
  const groupingOptionsArray = [
    {
      value: 'day',
      label: 'День',
      dataIndex: 'createdAt',
    },
    {
      value: 'month',
      label: 'Місяць',
      dataIndex: 'createdAt',
    },
    {
      value: 'box',
      label: 'Бокс',
      dataIndex: 'box_id',
    },
    {
      value: 'app_order',
      label: 'Замовлення',
      dataIndex: 'order_id',
    },
    {
      value: 'customer',
      label: 'Замовник',
    },
    {
      value: 'country',
      label: 'Країна',
      dataIndex: 'country',
    },
    {
      value: 'buyer',
      label: 'Баєр',
      dataIndex: 'buyer_id',
    },
    {
      value: 'affiliate',
      label: 'Аффілейт',
      dataIndex: 'affiliate_id',
    },
    {
      value: 'bizdev',
      label: 'Біздев',
      dataIndex: 'bizdev_id',
    },
  ];

  const groupingOptions = groupingOptionsArray
    .filter((o) => !groupings.find((g) => g.value === o.value))
    .filter(
      (o) =>
        !o.dataIndex ||
        allowedColumnsFromUser.includes(o.dataIndex) ||
        ['affiliate_id', 'bizdev_id'].includes(o.dataIndex)
    );

  const initialFetchGroupCharts = async () => {
    return groupingOptionsArray.filter(
      (o) => !o.dataIndex || allowedColumnsFromUser.includes(o.dataIndex)
    );
  };

  return (
    <div>
      <FloatButton
        onClick={() => {
          localStorage.removeItem('data');
          window.location.reload();
        }}
      />
      <div>
        <Divider orientation="left" plain>
          Фільтри
        </Divider>
        <div className="flex justify-between my-4">
          <Form layout="inline">
            {allowedColumnsFromUser.includes('createdAt') && (
              <Form.Item key="from" label="Від (відправка)">
                <DatePicker
                  onChange={(e) => {
                    const date = e
                      ?.set('hour', 0)
                      .set('minute', 0)
                      .set('second', 0)
                      .tz(user.timezone);
                    setFilters({
                      ...filters,
                      from: date,
                    });
                  }}
                  placeholder="Від"
                  value={filters.from}
                  allowClear={false}
                />
              </Form.Item>
            )}
            {allowedColumnsFromUser.includes('createdAt') && (
              <Form.Item key="to" label="До (відправка)">
                <DatePicker
                  onChange={(e) => {
                    const date = e
                      ?.set('hour', 23)
                      .set('minute', 23)
                      .set('second', 59)
                      .tz(user.timezone);
                    setFilters({
                      ...filters,
                      to: date,
                    });
                  }}
                  placeholder="До"
                  value={filters.to}
                  allowClear={false}
                />
              </Form.Item>
            )}
            {allowedColumnsFromUser.includes('country') && (
              <Form.Item name="countries" label="Країни">
                <CountrySelect
                  style={{ width: 120 }}
                  mode="multiple"
                  values={filters.country}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      country: e,
                    })
                  }
                />
              </Form.Item>
            )}
            {allowedColumnsFromUser.includes('order_id') && (
              <Form.Item label="Замовлення">
                <AutocompleteSelect
                  style={{ width: 120 }}
                  mode="multiple"
                  value={
                    isLocalstorage ? filters.order?.label : filters.order?.value
                  }
                  placeholder="Замовлення"
                  initialFetch={initialFetchOrders}
                  fetch={fetchOrders}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      order: {
                        value: e.map((e) => e.value),
                        label: e.map((e) => e.label),
                      },
                    });
                  }}
                />
              </Form.Item>
            )}
            {allowedColumnsFromUser.includes('box_id') && (
              <Form.Item label="Бокс">
                <AutocompleteSelect
                  style={{ width: 120 }}
                  mode="multiple"
                  value={
                    isLocalstorage ? filters.box?.label : filters.box?.value
                  }
                  placeholder="Бокс"
                  initialFetch={initialFetchBoxes}
                  fetch={fetchBoxes}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      box: {
                        value: e.map((e) => e.value),
                        label: e.map((e) => e.label),
                      },
                    })
                  }
                />
              </Form.Item>
            )}
            {allowedColumnsFromUser.includes('groupingOptions') && (
              <Form.Item label="Баєр">
                <AutocompleteSelect
                  style={{ width: 120 }}
                  mode="multiple"
                  value={filters.buyer}
                  placeholder="Баєр"
                  initialFetch={initialFetchBuyers}
                  fetch={fetchBuyers}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      user: e.map((v) => v.value),
                    })
                  }
                />
              </Form.Item>
            )}
            {allowedColumnsFromUser.includes('buyer_id') &&
              allowedColumnsFromUser.includes('user_id') && (
                <Form.Item label="Аффілейт">
                  <AutocompleteSelect
                    style={{ width: 120 }}
                    mode="multiple"
                    value={filters.affiliate}
                    placeholder="Аффілейт"
                    initialFetch={initialFetchAffiliates}
                    fetch={fetchAffiliates}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        user: e.map((v) => v.value),
                      })
                    }
                  />
                </Form.Item>
              )}
            {allowedColumnsFromUser.includes('order_id') &&
              allowedColumnsFromUser.includes('user_id') && (
                <Form.Item label="Біздев">
                  <AutocompleteSelect
                    style={{ width: 120 }}
                    mode="multiple"
                    value={filters.bizdev}
                    placeholder="Біздев"
                    initialFetch={initialFetchBizdevs}
                    fetch={fetchBizdevs}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        user: e.map((v) => v.value),
                      })
                    }
                  />
                </Form.Item>
              )}
          </Form>
        </div>
        <Divider orientation="left" plain>
          Групи
        </Divider>
        <Form.Item label="Групування">
          <AutocompleteSelect
            style={{ width: 120 }}
            mode="select"
            placeholder="Групування"
            defaultValue={groupingsChart}
            initialFetch={initialFetchGroupCharts}
            fetch={initialFetchGroupCharts}
            onChange={(e) => setGroupingsChart([e])}
          />
        </Form.Item>
      </div>
      <div>
        {isReportLoading ? (
          <Spin size="large" />
        ) : (
          <ReportChart data={reportData} />
        )}
      </div>
      <Divider orientation="left" plain>
        Групи
      </Divider>
      <div className="my-4 flex justify-between">
        <TagsSelector
          tags={groupings}
          closable={groupings.length > 1}
          defaultValue={groupings}
          onClose={(g) => {
            setGroupings(groupings.filter((fg) => g.value !== fg.value));
            setColumns(columns.filter((c) => g.value !== c.dataIndex));
          }}
          options={groupingOptions}
          placeholder="Додати"
          onSelectChange={(e) => {
            setGroupings([
              ...groupings,
              { value: e, label: translateGrouping(e) },
            ]);
            setColumns([
              {
                title: translateGrouping(e),
                key: e,
                dataIndex: e,
                render: (val, obj) => {
                  if (e === 'day') {
                    return fromApiToClientDate(obj.day);
                  }
                  if (e === 'month') {
                    return fromApiToClientMonth(obj.month);
                  }
                  return val;
                },
              },
              ...columns,
            ]);
          }}
        />
        <Select
          mode="tags"
          style={{
            width: '250px',
          }}
          placeholder="Tags Mode"
          defaultValue={[
            {
              value: 'leads',
              label: 'Ліди',
            },
          ]}
          value={subCols}
          onChange={(e) => {
            if (e.length > 0) {
              localStorage.setItem(
                'data',
                JSON.stringify({
                  ...JSON.parse(localStorage.getItem('data')),
                  subCols: e,
                })
              );
              setSubCols(e);
              setColumns([
                ...groupings.map((g) => ({
                  title: g.label,
                  dataIndex: g.value,
                  key: g.value,
                  render: (val, obj) => {
                    if (g.value === 'day') {
                      return fromApiToClientDate(obj.day);
                    }
                    if (g.value === 'month') {
                      return fromApiToClientMonth(obj.month);
                    }
                    return val;
                  },
                })),
                {
                  title: 'Всього',
                  key: 'total',
                  align: 'center',
                  children: e.map((col) => {
                    return {
                      title: translateCols(col),
                      dataIndex: `total${
                        col.charAt(0).toUpperCase() + col.slice(1)
                      }`,
                      key: `total${col.charAt(0).toUpperCase() + col.slice(1)}`,
                      align: 'center',
                      render: (val, obj) =>
                        col === 'price'
                          ? `$${obj.totalPrice}`
                          : obj[
                              `total${
                                col.charAt(0).toUpperCase() + col.slice(1)
                              }`
                            ],
                    };
                  }),
                },
                ...statuses
                  .filter(
                    (s) => s !== 'unsigned' && s !== 'test' && s !== 'error'
                  )
                  .map((s) => ({
                    title: <StatusTag status={s} />,
                    dataIndex: 'statusData',
                    key: 'statusData',
                    align: 'center',
                    children: e.map((col) => ({
                      title: translateCols(col),
                      key: col,
                      align: 'center',
                      sorter: (a, b) => {
                        const statusA =
                          a.statusData.find((sd) => sd.status === s)?.leads ||
                          0;
                        const statusB =
                          b.statusData.find((sd) => sd.status === s)?.leads ||
                          0;
                        return statusA - statusB;
                      },
                      render: (_, obj) => {
                        if (col === 'percent') {
                          const data = obj.statusData.find(
                            (sd) => sd.status === s
                          )?.[col];
                          return data ? `${data}%` : '';
                        }
                        if (col === 'price') {
                          const data = obj.statusData.find(
                            (sd) => sd.status === s
                          )?.[col];
                          return data ? `$${data}` : '';
                        }
                        const updateValue = (e, oldValue) => {
                          localStorage.removeItem('data');
                          localStorage.removeItem('reportData');
                          localStorage.removeItem('filters');
                          localStorage.removeItem('grouping');
                          localStorage.removeItem('groupingsChart');
                          localStorage.removeItem('subCols');
                          let keyUser = '';
                          let keyStatus = '';
                          let keyValue = '';
                          for (const eKey in e) {
                            keyValue = e[eKey];
                            keyUser = eKey.split('/')[1];
                            keyStatus = eKey.split('/')[0];
                          }
                          let allLeads = 0;
                          let allLeadBefore = 0;
                          oldValue.statusData
                            .filter((y) => y.status === keyStatus)
                            .map((y) => {
                              allLeadBefore = y.leads;
                            });
                          data
                            .filter((e) => {
                              let flag = false;
                              for (let i = 0; i < groupings.length; i++) {
                                if (
                                  e[groupings[i]?.value] ||
                                  e[groupings[i]?.value] == null
                                ) {
                                  if (
                                    e[groupings[i]?.value] ===
                                    oldValue[groupings[i]?.value]
                                  ) {
                                    flag = true;
                                  } else {
                                    flag = false;
                                    break;
                                  }
                                }
                              }
                              return flag;
                            })
                            .map((e) => {
                              e.statusData
                                .filter((y) => y.status === keyStatus)
                                .map((y) => {
                                  y.leads = +keyValue;
                                });
                              e.statusData.map((r) => {
                                allLeads += r.leads;
                              });
                              e.totalLeads = +allLeads;
                            });
                          data.map((e) => {
                            e.statusData.map((v) => {
                              const percent = (v.leads * 100) / e.totalLeads;
                              v.percent = round(percent, 2);
                            });
                          });
                          if (allLeadBefore - +keyValue > 0) {
                            let totalSum = allLeadBefore - +keyValue;
                            while (totalSum > 0) {
                              reportData
                                .filter((e) => {
                                  if (
                                    groupingsChart?.[0]?.value &&
                                    oldValue?.[groupingsChart[0].value]
                                  ) {
                                    return (
                                      e[groupingsChart[0].value] ===
                                      oldValue[groupingsChart[0].value]
                                    );
                                  } else {
                                    return true;
                                  }
                                })
                                .forEach((e) => {
                                  e.statusData
                                    .filter((e) => e.status === keyStatus)
                                    .map((y) => {
                                      if (totalSum > 0 && y.leads > 0) {
                                        y.leads--;
                                        totalSum--;
                                      }
                                    });
                                });
                            }
                          } else {
                            let totalSum = +keyValue - allLeadBefore;
                            while (totalSum > 0) {
                              reportData
                                .filter((e) => {
                                  if (
                                    oldValue?.[groupingsChart[0].value] === null
                                  ) {
                                    totalSum = 0;
                                    return false;
                                  }
                                  if (
                                    groupingsChart?.[0]?.value &&
                                    oldValue?.[groupingsChart[0].value]
                                  ) {
                                    return (
                                      e[groupingsChart[0].value] ===
                                      oldValue[groupingsChart[0].value]
                                    );
                                  } else {
                                    return true;
                                  }
                                })
                                .forEach((e) => {
                                  e.statusData
                                    .filter((e) => e.status === keyStatus)
                                    .map((y) => {
                                      if (totalSum > 0) {
                                        y.leads++;
                                        totalSum--;
                                      }
                                    });
                                });
                            }
                          }
                          setData([...data]);
                          localStorage.setItem('data', JSON.stringify(data));
                          localStorage.setItem(
                            'reportData',
                            JSON.stringify(reportData)
                          );
                          localStorage.setItem(
                            'grouping',
                            JSON.stringify(groupings)
                          );
                          localStorage.setItem(
                            'filters',
                            JSON.stringify(filters)
                          );
                          localStorage.setItem(
                            'groupingsChart',
                            JSON.stringify(groupingsChart)
                          );
                          localStorage.setItem(
                            'subCols',
                            JSON.stringify(subCols)
                          );
                        };
                        return (
                          <EditableCell
                            name={`${s}/${obj.app_user}`}
                            onUpdate={(val) => updateValue(val, obj)}
                            value={
                              obj.statusData.find((sd) => sd.status === s)
                                ?.leads
                            }
                          />
                        );
                      },
                    })),
                  })),
              ]);
            }
          }}
          options={[
            {
              value: 'price',
              label: 'Дохід',
            },
            {
              value: 'leads',
              label: 'Ліди',
            },
            { value: 'percent', label: 'Процент' },
          ]}
        />
      </div>
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered
          summary={() => {
            return (
              <>
                <Table.Summary.Row className="font-bold">
                  {groupings.map((_, index) => {
                    if (index !== groupings.length - 1) {
                      return (
                        <>
                          <Table.Summary.Cell index={groupings.length} />
                        </>
                      );
                    }
                    return '';
                  })}
                  <Table.Summary.Cell index={groupings.length}>
                    Всього
                  </Table.Summary.Cell>
                  {subCols.includes('leads') && (
                    <Table.Summary.Cell
                      index={groupings.length + 1}
                      align="center"
                    >
                      <div>
                        {data.reduce((acc, curr) => acc + curr.totalLeads, 0)}
                      </div>
                    </Table.Summary.Cell>
                  )}
                  {subCols.includes('price') && (
                    <Table.Summary.Cell
                      index={groupings.length + 2}
                      align="center"
                    >
                      <div>
                        {data.reduce((acc, curr) => acc + curr.totalPrice, 0) >
                        0
                          ? `$${data.reduce(
                              (acc, curr) => acc + curr.totalPrice,
                              0
                            )}`
                          : ''}
                      </div>
                    </Table.Summary.Cell>
                  )}
                  {subCols.includes('percent') && (
                    <Table.Summary.Cell
                      index={groupings.length + 2}
                      align="center"
                    >
                      <div></div>
                    </Table.Summary.Cell>
                  )}
                  {statuses
                    .filter(
                      (s) => s !== 'unsigned' && s !== 'test' && s !== 'error'
                    )
                    .map((s, index) => {
                      const leads = data.reduce(
                        (acc, curr) =>
                          acc +
                          (curr.statusData.find((sd) => sd.status === s)
                            ?.leads || 0),
                        0
                      );
                      const price = data.reduce(
                        (acc, curr) =>
                          acc +
                          (curr.statusData.find((sd) => sd.status === s)
                            ?.price || 0),
                        0
                      );
                      return (
                        <>
                          {subCols.includes('leads') && (
                            <Table.Summary.Cell index={0} align="center">
                              <div key={`leads${s}`}>
                                {leads === 0 ? '' : leads}
                              </div>
                            </Table.Summary.Cell>
                          )}
                          {subCols.includes('price') && (
                            <Table.Summary.Cell index={index} align="center">
                              <div key={`price${s}`}>
                                {price === 0 ? '' : `$${price}`}
                              </div>
                            </Table.Summary.Cell>
                          )}
                          {subCols.includes('percent') && (
                            <Table.Summary.Cell index={0} align="center">
                              <div key={`percent${s}`}></div>
                            </Table.Summary.Cell>
                          )}
                        </>
                      );
                    })}
                </Table.Summary.Row>
              </>
            );
          }}
        />
      )}
    </div>
  );
};
