import { Button, Form, message, Modal } from 'antd';
import React, { useContext, useState } from 'react';
import api from '../../api/api';
import { AutocompleteSelect } from '../AutocompleteSelect/AutocompleteSelect';
export const AddUserModal = ({
  entityId,
  location,
  isModalOpen,
  handleCancel,
  notInIds,
}) => {
  const [selectedUser, setSelectedUser] = useState();
  const affiliatesTeam = [
    'AFFILIATE',
    'AFFILIATE_TEAM_LEAD',
    'AFFILIATE_SUPPORT',
  ];
  const fullstackTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  const bizdevTeam = ['BIZDEV', 'BIZDEV_TEAM_LEAD', 'BIZDEV_SUPPORT'];
  const roles =
    location === 'buyers'
      ? fullstackTeam.concat(affiliatesTeam)
      : fullstackTeam.concat(bizdevTeam);

  const fetchUsers = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length
        ? `&filter.id=$or:$eq:${name}&filter.role=$in:${roles}&filter.id=$not:$in:${notInIds}`
        : '';
    const res = await api.get(
      `/user?filter.isActive=$eq:true&filter.role=$in:${roles}&filter.nickname=$ilike:${name}&filter.id=$not:$in:${notInIds}${idFilter}`
    );
    return res.data.data.map((u) => ({
      label: `#${u.id} ${u.nickname}`,
      value: u.id,
    }));
  };
  const initialFetchUsers = async () => {
    const res = await api.get(
      `/user?limit=100&filter.isActive=$eq:true&filter.role=$in:${roles}&filter.id=$not:$in:${notInIds}`
    );
    return res.data.data.map((u) => ({
      label: `#${u.id} ${u.nickname}`,
      value: u.id,
    }));
  };

  if (!entityId) return '';
  const onUpdateBuyers = () => {
    api
      .post(`/shared/buyer/${entityId}`, {
        userId: selectedUser.value,
      })
      .then((_) => {
        // eslint-disable-next-line no-restricted-globals
        window.location.reload();
        message.success('Розшарено');
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };
  const onUpdateCustomers = () => {
    api
      .post(`/shared/customer/${entityId}`, {
        userId: selectedUser.value,
      })
      .then((_) => {
        // eslint-disable-next-line no-restricted-globals
        window.location.reload();
        message.success('Розшарено');
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };
  return (
    <Modal
      title="Поділитися"
      open={isModalOpen}
      footer={null}
      onCancel={handleCancel}
    >
      <Form
        wrapperCol={{ span: 12, offset: 1 }}
        labelCol={{ span: 7, offset: 1 }}
        onFinish={location == 'buyers' ? onUpdateBuyers : onUpdateCustomers}
      >
        <Form.Item
          name="user"
          label="Користувач"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <AutocompleteSelect
            value={selectedUser}
            placeholder="Нікнейм"
            initialFetch={initialFetchUsers}
            fetch={fetchUsers}
            onChange={setSelectedUser}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button type="primary" htmlType="submit">
            Розшарити
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
