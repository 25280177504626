import React, { useContext, useEffect, useState } from 'react';

import { usePermissionsAllowed } from '../../hooks/usePermissionsAllowed';
import { statuses } from '../../utils/statusOptions';
import {
  App,
  DatePicker,
  Divider,
  Form,
  Select,
  Spin,
  Table,
  Tooltip,
} from 'antd';
import dayjs from 'dayjs';
import api from '../../api/api';
import {
  fromApiToClientDate,
  fromApiToClientMonth,
} from '../../utils/dateFilters';
import { ReportChart } from './ReportChart';
import { StatusTag } from '../../shared/StatusTag/StatusTag';
import { TagsSelector } from '../../shared/TagsSelector/TagsSelector';
import { AutocompleteSelect } from '../../shared/AutocompleteSelect/AutocompleteSelect';
import { CountrySelect } from '../../shared/CountryMultiselect/CountrySelect';
import { CurrentUserContext } from '../../providers/AuthProvider';
import { roundTo2 } from '../../utils/roundTo2';
import {
  hasPermissions,
  usePermissions,
} from '../../hooks/usePermissionsAllowed';
import { QuestionCircleOutlined } from '@ant-design/icons';

export const Report = () => {
  const role = usePermissions();
  const translateGrouping = (g) => {
    if (g === 'box') return 'Бокс';
    if (g === 'day') return 'День';
    if (g === 'month') return 'Місяць';
    if (g === 'customer') return 'Замовник';
    if (g === 'country') return 'Країна';
    if (g === 'app_order') return 'Замовлення';
    if (g === 'buyer') return 'Баєр';
    if (g === 'affiliate') return 'Аффілейт';
    if (g === 'bizdev') return 'Біздев';
  };

  const translateCols = (g) => {
    if (g === 'leads') return 'Ліди';
    if (g === 'price') return 'Дохід';
    if (g === 'percent') return 'Проценти';
  };

  const { message } = App.useApp();
  const [isLoading, setIsLoading] = useState(false);
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [groupings, setGroupings] = useState([{ value: 'day', label: 'День' }]);
  const [groupingsChart, setGroupingsChart] = useState([
    { value: 'day', label: 'Дні' },
  ]);

  const [data, setData] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [subCols, setSubCols] = useState(['leads', 'FTD', 'CR']);
  const [filters, setFilters] = useState({
    from: dayjs().subtract(14, 'days').startOf('day'),
    to: dayjs().endOf('day'),
  });

  const currentUser = useContext(CurrentUserContext);

  const affiliatesTeam = [
    'AFFILIATE',
    'AFFILIATE_TEAM_LEAD',
    'AFFILIATE_SUPPORT',
  ];
  const bizdevsTeam = ['BIZDEV', 'BIZDEV_TEAM_LEAD', 'BIZDEV_SUPPORT'];

  const [columns, setColumns] = useState([
    ...groupings.map((g) => ({
      title: g.label,
      dataIndex: g.value,
      key: g.value,
      render: (val, obj) => {
        if (g.value === 'day') {
          return fromApiToClientDate(obj.day);
        }
        if (g.value === 'month') {
          return fromApiToClientMonth(obj.month);
        }
        return val;
      },
    })),
    {
      title: 'Всього',
      key: 'total',
      align: 'center',
      children: [
        {
          title: 'Ліди',
          dataIndex: 'totalLeads',
          key: 'totalLeads',
          sorter: (a, b) => a.totalLeads - b.totalLeads,
          align: 'center',
        },
        {
          title: 'FTD',
          dataIndex: 'totalFTD',
          key: 'totalFTD',
          align: 'center',
          sorter: (a, b) => {
            const statusA = a.totalFTD || 0;
            const statusB = b.totalFTD || 0;
            return statusA - statusB;
          },
        },
        {
          title: <CRTitle />,
          dataIndex: 'totalCR',
          key: 'totalCR',
          sorter: (a, b) => {
            const statusA = a.totalCR || 0;
            const statusB = b.totalCR || 0;
            return statusA - statusB;
          },
          render: (cr) => `${cr || 0} %`,
          align: 'center',
        },
      ],
    },
    ...statuses
      .filter((s) => !['unsigned', 'test', 'error'].includes(s))
      .map((s) => ({
        title: <StatusTag status={s} />,
        dataIndex: 'statusData',
        key: 'statusData',
        align: 'center',
        children: [
          {
            title: 'Ліди',
            key: `leads${s}`,
            sorter: (a, b) => {
              const statusA =
                a.statusData.find((sd) => sd.status === s)?.leads || 0;
              const statusB =
                b.statusData.find((sd) => sd.status === s)?.leads || 0;
              return statusA - statusB;
            },
            align: 'center',
            render: (_, obj) => {
              return obj.statusData.find((sd) => sd.status === s)?.leads;
            },
          },
        ],
      })),
  ]);

  useEffect(() => {
    getReport();
  }, [groupings, filters]);

  useEffect(() => {
    getReportChartData();
  }, [filters, groupingsChart]);

  const getReport = () => {
    setIsLoading(true);
    api
      .get(
        `/report?groupings=${groupings
          .map((g) => g.value)
          .join(',')}&filters=${JSON.stringify(filters)}`
      )
      .then((res) => {
        setData(res.data);
        setIsLoading(false);
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  const getReportChartData = () => {
    setIsReportLoading(true);
    api
      .get(
        `/report/chart?groupings=${
          groupingsChart[0]?.value
        }&filters=${JSON.stringify(filters)}`
      )
      .then((res) => {
        setReportData(res.data);
        setIsReportLoading(false);
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };
  const fetchOrders = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(`/order?filter.name=$ilike:${name}${idFilter}`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchOrders = async () => {
    const res = await api.get(`/order?limit=10`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const fetchCustomers = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/customer?filter.name=$ilike:${name}${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchCustomers = async () => {
    const res = await api.get(`/customer?limit=10`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const fetchAffiliates = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:AFFILIATE&filter.role=$or:$eq:FULLSTACK&filter.nickname=$ilike:${name}${idFilter}`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: `#${u.id} ${u.name}`,
        value: u.id,
      }));
  };

  const initialFetchAffiliates = async (name) => {
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:AFFILIATE&filter.role=$or:$eq:FULLSTACK`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: `#${u.id} ${u.name}`,
        value: u.id,
      }));
  };

  const fetchBizdevs = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/user?filter.role=$eq:BIZDEV&filter.role=$or:$eq:FULLSTACK&filter.nickname=$ilike:${name}${idFilter}`
    );
    return res.data.data.map((u) => ({
      label: `#${u.id} ${u.name}`,
      value: u.id,
    }));
  };

  const initialFetchBizdevs = async () => {
    const res = await api.get(
      `/user?limit=10&filter.role=$eq:BIZDEV&filter.role=$or:$eq:FULLSTACK`
    );
    return res.data.data.map((u) => ({
      label: `#${u.id} ${u.name}`,
      value: u.id,
    }));
  };

  const initialFetchBuyers = async () => {
    const res = await api.get(`/buyer?limit=10&filter.isActive=$eq:true`);
    return res.data.data.map((b) => ({
      label: `#${b.id} ${b.name}`,
      value: b.id,
    }));
  };

  const fetchBuyers = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(`/buyer?filter.name=$ilike:${name}${idFilter}`);
    return res.data.data.map((b) => ({
      label: `#${b.id} ${b.name}`,
      value: b.id,
    }));
  };

  const fetchBoxes = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(`/box?filter.name=$ilike:${name}${idFilter}`);
    return res.data.data.map((b) => ({
      label: `#${b.id} ${b.name}`,
      value: b.id,
    }));
  };

  const initialFetchBoxes = async () => {
    const res = await api.get(`/box?limit=10`);
    return res.data.data.map((b) => ({
      label: `#${b.id} ${b.name}`,
      value: b.id,
    }));
  };

  const user = useContext(CurrentUserContext);
  if (!user) return;
  const allowedColumnsFromUser = JSON.parse(user.permissions.columns).leads;
  const groupingOptionsArray = [
    {
      value: 'day',
      label: 'День',
      dataIndex: 'createdAt',
    },
    {
      value: 'month',
      label: 'Місяць',
      dataIndex: 'createdAt',
    },
    {
      value: 'box',
      label: 'Бокс',
      dataIndex: 'box_id',
    },
    {
      value: 'app_order',
      label: 'Замовлення',
      dataIndex: 'order_id',
    },
    {
      value: 'customer',
      label: 'Замовник',
      dataIndex: 'order_id',
    },
    {
      value: 'country',
      label: 'Країна',
      dataIndex: 'country ',
    },
    {
      value: 'buyer',
      label: 'Баєр',
      dataIndex: 'buyer_id',
    },
    {
      value: 'affiliate',
      label: 'Аффілейт',
      dataIndex: 'affiliate_id',
    },
    {
      value: 'bizdev',
      label: 'Біздев',
      dataIndex: 'bizdev_id',
    },
  ];

  const groupingOptions = groupingOptionsArray
    .filter((o) => !groupings.find((g) => g.value === o.value))
    .filter(
      (o) =>
        !o.dataIndex ||
        allowedColumnsFromUser.includes(o.dataIndex) ||
        ['affiliate_id', 'bizdev_id'].includes(o.dataIndex)
    );

  const initialFetchGroupCharts = async () => {
    return groupingOptionsArray.filter(
      (o) => !o.dataIndex || allowedColumnsFromUser.includes(o.dataIndex)
    );
  };
  const allCr = (data) => {
    let allCountApproved = 0;
    const ftd = data.reduce((acc, curr) => +acc + +curr.totalFTD, 0);
    data.map((el) => {
      allCountApproved += el.countApprovedLead;
    });
    return `${roundTo2((ftd / allCountApproved) * 100) || 0} %`;
  };
  return (
    <div>
      <div>
        <Divider orientation="left" plain>
          Фільтри
        </Divider>
        <div className="flex justify-between my-4">
          <Form layout="inline">
            {allowedColumnsFromUser.includes('sentAt') && (
              <Form.Item key="from" label="Від (відправка)">
                <DatePicker
                  onChange={(e) => {
                    const date = e
                      ?.set('hour', 0)
                      .set('minute', 0)
                      .set('second', 0)
                      .tz(user.timezone);
                    setFilters({
                      ...filters,
                      from: date,
                    });
                  }}
                  placeholder="Від"
                  value={filters.from}
                  allowClear={false}
                />
              </Form.Item>
            )}
            {allowedColumnsFromUser.includes('sentAt') && (
              <Form.Item key="to" label="До (відправка)">
                <DatePicker
                  onChange={(e) => {
                    const date = e
                      ?.set('hour', 23)
                      .set('minute', 23)
                      .set('second', 59)
                      .tz(user.timezone);
                    setFilters({
                      ...filters,
                      to: date,
                    });
                  }}
                  placeholder="До"
                  value={filters.to}
                  allowClear={false}
                />
              </Form.Item>
            )}
            {allowedColumnsFromUser.includes('country') && (
              <Form.Item name="countries" label="Країни">
                <CountrySelect
                  style={{ width: 120 }}
                  mode="multiple"
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      country: e,
                    })
                  }
                />
              </Form.Item>
            )}
            {allowedColumnsFromUser.includes('order_id') && (
              <Form.Item label="Замовлення">
                <AutocompleteSelect
                  style={{ width: 120 }}
                  mode="multiple"
                  value={filters.order}
                  placeholder="Замовлення"
                  initialFetch={initialFetchOrders}
                  fetch={fetchOrders}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      order: e.map((v) => v.value),
                    })
                  }
                />
              </Form.Item>
            )}
            {allowedColumnsFromUser.includes('box_id') && (
              <Form.Item label="Бокс">
                <AutocompleteSelect
                  style={{ width: 120 }}
                  mode="multiple"
                  value={filters.box}
                  placeholder="Бокс"
                  initialFetch={initialFetchBoxes}
                  fetch={fetchBoxes}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      box: e.map((v) => v.value),
                    })
                  }
                />
              </Form.Item>
            )}
            {allowedColumnsFromUser.includes('buyer_id') && (
              <Form.Item label="Баєр">
                <AutocompleteSelect
                  style={{ width: 120 }}
                  mode="multiple"
                  value={filters.buyer}
                  placeholder="Баєр"
                  initialFetch={initialFetchBuyers}
                  fetch={fetchBuyers}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      buyer: e.map((v) => v.value),
                    })
                  }
                />
              </Form.Item>
            )}
            {allowedColumnsFromUser.includes('buyer_id') &&
              allowedColumnsFromUser.includes('user_id') && (
                <Form.Item label="Аффілейт">
                  <AutocompleteSelect
                    style={{ width: 120 }}
                    mode="multiple"
                    value={filters.affiliate}
                    placeholder="Аффілейт"
                    initialFetch={initialFetchAffiliates}
                    fetch={fetchAffiliates}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        user: e.map((v) => v.value),
                      })
                    }
                  />
                </Form.Item>
              )}
            {allowedColumnsFromUser.includes('order_id') &&
              allowedColumnsFromUser.includes('user_id') && (
                <Form.Item label="Біздев">
                  <AutocompleteSelect
                    style={{ width: 120 }}
                    mode="multiple"
                    value={filters.bizdev}
                    placeholder="Біздев"
                    initialFetch={initialFetchBizdevs}
                    fetch={fetchBizdevs}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        user: e.map((v) => v.value),
                      })
                    }
                  />
                </Form.Item>
              )}
          </Form>
        </div>
        <Divider orientation="left" plain>
          Групи
        </Divider>
        <Form.Item label="Групування">
          <AutocompleteSelect
            style={{ width: 120 }}
            mode="select"
            placeholder="Групування"
            defaultValue={groupingsChart}
            initialFetch={initialFetchGroupCharts}
            fetch={initialFetchGroupCharts}
            onChange={(e) => setGroupingsChart([e])}
          />
        </Form.Item>
      </div>
      <div>
        {isReportLoading ? (
          <Spin size="large" />
        ) : (
          <ReportChart data={reportData} />
        )}
      </div>
      <Divider orientation="left" plain>
        Групи
      </Divider>
      <div className="my-4 flex justify-between">
        <TagsSelector
          tags={groupings}
          closable={groupings.length > 1}
          onClose={(g) => {
            setGroupings(groupings.filter((fg) => g.value !== fg.value));
            setColumns(columns.filter((c) => g.value !== c.dataIndex));
          }}
          options={groupingOptions}
          placeholder="Додати"
          onSelectChange={(e) => {
            setGroupings([
              ...groupings,
              { value: e, label: translateGrouping(e) },
            ]);
            setColumns([
              {
                title: translateGrouping(e),
                key: e,
                dataIndex: e,
                render: (val, obj) => {
                  if (e === 'day') return fromApiToClientDate(obj.day);
                  if (e === 'month') return fromApiToClientMonth(obj.month);
                  return val;
                },
              },
              ...columns,
            ]);
          }}
        />
        <Select
          mode="tags"
          style={{
            width: '250px',
          }}
          placeholder="Tags Mode"
          defaultValue={[
            {
              value: 'FTD',
              label: 'FTD',
            },
            {
              value: 'leads',
              label: 'Ліди',
            },
            {
              value: 'CR',
              label: 'CR',
            },
          ]}
          value={subCols}
          onChange={(e) => {
            if (e.length > 0) {
              setSubCols(e);
              setColumns([
                ...groupings.map((g) => ({
                  title: g.label,
                  dataIndex: g.value,
                  key: g.value,
                  render: (val, obj) => {
                    if (g.value === 'day') {
                      return fromApiToClientDate(obj.day);
                    }
                    if (g.value === 'month') {
                      return fromApiToClientMonth(obj.month);
                    }
                    return val;
                  },
                })),
                {
                  title: 'Всього',
                  key: 'total',
                  align: 'center',
                  children: e
                    .filter((col) => {
                      return col !== 'percent';
                    })
                    .map((col) => {
                      return {
                        title:
                          col === 'CR' ? (
                            <CRTitle />
                          ) : (
                            translateCols(col) || col
                          ),
                        dataIndex: `total${
                          col.charAt(0).toUpperCase() + col.slice(1)
                        }`,
                        key: `total${
                          col.charAt(0).toUpperCase() + col.slice(1)
                        }`,
                        align: 'center',
                        render: (val, obj) => {
                          if (col === 'price') {
                            return `$${obj.totalPrice}`;
                          } else if (col === 'FTD') {
                            return `${obj.totalFTD || '0'}`;
                          } else if (col === 'CR') {
                            return `${obj.totalCR || 0} %`;
                          } else {
                            return obj[
                              `total${
                                col.charAt(0).toUpperCase() + col.slice(1)
                              }`
                            ];
                          }
                        },
                      };
                    }),
                },
                ...statuses
                  .filter(
                    (s) => s !== 'unsigned' && s !== 'test' && s !== 'error'
                  )
                  .map((s) => ({
                    title: <StatusTag status={s} />,
                    dataIndex: 'statusData',
                    key: 'statusData',
                    align: 'center',
                    children: [...e]
                      .filter((e) => e !== 'FTD' && e !== 'CR')
                      .map((col) => ({
                        title: translateCols(col),
                        key: col,
                        align: 'center',
                        sorter: (a, b) => {
                          const statusA =
                            a.statusData.find((sd) => sd.status === s)?.leads ||
                            0;
                          const statusB =
                            b.statusData.find((sd) => sd.status === s)?.leads ||
                            0;
                          return statusA - statusB;
                        },
                        render: (_, obj) => {
                          if (col === 'percent') {
                            const data = obj.statusData.find(
                              (sd) => sd.status === s
                            )?.[col];
                            return data ? `${data}%` : '';
                          }
                          if (col === 'price') {
                            const data = obj.statusData.find(
                              (sd) => sd.status === s
                            )?.[col];
                            return data ? `$${data}` : '';
                          }
                          return obj.statusData.find((sd) => sd.status === s)?.[
                            col
                          ];
                        },
                      })),
                  })),
              ]);
            }
          }}
          options={[
            {
              value: 'leads',
              label: 'Ліди',
            },
            {
              value: 'FTD',
              label: 'FTD',
            },
            {
              value: 'CR',
              label: 'CR',
            },
            { value: 'percent', label: 'Процент' },
            {
              value: 'price',
              label: 'Дохід',
            },
          ]}
        />
      </div>
      {isLoading ? (
        <Spin size="large" />
      ) : (
        <Table
          columns={columns}
          dataSource={data}
          pagination={false}
          bordered
          summary={() => {
            return (
              <>
                <Table.Summary.Row className="font-bold">
                  {groupings.map((_, index) => {
                    if (index !== groupings.length - 1) {
                      return (
                        <>
                          <Table.Summary.Cell index={groupings.length} />
                        </>
                      );
                    }
                    return '';
                  })}
                  <Table.Summary.Cell index={0}>Всього</Table.Summary.Cell>
                  {subCols.includes('leads') && (
                    <Table.Summary.Cell
                      index={subCols.indexOf('leads') + 1}
                      align="center"
                    >
                      <div>
                        {data.reduce((acc, curr) => acc + curr.totalLeads, 0)}
                      </div>
                    </Table.Summary.Cell>
                  )}
                  {subCols.includes('FTD') && (
                    <Table.Summary.Cell
                      index={subCols.indexOf('FTD') + 1}
                      align="center"
                    >
                      <div>
                        {data.reduce((acc, curr) => +acc + +curr.totalFTD, 0)}
                      </div>
                    </Table.Summary.Cell>
                  )}
                  {subCols.includes('CR') && (
                    <Table.Summary.Cell
                      index={subCols.indexOf('CR') + 1}
                      align="center"
                    >
                      <div>{allCr(data)}</div>
                    </Table.Summary.Cell>
                  )}
                  {subCols.includes('price') && (
                    <Table.Summary.Cell
                      index={subCols.indexOf('price') + 1}
                      align="center"
                    >
                      <div>
                        {data.reduce((acc, curr) => acc + curr.totalPrice, 0) >
                        0
                          ? `$${data.reduce(
                              (acc, curr) => acc + curr.totalPrice,
                              0
                            )}`
                          : ''}
                      </div>
                    </Table.Summary.Cell>
                  )}
                  {statuses
                    .filter(
                      (s) => s !== 'unsigned' && s !== 'test' && s !== 'error'
                    )
                    .map((s, index) => {
                      const leads = data.reduce(
                        (acc, curr) =>
                          acc +
                          (curr.statusData.find((sd) => sd.status === s)
                            ?.leads || 0),
                        0
                      );
                      const price = data.reduce(
                        (acc, curr) =>
                          acc +
                          (curr.statusData.find((sd) => sd.status === s)
                            ?.price || 0),
                        0
                      );
                      const resSubCols = subCols.filter(
                        (c) => c !== 'CR' && c !== 'FTD'
                      );
                      return resSubCols.map((col) => {
                        console.log(s, col);
                        if (col)
                          return (
                            <Table.Summary.Cell align="center">
                              <div
                                key={col}
                                index={resSubCols.indexOf(col) + 1}
                              >
                                {col === 'leads'
                                  ? leads === 0
                                    ? ''
                                    : leads
                                  : col === 'price'
                                  ? price === 0
                                    ? ''
                                    : `$${price}`
                                  : ''}
                              </div>
                            </Table.Summary.Cell>
                          );
                      });
                    })}
                </Table.Summary.Row>
              </>
            );
          }}
        />
      )}
    </div>
  );
};

const CRTitle = () => {
  return (
    <Tooltip title={`( FTD / ( В обробці + Підтверджений ) ) * 100 `}>
      <span>
        CR <QuestionCircleOutlined />
      </span>
    </Tooltip>
  );
};
