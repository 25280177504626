export const sendLeadPattern = () => {
  return `
  /*
  На вхід функції передається лід з такими полями:
  {
    "phone": "string",
    "name": "string",
    "lastName": "string",
    "ip": "string",
    "country": "string",
    "boxId": 0,
    "userId": 0,
    "email": "string",
    "userAgent": "string",
    "sub1": "string",
    "sub2": "string",
    "sub3": "string",
    "sub4": "string",
    "sub5": "string",
    "sub6": "string",
    "sub7": "string",
    "sub8": "string"
  }
  Потрібно описати функцію sendLead, яка приймає цей об'єкт, відправляє лід до замовника 
  і повертає Promise з наступним обєктом:
  {
    "externalId": "string",
    "autologinLink": "string"
  }
  
  В разі помилки або відсутноті externalId чи autologinLink потрібно повернути обєкт з полем  error і описом помилки:
    
  return { error: "string" }
  
  Якщо потрібно обробити дублікат треба повернути обєкт з полем  error і описом помилки:
    
  return { isDuplicate: true }
 
  */
  
async (lead) => {
  ///
  return {
    externalId: "string",
    autologinLink: "string",
    originResp: "string",
    originReq: "string"
  }
}
`;
};

export const checkStatusPattern = () => {
  return `
  /*
  На вхід функції передається масив строк externalIds, які є унікальними ідентифікаторами
  в системі замовника, за яким ми можемо перевірити статус ліда
  
  Потрібно описати функцію checkStatus, яка приймає масив externalIds 
  і повертає Promise з масивом об'єктів настопного типу:
  
  {
    externalId: "string",
    callStatus: "string",
    isDeposit: boolean,
  }
  */
  
async (externalIds) => {
  ///
  return [
    {
       externalId: "string",
       callStatus: "string",
       isDeposit: boolean,
     }
  ]
}
`;
};
