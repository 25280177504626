export const colorList = {
  yellow: {
    color: 'rgb(249 233 14 / 14%)',
    fullColor: 'rgb(249 233 14 / 44%)',
  },
  green: {
    color: '#0080002e',
    fullColor: 'rgb(0 128 0 / 53%)',
  },
  blue: {
    color: '#97eaff3b',
    fullColor: 'rgb(151 234 255 / 54%)',
  },
  purple: {
    color: '#5f378147',
    fullColor: '#5f3781a1',
  },
};

export const cellBackgroundColor = (leadId, setableLeadsList) => {
  if (setableLeadsList.yellow?.includes(leadId)) return colorList.yellow.color;
  if (setableLeadsList.green?.includes(leadId)) return colorList.green.color;
  if (setableLeadsList.blue?.includes(leadId)) return colorList.blue.color;
  if (setableLeadsList.purple?.includes(leadId)) return colorList.purple.color;
};
