import { StatusTag } from '../shared/StatusTag/StatusTag';
import React from 'react';

export const statusOptions = [
  {
    value: 'notDistributed',
    label: (
      <StatusTag
        status="notDistributed"
        style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
      />
    ),
  },
  {
    value: 'pending',
    label: (
      <StatusTag
        status="pending"
        style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
      />
    ),
  },
  {
    value: 'approved',
    label: (
      <StatusTag
        status="approved"
        style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
      />
    ),
  },
  {
    value: 'unsigned',
    label: (
      <StatusTag
        status="unsigned"
        style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
      />
    ),
  },
  {
    value: 'rejected',
    label: (
      <StatusTag
        status="rejected"
        style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
      />
    ),
  },
  {
    value: 'error',
    label: (
      <StatusTag
        status="error"
        style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
      />
    ),
  },
  {
    value: 'fraud',
    label: (
      <StatusTag
        status="fraud"
        style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
      />
    ),
  },
  {
    value: 'test',
    label: (
      <StatusTag
        status="test"
        style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
      />
    ),
  },
];

export const statuses = [
  'notDistributed',
  'pending',
  'approved',
  'unsigned',
  'rejected',
  'fraud',
  'test',
  'error',
];
