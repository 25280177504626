export const AFFILIATE = 'AFFILIATE';
export const AFFILIATE_SUPPORT = 'AFFILIATE_SUPPORT';
export const AFFILIATE_TEAM_LEAD = 'AFFILIATE_TEAM_LEAD';
export const BIZDEV = 'BIZDEV';
export const BIZDEV_SUPPORT = 'BIZDEV_SUPPORT';
export const BIZDEV_TEAM_LEAD = 'BIZDEV_TEAM_LEAD';
export const FULLSTACK = 'FULLSTACK';
export const FULLSTACK_TEAM_LEAD = 'FULLSTACK_TEAM_LEAD';
export const FULLSTACK_SUPPORT = 'FULLSTACK_SUPPORT';
export const ANALYST = 'ANALYST';
export const SUPER_ADMIN = 'SUPER_ADMIN';
