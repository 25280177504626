export const getRange = (score) => {
  if (score >= 85) {
    return 'high';
  } else if (score >= 75 && score < 85) {
    return 'suspicious';
  } else if (score >= 40 && score < 75) {
    return 'medium';
  } else if (score > 0 && score < 40) {
    return 'low';
  } else if (score >= 0 && score < 20) {
    return 'none';
  } else {
    return 'error';
  }
};

export const getColorByRange = (range, isDarkTheme) => {
  if (isDarkTheme) {
    switch (range) {
      case 'high':
        return {
          mainColor: '#f43a3b',
          background: '#2f1f1d',
          borderColor: '#f43a3b',
        };
      case 'suspicious':
        return {
          mainColor: '#CD7A52',
          background: '#452B1A',
          borderColor: '#E57944',
        };
      case 'medium':
        return {
          mainColor: '#EDC95C',
          background: '#3D341B',
          borderColor: '#D1A72A',
        };
      case 'low':
        return {
          mainColor: '#BED173',
          background: '#3D4327',
          borderColor: '#B0BD7E',
        };
      case 'none':
        return {
          mainColor: '#6FCF4A',
          background: '#283523',
          borderColor: '#61AD43',
        };
      default:
        return {
          mainColor: '#818181',
          background: '#232425',
          borderColor: '#818181',
        };
    }
  } else {
    switch (range) {
      case 'high':
        return '#f43a3b';
      case 'suspicious':
        return '#E57944';
      case 'medium':
        return '#D1A72A';
      case 'low':
        return '#B0BD7E';
      case 'none':
        return '#61AD43';
      default:
        return '#97bcd1';
    }
  }
};

export const checkInfo = (response) => {
  const res = {};

  if (!response || response.length === 0) return { error: 'response error' };

  if (response.success === false) return { error: response.message };

  res['fraud score'] = response.fraud_score;

  for (const key in response) {
    if (
      typeof response[key] === 'boolean' &&
      response[key] === true &&
      key !== 'success'
    ) {
      res[key.replace(/_/g, ' ')] = true;
    }
  }

  if (response.ISP === 'Telegram Messenger') res['ISP'] = 'Telegram Messenger';

  res['country code'] = response.country_code;

  return res;
};
