import { snakeToCamel } from '../../utils/snakeToCamel';

export const FormFieldRestricter = ({ children, allowedFields }) => {
  const transformed = allowedFields
    .map(snakeToCamel)
    .map((f) => (f.endsWith('Id') ? f.slice(0, f.length - 2) : f));
  return children.filter((c) =>
    transformed.find((af) =>
      af.toLowerCase().includes(c?.props?.name?.toLowerCase())
    )
  );
};
