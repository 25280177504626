import { Form, List, Modal, Tabs } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { isArray } from 'lodash';

export const DetailModal = ({ task, isModalOpen, handleCancel }) => {
  const [form] = Form.useForm();
  const [allLeads, setAllLeads] = useState();
  const [failLeads, setFailLeads] = useState([]);
  const [filtersExport, setFiltersExport] = useState([]);
  const [columnsExport, setColumnsExport] = useState([]);
  useEffect(() => {
    if (task.description) {
      const description = JSON.parse(task?.description);
      try {
        const all = description?.all?.split(',');
        setAllLeads(all);
      } catch (e) {
        if (description?.all?.Leads && description?.all?.Order) {
          try {
            setAllLeads(
              Array.prototype.concat(
                [`Order:${description?.all?.Order}`],
                description?.all?.Leads.split(',')
              )
            );
          } catch (e) {
            setAllLeads(['Error']);
          }
        } else {
          setAllLeads(['Error']);
        }
      }
      let errorLead = [];
      JSON.parse(task?.description)?.fail?.forEach((el) => {
        const d = `${el.email} - ${el.error}`;
        errorLead.push(d);
      });
      setFailLeads(errorLead);
      if (JSON.parse(task?.description)?.columns) {
        let filtersDetail = [];
        const filter = JSON.parse(task?.description)?.filters?.filter;
        setColumnsExport(JSON.parse(task?.description)?.columns?.split(','));
        for (const filtersKey in filter) {
          if (isArray(filter[filtersKey])) continue;
          let value = filter[filtersKey]
            .replace(/\$and/g, '')
            .replace(/:/g, ' ');
          const d = `${filtersKey} - ${value}`;
          filtersDetail.push(d);
          setFiltersExport(filtersDetail);
        }
      }
    }
  }, [task]);
  let items = [
    {
      key: '1',
      label: `Усі`,
      children: (
        <List
          size="small"
          bordered
          dataSource={allLeads}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      ),
    },
    {
      key: '2',
      label: `Помилки`,
      children: (
        <List
          size="small"
          bordered
          dataSource={failLeads}
          renderItem={(item) => <List.Item>{item}</List.Item>}
        />
      ),
    },
  ];
  if (task.type === 'export') {
    items = [
      {
        key: '1',
        label: `Фільтри`,
        children: (
          <List
            size="small"
            bordered
            dataSource={filtersExport}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        ),
      },
      {
        key: '2',
        label: `Колонки`,
        children: (
          <List
            size="small"
            bordered
            dataSource={columnsExport}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        ),
      },
    ];
  }
  return (
    <Modal
      title="Журнал лідів"
      open={isModalOpen}
      footer={null}
      onCancel={handleCancel}
    >
      <Form
        wrapperCol={{ span: 12, offset: 1 }}
        labelCol={{ span: 7, offset: 1 }}
        style={{ maxHeight: '700px', overflowY: 'scroll' }}
      >
        <Tabs defaultActiveKey="1" items={items} />
      </Form>
    </Modal>
  );
};
