import api from '../../../../api/api';

export const fetchCustomers = async (name) => {
  const idFilter =
    !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
  const res = await api.get(`/customer?filter.name=$ilike:${name}${idFilter}`);
  return res.data.data.map((c) => ({
    label: `#${c.id} ${c.name}`,
    value: c.id,
  }));
};

export const initialFetchCustomers = async () => {
  const res = await api.get(
    `/customer?limit=10&filter.isActive=$and:$eq:true&`
  );
  return res.data.data.map((c) => ({
    label: `#${c.id} ${c.name}`,
    value: c.id,
  }));
};
