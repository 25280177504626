import { useNavigate } from 'react-router-dom';
import api from '../../../api/api';
import { App } from 'antd';
import { CountryBoxForm } from '../CountryBoxForm/CountryBoxForm';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';

export const CreateCountryBox = () => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const bizdevsTeam = ['BIZDEV', 'BIZDEV_TEAM_LEAD', 'BIZDEV_SUPPORT'];
  const fullstacksTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  usePermissionsAllowed(bizdevsTeam.concat(fullstacksTeam));

  const onFinish = (box) => {
    api
      .post('/country-box', {
        ...box,
      })
      .then((res) => {
        message.success('Country Box додано');
        navigate(`/country-box`);
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  return <CountryBoxForm onFinish={onFinish} />;
};
