import { Select, Tag } from 'antd';
import React from 'react';

export const TagsSelector = ({
  tags,
  onClose,
  closable,
  onSelectChange,
  options,
  placeholder,
}) => {
  return (
    <div>
      {tags.map((g) => (
        <Tag
          key={g.value}
          closable={closable}
          onClose={() => {
            onClose(g);
          }}
        >
          {g.label}
        </Tag>
      ))}
      <Select
        style={{ width: 200 }}
        placeholder={placeholder}
        onChange={onSelectChange}
        value={null}
        options={options}
      />
    </div>
  );
};
