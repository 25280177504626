import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Switch,
  Tooltip,
} from 'antd';
import styles from './AppTable.module.css';
import { useNavigate } from 'react-router-dom';
import {
  ExportOutlined,
  ImportOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { AutocompleteSelect } from '../AutocompleteSelect/AutocompleteSelect';
import ResizeTable from 'resizable-antd-table';
import {
  hasPermissions,
  usePermissions,
} from '../../hooks/usePermissionsAllowed';
import dayjs from 'dayjs';
import { CurrentUserContext } from '../../providers/AuthProvider';
import { CountrySelect } from '../CountryMultiselect/CountrySelect';
import { CountrySelectFilter } from '../CountryMultiselect/FiltersCountry/CountrySelectFilter';
import { AutocompleteSelectFilter } from '../AutocompleteSelect/Filter/AutocompleteSelectFilter';
import ReactDragListView from 'react-drag-listview';
import { ThemeContext } from '../../providers/ThemeProvider';

export const AppTable = ({
  columns,
  onColumnsChange,
  data,
  filters,
  dataPrefix,
  allowChangingColumns,
  allowChangingFilters,
  showAddButton,
  isColoredOfCells,
  changeIsColoredOfCellValue,
  createUrl,
  onCsvExport,
  onCsvImport,
  querystr,
  isExportDisabled,
  isImportDisabled,
  isColoredCellsBtn,
  legendColorList,
  ...props
}) => {
  const { isDarkTheme } = useContext(ThemeContext);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [lcColumns, setLcColumns] = useState([...columns]);
  const [lcFilters, setLcFilters] = useState([...filters]);
  const [inputState, setInputState] = useState({});
  const [countries, setCountries] = useState([]);
  const permissions = usePermissions();
  const role = usePermissions();
  const user = useContext(CurrentUserContext);
  const changedColums = localStorage.getItem(`${dataPrefix}_columns_order`);

  useEffect(() => {
    if (allowChangingColumns) {
      let lc = localStorage.getItem(`${dataPrefix}_columns`);
      if (!lc) {
        localStorage.setItem(
          `${dataPrefix}_columns`,
          JSON.stringify(
            columns
              .filter((arr) => arr.hide)
              .map((arr) => arr.title.props.children)
          )
        );
      }

      lc = localStorage.getItem(`${dataPrefix}_columns`);
      const lcItems = lc ? JSON.parse(lc) : [];
      setLcColumns(
        columns.map((c) => {
          return {
            ...c,
            hide: lcItems.find((lcCol) => lcCol === c.title.props.children),
          };
        })
      );
    } else {
      setLcColumns(columns);
    }
    let res = {};
    filters.forEach((f) => {
      res = {
        ...res,
        [f.label]: f.defaultValue,
      };
    });
    setInputState(res);
  }, [columns]);

  useEffect(() => {
    if (allowChangingFilters) {
      let lc = localStorage.getItem(`${dataPrefix}_filters`);
      if (!lc) {
        localStorage.setItem(
          `${dataPrefix}_filters`,
          JSON.stringify(
            filters.filter((arr) => arr.hide).map((arr) => arr.label)
          )
        );
      }

      lc = localStorage.getItem(`${dataPrefix}_filters`);
      const lcItems = lc ? JSON.parse(lc) : [];
      setLcFilters(
        filters.map((f) => {
          return {
            ...f,
            hide: lcItems.find((lcFilter) => lcFilter === f.label),
          };
        })
      );
    } else {
      setLcFilters(filters);
    }
  }, [filters]);

  if (!user) return;

  const showModal = () => {
    setIsModalOpen(true);
  };
  const onCountryChange = (value) => {
    setCountries(value);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setIsFiltersModalOpen(false);
  };

  const mergeColumns = lcColumns.map((col, index) => ({
    ...col,
  }));

  const allowedColumnsFromUser = JSON.parse(user.permissions.columns)[
    dataPrefix
  ];
  const allowedColumns = user ? allowedColumnsFromUser : [];
  const allowed = mergeColumns.filter((c) =>
    allowedColumns?.find((ac) => ac.includes(c.dataIndex))
  );
  const allowedFilters = lcFilters.filter((f) =>
    allowedColumns?.find((af) => af.includes(f.dataIndex))
  );
  const finalColumns = allowedColumnsFromUser ? allowed : mergeColumns;
  const resultColumns = finalColumns.filter((c) => !c.hide);
  const resultFilters = allowedColumnsFromUser ? allowedFilters : lcFilters;

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const columunens = [...resultColumns];
      const item = columunens.splice(fromIndex - 1, 1)[0];
      columunens.splice(toIndex - 1, 0, item);
      setLcColumns(columunens);
      localStorage.setItem(
        `${dataPrefix}_columns_order`,
        JSON.stringify(columunens.map((c) => c.title.props.children))
      );
    },
    nodeSelector: 'th',
    handleSelector: '.dragHandler',
    ignoreSelector: '.ant-table-column-title',
  };

  return (
    <div>
      <div className={styles.tableHeader}>
        <div>
          <Form layout="inline" className={styles.filters}>
            {resultFilters
              .filter(
                (f) =>
                  !f.secondLine &&
                  !f.thirdLine &&
                  !f.hide &&
                  (!f.permissions || hasPermissions(user?.role, f.permissions))
              )
              .map((f) => {
                if (f.type === 'multiselect') {
                  return (
                    <Form.Item
                      key={f.label}
                      label={f.label}
                      className={styles.filter}
                    >
                      <AutocompleteSelect
                        mode="multiple"
                        style={f.style}
                        value={f.value}
                        placeholder={f.label}
                        initialFetch={f.initialFetch}
                        fetch={f.fetch}
                        onChange={(v) => f.onChange(v)}
                      />
                    </Form.Item>
                  );
                }
                if (f.type === 'multiselectFilter') {
                  return (
                    <Form.Item
                      key={f.label}
                      label={f.label}
                      className={styles.filter}
                    >
                      <AutocompleteSelectFilter
                        mode="multiple"
                        style={f.style}
                        value={f.value}
                        placeholder={f.label}
                        initialFetch={f.initialFetch}
                        fetch={f.fetch}
                        onChange={(v) => f.onChange(v)}
                      />
                    </Form.Item>
                  );
                }
                if (f.type === 'datepicker') {
                  return (
                    <Form.Item
                      key={f.label}
                      label={f.label}
                      className={styles.filter}
                    >
                      <DatePicker
                        onChange={f.onChange}
                        placeholder={f.placeholder}
                        value={f.value ? dayjs(f.value) : ''}
                        style={f.style}
                      />
                    </Form.Item>
                  );
                }
                if (f.type === 'checkbox') {
                  return (
                    <Form.Item
                      key={f.label}
                      label={f.label}
                      className={styles.filter}
                    >
                      <Checkbox
                        onChange={f.onChange}
                        defaultChecked={f.defaultChecked}
                        style={f.style}
                      />
                    </Form.Item>
                  );
                }
                if (f.type === 'select') {
                  return (
                    <Form.Item
                      key={f.label}
                      label={f.label}
                      className={styles.filter}
                    >
                      <Select
                        onChange={f.onChange}
                        value={f.value ? f.value : ''}
                        allowClear
                        style={f.style}
                        options={f.options}
                        mode={f.mode}
                      />
                    </Form.Item>
                  );
                }
                if (f.type === 'countrySelect') {
                  return (
                    <Form.Item
                      key={f.label}
                      label={f.label}
                      className={styles.filter}
                    >
                      <CountrySelectFilter
                        mode="multiple"
                        style={f.style}
                        value={f.value ? f.value : []}
                        onChange={f.onChange}
                      />
                    </Form.Item>
                  );
                }
                return (
                  <Form.Item
                    key={f.label}
                    label={f.label}
                    className={styles.filter}
                  >
                    <Input
                      type={f.type}
                      placeholder={f.label}
                      value={inputState[f.label]}
                      onChange={(e) => {
                        setInputState({
                          ...inputState,
                          [f.label]: e.target.value,
                        });
                        f.onChange(e);
                      }}
                      style={f.style}
                    />
                  </Form.Item>
                );
              })}
          </Form>
          <Form>
            <Form layout="inline" className={styles.filters}>
              {resultFilters
                .filter(
                  (f) =>
                    f.secondLine &&
                    !f.hide &&
                    (!f.permissions ||
                      hasPermissions(user?.role, f.permissions))
                )
                .map((f) => {
                  if (f.type === 'multiselect') {
                    return (
                      <Form.Item
                        key={f.label}
                        label={f.label}
                        className={styles.filter}
                      >
                        <AutocompleteSelect
                          mode="multiple"
                          style={f.style}
                          value={f.value}
                          placeholder={f.label}
                          initialFetch={f.initialFetch}
                          fetch={f.fetch}
                          onChange={(v) => f.onChange(v)}
                        />
                      </Form.Item>
                    );
                  }
                  if (f.type === 'multiselectFilter') {
                    return (
                      <Form.Item
                        key={f.label}
                        label={f.label}
                        className={styles.filter}
                      >
                        <AutocompleteSelectFilter
                          mode="multiple"
                          style={f.style}
                          value={f.value}
                          placeholder={f.label}
                          initialFetch={f.initialFetch}
                          fetch={f.fetch}
                          onChange={(v) => f.onChange(v)}
                        />
                      </Form.Item>
                    );
                  }
                  if (f.type === 'datepicker') {
                    return (
                      <Form.Item
                        key={f.label}
                        label={f.label}
                        className={styles.filter}
                      >
                        <DatePicker
                          onChange={f.onChange}
                          placeholder={f.placeholder}
                          value={f.value ? dayjs(f.value) : ''}
                          style={f.style}
                        />
                      </Form.Item>
                    );
                  }
                  if (f.type === 'checkbox') {
                    return (
                      <Form.Item
                        key={f.label}
                        label={f.label}
                        className={styles.filter}
                      >
                        <Checkbox
                          onChange={f.onChange}
                          defaultChecked={f.defaultChecked}
                          style={f.style}
                        />
                      </Form.Item>
                    );
                  }
                  if (f.type === 'select') {
                    return (
                      <Form.Item
                        key={f.label}
                        label={f.label}
                        className={styles.filter}
                      >
                        <Select
                          onChange={f.onChange}
                          value={f.value ? f.value : ''}
                          allowClear
                          style={f.style}
                          options={f.options}
                          mode={f.mode}
                        />
                      </Form.Item>
                    );
                  }
                  if (f.type === 'countrySelect') {
                    return (
                      <Form.Item
                        key={f.label}
                        label={f.label}
                        className={styles.filter}
                      >
                        <CountrySelectFilter
                          mode="multiple"
                          style={f.style}
                          value={f.value ? f.value : []}
                          onChange={f.onChange}
                        />
                      </Form.Item>
                    );
                  }
                  return (
                    <Form.Item
                      key={f.label}
                      label={f.label}
                      className={styles.filter}
                    >
                      <Input
                        type={f.type}
                        placeholder={f.label}
                        value={inputState[f.label]}
                        onChange={(e) => {
                          setInputState({
                            ...inputState,
                            [f.label]: e.target.value,
                          });
                          f.onChange(e);
                        }}
                        style={f.style}
                      />
                    </Form.Item>
                  );
                })}
            </Form>
            <Form layout="inline" className={styles.filters}>
              {resultFilters
                .filter(
                  (f) =>
                    f.thirdLine &&
                    !f.hide &&
                    (!f.permissions ||
                      hasPermissions(user?.role, f.permissions))
                )
                .map((f) => {
                  if (f.type === 'multiselect') {
                    return (
                      <Form.Item
                        key={f.label}
                        label={f.label}
                        className={styles.filter}
                      >
                        <AutocompleteSelect
                          mode="multiple"
                          style={f.style}
                          value={f.value}
                          placeholder={f.label}
                          initialFetch={f.initialFetch}
                          fetch={f.fetch}
                          onChange={(v) => f.onChange(v)}
                        />
                      </Form.Item>
                    );
                  }
                  if (f.type === 'multiselectFilter') {
                    return (
                      <Form.Item
                        key={f.label}
                        label={f.label}
                        className={styles.filter}
                      >
                        <AutocompleteSelectFilter
                          mode="multiple"
                          style={f.style}
                          value={f.value}
                          placeholder={f.label}
                          initialFetch={f.initialFetch}
                          fetch={f.fetch}
                          onChange={(v) => f.onChange(v)}
                        />
                      </Form.Item>
                    );
                  }
                  if (f.type === 'datepicker') {
                    return (
                      <Form.Item
                        key={f.label}
                        label={f.label}
                        className={styles.filter}
                      >
                        <DatePicker
                          onChange={f.onChange}
                          placeholder={f.placeholder}
                          value={f.value ? dayjs(f.value) : ''}
                          style={f.style}
                        />
                      </Form.Item>
                    );
                  }
                  if (f.type === 'checkbox') {
                    return (
                      <Form.Item
                        key={f.label}
                        label={f.label}
                        className={styles.filter}
                      >
                        <Checkbox
                          onChange={f.onChange}
                          defaultChecked={f.defaultChecked}
                          style={f.style}
                        />
                      </Form.Item>
                    );
                  }
                  if (f.type === 'select') {
                    return (
                      <Form.Item
                        key={f.label}
                        label={f.label}
                        className={styles.filter}
                      >
                        <Select
                          onChange={f.onChange}
                          value={f.value ? f.value : ''}
                          allowClear
                          style={f.style}
                          options={f.options}
                          mode={f.mode}
                        />
                      </Form.Item>
                    );
                  }
                  if (f.type === 'countrySelect') {
                    return (
                      <Form.Item
                        key={f.label}
                        label={f.label}
                        className={styles.filter}
                      >
                        <CountrySelectFilter
                          mode="multiple"
                          style={f.style}
                          value={f.value ? f.value : []}
                          onChange={f.onChange}
                        />
                      </Form.Item>
                    );
                  }
                  return (
                    <Form.Item
                      key={f.label}
                      label={f.label}
                      className={styles.filter}
                    >
                      <Input
                        type={f.type}
                        placeholder={f.label}
                        value={inputState[f.label]}
                        onChange={(e) => {
                          setInputState({
                            ...inputState,
                            [f.label]: e.target.value,
                          });
                          f.onChange(e);
                        }}
                        style={f.style}
                      />
                    </Form.Item>
                  );
                })}
            </Form>
          </Form>
        </div>

        <div>
          <div className={styles.tableHeader}>
            {allowChangingColumns && (
              <Button type="primary" onClick={showModal}>
                Колонки
              </Button>
            )}
            {allowChangingFilters && (
              <Button
                className={styles.addButton}
                type="primary"
                onClick={() => setIsFiltersModalOpen(true)}
              >
                Фільтри
              </Button>
            )}
            {role !== 'ANALYST' && showAddButton && (
              <Button
                className={styles.addButton}
                type="primary"
                onClick={() => navigate(createUrl)}
              >
                <PlusOutlined />
              </Button>
            )}
            {onCsvExport && user.isCanExport && (
              <Tooltip title="Експорт">
                <Button
                  disabled={isExportDisabled}
                  className={styles.addButton}
                  type="primary"
                  onClick={() =>
                    onCsvExport(
                      mergeColumns.filter((c) => !c.hide).map((c) => c.key)
                    )
                  }
                >
                  <ExportOutlined />
                </Button>
              </Tooltip>
            )}
            {onCsvImport && user?.isCanInject && (
              <Tooltip title="Імпорт">
                <Button
                  disabled={isImportDisabled}
                  className={styles.addButton}
                  type="primary"
                  onClick={onCsvImport}
                >
                  <ImportOutlined />
                </Button>
              </Tooltip>
            )}
          </div>
          {dataPrefix == 'leads' && (
            <>
              <div className={styles.switchWraper}>
                <span
                  style={{
                    color: isDarkTheme ? 'white' : 'black',
                    marginRight: 15,
                  }}
                >
                  Підсвітити доступні
                </span>
                <Switch
                  defaultChecked={isColoredOfCells}
                  onChange={changeIsColoredOfCellValue}
                />
              </div>
              {isColoredOfCells && (
                <div
                  className={styles.legendWraper}
                  style={{
                    color: isDarkTheme ? '#ffffffab' : '#000000b3',
                  }}
                >
                  <LegendEl
                    color={legendColorList.green.fullColor}
                    text={'доступні'}
                  />
                  <LegendEl
                    color={legendColorList.yellow.fullColor}
                    text={'залишилось 5 хв'}
                  />
                  <LegendEl
                    color={legendColorList.blue.fullColor}
                    text={'фейк'}
                  />
                  <LegendEl
                    color={legendColorList.purple.fullColor}
                    text={'без call статусу'}
                  />
                </div>
              )}
            </>
          )}
        </div>
        <Modal
          title="Показати колoнки"
          open={isModalOpen}
          footer={null}
          onCancel={handleCancel}
        >
          <Col>
            {finalColumns.map((c, index) => {
              return (
                <Row key={index}>
                  <Checkbox
                    defaultChecked={!c.hide}
                    onChange={(e) => {
                      const newArr = [...finalColumns];
                      newArr[index] = {
                        ...newArr[index],
                        hide: !e.target.checked,
                      };
                      setLcColumns(newArr);
                      localStorage.setItem(
                        `${dataPrefix}_columns`,
                        JSON.stringify(
                          newArr
                            .filter((arr) => arr.hide)
                            .map((arr) => arr.title.props.children)
                        )
                      );
                    }}
                  >
                    {c.title}
                  </Checkbox>
                </Row>
              );
            })}
          </Col>
        </Modal>
        <Modal
          title="Показати фільтри"
          open={isFiltersModalOpen}
          footer={null}
          onCancel={handleCancel}
        >
          <Col>
            {allowedFilters.map((f, index) => {
              return (
                <Row key={index}>
                  <Checkbox
                    defaultChecked={!f.hide}
                    onChange={(e) => {
                      const newArr = [...allowedFilters];
                      newArr[index] = {
                        ...newArr[index],
                        hide: !e.target.checked,
                      };
                      setLcFilters(newArr);
                      localStorage.setItem(
                        `${dataPrefix}_filters`,
                        JSON.stringify(
                          newArr
                            .filter((arr) => arr.hide)
                            .map((arr) => arr.label)
                        )
                      );
                    }}
                  >
                    {f.label}
                  </Checkbox>
                </Row>
              );
            })}
          </Col>
        </Modal>
      </div>
      <ReactDragListView.DragColumn {...dragProps}>
        <ResizeTable
          bordered
          columns={
            changedColums
              ? resultColumns.sort((a, b) => {
                  const titleA = a.title.props.children;
                  const titleB = b.title.props.children;
                  const indexA = changedColums.indexOf(titleA);
                  const indexB = changedColums.indexOf(titleB);
                  if (indexA === -1) return 1;
                  if (indexB === -1) return -1;
                  return indexA - indexB;
                })
              : resultColumns
          }
          dataSource={data}
          // scroll={{x: 'max-content'}}
          rowKey="id"
          {...props}
        />
      </ReactDragListView.DragColumn>
    </div>
  );
};

const LegendEl = ({ color, text }) => {
  return (
    <div className={styles.legendEl}>
      <div
        className={styles.legendDot}
        style={{
          backgroundColor: color,
        }}
      ></div>
      <div>{text}</div>
    </div>
  );
};
