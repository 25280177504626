import React, { useContext, useEffect, useState } from 'react';
import api from '../../../api/api';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Spin,
  Switch,
} from 'antd';
import styles from './LeadDetails.module.css';
import {
  hasPermissions,
  usePermissions,
} from '../../../hooks/usePermissionsAllowed';
import {
  hasSystemStatus,
  translateStatus,
} from '../../../utils/translateStatus';
import { StatusTag } from '../../../shared/StatusTag/StatusTag';
import { CurrentUserContext } from '../../../providers/AuthProvider';
import {
  fromApiToClient,
  fromApiToClientDate,
  fromApiToClientTimePlusShift,
} from '../../../utils/dateFilters';
import { FormFieldRestricter } from '../../../shared/FormFieldRestricter/FormFieldRestricter';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';

const { TextArea } = Input;

export const LeadDetails = (props) => {
  const [lead, setLead] = useState();
  const [isStatusLocked, setIsStatusLocked] = useState(false);
  const [status, setStatus] = useState({ label: '', value: '' });
  const [loading, setLoading] = useState(true);
  const [fakeIsDeposit, setFakeIsDeposit] = useState(false);
  const [isDeposit, setIsDeposit] = useState(false);
  const [currentIsDeposit, setCurrentIsDeposit] = useState(false);
  const [switchUseFakeIsDeposit, setSwitchUseFakeIsDeposit] = useState(false);
  const [switchUseFakeCallStatus, setSwitchUseFakeCallStatus] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIgnorOfWarning, setIsIgnorOfWarning] = useState(false);
  const [checkRes, setCheckRes] = useState({
    callStatus: false,
    idDeposit: false,
  });
  const [form] = Form.useForm();
  const { id } = useParams();
  const navigate = useNavigate();
  const role = usePermissions();
  const currentUser = useContext(CurrentUserContext);
  const affiliatesTeam = [
    'AFFILIATE',
    'AFFILIATE_TEAM_LEAD',
    'AFFILIATE_SUPPORT',
  ];
  const fullstacksTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  const [allStatus, setAllStatus] = useState([
    {
      value: 'pending',
      label: (
        <StatusTag
          status="pending"
          style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
        />
      ),
    },
    {
      value: 'approved',
      label: (
        <StatusTag
          status="approved"
          style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
        />
      ),
    },
    {
      value: 'rejected',
      label: (
        <StatusTag
          status="rejected"
          style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
        />
      ),
    },
    {
      value: 'test',
      label: (
        <StatusTag
          status="test"
          style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
        />
      ),
    },
    {
      value: 'fraud',
      label: (
        <StatusTag
          status="fraud"
          style={{
            height: 20,
            lineHeight: '20px',
            marginTop: 5,
          }}
        />
      ),
    },
  ]);

  useEffect(() => {
    api.get(`/lead/${id}`).then((res) => {
      let callStatusHistory = '';
      for (const h of JSON.parse(res.data.callStatusHistory)) {
        callStatusHistory = callStatusHistory.concat(
          `${fromApiToClient(h.dateTime)} | ${h.callStatus} \n`
        );
      }
      form.setFieldValue('callStatusHistory', callStatusHistory);
      form.setFieldValue('callStatus', res.data.callStatus);
      form.setFieldValue('currentCallStatus', res.data.currentCallStatus);
      form.setFieldValue('fakeCallStatus', res.data.fakeCallStatus);
      setIsDeposit(res.data.isDeposit);
      setCurrentIsDeposit(res.data.currentIsDeposit);
      form.setFieldValue('isDeposit', res.data.isDeposit);
      setFakeIsDeposit(res.data.fakeIsDeposit);
      setSwitchUseFakeCallStatus(res.data.useFakeCallStatus);
      setSwitchUseFakeIsDeposit(res.data.useFakeIsDeposit);
      form.setFieldValue('unsignedDetails', res.data.unsignedDetails);
      form.setFieldValue('responseDetail', res.data.responseDetail);
      form.setFieldValue('requestDetail', res.data.requestDetail);
      setLoading(false);
      setLead(res.data);
      form.setFieldValue('name', res.data.name);
      form.setFieldValue('lastName', res.data.lastName);
      form.setFieldValue('phone', res.data.phone);
      form.setFieldValue('email', res.data.email);
      form.setFieldValue('country', res.data.country);
      form.setFieldValue('lang', res.data.lang);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(res.data.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(res.data.updatedAt, user.timezone)
      );
      form.setFieldValue('userAgent', res.data.userAgent);
      form.setFieldValue('status', res.data.status);
      form.setFieldValue('sub1', res.data.sub1);
      form.setFieldValue('sub2', res.data.sub2);
      form.setFieldValue('sub3', res.data.sub3);
      form.setFieldValue('sub4', res.data.sub4);
      form.setFieldValue('sub5', res.data.sub5);
      form.setFieldValue('sub6', res.data.sub6);
      form.setFieldValue('sub7', res.data.sub7);
      form.setFieldValue('sub8', res.data.sub8);
      form.setFieldValue('ip', res.data.ip);
      form.setFieldValue('id', res.data.id);
      form.setFieldValue(
        'order',
        `#${res.data.order.id} ${res.data.order.name}`
      );
      form.setFieldValue(
        'buyer',
        `#${res.data.buyer.id} ${res.data.buyer.name}`
      );
      form.setFieldValue('box', `#${res.data.box.id} ${res.data.box.name}`);
      form.setFieldValue('isStatusLocked', res.data.isStatusLocked);
      form.setFieldValue('status', res.data.status);
      form.setFieldValue('price', res.data.price);
      form.setFieldValue('externalId', res?.data?.externalId);
      setIsStatusLocked(res.data.isStatusLocked);
      setStatus({
        label: (
          <StatusTag
            status={translateStatus(res.data.status)}
            style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
          />
        ),
        value: res.data.status,
      });
    });
  }, [currentUser]);

  const onFinish = async () => {
    if (
      ((switchUseFakeCallStatus && !lead.useFakeCallStatus) ||
        (switchUseFakeIsDeposit && !lead.useFakeIsDeposit)) &&
      !isIgnorOfWarning
    ) {
      const res = await api.get(`/lead/check-able-to-modify/${id}`);
      setCheckRes(res);
      if (!res.callStatus || !res.isDeposit) {
        setIsModalOpen(true);
      }
    } else {
      api
        .put(`/lead/${id}`, {
          name: form.getFieldValue('name'),
          lastName: form.getFieldValue('lastName'),
          email: form.getFieldValue('email'),
          country: form.getFieldValue('country'),
          lang: form.getFieldValue('lang'),
          userAgent: form.getFieldValue('userAgent'),
          ip: form.getFieldValue('ip'),
          phone: form.getFieldValue('phone'),
          sub1: form.getFieldValue('sub1'),
          sub2: form.getFieldValue('sub2'),
          sub3: form.getFieldValue('sub3'),
          sub4: form.getFieldValue('sub4'),
          sub5: form.getFieldValue('sub5'),
          sub6: form.getFieldValue('sub6'),
          sub7: form.getFieldValue('sub7'),
          sub8: form.getFieldValue('sub8'),
          externalId: form.getFieldValue('externalId'),
          price: +form.getFieldValue('price'),
          fakeCallStatus: form.getFieldValue('fakeCallStatus'),
          fakeIsDeposit,
          useFakeCallStatus: switchUseFakeCallStatus,
          useFakeIsDeposit: switchUseFakeIsDeposit,
          isStatusLocked,
          status: status.value || status,
        })
        .then(() => {
          navigate(-1);
        });
    }
  };

  const onLockClick = (e) => {
    setIsStatusLocked(e.target.checked);
  };

  const onFakeIsDepositClick = (e) => {
    setFakeIsDeposit(e.target.checked);
  };

  const onStatusChange = (value) => {
    setStatus(value);
    setIsStatusLocked(true);
  };

  const setIsModalClose = () => {
    setIsModalOpen(false);
  };

  const confirmSaveLeadChange = () => {
    setIsIgnorOfWarning(true);
  };

  useEffect(() => {
    if (isIgnorOfWarning) onFinish();
  }, [isIgnorOfWarning]);

  const user = useContext(CurrentUserContext);

  if (loading || !user) return <Spin size="large" />;

  if (
    user.role === 'SUPER_ADMIN' ||
    user.role === 'AFFILIATE_TEAM_LEAD' ||
    user.role === 'FULLSTACK_TEAM_LEAD'
  ) {
    allStatus.push(
      {
        value: 'error',
        label: (
          <StatusTag
            status="error"
            style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
          />
        ),
      },
      {
        value: 'unsigned',
        label: (
          <StatusTag
            status="unsigned"
            style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
          />
        ),
      },
      {
        value: 'notDistributed',
        label: (
          <StatusTag
            status="notDistributed"
            style={{ height: 20, lineHeight: '20px', marginTop: 5 }}
          />
        ),
      }
    );
  }

  return (
    <div>
      <WarningModal
        checkRes={checkRes}
        isModalOpen={isModalOpen}
        onOk={confirmSaveLeadChange}
        onCancel={setIsModalClose}
        switchUseFakeCallStatus={switchUseFakeCallStatus}
        switchUseFakeIsDeposit={switchUseFakeIsDeposit}
      />
      <Form
        layout=""
        form={form}
        onFinish={onFinish}
        labelCol={{
          span: 6,
        }}
        wrapperCol={{
          span: 12,
        }}
      >
        <Row className={styles.formContainer}>
          <Col style={{ width: '50%' }}>
            <FormFieldRestricter
              allowedFields={JSON.parse(user.permissions.columns).leads}
            >
              <Form.Item
                label="ID"
                name="id"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled className={styles.formItem} />
              </Form.Item>
              <Form.Item
                label="Ім'я"
                name="name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  disabled={currentUser.role !== 'SUPER_ADMIN'}
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item
                label="Прізвище"
                name="lastName"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  disabled={currentUser.role !== 'SUPER_ADMIN'}
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item
                label="Телефон"
                name="phone"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  disabled={currentUser.role !== 'SUPER_ADMIN'}
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  disabled={currentUser.role !== 'SUPER_ADMIN'}
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item
                label="Країна"
                name="country"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  disabled={currentUser.role !== 'SUPER_ADMIN'}
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item
                label="Мова"
                name="lang"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  disabled={currentUser.role !== 'SUPER_ADMIN'}
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item
                label="IP"
                name="ip"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input
                  disabled={currentUser.role !== 'SUPER_ADMIN'}
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item label="User agent" name="userAgent">
                <Input
                  disabled={currentUser.role !== 'SUPER_ADMIN'}
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item label="Sub 1" name="sub1">
                <Input
                  disabled={currentUser.role !== 'SUPER_ADMIN'}
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item label="Sub 2" name="sub2">
                <Input
                  disabled={currentUser.role !== 'SUPER_ADMIN'}
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item label="Sub 3" name="sub3">
                <Input
                  disabled={currentUser.role !== 'SUPER_ADMIN'}
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item label="Sub 5" name="sub5">
                <Input
                  disabled={currentUser.role !== 'SUPER_ADMIN'}
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item label="Sub 4" name="sub4">
                <Input
                  disabled={currentUser.role !== 'SUPER_ADMIN'}
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item label="Sub 6" name="sub6">
                <Input
                  disabled={currentUser.role !== 'SUPER_ADMIN'}
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item label="Sub 7" name="sub7">
                <Input
                  disabled={currentUser.role !== 'SUPER_ADMIN'}
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item label="Sub 8" name="sub8">
                <Input
                  disabled={currentUser.role !== 'SUPER_ADMIN'}
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item
                label="Створено"
                name="createdAt"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled className={styles.formItem} />
              </Form.Item>
              <Form.Item
                label="Оновлено"
                name="updatedAt"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input disabled className={styles.formItem} />
              </Form.Item>
            </FormFieldRestricter>
          </Col>
          <Col style={{ width: '50%' }}>
            <FormFieldRestricter
              allowedFields={JSON.parse(user.permissions.columns).leads}
            >
              <Form.Item name="order" label="Замовлення">
                <Input disabled className={styles.formItem} />
              </Form.Item>
              <Form.Item name="buyer" label="Баєр">
                <Input disabled className={styles.formItem} />
              </Form.Item>
              <Form.Item name="box" label="Бокс">
                <Input disabled className={styles.formItem} />
              </Form.Item>
              <Form.Item label="Зовнішній ID" name="externalId">
                <Input
                  disabled={
                    !hasPermissions(role, [
                      'SUPER_ADMIN',
                      'AFFILIATE_TEAM_LEAD',
                      'FULLSTACK_TEAM_LEAD',
                    ])
                  }
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item label="Ціна" name="price">
                <Input
                  type="number"
                  disabled={
                    !hasPermissions(role, affiliatesTeam.concat(fullstacksTeam))
                  }
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item label="Статус" name="status">
                <Select
                  disabled={
                    hasSystemStatus(form.getFieldValue('status')) &&
                    !hasPermissions(role, affiliatesTeam.concat(fullstacksTeam))
                  }
                  onChange={onStatusChange}
                  options={allStatus}
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item label="Call статус" name="callStatus">
                <Input disabled className={styles.formItem} />
              </Form.Item>
              <Form.Item
                display={'false'}
                label="Поточний Call статус"
                className={styles.formItemWithSwicherWraper}
                name="currentCallStatus"
              >
                <div className={styles.formItemWithSwicher}>
                  <Form.Item name="currentCallStatus">
                    <Input
                      style={{ width: 250 }}
                      className={styles.formItem}
                      disabled
                    />
                  </Form.Item>
                  <CallStatusSideInfo
                    useFakeCallStatus={lead.useFakeCallStatus}
                    nextCallStatus={lead.nextCallStatus}
                    nextCallStatusTime={lead.nextCallStatusTime}
                    timezone={user.timezone}
                  />
                </div>
              </Form.Item>
              <Form.Item name="callStatusHistory" label="Історія call статусів">
                <TextArea
                  disabled
                  rows={4}
                  placeholder="Історія call статусів"
                  className={styles.formItem250}
                />
              </Form.Item>
              {(JSON.parse(user.permissions.columns).leads.fakeCallStatus ||
                role == 'SUPER_ADMIN') && (
                <Form.Item
                  display={'false'}
                  label="FAKE call статус"
                  className={styles.formItemWithSwicherWraper}
                  name="fakeCallStatus"
                >
                  <div className={styles.formItemWithSwicher}>
                    <Form.Item name="fakeCallStatus">
                      <Input
                        style={{ width: 250 }}
                        className={styles.formItem}
                        disabled={
                          !currentUser?.isCanSetFakeInfo &&
                          role != 'SUPER_ADMIN'
                        }
                      />
                    </Form.Item>
                    {(currentUser?.isCanSetFakeInfo ||
                      role === 'SUPER_ADMIN') && (
                      <div className={styles.switchWraper}>
                        <Switch
                          defaultChecked={switchUseFakeCallStatus}
                          onChange={setSwitchUseFakeCallStatus}
                        />
                      </div>
                    )}
                  </div>
                </Form.Item>
              )}
              <Form.Item label="Депозит" name="isDeposit">
                <Checkbox checked={isDeposit} disabled />
                {isDeposit ? (
                  <span className={styles.depositText}>Депозит наявний</span>
                ) : (
                  <span className={styles.depositText}>Депозит відсутній</span>
                )}
              </Form.Item>

              <Form.Item
                display={'false'}
                label="Поточний депозит"
                className={styles.formItemWithSwicherWraper}
                name="currentIsDeposit"
              >
                <div className={styles.formItemWithSwicher}>
                  <Form.Item name="currentIsDeposit">
                    <Checkbox checked={currentIsDeposit} disabled />
                    {isDeposit ? (
                      <span className={styles.depositText}>
                        Депозит наявний
                      </span>
                    ) : (
                      <span className={styles.depositText}>
                        Депозит відсутній
                      </span>
                    )}
                  </Form.Item>
                  <DepositSideInfo
                    currentIsDeposit={lead.currentIsDeposit}
                    useFakeIsDeposit={lead.useFakeIsDeposit}
                    depositAt={lead.depositAt}
                    timezone={user.timezone}
                  />
                </div>
              </Form.Item>
              {(JSON.parse(user.permissions.columns).leads.fakeCallStatus ||
                role == 'SUPER_ADMIN') && (
                <Form.Item
                  name="fakeIsDeposit"
                  label="FAKE депозит"
                  className={styles.formItemWithSwicherWraper}
                >
                  <div className={styles.formItemWithSwicher}>
                    <Form.Item name="fakeIsDeposit">
                      <Checkbox
                        className={styles.formItem}
                        onClick={onFakeIsDepositClick}
                        checked={fakeIsDeposit}
                        defaultChecked={false}
                        disabled={
                          !currentUser?.isCanSetFakeInfo &&
                          role != 'SUPER_ADMIN'
                        }
                      />
                      {fakeIsDeposit ? (
                        <span className={styles.depositText}>
                          Депозит наявний
                        </span>
                      ) : (
                        <span className={styles.depositText}>
                          Депозит відсутній
                        </span>
                      )}
                    </Form.Item>
                    {(currentUser?.isCanSetFakeInfo ||
                      role === 'SUPER_ADMIN') && (
                      <div className={styles.switchWraper}>
                        <Switch
                          defaultChecked={switchUseFakeIsDeposit}
                          onChange={setSwitchUseFakeIsDeposit}
                        />
                      </div>
                    )}
                  </div>
                </Form.Item>
              )}
              <Form.Item name="requestDetail" label="Request">
                <TextArea
                  disabled
                  rows={4}
                  placeholder="Request"
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item name="responseDetail" label="Response">
                <TextArea
                  disabled
                  rows={4}
                  placeholder="Response"
                  className={styles.formItem}
                />
              </Form.Item>
              <Form.Item name="unsignedDetails" label="Деталі">
                <TextArea
                  disabled
                  rows={4}
                  placeholder="Деталі"
                  className={styles.formItem}
                />
              </Form.Item>
            </FormFieldRestricter>
          </Col>
        </Row>
        {(currentUser?.isCanLeadsFakeInfoSet || role === 'SUPER_ADMIN') && (
          <Form.Item
            wrapperCol={{ offset: 1 }}
            labelCol={{ offset: 10 }}
            name="isStatusLocked"
            label="Блокування статусу"
          >
            <Checkbox
              onClick={onLockClick}
              defaultChecked={lead ? lead.isStatusLocked : true}
              checked={isStatusLocked}
            />
          </Form.Item>
        )}
        {hasPermissions(role, affiliatesTeam.concat(fullstacksTeam)) && (
          <Row className={styles.formContainer}>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Зберегти
              </Button>
            </Form.Item>
          </Row>
        )}
      </Form>
    </div>
  );
};

const CallStatusSideInfo = ({
  useFakeCallStatus,
  nextCallStatus,
  nextCallStatusTime,
  timezone,
}) => {
  return useFakeCallStatus ? (
    <div className={styles.switchWraper}>
      <i>(fake)</i>
    </div>
  ) : (
    nextCallStatus && (
      <div className={styles.switchWraper}>
        <i>Наступний:</i> {nextCallStatus} [
        <i>{fromApiToClientTimePlusShift(nextCallStatusTime, timezone)}]</i>
      </div>
    )
  );
};

const DepositSideInfo = ({
  currentIsDeposit,
  useFakeIsDeposit,
  depositAt,
  timezone,
}) => {
  return useFakeIsDeposit ? (
    <div className={styles.switchWraper}>
      <i>(fake)</i>
    </div>
  ) : (
    depositAt && !currentIsDeposit && (
      <div className={styles.switchWraper}>
        <i>Буде проставлено:</i> [
        <i>{fromApiToClientTimePlusShift(depositAt, timezone)}]</i>
      </div>
    )
  );
};

const WarningModal = ({
  isModalOpen,
  checkRes,
  onOk,
  onCancel,
  switchUseFakeCallStatus,
  switchUseFakeIsDeposit,
}) => {
  return (
    <Modal
      title="Увага!"
      open={isModalOpen}
      onOk={onOk}
      onCancel={onCancel}
      okText="Підтвердити, розуміючи ризики"
      cancelText="Скасувати"
    >
      {!checkRes.callStatus && switchUseFakeCallStatus && (
        <div>Час безпечної модифікації call статуса вичерпано</div>
      )}
      {!checkRes.isDeposit && switchUseFakeIsDeposit && (
        <div>Час безпечної модифікації ознаки депозиту вичерпано</div>
      )}
    </Modal>
  );
};
