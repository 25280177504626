import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { OrdersForm } from '../OrderForm/OrdersForm';

export const EditOrder = () => {
  const { message } = App.useApp();
  const [order, setOrder] = useState();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    api.get(`/order/${id}`).then((res) => {
      setOrder(res.data);
      setIsLoading(false);
    });
  }, []);

  const onFinish = (updatedOrder) => {
    delete updatedOrder.updatedAt;
    delete updatedOrder.createdAt;
    api
      .put(`/order/${id}`, {
        ...updatedOrder,
      })
      .then((_) => {
        navigate(-1);
        message.success('Замовлення оновлено');
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  if (isLoading) return 'loading';

  return <OrdersForm order={order} onFinish={onFinish} />;
};
