import {
  hasPermissions,
  usePermissions,
} from '../../hooks/usePermissionsAllowed';
import { Link } from 'react-router-dom';

export const PermissionalLink = ({ url, permissions, label }) => {
  const actualPermissions = usePermissions();
  if (hasPermissions(actualPermissions, permissions)) {
    return <Link to={url}>{label}</Link>;
  }
  return label;
};
