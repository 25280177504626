import { Tag } from 'antd';
import { translateStatus } from '../../utils/translateStatus';
import React from 'react';

export const StatusTag = ({ status, style }) => {
  const statuses = {
    error: 'red',
    rejected: 'orange',
    notDistributed: 'geekblue',
    pending: 'yellow',
    approved: 'green',
    unsigned: 'purple',
    fraud: 'magenta',
    test: 'cyan',
  };
  return (
    <Tag style={style} color={statuses[status]}>
      {translateStatus(status)}
    </Tag>
  );
};
