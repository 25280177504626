import { useNavigate } from 'react-router-dom';
import api from '../../../api/api';
import { App } from 'antd';
import { CallStatusGroupForm } from '../CallStatusGroupForm/CallStatusGroupForm';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';

export const CreateCallStatusGroup = () => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const affiliatesTeam = [
    'AFFILIATE',
    'AFFILIATE_TEAM_LEAD',
    'AFFILIATE_SUPPORT',
  ];
  const fullstackTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  usePermissionsAllowed(affiliatesTeam.concat(fullstackTeam));

  const onFinish = (box) => {
    api
      .post('/call-status-group', {
        ...box,
      })
      .then((res) => {
        message.success('Call статус групу додано');
        navigate(`/call-status-groups/${res.data}`);
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  return <CallStatusGroupForm onFinish={onFinish} />;
};
