// ./services/api.js
import axios from 'axios';

// Проверим в самом начале, есть ли токен в хранилище
const JWTToken = localStorage.getItem('jwt');

// Создать инстанс axios
const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}:${process.env.REACT_APP_API_PORT}`,
});

function apiSetHeader(name, value) {
  if (value) {
    api.defaults.headers[name] = value;
  }
}

// Если токен есть, то добавим заголовок к запросам
if (JWTToken) {
  apiSetHeader('Authorization', `Bearer ${JWTToken}`);
}

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('access_token');
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    if (!['/lead/import'].includes(config.url)) {
      config.headers['Content-Type'] = 'application/json';
    }
    config.headers['x-user-timezone'] =
      Intl.DateTimeFormat().resolvedOptions().timeZone == 'Europe/Kyiv'
        ? 'Europe/Kiev'
        : Intl.DateTimeFormat().resolvedOptions().timeZone;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

export default api;
