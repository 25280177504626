import React, { useContext, useEffect, useState } from 'react';
import {
  usePermissions,
  usePermissionsAllowed,
} from '../../../hooks/usePermissionsAllowed';
import { Link, useSearchParams } from 'react-router-dom';
import { fromApiToClient } from '../../../utils/dateFilters';
import api from '../../../api/api';
import { inputToFilter } from '../../../utils/inputToFilter';
import { AppTable } from '../../../shared/Table/AppTable';
import debounce from 'lodash.debounce';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';
import { PermissionalLink } from '../../../shared/PermissionalLink/PermissionalLink';
import { CurrentUserContext } from '../../../providers/AuthProvider';

export const Integrations = (props) => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filterValues, setFilterValues] = useState({});
  const role = usePermissions();
  const user = useContext(CurrentUserContext);
  const bizdevsTeam = ['BIZDEV', 'BIZDEV_TEAM_LEAD', 'BIZDEV_SUPPORT'];
  const fullstackTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  usePermissionsAllowed(bizdevsTeam.concat(fullstackTeam).concat('ANALYST'));
  const url = searchParams.get('filters');
  const querystr = JSON.parse(url) ? JSON.parse(url) : null;

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (user) {
      setColumns([
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          render: (id) => (
            <PermissionalLink
              label={id}
              url={`/integrations/${id}`}
              permissions={fullstackTeam.concat(bizdevsTeam)}
            />
          ),
        },
        {
          title: 'Назва',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Замовник',
          dataIndex: 'customer',
          key: 'customer',
          render: (c) => (c ? `#${c.id} ${c.name}` : 'Відсутній'),
        },
        {
          title: 'Коментар',
          dataIndex: 'comment',
          key: 'comment',
        },
        {
          title: 'Стан',
          dataIndex: 'isActive',
          key: 'isActive',
          render: (date) => (date ? 'Активний' : 'Не активний'),
        },
        {
          title: 'Створено',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (date) => fromApiToClient(date, user.timezone),
        },
        {
          title: 'Оновлено',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          render: (date) => fromApiToClient(date, user.timezone),
        },
      ]);
    }
  }, [user]);
  useEffect(() => {
    let res = '';
    if (!url) {
      res = 'filter.isActive=$and:$eq:true&';
    }
    for (let i in querystr) {
      res += `filter.${querystr[i][1]}=$and:${querystr[i][2]}&`;
    }
    api.get('/integration?' + res + 'page=1&limit=10').then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
    });
  }, []);

  useDidUpdateEffect(() => {
    const url = inputToFilter(filterValues, currentPage);
    api.get(`/integration?${url}`).then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
      window.scrollTo(0, 0);
    });
  }, [filterValues, currentPage]);

  const onFiltersChange = (name, value) => {
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  };
  const onFiltersSet = (filters) => {
    let object = {};
    for (let i in filters) {
      object[filters[i][1]] = filters[i][2];
    }
    setFilterValues({
      ...object,
    });
  };
  const onTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  return (
    <div>
      <AppTable
        filters={GetFilters({ onFiltersChange, onFiltersSet, querystr })}
        columns={columns}
        pagination={{
          total,
          current: currentPage,
          pageSize: 10,
        }}
        onChange={onTableChange}
        onColumnsChange={setColumns}
        data={data}
        dataPrefix="integrations"
        showAddButton={role !== 'ANALYST'}
        createUrl={'/integrations/create'}
        querystr={querystr}
      />
    </div>
  );
};

const GetFilters = ({ onFiltersChange, onFiltersSet, querystr }) => {
  const [customers, setCustomers] = useState(
    querystr?.hasOwnProperty('customer') ? querystr.customer[0] : []
  );
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (querystr) {
      onFiltersSet(querystr);
    }
  }, []);
  const fetchCustomers = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/customer?filter.name=$ilike:${name}${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchCustomers = async () => {
    const res = await api.get(`/customer?limit=10`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  return [
    {
      label: 'ID',
      type: 'number',
      defaultValue: querystr?.hasOwnProperty('id') && querystr.id[0],
      onChange: debounce((e) => {
        onFiltersChange('id', `$eq:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            id: [e.target.value, 'id', `$eq:${e.target.value}`],
          }),
        });
      }, 300),
      style: {
        width: '80px',
      },
    },
    {
      label: 'Назва',
      defaultValue: querystr?.hasOwnProperty('name') && querystr.name[0],
      onChange: debounce((e) => {
        onFiltersChange('name', `$ilike:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            name: [e.target.value, 'name', `$ilike:${e.target.value}`],
          }),
        });
      }, 300),
    },
    {
      label: 'Замовник',
      type: 'multiselect',
      onChange: (e) => {
        setCustomers(e);
        onFiltersChange(
          'customer.id',
          `$in:${e.map((u) => u.value).join(',')}`
        );
        e.forEach((d) => {
          delete d.disabled;
          delete d.title;
          delete d.key;
        });
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            customer: [
              e,
              'customer.id',
              `$in:${e.map((u) => u.value).join(',')}`,
            ],
          }),
        });
      },
      fetch: fetchCustomers,
      initialFetch: initialFetchCustomers,
      value: customers,
      style: {
        width: '130px',
      },
    },
    {
      label: 'Від',
      type: 'datepicker',
      onChange: (e) => {
        onFiltersChange('createdAt', `$gte:${e?.toISOString() || ''}`);
      },
      style: {
        width: '130px',
      },
    },
    {
      label: 'До',
      type: 'datepicker',
      onChange: (e) => {
        onFiltersChange('createdAt', `$lte:${e?.toISOString() || ''}`);
      },
      style: {
        width: '130px',
      },
    },
    {
      label: 'Стан',
      type: 'checkbox',
      defaultChecked: querystr?.hasOwnProperty('isActive')
        ? querystr.isActive[0]
        : true,
      onChange: (e) => {
        if (e.target.checked) {
          onFiltersChange('isActive', `$eq:${e.target.checked}`);
        } else {
          onFiltersChange('isActive', `$ilike:`);
        }
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            isActive: [
              e.target.checked,
              'isActive',
              e.target.checked ? `$eq:${e.target.checked}` : '$ilike:',
            ],
          }),
        });
      },
    },
  ];
};
