import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { BuyersForm } from '../BuyersForm/BuyersForm';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';

export const EditBuyer = () => {
  const { message } = App.useApp();
  const [buyer, setUser] = useState();
  const { id } = useParams();
  const affiliatesTeam = [
    'AFFILIATE',
    'AFFILIATE_TEAM_LEAD',
    'AFFILIATE_SUPPORT',
  ];
  const fullstackTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  usePermissionsAllowed(affiliatesTeam.concat(fullstackTeam).concat('ANALYST'));
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    api.get(`/buyer/${id}`).then((res) => {
      setUser({
        ...res.data,
      });
      setIsLoading(false);
    });
  }, []);

  const onFinish = (updatedUser) => {
    delete updatedUser.updatedAt;
    api
      .put(`/buyer/${id}`, {
        ...updatedUser,
      })
      .then((_) => {
        navigate(-1);
        message.success('Баєра оновлено');
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  if (isLoading) return 'loading';

  return <BuyersForm buyer={buyer} onFinish={onFinish} type={'edit'} />;
};
