import { App, Button, DatePicker, Form, Input, InputNumber, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import api from '../../../api/api';
import { translateStatus } from '../../../utils/translateStatus';
import { usePermissions } from '../../../hooks/usePermissionsAllowed';
import dayjs from 'dayjs';
import { CurrentUserContext } from '../../../providers/AuthProvider';

const { TextArea } = Input;

export const ResendLeadsModal = ({
  leads,
  isModalOpen,
  handleCancel,
  onSuccess,
}) => {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const role = usePermissions();
  const [startAt, setStartAt] = useState(dayjs());
  const user = useContext(CurrentUserContext);

  const onInject = () => {
    const date = startAt?.tz(user.timezone);
    api
      .post('/lead/resend', {
        ...form.getFieldsValue(),
        startAt: date.toISOString(),
        leads: leads
          .filter((l) => {
            {
              if (l?.status) {
                return l.status === 'unsigned' && l.box;
              } else {
                return l.fakeStatus === 'unsigned' && l.box;
              }
            }
          })
          .map((l) => l.id),
      })
      .then((_) => {
        message.success('Перевідправка лідів почалась в фоновому режимі');
        onSuccess();
      })
      .catch((e) => message.error(e.response.data?.message));
  };

  const wrongStatusLeads = leads.filter((l) => {
    // console.log(l.id);
    // console.log(l.status);
    // console.log(l.box);
    // console.log(l.status !== 'unsigned' || !l.box);
    return l.status !== 'unsigned' || !l.box;
  });

  if (!user) return '';

  return (
    <Modal
      title="Перевідправити ліди"
      open={isModalOpen}
      footer={null}
      onCancel={handleCancel}
    >
      {!!wrongStatusLeads.length && (
        <div>
          Перевідправити можна тільки ліди в статусі "
          {translateStatus('unsigned')}" та з наявним боксом. Наступні ліди не
          будуть перевідправлені:{' '}
          <b>{wrongStatusLeads.map((l) => l.email).join(', ')}</b>
        </div>
      )}
      {wrongStatusLeads.length !== leads.length && (
        <Form
          wrapperCol={{ span: 12, offset: 1 }}
          labelCol={{ span: 7, offset: 1 }}
          form={form}
          onFinish={onInject}
        >
          <Form.Item
            name="startAt"
            label="Початок"
            style={{ marginTop: 15 }}
            rules={[
              {
                validator: (rule, value, callback) => {
                  if (!startAt) callback('Будь ласка, виберіть час початку');
                  return callback();
                },
              },
            ]}
          >
            <DatePicker
              defaultValue={dayjs().tz(user.timezone)}
              showTime
              changeOnBlur
              onChange={setStartAt}
              placeholder="Початок"
              allowClear={false}
            />
          </Form.Item>
          <Form.Item
            name="min"
            label="Мінімальний інтервал (хв)"
            style={{ marginTop: 15 }}
            wrapperCol={{ span: 12, offset: 1 }}
            labelCol={{ span: 12, offset: 1 }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber placeholder="0" min={0} max={60} />
          </Form.Item>
          <Form.Item
            name="max"
            label="Максимальний інтервал (хв)"
            style={{ marginTop: 15 }}
            wrapperCol={{ span: 12, offset: 1 }}
            labelCol={{ span: 12, offset: 1 }}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <InputNumber placeholder="60" min={0} max={60} />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 10 }}>
            <Button type="primary" htmlType="submit">
              Відправити
            </Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
