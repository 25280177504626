import React, { useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import { Button, Checkbox, Form, Input } from 'antd';
import styles from './IntegrationsDetails.module.css';
import Editor from '@monaco-editor/react';
import { AutocompleteSelect } from '../../../shared/AutocompleteSelect/AutocompleteSelect';
import api from '../../../api/api';
import {
  checkStatusPattern,
  sendLeadPattern,
} from '../../../utils/editorUtils';
import {
  hasPermissions,
  usePermissions,
  usePermissionsAllowed,
} from '../../../hooks/usePermissionsAllowed';
const { TextArea } = Input;

export const IntegrationsDetails = ({ integration, onFinish }) => {
  const role = usePermissions();
  const bizdevsTeam = ['BIZDEV', 'BIZDEV_TEAM_LEAD', 'BIZDEV_SUPPORT'];
  const fullstacksTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  usePermissionsAllowed(bizdevsTeam.concat(fullstacksTeam));
  const [sendLead, setSendLead] = useState(
    integration ? integration.sendLead : sendLeadPattern()
  );
  const [checkStatus, setCheckStatus] = useState(
    integration ? integration.checkStatus : checkStatusPattern()
  );
  const [isActive, setIsActive] = useState(
    integration ? integration.isActive : true
  );
  const [selectedCustomer, setSelectedCustomer] = useState();

  const [form] = Form.useForm();

  useEffect(() => {
    if (!integration) form.setFieldValue('isActive', true);
  }, []);

  useEffect(() => {
    if (integration) {
      form.setFieldsValue(integration);
      form.setFieldValue('createdAt', fromApiToClient(integration.createdAt));
      form.setFieldValue('updatedAt', fromApiToClient(integration.updatedAt));
      form.setFieldValue('customer', {
        label: integration.customer.name,
        value: integration.customer.id,
        key: integration.customer.id,
      });
      setIsActive(integration.isActive);
      setSelectedCustomer({
        label: integration.customer.name,
        value: integration.customer.id,
        key: integration.customer.id,
      });
      setSendLead(integration.sendLead);
      setCheckStatus(integration.checkStatus);
    }
  }, [integration]);

  const onActiveChange = (e) => {
    setIsActive(e.target.checked);
  };

  const fetchCustomers = async (name) => {
    const res = await api.get(`/customer?filter.name=$ilike:${name}`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchCustomers = async (name) => {
    const res = await api.get(`/customer?limit10`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  return (
    <Form
      form={form}
      onFinish={() => {
        onFinish({
          ...form.getFieldsValue(),
          customerId: selectedCustomer.value,
          sendLead,
          checkStatus,
          isActive,
          createdAt: undefined,
          updatedAt: undefined,
        });
      }}
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
    >
      <Form.Item
        name="name"
        label="Назва"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input
          placeholder="Назва"
          rootClassName={styles.input}
          disabled={!hasPermissions(role, bizdevsTeam.concat(fullstacksTeam))}
        />
      </Form.Item>

      <Form.Item
        name="customer"
        label="Замовник"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <AutocompleteSelect
          className={styles.input}
          value={selectedCustomer}
          placeholder="Замовник"
          initialFetch={initialFetchCustomers}
          fetch={fetchCustomers}
          onChange={setSelectedCustomer}
          disabled={!hasPermissions(role, bizdevsTeam.concat(fullstacksTeam))}
        />
      </Form.Item>
      <Form.Item name="comment" label="Коментар">
        <TextArea
          rows={4}
          placeholder="Коментар"
          className={styles.input}
          style={{ resize: 'none' }}
        />
      </Form.Item>
      {integration && (
        <Form.Item name="createdAt" label="Створено">
          <Input disabled placeholder="Створено" rootClassName={styles.input} />
        </Form.Item>
      )}
      {integration && (
        <Form.Item name="updatedAt" label="Оновлено">
          <Input disabled placeholder="Оновлено" rootClassName={styles.input} />
        </Form.Item>
      )}
      <Form.Item
        name="isActive"
        label="Активна"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Checkbox
          onClick={onActiveChange}
          defaultChecked={integration ? integration.isActive : true}
          rootClassName={styles.input}
        />
      </Form.Item>

      <div className={styles.editorLabel}>Відправка ліда</div>
      <Form.Item
        className={styles.editor}
        wrapperCol={{ offset: 1 }}
        labelCol={{ span: 24 }}
        layout="vertical"
        name="sendLead"
      >
        <Editor
          options={{
            minimap: { enabled: true },
            scrollbar: {
              handleMouseWheel: false,
              alwaysConsumeMouseWheel: false,
            },
            readOnly: role === 'ANALYST',
          }}
          height="80vh"
          width="70vw"
          theme="vs-dark"
          defaultLanguage="javascript"
          value={sendLead}
          onChange={setSendLead}
          defaultValue={sendLeadPattern()}
        />
      </Form.Item>

      <div className={styles.editorLabel}>Перевірка статуса</div>
      <Form.Item
        wrapperCol={{ offset: 1 }}
        labelCol={{ span: 24 }}
        layout="vertical"
        name="checkStatus"
      >
        <Editor
          options={{
            minimap: { enabled: true },
            scrollbar: {
              handleMouseWheel: false,
              alwaysConsumeMouseWheel: false,
            },
            readOnly: role === 'ANALYST',
          }}
          height="80vh"
          width="70vw"
          theme="vs-dark"
          value={checkStatus}
          onChange={setCheckStatus}
          defaultLanguage="javascript"
          defaultValue={checkStatusPattern()}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 11 }}>
        <Button type="primary" htmlType="submit">
          Зберегти
        </Button>
      </Form.Item>
    </Form>
  );
};
