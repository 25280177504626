import { App } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import api from '../../../api/api';
import { IntegrationsDetails } from '../IntegrationsDetails/IntegrationsDetails';

export const EditIntegration = () => {
  const { message } = App.useApp();
  const [integration, setIntegration] = useState();
  const { id } = useParams();
  const bizdevTeam = ['BIZDEV', 'BIZDEV_TEAM_LEAD', 'BIZDEV_SUPPORT'];
  const fullstackTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  usePermissionsAllowed(bizdevTeam.concat(fullstackTeam).concat('ANALYST'));
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    api.get(`/integration/${id}`).then((res) => {
      setIntegration(res.data);
      setIsLoading(false);
    });
  }, []);

  const onFinish = (updatedIntegration) => {
    delete updatedIntegration.updatedAt;
    api
      .put(`/integration/${id}`, {
        ...updatedIntegration,
      })
      .then((_) => {
        navigate(-1);
        message.success('Інтеграцію оновлено');
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  if (isLoading) return 'loading';

  return <IntegrationsDetails onFinish={onFinish} integration={integration} />;
};
