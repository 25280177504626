import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import {
  fromApiToClientDate,
  fromApiToClientMonth,
} from '../../../utils/dateFilters';
import {
  getColorByStatus,
  translateStatus,
} from '../../../utils/translateStatus';
import { statuses } from '../../../utils/statusOptions';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    datalabels: {
      align: 'end',
      anchor: 'end',
      font: {
        weight: 'bold',
      },
      formatter: (value, context) => {
        if (context.datasetIndex === context.chart.data.datasets.length - 1) {
          const dataIndex = context.dataIndex;
          const total = context.chart.data.datasets.reduce(
            (acc, dataset) => acc + (dataset.data[dataIndex] || 0),
            0
          );
          return total;
        } else {
          return '';
        }
      },
    },
  },
  layout: {
    padding: 20,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
};

export const ReportChart = ({ data }) => {
  const chartData = {
    labels: data.map((d) => {
      const d0Key = Object.keys(d)[0];
      const d0Value = Object.values(d)[0];
      if (d0Key === 'day') {
        return fromApiToClientDate(d0Value);
      } else if (d0Key === 'month') {
        return fromApiToClientMonth(d0Value);
      } else {
        return d0Value;
      }
    }),
    datasets: statuses
      .filter((s) => s !== 'unsigned' && s !== 'test' && s !== 'error')
      .map((s) => ({
        label: translateStatus(s),
        data: data.map(
          (d) => d.statusData.find((sd) => sd.status === s)?.leads
        ),
        borderColor: getColorByStatus(s),
        backgroundColor: getColorByStatus(s),
      })),
  };
  return (
    <Bar
      plugins={[
        ChartDataLabels,
        {
          beforeInit(chart) {
            const originalFit = chart.legend.fit;

            chart.legend.fit = function fit() {
              originalFit.bind(chart.legend)();
              this.height += 15;
            };
          },
        },
      ]}
      height={50}
      options={options}
      data={chartData}
    />
  );
};
