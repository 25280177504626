import { Button, Checkbox, Form, Input, Row } from 'antd';
import styles from './PermissionsForm.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { CurrentUserContext } from '../../../providers/AuthProvider';
import { fromApiToClient } from '../../../utils/dateFilters';
import api from '../../../api/api';

export const PermissionsForm = ({ permissions, onFinish }) => {
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(
    permissions ? permissions.isActive : true
  );
  const [leadsOptions, setLeadsOptions] = useState([]);
  const [selectedLeadsOptions, setSelectedLeadsOptions] = useState([]);
  const [orderOptions, setOrderOptions] = useState([]);
  const [selectedOrderOptions, setSelectedOrderOptions] = useState([]);

  const user = useContext(CurrentUserContext);
  useEffect(() => {
    if (!permissions) form.setFieldValue('isActive', true);

    api.get('/lead/columns').then((res) => {
      setLeadsOptions(res.data);
    });
    api.get('/order/columns').then((res) => setOrderOptions(res.data));
  }, []);

  const onChangeOrders = (e) => {
    setSelectedOrderOptions(e);
  };

  const onChangeLeads = (e) => {
    setSelectedLeadsOptions(e);
  };

  useEffect(() => {
    if (permissions && user) {
      form.setFieldsValue(permissions);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(permissions.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(permissions.updatedAt, user.timezone)
      );
      setIsActive(permissions.isActive);
      const selectedLeads = JSON.parse(permissions.columns).leads;
      const selectedOrders = JSON.parse(permissions.columns).orders;
      form.setFieldValue('leads', selectedLeads);
      form.setFieldValue('orders', selectedOrders);
      setSelectedOrderOptions(selectedOrders);
      setSelectedLeadsOptions(selectedLeads);
    }
  }, [permissions, user]);

  const onActiveChange = (e) => {
    setIsActive(e.target.checked);
  };

  return (
    <Form
      form={form}
      onFinish={() => {
        onFinish({
          name: form.getFieldValue('name'),
          createdAt: undefined,
          updatedAt: undefined,
          leads: selectedLeadsOptions,
          orders: selectedOrderOptions,
          isActive,
        });
      }}
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
    >
      <Form.Item
        name="name"
        label="Назва"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Назва" rootClassName={styles.input} />
      </Form.Item>

      {permissions && (
        <Form.Item name="createdAt" label="Створено">
          <Input disabled placeholder="Створено" rootClassName={styles.input} />
        </Form.Item>
      )}
      {permissions && (
        <Form.Item name="updatedAt" label="Оновлено">
          <Input disabled placeholder="Оновлено" rootClassName={styles.input} />
        </Form.Item>
      )}
      <Form.Item
        name="isActive"
        label="Активний"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Checkbox
          onClick={onActiveChange}
          defaultChecked={permissions ? permissions.isActive : true}
          rootClassName={styles.input}
        />
      </Form.Item>
      <Row className="flex justify-center">
        <div className="flex">
          <div className="mr-2">Ліди:</div>
          <Checkbox.Group
            value={selectedLeadsOptions}
            options={leadsOptions}
            onChange={onChangeLeads}
          />
        </div>
        <div className="flex">
          <div className="mr-2">Замовлення:</div>
          <Checkbox.Group
            value={selectedOrderOptions}
            options={orderOptions}
            onChange={onChangeOrders}
          />
        </div>
      </Row>

      <Form.Item wrapperCol={{ offset: 11 }}>
        <Button type="primary" htmlType="submit">
          Зберегти
        </Button>
      </Form.Item>
    </Form>
  );
};
