import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { CallStatusGroupForm } from '../CallStatusGroupForm/CallStatusGroupForm';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';

export const EditCallStatusGroups = () => {
  const { message } = App.useApp();
  const [callStatusGroup, setCallStatusGroup] = useState();
  const { id } = useParams();
  const affiliatesTeam = [
    'AFFILIATE',
    'AFFILIATE_TEAM_LEAD',
    'AFFILIATE_SUPPORT',
  ];
  const fullstackTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  usePermissionsAllowed(affiliatesTeam.concat(fullstackTeam).concat('ANALYST'));
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  useDidUpdateEffect(() => {
    getData();
  }, [id]);

  const getData = () => {
    setIsLoading(true);
    api.get(`/call-status-group/${id}`).then((res) => {
      setCallStatusGroup(res.data);
      setIsLoading(false);
    });
  };

  const onFinish = (updatedBox) => {
    api
      .put(`/call-status-group/${id}`, {
        ...updatedBox,
      })
      .then((_) => {
        navigate(-1);
        message.success('Call статус групу оновлено');
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  if (isLoading) return 'loading';

  return (
    <CallStatusGroupForm
      onFinish={onFinish}
      callStatusGroup={callStatusGroup}
      isDefault={callStatusGroup.isDefault}
      onRefresh={getData}
    />
  );
};
