import { useNavigate } from 'react-router-dom';
import api from '../../../api/api';
import { App, Tabs, Tooltip } from 'antd';
import { BoxForm } from '../BoxForm/BoxForm';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';

export const CreateBox = () => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const affiliatesTeam = [
    'AFFILIATE',
    'AFFILIATE_TEAM_LEAD',
    'AFFILIATE_SUPPORT',
  ];
  const fullstackTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  usePermissionsAllowed(affiliatesTeam.concat(fullstackTeam));

  const onFinish = (box) => {
    api
      .post('/box', {
        ...box,
      })
      .then((res) => {
        message.success('Бокс додано');
        navigate(`/boxes/${res.data}`);
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  const tabs = [
    {
      key: '1',
      label: `Бокс`,
      children: <BoxForm onFinish={onFinish} />,
    },
    {
      key: '2',
      label: <Tooltip title="Спочатку створіть бокс">Замовлення</Tooltip>,
      children: '',
      disabled: true,
    },
  ];

  return <Tabs defaultActiveKey="1" items={tabs} />;
};
