import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { UsersForm } from '../UsersForm/UsersForm';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';

export const EditUser = () => {
  const { message } = App.useApp();
  const [user, setUser] = useState();
  const { id } = useParams();
  usePermissionsAllowed([
    'AFFILIATE_TEAM_LEAD',
    'BIZDEV_TEAM_LEAD',
    'FULLSTACK_TEAM_LEAD',
    'SUPER_ADMIN',
  ]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setIsLoading(true);
    api.get(`/user/${id}`).then((res) => {
      setUser({
        ...res.data,
      });
      setIsLoading(false);
    });
  };

  const onFinish = (updatedUser) => {
    delete updatedUser.updatedAt;
    api
      .put(`/user/${id}`, {
        ...updatedUser,
      })
      .then((_) => {
        navigate(-1);
        message.success('Користувача оновлено');
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  if (isLoading) return 'loading';

  return (
    <UsersForm user={user} onFinish={onFinish} refetch={getData} type="edit" />
  );
};
