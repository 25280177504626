import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../api/api';
import jwt_decode from 'jwt-decode';

export const CurrentUserContext = React.createContext(null);

export const AuthProvider = ({ children }) => {
  let location = useLocation();
  const navigate = useNavigate();
  const [user, setUser] = useState();
  const token = localStorage.getItem('access_token');
  if (!token) {
    window.location.replace('/login');
  }
  const decoded = jwt_decode(token);

  useEffect(() => {
    if (!localStorage.getItem('access_token')) {
      navigate('/login');
    }
  }, [location, navigate]);

  useEffect(() => {
    api.get(`/user/${decoded.sub}`).then((res) => {
      setUser(res.data);
      if (!res.data.isActive) navigate('/login');
    });
  }, []);

  return (
    <CurrentUserContext.Provider value={user}>
      {children}
    </CurrentUserContext.Provider>
  );
};
