import React, { useContext, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';
import { usePermissionsAllowed } from '../../hooks/usePermissionsAllowed';
import { fromApiToClient } from '../../utils/dateFilters';
import api from '../../api/api';
import { inputToFilter } from '../../utils/inputToFilter';
import { AppTable } from '../../shared/Table/AppTable';
import { DetailsModal } from './DetailsModal';
import {
  ExtendableTextTableCell,
  ExtendableTextTableCellWithModal,
} from '../../shared/ExtendableTableCell/ExtendableTableCell';
import { CurrentUserContext } from '../../providers/AuthProvider';

export const EntityLogs = () => {
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filterValues, setFilterValues] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [selectedData, setSelectedData] = useState('{}');
  const user = useContext(CurrentUserContext);
  usePermissionsAllowed(['SUPER_ADMIN']);

  const [columns, setColumns] = useState([]);
  useEffect(() => {
    if (user) {
      setColumns([
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Таблиця',
          dataIndex: 'entity',
          key: 'entity',
        },
        {
          title: 'Дія',
          dataIndex: 'action',
          key: 'action',
        },
        {
          title: 'Користувач',
          dataIndex: 'user',
          key: 'user',
          render: (user) => user?.nickname,
        },
        {
          title: 'Створено',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (date) => fromApiToClient(date, user?.timezone),
        },
        {
          title: 'Дані',
          dataIndex: 'data',
          key: 'data',
          render: (d) => (
            <ExtendableTextTableCellWithModal
              data={d}
              stringLength={255}
              setIsOpen={setIsOpen}
              setSelectedData={setSelectedData}
            />
          ),
        },
      ]);
    }
  }, [user]);
  useEffect(() => {
    api.get('/entity-log?page=1&limit=10').then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
    });
  }, []);

  useEffect(() => {
    const url = inputToFilter(filterValues, currentPage);
    api.get(`/entity-log?${url}`).then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
      window.scrollTo(0, 0);
    });
  }, [filterValues, currentPage]);

  const onFiltersChange = (name, value) => {
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  };

  const onTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  return (
    <div>
      <DetailsModal
        data={selectedData}
        isModalOpen={isOpen}
        handleCancel={() => setIsOpen(false)}
      />
      <AppTable
        filters={GetFilters({ onFiltersChange })}
        columns={columns}
        pagination={{
          total,
          current: currentPage,
          pageSize: 10,
        }}
        onChange={onTableChange}
        onColumnsChange={setColumns}
        data={data}
        dataPrefix="logs"
      />
    </div>
  );
};

const GetFilters = ({ onFiltersChange }) => {
  const [users, setUsers] = useState([]);
  const fetchUsers = async (name) => {
    const res = await api.get(
      `/user?filter.nickname=$ilike:${name}&filter.isActive=$eq:true`
    );
    return res.data.data.map((c) => ({
      label: c.nickname,
      value: c.id,
    }));
  };

  const initialFetchUsers = async () => {
    const res = await api.get(`/user?limit=10&filter.isActive=$eq:true`);
    return res.data.data.map((c) => ({
      label: c.nickname,
      value: c.id,
    }));
  };

  return [
    {
      label: 'ID',
      type: 'number',
      onChange: debounce((e) => {
        onFiltersChange('id', `$eq:${e.target.value}`);
      }, 300),
      style: {
        width: '80px',
      },
    },
    {
      label: 'Від',
      type: 'datepicker',
      onChange: (e) => {
        const date = e?.set('hour', 0).set('minute', 0).set('second', 0);
        onFiltersChange('createdAt', `$gte:${date?.toISOString() || ''}`);
      },
      style: {
        width: '130px',
      },
    },
    {
      label: 'До',
      type: 'datepicker',
      onChange: (e) => {
        const date = e?.set('hour', 23).set('minute', 23).set('second', 59);
        onFiltersChange('createdAt', `$lte:${date?.toISOString() || ''}`);
      },
      style: {
        width: '130px',
      },
    },
    {
      label: 'Дані',
      onChange: debounce((e) => {
        onFiltersChange('data', `$ilike:${e.target.value}`);
      }, 300),
    },
    {
      label: 'Таблиця',
      onChange: debounce((e) => {
        onFiltersChange('entity', `$ilike:${e.target.value}`);
      }, 300),
    },
    {
      label: 'Користувач',
      type: 'multiselect',
      onChange: (e) => {
        setUsers(e);
        e.forEach((d) => {
          delete d.disabled;
          delete d.title;
          delete d.key;
        });
        onFiltersChange('user_id', `$in:${e.map((u) => u.value).join(',')}`);
      },
      fetch: fetchUsers,
      initialFetch: initialFetchUsers,
      value: users,
      style: {
        width: '130px',
      },
    },
  ];
};
