import {
  Button,
  Card,
  Checkbox,
  Divider,
  Form,
  Input,
  Popconfirm,
  Select,
} from 'antd';
import styles from './UsersForm.module.css';
import React, { useEffect, useState, useContext } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import { AutocompleteSelect } from '../../../shared/AutocompleteSelect/AutocompleteSelect';
import api from '../../../api/api';
import { StatusTag } from '../../../shared/StatusTag/StatusTag';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { CreatePostbackModal } from './CreatePostbackModal';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';
import { CurrentUserContext } from '../../../providers/AuthProvider';
import timezones from 'timezones-list';
import {
  hasPermissions,
  usePermissions,
} from '../../../hooks/usePermissionsAllowed';

export const UsersForm = ({ user, onFinish, refetch, type }) => {
  const [activePostback, setActivePostback] = useState();
  const [selectedPermissions, setSelectedPermissions] = useState();
  const [form] = Form.useForm();
  const [checkboxRole, setCheckboxRole] = useState(user ? user.role : '');
  const [isActive, setIsActive] = useState(true);
  const [isCanInject, setIsCanInject] = useState(false);
  const [isSeeAllInfo, setIsSeeAllInfo] = useState(true);
  const [isCanSetFakeInfo, setIsCanSetFakeInfo] = useState(true);
  const [isCanTranshipment, setIsCanTranshipment] = useState(false);
  const [isPostbackModalOpen, setIsPostbackModalOpen] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const [userRolesOptions, setUserRolesOptions] = useState([]);
  const [selectedSupport, setSelectedSupport] = useState();
  const [selectedTeamLead, setSelectedTeamLead] = useState();
  const [userRoleSideTitle, setUserRoleSideTitle] = useState();
  const [selectedWorkers, setSelectedWorkers] = useState([]);
  const [timezonesList, setTimezonesList] = useState([]);
  const [timezone, setTimezone] = useState();
  const [isCanExport, setIsCanExport] = useState(false);
  const [rolesPermissions, setRolesPermissions] = useState({
    support: false,
    teamlead: false,
    workers: false,
  });
  const currentUser = useContext(CurrentUserContext);
  const role = usePermissions();

  useEffect(() => {
    api.get(`/roles`).then((res) => {
      setUserRoles(res.data);
    });
  }, []);

  useDidUpdateEffect(() => {
    if (type === 'create') {
      if (role === 'AFFILIATE_TEAM_LEAD') {
        const roles = [];
        for (const role of userRoles) {
          if (role.name === 'AFFILIATE_SUPPORT' || role.name === 'AFFILIATE')
            roles.push(role);
        }
        setUserRoles(roles);
      }
      if (role === 'BIZDEV_TEAM_LEAD') {
        const roles = [];
        for (const role of userRoles) {
          if (role.name === 'BIZDEV_SUPPORT' || role.name === 'BIZDEV')
            roles.push(role);
        }
        setUserRoles(roles);
      }
      if (role === 'FULLSTACK_TEAM_LEAD') {
        const roles = [];
        for (const role of userRoles) {
          if (role.name === 'FULLSTACK_SUPPORT' || role.name === 'FULLSTACK')
            roles.push(role);
        }
        setUserRoles(roles);
      }
      setSelectedTeamLead({
        label: currentUser?.nickname,
        value: currentUser?.id,
        key: currentUser?.id,
      });
      form.setFieldValue('teamLead', {
        label: currentUser?.nickname,
        value: currentUser?.id,
        key: currentUser?.id,
      });
    }
  }, [userRoles, currentUser]);

  useDidUpdateEffect(() => {
    const rolesOptions = userRoles.map((role) => ({
      label: role.ua_title,
      value: role.name,
      key: role.name,
    }));
    setUserRolesOptions(rolesOptions);
  }, [userRoles]);

  useEffect(() => {
    if (user && currentUser) {
      form.setFieldsValue(user);
      setTimezone(user.timezone);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(user.createdAt, currentUser.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(user.updatedAt, currentUser.timezone)
      );

      // form.setFieldValue('createdAt', fromApiToClient(user.createdAt));
      // form.setFieldValue('updatedAt', fromApiToClient(user.updatedAt));
      setIsCanExport(user.isCanExport);
      setIsActive(user.isActive);
      setIsCanInject(user.isCanInject);
      setIsSeeAllInfo(user.isSeeAllInfo);
      setIsCanSetFakeInfo(user.isCanSetFakeInfo);
      setIsCanTranshipment(user.isCanTranshipment);
      form.setFieldValue('permissions', {
        label: user.permissions.name,
        value: user.permissions.id,
        key: user.permissions.id,
      });
      setSelectedPermissions({
        label: user.permissions.name,
        value: user.permissions.id,
        key: user.permissions.id,
      });
      for (const role of userRoles) {
        if (user.role === role.name) {
          if (role.side === 'AFFILIATES') setUserRoleSideTitle('Аффілейти');
          if (role.side === 'BIZDEVS') setUserRoleSideTitle('Біздеви');
          if (role.side === 'FULLSTACKS') setUserRoleSideTitle('Користувачі');
        }
      }
      if (user.supportUsers.length !== 0) {
        setSelectedWorkers(
          user.supportUsers.map((u) => ({
            label: `#${u.id} ${u.nickname}`,
            value: u.id,
            key: u.id,
          }))
        );
        form.setFieldValue(
          'workers',
          user.supportUsers.map((u) => ({
            label: `#${u.id} ${u.nickname}`,
            value: u.id,
            key: u.id,
          }))
        );
      } else if (user.teamLeadUsers.length !== 0) {
        setSelectedWorkers(
          user.teamLeadUsers.map((u) => ({
            label: `#${u.id} ${u.nickname}`,
            value: u.id,
            key: u.id,
          }))
        );
        form.setFieldValue(
          'workers',
          user.teamLeadUsers.map((u) => ({
            label: `#${u.id} ${u.nickname}`,
            value: u.id,
            key: u.id,
          }))
        );
      }
      if (user.supportId) {
        setSelectedSupport({
          label: user.support.nickname,
          value: user.support.id,
          key: user.support.id,
        });
        form.setFieldValue('support', {
          label: user.support.nickname,
          value: user.support.id,
          key: user.support.id,
        });
      }
      if (user.teamLeadId) {
        setSelectedTeamLead({
          label: user.teamLead.nickname,
          value: user.teamLead.id,
          key: user.teamLead.id,
        });
        form.setFieldValue('teamLead', {
          label: user.teamLead.nickname,
          value: user.teamLead.id,
          key: user.teamLead.id,
        });
      }
    }
    api.get(`/timezones`).then((res) => {
      setTimezonesList(res.data);
    });
  }, [user, userRoles]);

  const onRoleChange = (value) => {
    setCheckboxRole(value);
    for (const role of userRoles) {
      if (value === role.name) {
        if (role.side === 'AFFILIATES') setUserRoleSideTitle('Аффілейти');
        if (role.side === 'BIZDEVS') setUserRoleSideTitle('Біздеви');
        if (role.side === 'FULLSTACKS') setUserRoleSideTitle('Користувачі');
      }
    }
  };

  const onActiveChange = (e) => {
    setIsActive(e.target.checked);
  };

  const onCanInjectChange = (e) => {
    setIsCanInject(e.target.checked);
  };

  const onSeeAllInfoChange = (e) => {
    setIsSeeAllInfo(e.target.checked);
  };

  const onSetIsCanFakeInfoChange = (e) => {
    setIsCanSetFakeInfo(e.target.checked);
  };

  const onSetIsCanTranshipment = (e) => {
    setIsCanTranshipment(e.target.checked);
  };

  const fetchPermissions = async (name) => {
    const res = await api.get(`/permissions?filter.name=$ilike:${name}`);
    return res.data.data.map((c) => ({
      label: c.name,
      value: c.id,
    }));
  };

  const initialFetchPermissions = async () => {
    const res = await api.get(`/permissions?limit10`);
    return res.data.data.map((c) => ({
      label: c.name,
      value: c.id,
    }));
  };

  const onDelete = (id) => {
    api.delete(`/postback/${id}`).then(() => {
      refetch();
    });
  };

  useEffect(() => {
    if (currentUser) {
      if (
        currentUser.role === 'SUPER_ADMIN' ||
        currentUser.extendedRole.role_group === 'TEAM_LEAD'
      ) {
        if (
          checkboxRole === 'AFFILIATE_SUPPORT' ||
          checkboxRole === 'BIZDEV_SUPPORT' ||
          checkboxRole === 'FULLSTACK_SUPPORT' ||
          checkboxRole === 'AFFILIATE_TEAM_LEAD' ||
          checkboxRole === 'BIZDEV_TEAM_LEAD' ||
          checkboxRole === 'FULLSTACK_TEAM_LEAD'
        )
          setRolesPermissions({
            support: false,
            teamlead: false,
            workers: true,
          });
        else if (
          checkboxRole === 'AFFILIATE' ||
          checkboxRole === 'BIZDEV' ||
          checkboxRole === 'FULLSTACK'
        )
          setRolesPermissions({
            support: true,
            teamlead: true,
            workers: false,
          });
        else
          setRolesPermissions({
            support: false,
            teamlead: false,
            workers: false,
          });
      }
    }
    form.setFieldValue('support', '');
    setSelectedSupport('');
    form.setFieldValue('teamLead', '');
    setSelectedTeamLead('');
    form.setFieldValue('workers', []);
    setSelectedWorkers([]);
  }, [checkboxRole]);

  const initialFetchConnectedUsersByUserRole = async (desiredRole) => {
    const res = await api.get(
      `/user?limit=10&filter.role=$eq:${desiredRole}&filter.isActive=$eq:true`
    );
    return res.data.data.map((user) => ({
      label: user.nickname,
      value: user.id,
    }));
  };

  const initialFetchConnectedSupportsByUserRole = async (desiredRole) => {
    const res = await api.get(
      `/user/supports?limit=10&filter.role=$eq:${desiredRole}&filter.isActive=$eq:true`
    );
    return res.data.data.map((user) => ({
      label: user.nickname,
      value: user.id,
    }));
  };

  const fetchConnectedUsersByUserRole = async (desiredRole, nickname) => {
    const idFilter =
      !isNaN(nickname) && nickname?.length
        ? `&filter.id=$or:$eq:${nickname}`
        : '';
    const res = await api.get(
      `/user?filter.role=$eq:${desiredRole}&filter.nickname=$ilike:${nickname}&filter.isActive=$eq:true${idFilter}`
    );
    return res.data.data.map((user) => ({
      label: user.nickname,
      value: user.id,
    }));
  };

  const fetchConnectedSupportsByUserRole = async (desiredRole, nickname) => {
    const idFilter =
      !isNaN(nickname) && nickname?.length
        ? `&filter.id=$or:$eq:${nickname}${idFilter}`
        : '';
    const res = await api.get(
      `/user/supports?filter.role=$eq:${desiredRole}&filter.nickname=$ilike:${nickname}&filter.isActive=$eq:true`
    );
    return res.data.data.map((user) => ({
      label: user.nickname,
      value: user.id,
    }));
  };

  const initialFetchSupportByUserRole = async () => {
    if (checkboxRole) {
      if (checkboxRole === 'AFFILIATE')
        return initialFetchConnectedSupportsByUserRole('AFFILIATE_SUPPORT');
      if (checkboxRole === 'BIZDEV')
        return initialFetchConnectedSupportsByUserRole('BIZDEV_SUPPORT');
      if (checkboxRole === 'FULLSTACK')
        return initialFetchConnectedSupportsByUserRole('FULLSTACK_SUPPORT');
    }
  };

  const fetchSupportByUserRole = async (nickname) => {
    if (checkboxRole) {
      if (checkboxRole === 'AFFILIATE')
        return fetchConnectedSupportsByUserRole('AFFILIATE_SUPPORT', nickname);
      if (checkboxRole === 'BIZDEV')
        return fetchConnectedUsersByUserRole('BIZDEV_SUPPORT', nickname);
      if (checkboxRole === 'FULLSTACK')
        return fetchConnectedUsersByUserRole('FULLSTACK_SUPPORT', nickname);
    }
  };

  const initialFetchTeamLeadByUserRole = async () => {
    if (checkboxRole) {
      if (checkboxRole === 'AFFILIATE')
        return initialFetchConnectedUsersByUserRole('AFFILIATE_TEAM_LEAD');
      if (checkboxRole === 'BIZDEV')
        return initialFetchConnectedUsersByUserRole('BIZDEV_TEAM_LEAD');
      if (checkboxRole === 'FULLSTACK')
        return initialFetchConnectedUsersByUserRole('FULLSTACK_TEAM_LEAD');
    }
  };

  const fetchTeamLeadByUserRole = async (nickname) => {
    if (checkboxRole) {
      if (checkboxRole === 'AFFILIATE')
        return fetchConnectedUsersByUserRole('AFFILIATE_TEAM_LEAD', nickname);
      if (checkboxRole === 'BIZDEV')
        return fetchConnectedUsersByUserRole('BIZDEV_TEAM_LEAD', nickname);
      if (checkboxRole === 'FULLSTACK')
        return fetchConnectedUsersByUserRole('FULLSTACK_TEAM_LEAD', nickname);
    }
  };

  const initialFetchWorkersByUserRole = async () => {
    if (checkboxRole) {
      if (
        checkboxRole === 'AFFILIATE_SUPPORT' ||
        checkboxRole === 'AFFILIATE_TEAM_LEAD'
      )
        return initialFetchConnectedUsersByUserRole('AFFILIATE');
      if (
        checkboxRole === 'BIZDEV_SUPPORT' ||
        checkboxRole === 'BIZDEV_TEAM_LEAD'
      )
        return initialFetchConnectedUsersByUserRole('BIZDEV');
      if (
        checkboxRole === 'FULLSTACK_SUPPORT' ||
        checkboxRole === 'FULLSTACK_TEAM_LEAD'
      )
        return initialFetchConnectedUsersByUserRole('FULLSTACK');
    }
  };

  const fetchWorkersByUserRole = async (nickname) => {
    if (checkboxRole) {
      if (
        checkboxRole === 'AFFILIATE_SUPPORT' ||
        checkboxRole === 'AFFILIATE_TEAM_LEAD'
      )
        return fetchConnectedUsersByUserRole('AFFILIATE', nickname);
      if (
        checkboxRole === 'BIZDEV_SUPPORT' ||
        checkboxRole === 'BIZDEV_TEAM_LEAD'
      )
        return fetchConnectedUsersByUserRole('BIZDEV', nickname);
      if (
        checkboxRole === 'FULLSTACK_SUPPORT' ||
        checkboxRole === 'FULLSTACK_TEAM_LEAD'
      )
        return fetchConnectedUsersByUserRole('FULLSTACK', nickname);
    }
  };

  const onCanExport = (e) => {
    setIsCanExport(e.target.checked);
  };
  return (
    <div>
      <Form
        form={form}
        onFinish={() => {
          if (checkboxRole.length) {
            onFinish({
              name: form.getFieldValue('name'),
              nickname: form.getFieldValue('nickname'),
              email: form.getFieldValue('email'),
              password: form.getFieldValue('password'),
              telegram: form.getFieldValue('telegram'),
              createdAt: undefined,
              updatedAt: undefined,
              role: checkboxRole,
              supportId: form.getFieldValue('support')?.value || null,
              teamLeadId: form.getFieldValue('teamLead')?.value || null,
              workers: form.getFieldValue('workers')
                ? form.getFieldValue('workers').map((w) => w.value)
                : null,
              permissions_id: form.getFieldValue('permissions').value,
              isActive,
              isCanInject,
              isCanTranshipment,
              isCanExport: isCanExport,
              isSeeAllInfo,
              isCanSetFakeInfo,
              timezone,
              permissions: undefined,
            });
          } else {
            form.setFields([
              {
                name: 'role',
                errors: 'Будь ласка, виберіть ролі',
              },
            ]);
          }
        }}
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <Form.Item
          name="name"
          label="Ім'я"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Ім'я" rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="nickname"
          label="Нік"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input placeholder="Нік" rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              type: 'email',
            },
          ]}
        >
          <Input placeholder="email" rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          rules={[
            {
              message: 'Знак "@" недопустимий',
              validator: (_, value, callback) => {
                let regex = /@/;
                if (regex.test(value)) {
                  callback('no error');
                } else {
                  callback();
                }
              },
            },
          ]}
          name="telegram"
          label="Telegram"
        >
          <Input placeholder="Telegram" rootClassName={styles.input} />
        </Form.Item>
        <Form.Item name="isActive" label="Активний">
          <Checkbox
            onClick={onActiveChange}
            defaultChecked={user?.isActive}
            rootClassName={styles.input}
          />
        </Form.Item>
        <Form.Item
          name="password"
          label="Пароль"
          rules={[
            {
              required: !user,
            },
          ]}
        >
          <Input placeholder="Пароль" rootClassName={styles.input} />
        </Form.Item>
        <Form.Item
          name="timezone"
          label="Часовий пояс"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            placeholder="Таймзона"
            style={{ maxWidth: 350 }}
            showSearch
            defaultValue={timezone}
            onChange={setTimezone}
            options={timezonesList.map((t) => ({
              value: t.timezone,
              label: t.name,
              key: t.timezone,
            }))}
          />
        </Form.Item>
        {user && (
          <Form.Item name="createdAt" label="Створено">
            <Input
              disabled
              placeholder="Створено"
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        {user && (
          <Form.Item name="updatedAt" label="Оновлено">
            <Input
              disabled
              placeholder="Оновлено"
              rootClassName={styles.input}
            />
          </Form.Item>
        )}
        <Form.Item
          name="role"
          label={'Роль'}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            rootClassName={styles.input}
            onChange={onRoleChange}
            value={checkboxRole}
            defaultValue={user && user.role}
            options={userRolesOptions}
            disabled={!hasPermissions(role, ['SUPER_ADMIN']) && type === 'edit'}
          />
        </Form.Item>
        {rolesPermissions.support && (
          <Form.Item name="support" label={'Support'}>
            <AutocompleteSelect
              className={styles.input}
              value={selectedSupport}
              placeholder="Support"
              initialFetch={initialFetchSupportByUserRole}
              fetch={fetchSupportByUserRole}
              onChange={setSelectedSupport}
            />
          </Form.Item>
        )}
        {rolesPermissions.teamlead && (
          <Form.Item name="teamLead" label={'Team Lead'}>
            <AutocompleteSelect
              className={styles.input}
              value={selectedTeamLead}
              placeholder="Team Lead"
              initialFetch={initialFetchTeamLeadByUserRole}
              fetch={fetchTeamLeadByUserRole}
              onChange={setSelectedTeamLead}
              disabled={!hasPermissions(role, ['SUPER_ADMIN'])}
            />
          </Form.Item>
        )}
        {rolesPermissions.workers && (
          <Form.Item name="workers" label={userRoleSideTitle}>
            <AutocompleteSelect
              className={styles.input}
              value={selectedWorkers}
              placeholder={userRoleSideTitle}
              mode="multiple"
              initialFetch={initialFetchWorkersByUserRole}
              fetch={fetchWorkersByUserRole}
            />
          </Form.Item>
        )}
        <Form.Item
          name="permissions"
          label="Права"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <AutocompleteSelect
            className={styles.input}
            value={selectedPermissions}
            placeholder="Права"
            initialFetch={initialFetchPermissions}
            fetch={fetchPermissions}
            onChange={setSelectedPermissions}
          />
        </Form.Item>
        <Form.Item name="isSeeAllInfo" label="Може бачити всі дані ліда">
          <Checkbox
            onClick={onSeeAllInfoChange}
            defaultChecked={user?.isSeeAllInfo}
            rootClassName={styles.input}
          />
        </Form.Item>
        <Form.Item name="isCanInject" label="Може інджектити">
          <Checkbox
            onClick={onCanInjectChange}
            defaultChecked={user?.isCanInject}
            rootClassName={styles.input}
          />
        </Form.Item>
        <Form.Item name="transhipment" label="Може перевідпавляти">
          <Checkbox
            onClick={onSetIsCanTranshipment}
            defaultChecked={user?.isCanTranshipment}
            rootClassName={styles.input}
          />
        </Form.Item>
        <Form.Item name="isSetFakeInfo" label="Може задавати фейкові дані ліду">
          <Checkbox
            onClick={onSetIsCanFakeInfoChange}
            defaultChecked={user?.isCanSetFakeInfo}
            rootClassName={styles.input}
          />
        </Form.Item>
        <Form.Item name="isCanexport" label="Може експортувати">
          <Checkbox
            onClick={onCanExport}
            defaultChecked={user?.isCanExport}
            rootClassName={styles.input}
          />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button type="primary" htmlType="submit">
            Зберегти
          </Button>
        </Form.Item>
      </Form>
      {user && (
        <div>
          <Divider />
          <h3 className="text-center">Постбеки</h3>
          <div className="flex items-center flex-wrap">
            {user.postbacks.map((p) => {
              return (
                <Card
                  key={p.id}
                  style={{ width: 500 }}
                  className="mx-8 my-2 cursor-pointer"
                  onClick={(e) => {
                    if (
                      e.target.innerText !== 'Ні' &&
                      e.target.innerText !== 'Так'
                    ) {
                      setActivePostback(p);
                      setIsPostbackModalOpen(true);
                    }
                  }}
                >
                  <div className="flex justify-between">
                    <div
                      className="flex items-center"
                      style={{
                        width: 300,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      <Popconfirm
                        title="Видалити postback"
                        description="Ви впевнені, що хочете видалити цей постбек?"
                        onConfirm={() => onDelete(p.id)}
                        okText="Так"
                        cancelText="Ні"
                      >
                        <DeleteOutlined
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          className="mx-2 text-red-500 text-lg leading-3"
                        />
                      </Popconfirm>

                      {p.link}
                    </div>
                    {p.status?.length && (
                      <StatusTag style={{ maxHeight: 25 }} status={p.status} />
                    )}
                  </div>
                </Card>
              );
            })}
            <Button
              className="mx-8"
              type="primary"
              onClick={() => {
                setActivePostback(null);
                setIsPostbackModalOpen(true);
              }}
            >
              <PlusOutlined />
            </Button>
          </div>
          <CreatePostbackModal
            onCancel={() => setIsPostbackModalOpen(false)}
            isOpen={isPostbackModalOpen}
            pb={activePostback}
            refetch={() => {
              refetch();
              setIsPostbackModalOpen(true);
            }}
            userId={user.id}
          />
        </div>
      )}
    </div>
  );
};
