import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { useEffect } from 'react';

export const usePermissionsAllowed = (allowedRoles) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('access_token');
  const decoded = jwt_decode(token);

  useEffect(() => {
    if (!hasPermissions(decoded.role, allowedRoles)) {
      navigate('/no-permissions');
    }
  }, []);

  return decoded.role;
};

export const usePermissions = () => {
  const token = localStorage.getItem('access_token');
  const decoded = jwt_decode(token);
  return decoded.role;
};

export const hasPermissions = (actualRole, allowedRoles) => {
  if (!allowedRoles?.length) return false;
  return actualRole === 'SUPER_ADMIN' || allowedRoles.includes(actualRole);
};
