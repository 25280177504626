import React, { useContext, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

import { AppTable } from '../../../shared/Table/AppTable';
import api from '../../../api/api';
import { inputToFilter } from '../../../utils/inputToFilter';
import { Link, useSearchParams } from 'react-router-dom';
import {
  usePermissions,
  usePermissionsAllowed,
} from '../../../hooks/usePermissionsAllowed';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';
import { fromApiToClient } from '../../../utils/dateFilters';
import { ExtendableTableCell } from '../../../shared/ExtendableTableCell/ExtendableTableCell';
import CountryList from 'country-list-with-dial-code-and-flag';
import { CurrentUserContext } from '../../../providers/AuthProvider';

export const CountryBox = () => {
  const [searchParams] = useSearchParams();
  const url = searchParams.get('filters');
  const querystr = JSON.parse(url) ? JSON.parse(url) : null;

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filterValues, setFilterValues] = useState({});
  const role = usePermissions();
  const user = useContext(CurrentUserContext);
  const bizdevsTeam = ['BIZDEV', 'BIZDEV_TEAM_LEAD', 'BIZDEV_SUPPORT'];
  const fullstacksTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  usePermissionsAllowed(bizdevsTeam.concat(fullstacksTeam).concat('ANALYST'));

  const [columns, setColumns] = useState([]);
  useEffect(() => {
    if (user) {
      setColumns([
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          show: true,
          render: (id) => <Link to={`/country-box/${id}`}>{id}</Link>,
        },
        {
          title: "Ім'я",
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Країни',
          dataIndex: 'countries',
          key: 'countries',
          render: (countries) => (
            <ExtendableTableCell
              data={JSON.parse(countries).map((c) => {
                const country = CountryList.findByCountryCode(c)[0];
                return `${country.flag} ${country.code}`;
              })}
            />
          ),
          width: 130,
        },
        {
          title: 'Створено',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (date) => fromApiToClient(date, user?.timezone),
        },
        {
          title: 'Оновлено',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          render: (date) => fromApiToClient(date, user?.timezone),
        },
      ]);
    }
  }, [user]);
  useEffect(() => {
    let res = '';
    if (!url) {
      res = 'filter.isActive=$and:$eq:true&';
    }
    for (let i in querystr) {
      res += `filter.${querystr[i][1]}=$and:${querystr[i][2]}&`;
    }
    api.get('/country-box?' + res + 'page=1&limit=10').then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
    });
  }, []);

  useDidUpdateEffect(() => {
    const url = inputToFilter(filterValues, currentPage);
    api.get(`/country-box?${url}`).then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
      window.scrollTo(0, 0);
    });
  }, [filterValues, currentPage]);

  const onFiltersChange = (name, value) => {
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  };

  const onFiltersSet = (filters) => {
    let object = {};
    for (let i in filters) {
      object[filters[i][1]] = filters[i][2];
    }
    setFilterValues({
      ...object,
    });
  };
  const onTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  return (
    <div>
      <AppTable
        filters={GetFilters({ onFiltersChange, onFiltersSet, querystr })}
        columns={columns}
        pagination={{
          total,
          current: currentPage,
          pageSize: 10,
        }}
        onChange={onTableChange}
        onColumnsChange={setColumns}
        data={data}
        dataPrefix="country-box"
        showAddButton={role !== 'ANALYST'}
        createUrl={'/country-box/create'}
        querystr={querystr}
      />
    </div>
  );
};

const GetFilters = ({ onFiltersChange, onFiltersSet, querystr }) => {
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (querystr) {
      onFiltersSet(querystr);
    }
  }, []);

  return [
    {
      label: 'ID',
      type: 'number',
      defaultValue: querystr?.hasOwnProperty('id') ? querystr.id[0] : '',
      onChange: debounce((e) => {
        onFiltersChange('id', `$eq:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            id: [e.target.value, 'id', `$eq:${e.target.value}`],
          }),
        });
      }, 300),
      style: {
        width: '80px',
      },
    },

    {
      label: "Ім'я",
      defaultValue: querystr?.hasOwnProperty('name') ? querystr.name[0] : '',
      onChange: debounce((e) => {
        onFiltersChange('name', `$ilike:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            name: [e.target.value, 'name', `$ilike:${e.target.value}`],
          }),
        });
      }, 300),
    },
    {
      label: 'Стан',
      type: 'checkbox',
      defaultChecked: querystr?.hasOwnProperty('isActive')
        ? querystr.isActive[0]
        : true,
      onChange: (e) => {
        if (e.target.checked) {
          onFiltersChange('isActive', `$eq:${e.target.checked}`);
        } else {
          onFiltersChange('isActive', `$ilike:`);
        }
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            isActive: [
              e.target.checked,
              'isActive',
              e.target.checked ? `$eq:${e.target.checked}` : '$ilike:',
            ],
          }),
        });
      },
    },
  ];
};
