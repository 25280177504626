import { Tag, Tooltip, Button, List } from 'antd';
import { getRange, getColorByRange, checkInfo } from '../../utils/fraudIPRange';
import styles from './FraudIPTag.module.css';
import { useContext } from 'react';
import { ThemeContext } from '../../providers/ThemeProvider';

const FraudIPTag = ({ checkIP, setIsModalOpen, setSelectedData }) => {
  const range = getRange(checkIP.fraud_score);
  const { isDarkTheme } = useContext(ThemeContext);
  const tagColor = getColorByRange(range, isDarkTheme);
  const checkIpInfo = checkInfo(checkIP);

  const DetailsList = ({ details }) => {
    const data = Object.entries(details).map(([key, value]) => ({
      title: key.charAt(0).toUpperCase() + key.slice(1),
      content: value.toString(),
    }));

    return (
      <List
        className={styles.tooltipList}
        dataSource={data}
        renderItem={(item) => (
          <List.Item className={styles.tooltipListItem}>
            <strong>{item.title}:</strong> {item.content}
          </List.Item>
        )}
      />
    );
  };

  const setModalData = () => {
    setSelectedData();
    setIsModalOpen();
  };

  const renderTooltip = (
    <div>
      <DetailsList details={checkIpInfo} />
      {!checkIpInfo.error && (
        <div style={{ textAlign: 'center' }}>
          <Button
            className={styles.tooltipButton}
            type="primary"
            onClick={setModalData}
          >
            <span className={styles.buttonText}>Усі деталі</span>
          </Button>
        </div>
      )}
    </div>
  );

  return (
    <div>
      <Tooltip overlay={renderTooltip} arrow={false} color="#001529cf">
        {!isDarkTheme && (
          <Tag
            style={{ minWidth: '80px', textAlign: 'center' }}
            color={tagColor}
            className="cursor-pointer"
          >
            {range}
          </Tag>
        )}
        {isDarkTheme && (
          <Tag
            style={{
              minWidth: '80px',
              textAlign: 'center',
              color: tagColor.mainColor,
              background: tagColor.background,
              borderColor: tagColor.borderColor,
              border: '1px solid',
            }}
            className="cursor-pointer"
          >
            {range}
          </Tag>
        )}
      </Tooltip>
    </div>
  );
};

export default FraudIPTag;
