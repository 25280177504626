import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { CountryBoxForm } from '../CountryBoxForm/CountryBoxForm';

export const EditCountryBox = () => {
  const { message } = App.useApp();
  const [countryBox, setCountryBox] = useState();
  const { id } = useParams();
  const bizdevsTeam = ['BIZDEV', 'BIZDEV_TEAM_LEAD', 'BIZDEV_SUPPORT'];
  const fullstacksTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  usePermissionsAllowed(bizdevsTeam.concat(fullstacksTeam).concat('ANALYST'));

  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    api.get(`/country-box/${id}`).then((res) => {
      setCountryBox({
        ...res.data,
      });
      setIsLoading(false);
    });
  }, []);

  const onFinish = (updatedCf) => {
    delete updatedCf.updatedAt;
    delete updatedCf.createdAt;
    api
      .put(`/country-box/${id}`, {
        ...updatedCf,
      })
      .then((_) => {
        navigate(-1);
        message.success('Country Box оновлено');
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  if (isLoading) return 'loading';

  return <CountryBoxForm onFinish={onFinish} countryBox={countryBox} />;
};
