import React from 'react';
import { useState } from 'react';
import { Tooltip, List } from 'antd';
import styles from './TooltipList.module.css';
import { CaretDownOutlined } from '@ant-design/icons';

const TooltipIcon = ({ icon }) => icon || <CaretDownOutlined />;

export const TooltipList = ({ icon, data }) => {
  const [isActive, setIsActive] = useState(false);
  const handleClick = (event) => {
    setIsActive((current) => !current);
  };

  const renderTooltip = (
    <List
      className={styles.tooltipList}
      dataSource={data}
      renderItem={(item) => (
        <List.Item className={styles.tooltipListItem}>{item}</List.Item>
      )}
    />
  );
  React.useEffect(() => {
    document.documentElement.scrollTop = document.documentElement.clientHeight;
    document.documentElement.scrollLeft = document.documentElement.clientWidth;
  }, []);
  if (data.length)
    return (
      <Tooltip
        placement="topLeft"
        trigger="click"
        overlay={renderTooltip}
        arrow={false}
        className={styles.tooltip}
        color="#001529cf"
      >
        <div
          className={
            isActive ? styles.tooltipButtonActive : styles.tooltipButton
          }
          onClick={handleClick}
        >
          <TooltipIcon
            className={styles.tooltipButtonIcon}
            onClick={handleClick}
            icon={icon}
          />
        </div>
      </Tooltip>
    );
};
