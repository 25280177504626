import { App, Button, DatePicker, Form, InputNumber, Modal } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import api from '../../../api/api';
import { AutocompleteSelect } from '../../../shared/AutocompleteSelect/AutocompleteSelect';
import dayjs from 'dayjs';
import { CurrentUserContext } from '../../../providers/AuthProvider';

export const InjectLeadsModal = ({
  leads,
  isModalOpen,
  handleCancel,
  onSuccess,
}) => {
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const [selectedOrders, setSelectedOrders] = useState();
  const [startAt, setStartAt] = useState(dayjs());
  const user = useContext(CurrentUserContext);

  const onInject = () => {
    const date = startAt?.tz(user.timezone);
    api
      .post('/lead/inject-batch', {
        ...form.getFieldsValue(),
        leads: leads.map((l) => l.id),
        order: selectedOrders.value,
        startAt: date.toISOString(),
      })
      .then((_) => {
        message.success('Інджект лідів почався в фоновому режимі');
        onSuccess();
      })
      .catch((e) => message.error(e.response.data?.message));
  };

  const fetchOrders = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/order?filter.name=$ilike:${name}&filter.isActive=$eq:true${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };
  const initialFetchOrders = async () => {
    const res = await api.get(`/order?limit=10&filter.isActive=$eq:true`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  if (!user) return '';

  return (
    <Modal
      title="Заінджектити ліди"
      open={isModalOpen}
      footer={null}
      onCancel={handleCancel}
    >
      <Form
        wrapperCol={{ span: 12, offset: 1 }}
        labelCol={{ span: 7, offset: 1 }}
        form={form}
        onFinish={onInject}
      >
        <Form.Item
          name="startAt"
          label="Початок"
          style={{ marginTop: 15 }}
          rules={[
            {
              validator: (rule, value, callback) => {
                if (!startAt) callback('Будь ласка, виберіть час початку');
                return callback();
              },
            },
          ]}
        >
          <DatePicker
            defaultValue={dayjs().tz(user.timezone)}
            showTime
            changeOnBlur
            onChange={setStartAt}
            placeholder="Початок"
            allowClear={false}
          />
        </Form.Item>
        <Form.Item
          name="order"
          label="Замовлення"
          style={{ marginTop: 15 }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <AutocompleteSelect
            value={selectedOrders}
            placeholder="Замовлення"
            initialFetch={initialFetchOrders}
            fetch={fetchOrders}
            onChange={setSelectedOrders}
          />
        </Form.Item>
        <Form.Item
          name="min"
          label="Мінімальний інтервал (хв)"
          style={{ marginTop: 15 }}
          wrapperCol={{ span: 12, offset: 1 }}
          labelCol={{ span: 12, offset: 1 }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber placeholder="0" min={0} max={60} />
        </Form.Item>
        <Form.Item
          name="max"
          label="Максимальний інтервал (хв)"
          style={{ marginTop: 15 }}
          wrapperCol={{ span: 12, offset: 1 }}
          labelCol={{ span: 12, offset: 1 }}
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber placeholder="60" min={0} max={60} />
        </Form.Item>
        <Form.Item wrapperCol={{ offset: 10 }}>
          <Button type="primary" htmlType="submit">
            Відправити
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
