import React, { useContext, useEffect, useState } from 'react';
import { Button } from 'antd';
import { usePermissions } from '../../../hooks/usePermissionsAllowed';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import {
  fromApiToClient,
  fromApiToClientDate,
} from '../../../utils/dateFilters';
import api from '../../../api/api';
import { inputToFilter } from '../../../utils/inputToFilter';
import { AppTable } from '../../../shared/Table/AppTable';
import {
  ExtendableLinkTableCell,
  ExtendableTableCell,
} from '../../../shared/ExtendableTableCell/ExtendableTableCell';
import CountryList from 'country-list-with-dial-code-and-flag';
import { cutString } from '../../../utils/cutString';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';
import { Progress } from 'antd';
import { PermissionalLink } from '../../../shared/PermissionalLink/PermissionalLink';
import { CurrentUserContext } from '../../../providers/AuthProvider';
import { GetFilters } from './GetFilters';
import {
  AFFILIATE_TEAM_LEAD,
  ANALYST,
  BIZDEV_TEAM_LEAD,
  FULLSTACK_TEAM_LEAD,
  SUPER_ADMIN,
} from '../../../utils/roles';
import { PinButton } from './PinButton';
import { orderStateChange } from './api/orderStateChange';
import debounce from 'lodash.debounce';

export const Orders = () => {
  const [searchParams] = useSearchParams();
  const filtersQuery = searchParams.get('filters');
  const querystr = JSON.parse(filtersQuery) ? JSON.parse(filtersQuery) : null;
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filterValues, setFilterValues] = useState({
    isActive: !!querystr?.length ? '$ilike:' : '$eq:true',
  });
  const role = usePermissions();
  const user = useContext(CurrentUserContext);
  const [columns, setColumns] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      setColumns([
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          render: (id) => <Link to={`/orders/${id}`}>{id}</Link>,
        },
        {
          title: 'Назва',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Замовник',
          dataIndex: 'customer',
          key: 'customer',
          render: (c) => (c ? `#${c.id} ${c.name}` : 'Відсутній'),
        },
        {
          title: 'До лідів замовлення',
          dataIndex: 'id',
          key: 'to_order',
          render: (order_id, order) =>
            order_id ? (
              <Button
                type="primary"
                onClick={() => {
                  const filter = JSON.stringify({
                    orders: [
                      [
                        {
                          label: `#${order.id} ${order.name}`,
                          value: order_id,
                        },
                      ],
                      'order.id',
                      `$in:${order_id}`,
                      false,
                    ],
                  });
                  navigate('/leads?filters=' + encodeURIComponent(filter));
                }}
              >
                Перейти
              </Button>
            ) : (
              'Відсутні'
            ),
        },
        {
          title: 'Баєра',
          dataIndex: 'buyers',
          key: 'buyers',
          render: (buyers) =>
            buyers ? (
              <ExtendableLinkTableCell
                data={buyers
                  .filter((b) => b.isActive)
                  .map((b) => ({
                    label: `#${b.id} ${b.name}`,
                    url: `/buyers/${b.id}`,
                  }))}
              />
            ) : (
              'Відсутні'
            ),
        },
        {
          title: 'Інтеграція',
          dataIndex: 'integration',
          key: 'integration',
          render: (c) => (c ? `#${c.id} ${c.name}` : 'Відсутня'),
          editable: true,
          inputType: 'integration',
        },
        {
          title: 'Країни',
          dataIndex: 'countries',
          key: 'countries',
          render: (countries) => (
            <ExtendableTableCell
              data={JSON.parse(countries).map((c) => {
                const country = CountryList.findByCountryCode(c)[0];
                return `${country?.flag} ${country?.code}`;
              })}
            />
          ),
          width: 130,
        },
        {
          title: 'Дата початку',
          dataIndex: 'startedAt',
          key: 'startedAt',
          render: (date) => fromApiToClientDate(date, user.timezone),
        },
        {
          title: 'Дата виконання',
          dataIndex: 'endedAt',
          key: 'endedAt',
          render: (date) => fromApiToClientDate(date, user.timezone),
        },
        {
          title: 'Ціна ліда',
          dataIndex: 'leadPrice',
          key: 'leadPrice',
        },
        {
          title: 'Форма оплати',
          dataIndex: 'paymentForm',
          key: 'paymentForm',
        },
        {
          title: 'Тип оплати',
          dataIndex: 'paymentType',
          key: 'paymentType',
        },
        {
          title: 'Денний кап',
          dataIndex: 'dailyCap',
          align: 'center',
          key: 'dailyCap',
          render: (dailyCap, order) => {
            let percent = 0;
            let leadsForFormat = 0;
            const cc = JSON.parse(order?.callCenterSchedule);
            if (cc?.timezone && dailyCap && dailyCap > 0) {
              leadsForFormat = order?.dailyCapLead;
              percent = (100 * leadsForFormat) / dailyCap;
            }
            return dailyCap && dailyCap > 0 ? (
              <div
                style={{
                  width: 130,
                  margin: '0px auto',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Progress
                  percent={percent}
                  size="small"
                  format={() => (
                    <div>
                      {leadsForFormat}/{dailyCap}
                    </div>
                  )}
                />
              </div>
            ) : (
              ''
            );
          },
        },
        {
          title: 'Загальний кап',
          dataIndex: 'cap',
          align: 'center',
          key: 'cap',
          render: (cap, order) => {
            const percent = (100 * order?.capLead) / cap;
            return cap && cap > 0 ? (
              <div
                style={{
                  width: 130,
                  margin: '0px auto',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Progress
                  percent={percent}
                  size="small"
                  format={() => (
                    <div>
                      {order?.capLead}/{cap}
                    </div>
                  )}
                />
              </div>
            ) : (
              ''
            );
          },
        },
        {
          title: 'FTD',
          dataIndex: 'ftd',
          key: 'ftd',
          render: (ftd, order) => {
            if (ftd) {
              const filters = JSON.stringify({
                orders: [
                  [
                    {
                      label: `#${order.id} ${order.name}`,
                      value: order.id,
                    },
                  ],
                  'order.id',
                  `$in:${order.id}`,
                  false,
                ],
                isDeposit: [
                  [
                    {
                      label: 'isDeposit',
                      value: true,
                    },
                  ],
                  'isDeposit',
                  `$eq:true`,
                  false,
                ],
              });
              return (
                <Link to={`/leads?filters=${encodeURIComponent(filters)}`}>
                  {ftd}
                </Link>
              );
            }
            return '';
          },
          alwaysShow: true,
          align: 'center',
        },
        {
          title: 'CR',
          dataIndex: 'cr',
          key: 'cr',
          alwaysShow: true,
          render: (cr) => (cr ? `${cr}%` : ''),
          align: 'center',
        },
        {
          title: 'Стан',
          dataIndex: 'isActive',
          key: 'isActive',
          render: (date) => (date ? 'Активний' : 'Не активний'),
        },
        {
          title: 'Коментар',
          dataIndex: 'comment',
          key: 'comment',
          render: (comment) => cutString(comment, 30),
        },
        {
          title: 'Аффілейт',
          dataIndex: 'affiliate',
          key: 'affiliate',
          render: (affiliate) =>
            affiliate ? (
              <PermissionalLink
                label={`#${affiliate.id} ${affiliate.nickname}`}
                url={`/users/${affiliate.id}`}
                permissions={[
                  SUPER_ADMIN,
                  AFFILIATE_TEAM_LEAD,
                  FULLSTACK_TEAM_LEAD,
                ]}
              />
            ) : (
              <span>Відсутній</span>
            ),
        },
        {
          title: 'Біздев',
          dataIndex: 'bizdev',
          key: 'bizdev',
          render: (bizdev) =>
            bizdev ? (
              <PermissionalLink
                label={`#${bizdev.id} ${bizdev.nickname}`}
                url={`/users/${bizdev.id}`}
                permissions={[
                  SUPER_ADMIN,
                  BIZDEV_TEAM_LEAD,
                  FULLSTACK_TEAM_LEAD,
                ]}
              />
            ) : (
              <span>Відсутній</span>
            ),
        },
        {
          title: 'Створено',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (date) => fromApiToClient(date, user.timezone),
        },
        {
          title: 'Оновлено',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          render: (date) => fromApiToClient(date, user.timezone),
        },
        {
          title: 'Закріплено',
          dataIndex: 'id',
          key: 'id',
          render: (order_id, order) => (
            <PinButton
              isSolid={order.userOrder.length}
              onClick={debounce(async () => {
                const response = await orderStateChange(order_id);
                if (response?.status === 200) {
                  updateData();
                }
              }, 600)}
            ></PinButton>
          ),
        },
      ]);
    }
  }, [user]);
  useEffect(() => {
    let preparedQuery = '';
    if (!filtersQuery) {
      preparedQuery = 'filter.isActive=$and:$eq:true&';
    }
    for (let i in querystr) {
      preparedQuery += `filter.${querystr[i][1]}=$and:${querystr[i][2]}&`;
    }
    api.get('/order?' + preparedQuery + 'page=1&limit=10').then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
    });
  }, []);
  useDidUpdateEffect(() => {
    updateData();
  }, [filterValues, currentPage]);

  const updateData = () => {
    const query = inputToFilter(filterValues, currentPage);

    api.get(`/order?${query}`).then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
      window.scrollTo(0, 0);
    });
  };

  const onFiltersChange = (name, value) => {
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  };
  const onFiltersSet = (filters) => {
    let object = {};
    for (let i in filters) {
      object[filters[i][1]] = filters[i][2];
    }
    setFilterValues({
      ...object,
    });
  };

  const onTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  return (
    <div>
      <AppTable
        filters={GetFilters({ onFiltersChange, onFiltersSet, querystr })}
        columns={columns}
        pagination={{
          total,
          current: currentPage,
          pageSize: 10,
        }}
        onChange={onTableChange}
        onColumnsChange={setColumns}
        data={data}
        dataPrefix="orders"
        allowChangingColumns
        showAddButton={role !== ANALYST}
        createUrl={'/orders/create'}
      />
    </div>
  );
};
