import React, { useContext, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

import { AppTable } from '../../../shared/Table/AppTable';
import api from '../../../api/api';
import { inputToFilter } from '../../../utils/inputToFilter';
import { Link, useSearchParams } from 'react-router-dom';
import { fromApiToClient } from '../../../utils/dateFilters';
import {
  usePermissions,
  usePermissionsAllowed,
} from '../../../hooks/usePermissionsAllowed';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';
import { PermissionalLink } from '../../../shared/PermissionalLink/PermissionalLink';
import { CurrentUserContext } from '../../../providers/AuthProvider';
import {
  AFFILIATE,
  AFFILIATE_SUPPORT,
  AFFILIATE_TEAM_LEAD,
  ANALYST,
  FULLSTACK,
  FULLSTACK_SUPPORT,
  FULLSTACK_TEAM_LEAD,
  SUPER_ADMIN,
} from '../../../utils/roles';
import { SharedCell } from '../../../shared/Shared/Shared';

export const Buyers = (props) => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filterValues, setFilterValues] = useState({});
  const role = usePermissions();
  usePermissionsAllowed([
    AFFILIATE,
    AFFILIATE_TEAM_LEAD,
    AFFILIATE_SUPPORT,
    FULLSTACK,
    FULLSTACK_TEAM_LEAD,
    FULLSTACK_SUPPORT,
    ANALYST,
  ]);
  const queryFilters = searchParams.get('filters');
  const querystr = JSON.parse(queryFilters) ? JSON.parse(queryFilters) : {};

  const user = useContext(CurrentUserContext);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (user) {
      setColumns([
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          render: (id, buyer) =>
            buyer.sharedTo.map((u) => u.id).includes(user.id) ? (
              id
            ) : (
              <Link to={`/buyers/${id}`}>{id}</Link>
            ),
        },
        {
          title: 'Назва',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Аффілейт',
          dataIndex: 'affiliate',
          key: 'affiliate',
          render: (affiliate) =>
            affiliate ? (
              <PermissionalLink
                label={`#${affiliate.id} ${affiliate.nickname}`}
                url={`/users/${affiliate.id}`}
                permissions={[
                  AFFILIATE_TEAM_LEAD,
                  AFFILIATE_SUPPORT,
                  FULLSTACK_TEAM_LEAD,
                  FULLSTACK_SUPPORT,
                ]}
              />
            ) : (
              'відсутній'
            ),
        },
        {
          title: 'Стан',
          dataIndex: 'isActive',
          key: 'isActive',
          render: (date) => (date ? 'Активний' : 'Не активний'),
        },
        {
          title: 'Створено',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (date) => fromApiToClient(date, user?.timezone),
        },
        {
          title: 'Оновлено',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          render: (date) => fromApiToClient(date, user?.timezone),
        },
        {
          title: 'Поділитись',
          dataIndex: 'id',
          key: 'id',
          render: (id, buyer) => (
            <SharedCell
              id={id}
              users={buyer.sharedTo}
              buyerUserId={buyer.affiliate.id}
            />
          ),
        },
        {
          title: 'api-key',
          dataIndex: 'apiKey',
          key: 'apiKey',
          hide: role === SUPER_ADMIN ? false : true,
        },
      ]);
    }
  }, [user]);

  useEffect(() => {
    let preparedQuery = '';
    if (!queryFilters) {
      preparedQuery = 'filter.isActive=$and:$eq:true&';
    }
    for (let i in querystr) {
      preparedQuery += `filter.${querystr[i][1]}=$and:${querystr[i][2]}&`;
    }
    const url = '/buyer?' + preparedQuery + '&page=1&limit=10';
    api.get(url).then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
    });
  }, []);

  useDidUpdateEffect(() => {
    const query = inputToFilter(filterValues, currentPage);
    api.get(`/buyer?${query}`).then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
      window.scrollTo(0, 0);
    });
  }, [filterValues, currentPage]);

  const onFiltersChange = (name, value) => {
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  };

  const onFiltersSet = (filters) => {
    let object = {};
    for (let i in filters) {
      object[filters[i][1]] = filters[i][2];
    }
    setFilterValues({
      ...object,
    });
  };
  const onTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  return (
    <div>
      <AppTable
        filters={GetFilters({ onFiltersChange, onFiltersSet, querystr })}
        columns={columns}
        pagination={{
          total,
          current: currentPage,
          pageSize: 10,
        }}
        onChange={onTableChange}
        onColumnsChange={setColumns}
        data={data}
        dataPrefix="buyer"
        showAddButton={role !== ANALYST}
        createUrl={'/buyers/create'}
        querystr={querystr}
      />
    </div>
  );
};

const GetFilters = ({ onFiltersChange, onFiltersSet, querystr }) => {
  const [affiliate, setAffiliates] = useState(
    querystr?.hasOwnProperty('affiliate') ? querystr.affiliate[0] : []
  );
  const [, setSearchParams] = useSearchParams();
  const currentUser = useContext(CurrentUserContext);

  useEffect(() => {
    if (querystr.length) {
      onFiltersSet(querystr);
    }
  }, []);

  const fetchAffiliates = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:AFFILIATE&filter.role=$or:$eq:FULLSTACK&filter.nickname=$ilike:${name}${idFilter}`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: `#${u.id} ${u.name}`,
        value: u.id,
      }));
  };

  const initialFetchAffiliates = async (name) => {
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:AFFILIATE&filter.role=$or:$eq:FULLSTACK`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: `#${u.id} ${u.name}`,
        value: u.id,
      }));
  };

  return [
    {
      label: 'ID',
      type: 'number',
      defaultValue: querystr?.hasOwnProperty('id') ? querystr.id[0] : '',
      onChange: debounce((e) => {
        onFiltersChange('id', `$eq:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            id: [e.target.value, 'id', `$eq:${e.target.value}`],
          }),
        });
      }, 300),
      style: {
        width: '80px',
      },
    },
    {
      label: "Ім'я",
      defaultValue: querystr?.hasOwnProperty('name') ? querystr.name[0] : '',
      onChange: debounce((e) => {
        onFiltersChange('name', `$ilike:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            name: [e.target.value, 'name', `$ilike:${e.target.value}`],
          }),
        });
      }, 300),
    },
    {
      label: 'Аффілейт',
      type: 'multiselect',
      onChange: (e) => {
        setAffiliates(e);
        onFiltersChange(
          'affiliate.id',
          `$in:${e.map((u) => u.value).join(',')}`
        );
        e.forEach((d) => {
          delete d.disabled;
          delete d.title;
          delete d.key;
        });
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            affiliate: [
              e,
              'affiliate.id',
              `$in:${e.map((u) => u.value).join(',')}`,
            ],
          }),
        });
      },
      fetch: fetchAffiliates,
      initialFetch: initialFetchAffiliates,
      value: affiliate,
      style: {
        width: '130px',
      },
      hide: currentUser
        ? [AFFILIATE, FULLSTACK].includes(currentUser.role)
        : false,
    },
    {
      label: 'Стан',
      type: 'checkbox',
      defaultChecked: querystr?.hasOwnProperty('isActive')
        ? querystr.isActive[0]
        : true,
      onChange: (e) => {
        if (e.target.checked) {
          onFiltersChange('isActive', `$eq:${e.target.checked}`);
        } else {
          onFiltersChange('isActive', `$ilike:`);
        }
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            isActive: [
              e.target.checked,
              'isActive',
              e.target.checked ? `$eq:${e.target.checked}` : '$ilike:',
            ],
          }),
        });
      },
    },
  ];
};
