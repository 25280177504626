import { Button, Checkbox, Form, Input } from 'antd';
import styles from './ServersForm.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { CurrentUserContext } from '../../../providers/AuthProvider';
import { fromApiToClient } from '../../../utils/dateFilters';

export const ServersForm = ({ server, onFinish }) => {
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(server ? server.isActive : true);

  const user = useContext(CurrentUserContext);
  useEffect(() => {
    if (!server) form.setFieldValue('isActive', true);
  }, []);

  useEffect(() => {
    if (server && user) {
      form.setFieldsValue(server);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(server.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(server.updatedAt, user.timezone)
      );
      setIsActive(server.isActive);
    }
  }, [server, user]);

  const onActiveChange = (e) => {
    setIsActive(e.target.checked);
  };

  return (
    <Form
      form={form}
      onFinish={() => {
        onFinish({
          ...form.getFieldsValue(),
          userId: user?.id,
          isActive,
        });
      }}
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
    >
      <Form.Item
        name="name"
        label="Назва"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Назва" rootClassName={styles.input} />
      </Form.Item>
      <Form.Item
        name="login"
        label="Login"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Login" rootClassName={styles.input} />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Password" rootClassName={styles.input} />
      </Form.Item>
      <Form.Item
        name="ip"
        label="IP"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="IP" rootClassName={styles.input} />
      </Form.Item>
      <Form.Item name="keitaroKey" label="Keytaro Key">
        <Input placeholder="Keytaro Key" rootClassName={styles.input} />
      </Form.Item>

      {server && (
        <Form.Item name="createdAt" label="Створено">
          <Input disabled placeholder="Створено" rootClassName={styles.input} />
        </Form.Item>
      )}
      {server && (
        <Form.Item name="updatedAt" label="Оновлено">
          <Input disabled placeholder="Оновлено" rootClassName={styles.input} />
        </Form.Item>
      )}
      <Form.Item
        name="isActive"
        label="Активний"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Checkbox
          onClick={onActiveChange}
          defaultChecked={server ? server.isActive : true}
          rootClassName={styles.input}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 11 }}>
        <Button type="primary" htmlType="submit">
          Зберегти
        </Button>
      </Form.Item>
    </Form>
  );
};
