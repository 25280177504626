import { useNavigate } from 'react-router-dom';
import api from '../../../api/api';
import { UsersForm } from '../UsersForm/UsersForm';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';

export const CreateUser = () => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  usePermissionsAllowed([
    'AFFILIATE_TEAM_LEAD',
    'BIZDEV_TEAM_LEAD',
    'FULLSTACK_TEAM_LEAD',
    'SUPER_ADMIN',
  ]);

  const onFinish = (user) => {
    api
      .post('/user', {
        ...user,
      })
      .then((_) => {
        navigate('/users');
        message.success('Користувача додано');
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  return (
    <>
      <UsersForm onFinish={onFinish} type="create" />
    </>
  );
};
