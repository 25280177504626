import { Button, Checkbox, Form, Input } from 'antd';
import styles from './BuyersForm.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import {
  hasPermissions,
  usePermissions,
} from '../../../hooks/usePermissionsAllowed';
import { AutocompleteSelect } from '../../../shared/AutocompleteSelect/AutocompleteSelect';
import api from '../../../api/api';
import { CurrentUserContext } from '../../../providers/AuthProvider';

export const BuyersForm = ({ buyer, onFinish, type }) => {
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(false);
  const [selectedAffiliate, setSelectedAffiliate] = useState();
  const role = usePermissions();
  const currentUser = useContext(CurrentUserContext);
  const affiliatesTeam = [
    'AFFILIATE',
    'AFFILIATE_TEAM_LEAD',
    'AFFILIATE_SUPPORT',
  ];
  const fullstackTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  const [isRequired, setIsRequired] = useState(false);

  useEffect(() => {
    if (buyer) {
      form.setFieldValue('name', buyer.name);
      form.setFieldValue('createdAt', fromApiToClient(buyer.createdAt));
      form.setFieldValue('updatedAt', fromApiToClient(buyer.updatedAt));
      if (buyer.affiliate) {
        setSelectedAffiliate({
          value: buyer.affiliate.id,
          label: buyer.affiliate.nickname,
          key: buyer.affiliate.id,
        });
        form.setFieldValue('affiliate', {
          value: buyer.affiliate.id,
          label: buyer.affiliate.nickname,
          key: buyer.affiliate.id,
        });
      }
      setIsActive(buyer.isActive);
      form.setFieldValue('api-key', buyer.apiKey);
    }
  }, [buyer]);

  useEffect(() => {
    if (currentUser)
      if (
        [
          'AFFILIATE_TEAM_LEAD',
          'AFFILIATE_SUPPORT',
          'FULLSTACK_TEAM_LEAD',
          'FULLSTACK_SUPPORT',
        ].includes(currentUser.role)
      )
        setIsRequired(true);
  }, [currentUser]);

  useEffect(() => {
    if (['AFFILIATE', 'FULLSTACK'].includes(currentUser.role)) {
      setSelectedAffiliate({
        value: currentUser.id,
        label: currentUser.nickname,
        key: currentUser.id,
      });
      form.setFieldValue('affiliate', {
        value: currentUser.id,
        label: currentUser.nickname,
        key: currentUser.id,
      });
    }
  }, [currentUser]);

  const onActiveChange = (e) => {
    setIsActive(e.target.checked);
  };

  const fetchAffiliates = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:AFFILIATE&filter.role=$or:$eq:FULLSTACK&filter.nickname=$ilike:${name}${idFilter}`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: `#${u.id} ${u.name}`,
        value: u.id,
      }));
  };

  const initialFetchAffiliates = async (name) => {
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:AFFILIATE&filter.role=$or:$eq:FULLSTACK`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: `#${u.id} ${u.name}`,
        value: u.id,
      }));
  };

  return (
    <Form
      form={form}
      onFinish={() => {
        onFinish({
          name: form.getFieldValue('name'),
          affiliateId: selectedAffiliate?.value || undefined,
          isActive,
        });
      }}
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
    >
      <Form.Item
        name="name"
        label="Назва"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input
          disabled={!hasPermissions(role, affiliatesTeam.concat(fullstackTeam))}
          placeholder="Назва"
          rootClassName={styles.input}
        />
      </Form.Item>
      <Form.Item
        name="affiliate"
        label="Аффілейт"
        rules={[
          {
            required: isRequired,
          },
        ]}
      >
        <AutocompleteSelect
          className={styles.input}
          value={selectedAffiliate}
          placeholder="Аффілейт"
          initialFetch={initialFetchAffiliates}
          fetch={fetchAffiliates}
          onChange={setSelectedAffiliate}
          disabled={
            !hasPermissions(role, [
              'AFFILIATE_TEAM_LEAD',
              'AFFILIATE_SUPPORT',
              'FULLSTACK_TEAM_LEAD',
              'FULLSTACK_SUPPORT',
            ])
          }
        />
      </Form.Item>
      {hasPermissions(role, 'SUPER_ADMIN') && type === 'edit' && (
        <Form.Item name="api-key" label="api-key">
          <Input
            disabled={true}
            placeholder="api-key"
            rootClassName={styles.input}
          />
        </Form.Item>
      )}
      <Form.Item name="isActive" label="Активний">
        <Checkbox
          disabled={!hasPermissions(role, affiliatesTeam.concat(fullstackTeam))}
          onClick={onActiveChange}
          defaultChecked={buyer?.isActive}
          rootClassName={styles.input}
        />
      </Form.Item>
      {hasPermissions(role, affiliatesTeam.concat(fullstackTeam)) && (
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button type="primary" htmlType="submit">
            Зберегти
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};
