import './App.css';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import AppLayout from './layout/Layout/AppLayout/AppLayout';
import { Users } from './pages/Users/Users/Users';
import { Orders } from './pages/Orders/Orders/Orders';
import { Boxes } from './pages/Boxes/Boxes/Boxes';
import { Integrations } from './pages/Integrations/Integrations/Integrations';
import { Login } from './pages/Login/Login';
import { AuthProvider } from './providers/AuthProvider';
import { LeadDetails } from './pages/Leads/LeadDetails/LeadDetails';
import { CreateUser } from './pages/Users/CreateUser/CreateUser';
import { App } from 'antd';
import { EditUser } from './pages/Users/EditUser/EditUser';
import { NoPermissions } from './pages/NoPermissions/NoPermissions';
import { Lids } from './pages/Leads/Leads/Leads';
import { EditOrder } from './pages/Orders/EditOrder/EditOrder';
import { CreateOrder } from './pages/Orders/CreateOrder/CreateOrder';
import { CreateBox } from './pages/Boxes/CreateBox/CreateBox';
import { EditBox } from './pages/Boxes/EditBox/EditBox';
import { CreateIntegration } from './pages/Integrations/CreateIntegration/CreateIntegration';
import { EditIntegration } from './pages/Integrations/EditIntegration/EditIntegration';
import { Autologin } from './pages/Autologin/Autologin';
import { Customers } from './pages/Customer/Customers/Customers';
import { EditCustomer } from './pages/Customer/EditCustomer/EditCustomer';
import { CreateCustomer } from './pages/Customer/CreateCustomer/CreateCustomer';
import { Buyers } from './pages/Buyer/Buyers/Buyers';
import { EditBuyer } from './pages/Buyer/EditBuyer/EditBuyers';
import { CreateBuyer } from './pages/Buyer/CreateBuyer/CreateBuyer';
import { Logs } from './pages/Logs/Logs';
import { CallStatusGroups } from './pages/CallStatusGroups/CallStatusGroups/CallStatusGroups';
import { CreateCallStatusGroup } from './pages/CallStatusGroups/CreateCallStatusGroup/CreateCallStatusGroup';
import { EditCallStatusGroups } from './pages/CallStatusGroups/EditCallStatusGroups/EditCallStatusGroups';
import { InjectForm } from './pages/InjectLeads/InjectLeadsForm/InjectForm';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import isBetween from 'dayjs/plugin/isBetween';
import { Domains } from './pages/Domains/Domains/Domains';
import { DomainsPurchase } from './pages/DomainsPurchase/DomainsPurchase';
import { Cloudflare } from './pages/Cloudflare/Cloudflare/Cloudflare';
import { EditCloudflare } from './pages/Cloudflare/EditCloudflare/EditCloudflare';
import { CreateCloudflare } from './pages/Cloudflare/CreateCloudflare/CreateCloudflare';
import { Servers } from './pages/Servers/Servers/Servers';
import { EditServers } from './pages/Servers/EditServers/EditServers';
import { CreateServer } from './pages/Servers/CreateServer/CreateServer';
import { Regway } from './pages/Regway/Regway/Regway';
import { EditRegway } from './pages/Regway/EditServers/EditRegway';
import { CreateRegway } from './pages/Regway/CreateRegway/CreateRegway';
import { Reports } from './pages/Report/fakeReports/Report';
import { Report } from './pages/Report/Report';
import { CountryBox } from './pages/CountryBox/CountryBox/CountryBox';
import { CreateCountryBox } from './pages/CountryBox/CreateCountryBox/CreateCountryBox';
import { EditCountryBox } from './pages/CountryBox/EditCloudflare/EditCountryBox';
import { EntityLogs } from './pages/EntityLogs/EntityLogs';
import { CallStatusCategories } from './pages/CallStatusCategories/CallStatusCategories/CallStatusCategories';
import { CreateCallStatusCategory } from './pages/CallStatusCategories/CreateCallStatusCategory/CreateCallStatusCategory';
import { EditCallStatusCategory } from './pages/CallStatusCategories/EditCallStatusCategory/EditCallStatusCategory';
import { CallStatusCategoryChildren } from './pages/CallStatusCategories/CallStatusCategoryChildren/CallStatusCategoryChildren';
import { Tasks } from './pages/Tasks/Tasks';
import { Permissions } from './pages/Permissions/Permissions/Permissions';
import { EditPermissions } from './pages/Permissions/EditPermissions/EditPermissions';
import { CreatePermissions } from './pages/Permissions/CreatePermissions/CreatePermissions';
import { ThemeProvider } from './providers/ThemeProvider';
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(isBetween);

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <AuthProvider>
        <ThemeProvider>
          <AppLayout />
        </ThemeProvider>
      </AuthProvider>
    ),
    children: [
      {
        path: 'leads',
        element: <Lids />,
      },
      {
        path: 'leads/:id',
        element: <LeadDetails />,
      },
      {
        path: 'orders',
        element: <Orders />,
      },
      {
        path: 'orders/:id',
        element: <EditOrder />,
      },
      {
        path: 'orders/create',
        element: <CreateOrder />,
      },
      {
        path: 'boxes',
        element: <Boxes />,
      },
      {
        path: 'boxes/:id',
        element: <EditBox />,
      },
      {
        path: 'boxes/create',
        element: <CreateBox />,
      },
      {
        path: 'integrations',
        element: <Integrations />,
      },
      {
        path: 'integrations/create',
        element: <CreateIntegration />,
      },
      {
        path: 'integrations/:id',
        element: <EditIntegration />,
      },
      {
        path: 'customers',
        element: <Customers />,
      },
      {
        path: 'customers/:id',
        element: <EditCustomer />,
      },
      {
        path: 'customers/create',
        element: <CreateCustomer />,
      },
      {
        path: 'buyers',
        element: <Buyers />,
      },
      {
        path: 'buyers/:id',
        element: <EditBuyer />,
      },
      {
        path: 'buyers/create',
        element: <CreateBuyer />,
      },
      {
        path: 'call-status-groups',
        element: <CallStatusGroups />,
      },
      {
        path: 'call-status-groups/create',
        element: <CreateCallStatusGroup />,
      },
      {
        path: 'call-status-groups/:id',
        element: <EditCallStatusGroups />,
      },
      {
        path: 'call-status-categories',
        element: <CallStatusCategories />,
      },
      {
        path: 'call-status-categories/create',
        element: <CreateCallStatusCategory />,
      },
      {
        path: 'call-status-categories/:id',
        element: <EditCallStatusCategory />,
      },
      {
        path: 'call-status-categories/children/:parentId',
        element: <CallStatusCategoryChildren />,
      },
      {
        path: 'call-status-categories/children/create/:parentId',
        element: <CreateCallStatusCategory />,
      },
      {
        path: 'country-box',
        element: <CountryBox />,
      },
      {
        path: 'country-box/create',
        element: <CreateCountryBox />,
      },
      {
        path: 'country-box/:id',
        element: <EditCountryBox />,
      },
      {
        path: 'users',
        element: <Users />,
      },
      {
        path: 'users/:id',
        element: <EditUser />,
      },
      {
        path: 'users/create',
        element: <CreateUser />,
      },
      {
        path: 'logs',
        element: <Logs />,
      },
      {
        path: 'entity-logs',
        element: <EntityLogs />,
      },
      {
        path: 'inject-form',
        element: <InjectForm />,
      },
      {
        path: 'no-permissions',
        element: <NoPermissions />,
      },
      {
        path: 'domains',
        element: <Domains />,
      },
      {
        path: 'cloudflare',
        element: <Cloudflare />,
      },
      {
        path: 'cloudflare/:id',
        element: <EditCloudflare />,
      },
      {
        path: 'cloudflare/create',
        element: <CreateCloudflare />,
      },
      {
        path: 'servers',
        element: <Servers />,
      },
      {
        path: 'servers/:id',
        element: <EditServers />,
      },
      {
        path: 'servers/create',
        element: <CreateServer />,
      },
      {
        path: 'regway',
        element: <Regway />,
      },
      {
        path: 'regway/:id',
        element: <EditRegway />,
      },
      {
        path: 'regway/create',
        element: <CreateRegway />,
      },
      {
        path: 'buy',
        element: <DomainsPurchase />,
      },
      {
        path: 'designer-reports',
        element: <Reports />,
      },
      {
        path: 'report',
        element: <Report />,
      },
      {
        path: 'tasks',
        element: <Tasks />,
      },
      {
        path: 'permissions',
        element: <Permissions />,
      },
      {
        path: 'permissions/:id',
        element: <EditPermissions />,
      },
      {
        path: 'permissions/create',
        element: <CreatePermissions />,
      },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/autologin/:id',
    element: <Autologin />,
  },
]);

function HellLeadsCrm() {
  return (
    <App>
      <RouterProvider router={router} />
    </App>
  );
}

export default HellLeadsCrm;
