import React, { useContext, useEffect, useState } from 'react';
import {
  usePermissions,
  usePermissionsAllowed,
} from '../../../hooks/usePermissionsAllowed';
import { Link, useSearchParams } from 'react-router-dom';
import { fromApiToClient } from '../../../utils/dateFilters';
import api from '../../../api/api';
import { inputToFilter } from '../../../utils/inputToFilter';
import { AppTable } from '../../../shared/Table/AppTable';
import debounce from 'lodash.debounce';
import { ExtendableLinkTableCell } from '../../../shared/ExtendableTableCell/ExtendableTableCell';
import { PermissionalLink } from '../../../shared/PermissionalLink/PermissionalLink';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';
import { CurrentUserContext } from '../../../providers/AuthProvider';
import { Progress } from 'antd';

export const Boxes = (props) => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filterValues, setFilterValues] = useState({});
  const role = usePermissions();
  const user = useContext(CurrentUserContext);
  usePermissionsAllowed([
    'AFFILIATE',
    'AFFILIATE_TEAM_LEAD',
    'AFFILIATE_SUPPORT',
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
    'ANALYST',
  ]);
  const url = searchParams.get('filters');
  const querystr = JSON.parse(url) ? JSON.parse(url) : null;
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    if (user) {
      setColumns([
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          render: (id) => <Link to={`/boxes/${id}`}>{id}</Link>,
        },
        {
          title: 'Назва',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Замовлення',
          dataIndex: 'orders',
          key: 'orders',
          render: (orders) => (
            <ExtendableLinkTableCell
              data={orders.map((o) => ({
                label: o.isActive
                  ? `#${o.id} ${o.name}`
                  : `#${o.id} ${o.name} (Неактивне)`,
                url: `/orders/${o.id}`,
              }))}
            />
          ),
        },
        {
          title: 'Аффілейт',
          dataIndex: 'affiliate',
          key: 'affiliate',
          render: (affiliate) =>
            affiliate ? (
              <PermissionalLink
                label={affiliate.nickname}
                url={`/users/${affiliate.id}`}
                permissions={[
                  'SUPER_ADMIN',
                  'AFFILIATE_TEAM_LEAD',
                  'FULLSTACK_TEAM_LEAD',
                ]}
              />
            ) : (
              <span>Відсутній</span>
            ),
        },
        {
          title: 'Денний кап',
          dataIndex: 'dailyCap',
          align: 'center',
          key: 'dailyCap',
          render: (dailyCap, box) => {
            let percent = 0;
            let leadsForFormat = 0;
            leadsForFormat = box?.nowCaps;
            percent = (100 * leadsForFormat) / dailyCap;
            return dailyCap && dailyCap > 0 ? (
              <div
                style={{
                  width: 130,
                  margin: '0px auto',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Progress
                  percent={percent}
                  size="small"
                  format={() => (
                    <div>
                      {leadsForFormat}/{dailyCap}
                    </div>
                  )}
                />
              </div>
            ) : (
              ''
            );
          },
        },
        {
          title: 'Стан',
          dataIndex: 'isActive',
          key: 'isActive',
          render: (date) => (date ? 'Активний' : 'Не активний'),
        },
        {
          title: 'Створено',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (date) => fromApiToClient(date, user?.timezone),
        },
        {
          title: 'Оновлено',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          render: (date) => fromApiToClient(date, user?.timezone),
        },
      ]);
    }
  }, [user]);

  useEffect(() => {
    let res = '';
    if (!url) {
      res = 'filter.isActive=$and:$eq:true&';
    }
    for (let i in querystr) {
      res += `filter.${querystr[i][1]}=$and:${querystr[i][2]}&`;
    }
    api.get('/box?' + res + 'page=1&limit=10').then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
    });
  }, []);

  useDidUpdateEffect(() => {
    const url = inputToFilter(filterValues, currentPage);
    api.get(`/box?${url}`).then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
      window.scrollTo(0, 0);
    });
  }, [filterValues, currentPage]);

  const onFiltersChange = (name, value) => {
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  };

  const onFiltersSet = (filters) => {
    let object = {};
    for (let i in filters) {
      object[filters[i][1]] = filters[i][2];
    }
    setFilterValues({
      ...object,
    });
  };

  const onTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  return (
    <div>
      <AppTable
        filters={GetFilters({ onFiltersChange, onFiltersSet, querystr })}
        columns={columns}
        pagination={{
          total,
          current: currentPage,
          pageSize: 10,
        }}
        onChange={onTableChange}
        onColumnsChange={setColumns}
        data={data}
        dataPrefix="boxes"
        showAddButton={role !== 'ANALYST'}
        createUrl={'/boxes/create'}
        querystr={querystr}
      />
    </div>
  );
};

const GetFilters = ({ onFiltersChange, onFiltersSet, querystr }) => {
  const user = useContext(CurrentUserContext);
  const [users, setUsers] = useState(
    querystr?.hasOwnProperty('users') ? querystr.users[0] : []
  );
  const [orders, setOrders] = useState(
    querystr?.hasOwnProperty('orders') ? querystr.orders[0] : []
  );
  const [affiliate, setAffiliates] = useState(
    querystr?.hasOwnProperty('affiliate') ? querystr.affiliate[0] : []
  );
  const [, setSearchParams] = useSearchParams();
  const currentUser = useContext(CurrentUserContext);

  useEffect(() => {
    if (querystr) {
      onFiltersSet(querystr);
    }
  }, []);
  const fetchUsers = async (name) => {
    const res = await api.get(
      `/user?filter.nickname=$ilike:${name}&filter.isActive=$eq:true`
    );
    return res.data.data.map((c) => ({
      label: c.nickname,
      value: c.id,
    }));
  };
  const initialFetchUsers = async () => {
    const res = await api.get(`/user?limit=10&filter.isActive=$eq:true`);
    return res.data.data.map((c) => ({
      label: c.nickname,
      value: c.id,
    }));
  };

  const fetchAffiliates = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:AFFILIATE&filter.role=$or:$eq:FULLSTACK&filter.nickname=$ilike:${name}${idFilter}`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: `#${u.id} ${u.name}`,
        value: u.id,
      }));
  };

  const initialFetchAffiliates = async (name) => {
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:AFFILIATE&filter.role=$or:$eq:FULLSTACK`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: `#${u.id} ${u.name}`,
        value: u.id,
      }));
  };

  const fetchOrders = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/order?filter.name=$ilike:${name}&filter.isActive=$eq:true${idFilter}`
    );
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };

  const initialFetchOrders = async () => {
    const res = await api.get(`/order/label?limit=10`);
    return res.data.data.map((c) => ({
      label: `#${c.id} ${c.name}`,
      value: c.id,
    }));
  };
  return [
    {
      label: 'ID',
      type: 'number',
      defaultValue: querystr?.hasOwnProperty('id') && querystr.id[0],
      onChange: debounce((e) => {
        onFiltersChange('id', `$eq:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            id: [e.target.value, 'id', `$eq:${e.target.value}`],
          }),
        });
      }, 300),
      style: {
        width: '80px',
      },
    },
    {
      label: 'Назва',
      defaultValue: querystr?.hasOwnProperty('name') && querystr.name[0],
      onChange: debounce((e) => {
        onFiltersChange('name', `$ilike:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            name: [e.target.value, 'name', `$ilike:${e.target.value}`],
          }),
        });
      }, 300),
    },
    {
      label: 'Замовлення',
      type: 'multiselect',
      onChange: (e) => {
        setOrders(e);
        e.forEach((d) => {
          delete d.disabled;
          delete d.title;
          delete d.key;
        });
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            orders: [e, 'orders.id', `$in:${e.map((u) => u.value).join(',')}`],
          }),
        });
        onFiltersChange('orders.id', `$in:${e.map((o) => o.value).join(',')}`);
      },
      fetch: fetchOrders,
      initialFetch: initialFetchOrders,
      value: orders,
      style: {
        width: '130px',
      },
    },
    {
      label: 'Аффілейт',
      type: 'multiselect',
      onChange: (e) => {
        setAffiliates(e);
        onFiltersChange(
          'affiliate.id',
          `$in:${e.map((u) => u.value).join(',')}`
        );
        e.forEach((d) => {
          delete d.disabled;
          delete d.title;
          delete d.key;
        });
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            affiliate: [
              e,
              'affiliate.id',
              `$in:${e.map((u) => u.value).join(',')}`,
            ],
          }),
        });
      },
      fetch: fetchAffiliates,
      initialFetch: initialFetchAffiliates,
      value: affiliate,
      style: {
        width: '130px',
      },
      hide: currentUser
        ? ['AFFILIATE', 'FULLSTACK'].includes(currentUser.role)
          ? true
          : false
        : true,
    },
    {
      label: 'Від',
      type: 'datepicker',
      value:
        querystr?.hasOwnProperty('createdAtFrom') && querystr.createdAtFrom[0],
      onChange: (e) => {
        const date = e
          ?.set('hour', 0)
          .set('minute', 0)
          .set('second', 0)
          .tz(user.timezone);
        onFiltersChange('createdAt', `$gte:${date?.toISOString() || ''}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            createdAtFrom: [
              date?.toISOString(),
              'createdAt',
              `$gte:${date?.toISOString() || ''}`,
            ],
          }),
        });
      },
      style: {
        width: '130px',
      },
    },
    {
      label: 'До',
      type: 'datepicker',
      value: querystr?.hasOwnProperty('createdAtTo') && querystr.createdAtTo[0],
      onChange: (e) => {
        const date = e
          ?.set('hour', 23)
          .set('minute', 23)
          .set('second', 59)
          .tz(user.timezone);
        onFiltersChange('createdAt', `$lte:${date?.toISOString() || ''}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            createdAtTo: [
              date?.toISOString(),
              'createdAt',
              `$lte:${date?.toISOString() || ''}`,
            ],
          }),
        });
      },
      style: {
        width: '130px',
      },
    },
    {
      label: 'Стан',
      type: 'checkbox',
      defaultChecked: querystr?.hasOwnProperty('isActive')
        ? querystr.isActive[0]
        : true,
      onChange: (e) => {
        if (e.target.checked) {
          onFiltersChange('isActive', `$eq:${e.target.checked}`);
        } else {
          onFiltersChange('isActive', `$ilike:`);
        }
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            isActive: [
              e.target.checked,
              'isActive',
              e.target.checked ? `$eq:${e.target.checked}` : '$ilike:',
            ],
          }),
        });
      },
    },
    // {
    //   label: 'Автор',
    //   type: 'multiselect',
    //   onChange: (e) => {
    //     setUsers(e);
    //     e.forEach((d) => {
    //       delete d.disabled;
    //       delete d.title;
    //       delete d.key;
    //     });
    //     setSearchParams({
    //       filters: JSON.stringify({
    //         ...querystr,
    //         users: [e, 'createdBy', `$in:${e.map((u) => u.value).join(',')}`],
    //       }),
    //     });
    //     onFiltersChange('createdBy', `$in:${e.map((u) => u.value).join(',')}`);
    //   },
    //   fetch: fetchUsers,
    //   initialFetch: initialFetchUsers,
    //   value: users,
    //   style: {
    //     width: '130px',
    //   },
    //   hide: currentUser
    //     ? currentUser.role !== 'SUPER_ADMIN' || currentUser.role !== 'ANALYST'
    //       ? true
    //       : false
    //     : true,
    // },
  ];
};
