import { useNavigate, useParams } from 'react-router-dom';
import api from '../../../api/api';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { CallStatusCategoryForm } from '../CallStatusCategoryForm/CallStatusCategoryForm';

export const CreateCallStatusCategory = () => {
  const { message } = App.useApp();
  const { parentId } = useParams();
  const navigate = useNavigate();
  const affiliatesTeam = [
    'AFFILIATE',
    'AFFILIATE_TEAM_LEAD',
    'AFFILIATE_SUPPORT',
  ];
  const fullstackTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  usePermissionsAllowed(affiliatesTeam.concat(fullstackTeam));

  const onFinish = (callStatusCategory) => {
    api
      .post('/call-status-category', {
        ...callStatusCategory,
      })
      .then((res) => {
        message.success('Категорію call статусів додано');
        parentId
          ? navigate(`/call-status-categories/children/${parentId}`)
          : navigate(`/call-status-categories`);
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  return <CallStatusCategoryForm onFinish={onFinish} parentId={parentId} />;
};
