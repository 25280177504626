import { Button, Form, Input, Modal, Select, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { statusOptions } from '../../../utils/statusOptions';
import styles from '../../Leads/LeadDetails/LeadDetails.module.css';
import { InfoCircleOutlined } from '@ant-design/icons';
import api from '../../../api/api';

const { TextArea } = Input;

export const CreatePostbackModal = ({
  isOpen,
  onCancel,
  pb,
  refetch,
  userId,
}) => {
  const [postback, setPostback] = useState(pb);
  const [form] = Form.useForm();
  const [action, setAction] = useState(
    postback && postback.status?.length ? 'updateStatus' : 'createLead'
  );
  const [status, setStatus] = useState('');

  useEffect(() => {
    setPostback(pb);
    form.setFieldValue('link', pb?.link);
    form.setFieldValue(
      'action',
      pb?.status?.length ? 'updateStatus' : 'createLead'
    );
    setAction(pb?.status?.length ? 'updateStatus' : 'createLead');
    setStatus(pb?.status);
  }, [pb]);

  const onApiCall = () => {
    if (postback) {
      api
        .put(`/postback/${postback.id}`, {
          link: form.getFieldValue('link'),
          status: action === 'updateStatus' ? status : undefined,
        })
        .then(refetch)
        .finally(() => {
          form.resetFields();
          setAction('createLead');
          setStatus('');
        });
    } else {
      api
        .post('/postback', {
          link: form.getFieldValue('link'),
          status: action === 'updateStatus' ? status : undefined,
          user_id: userId,
        })
        .then(refetch)
        .finally(() => {
          form.resetFields();
          setAction('createLead');
          setStatus('');
        });
    }
  };

  const onStatusChange = (value) => {
    setStatus(value);
  };

  const onActionChange = (value) => {
    setAction(value);
  };

  return (
    <Modal
      title="Постбек"
      open={isOpen}
      footer={null}
      onCancel={() => {
        onCancel();
        form.resetFields();
        setAction('createLead');
        setStatus('');
      }}
    >
      <Form
        wrapperCol={{ span: 12, offset: 1 }}
        labelCol={{ span: 7, offset: 1 }}
        form={form}
        onFinish={onApiCall}
      >
        <Form.Item label="Подія" name="action" style={{ marginTop: 15 }}>
          <Select
            onChange={onActionChange}
            options={[
              { value: 'createLead', label: 'Створення ліда' },
              { value: 'updateStatus', label: 'Оновлення статуса ліда' },
            ]}
            value={action}
          />
        </Form.Item>
        {action === 'updateStatus' && (
          <Form.Item label="Статус" name="status" style={{ marginTop: 15 }}>
            <Select
              defaultValue={postback?.status || ''}
              onChange={onStatusChange}
              options={statusOptions}
              value={status}
            />
          </Form.Item>
        )}

        <Form.Item
          name="link"
          label={
            <span>
              Посилання{' '}
              <Tooltip title="Змінні: {id} {email} {name} {lastName} {phone} {status} {country} {box} {order} {sub1} {sub2} {sub3} {sub4} {sub5} {sub6} {sub7} {sub8}">
                <InfoCircleOutlined />
              </Tooltip>
            </span>
          }
        >
          <TextArea
            style={{ margin: 0 }}
            rows={4}
            placeholder="http://example.com/xyz?foo={name}"
            className={styles.formItem}
          />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button type="primary" htmlType="submit">
            Зберегти
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
};
