import React, { useContext, useMemo } from 'react';
import { PushpinFilled, PushpinOutlined } from '@ant-design/icons';
import { ThemeContext } from '../../../providers/ThemeProvider';
import style from './PinButton.module.css';

export const PinButton = ({ isSolid, onClick }) => {
  const { isDarkTheme } = useContext(ThemeContext);

  const buttonClass = useMemo(() => {
    return isDarkTheme ? style.buttonDark : style.buttonLight;
  }, [isDarkTheme]);

  return (
    <button className={buttonClass}>
      {isSolid ? (
        <PushpinFilled className={style.iconClass} onClick={() => onClick()} />
      ) : (
        <PushpinOutlined
          className={style.iconClass}
          onClick={() => onClick()}
        />
      )}
    </button>
  );
};
