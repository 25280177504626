import { Button, Checkbox, Form, Input } from 'antd';
import styles from './CountryBoxForm.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { fromApiToClient } from '../../../utils/dateFilters';
import { CountrySelect } from '../../../shared/CountryMultiselect/CountrySelect';
import {
  hasPermissions,
  usePermissions,
} from '../../../hooks/usePermissionsAllowed';
import { CurrentUserContext } from '../../../providers/AuthProvider';

export const CountryBoxForm = ({ countryBox, onFinish }) => {
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(
    countryBox ? countryBox.isActive : true
  );
  const [countries, setCountries] = useState();
  const user = useContext(CurrentUserContext);
  const role = usePermissions();
  const bizdevsTeam = ['BIZDEV', 'BIZDEV_TEAM_LEAD', 'BIZDEV_SUPPORT'];
  const fullstacksTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  useEffect(() => {
    if (!countryBox) form.setFieldValue('isActive', true);
  }, []);

  useEffect(() => {
    if (countryBox) {
      form.setFieldsValue(countryBox);
      form.setFieldValue('countries', JSON.parse(countryBox.countries));
      form.setFieldValue(
        'createdAt',
        fromApiToClient(countryBox.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(countryBox.updatedAt, user.timezone)
      );
      setIsActive(countryBox.isActive);
    }
  }, [countryBox]);

  const onActiveChange = (e) => {
    setIsActive(e.target.checked);
  };

  const onCountryChange = (value) => {
    setCountries(value);
    form.setFieldValue('countries', value);
  };

  return (
    <Form
      form={form}
      onFinish={() => {
        onFinish({
          ...form.getFieldsValue(),
          countries: JSON.stringify(countries),
          isActive,
        });
      }}
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
    >
      <Form.Item
        name="name"
        label="Назва"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input
          disabled={!hasPermissions(role, bizdevsTeam.concat(fullstacksTeam))}
          placeholder="Назва"
          rootClassName={styles.input}
        />
      </Form.Item>
      <Form.Item
        name="countries"
        label="Країни"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <CountrySelect
          mode="multiple"
          onChange={onCountryChange}
          className={styles.input}
          disabled={!hasPermissions(role, bizdevsTeam.concat(fullstacksTeam))}
        />
      </Form.Item>

      {countryBox && (
        <Form.Item name="createdAt" label="Створено">
          <Input disabled placeholder="Створено" rootClassName={styles.input} />
        </Form.Item>
      )}
      {countryBox && (
        <Form.Item name="updatedAt" label="Оновлено">
          <Input disabled placeholder="Оновлено" rootClassName={styles.input} />
        </Form.Item>
      )}
      <Form.Item
        name="isActive"
        label="Активний"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Checkbox
          onClick={onActiveChange}
          defaultChecked={countryBox ? countryBox.isActive : true}
          rootClassName={styles.input}
          disabled={!hasPermissions(role, bizdevsTeam.concat(fullstacksTeam))}
        />
      </Form.Item>
      {hasPermissions(role, bizdevsTeam.concat(fullstacksTeam)) && (
        <Form.Item wrapperCol={{ offset: 11 }}>
          <Button type="primary" htmlType="submit">
            Зберегти
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};
