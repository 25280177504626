import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import api from '../../../api/api';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { CloudflareForm } from '../CloudflareForm/CloudflareForm';

export const EditCloudflare = () => {
  const { message } = App.useApp();
  const [cf, setCf] = useState();
  const { id } = useParams();
  usePermissionsAllowed(['SUPPORT', 'TEAM_LEAD']);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    api.get(`/domain-purchase/cloudflare/${id}`).then((res) => {
      setCf({
        ...res.data,
      });
      setIsLoading(false);
    });
  }, []);

  const onFinish = (updatedCf) => {
    delete updatedCf.updatedAt;
    delete updatedCf.createdAt;
    api
      .put(`/domain-purchase/cloudflare/${id}`, {
        ...updatedCf,
      })
      .then((_) => {
        navigate(-1);
        message.success('Regway оновлено');
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  if (isLoading) return 'loading';

  return <CloudflareForm onFinish={onFinish} cf={cf} />;
};
