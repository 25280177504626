import { Button, Checkbox, Form, Input } from 'antd';
import styles from './BoxForm.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { CurrentUserContext } from '../../../providers/AuthProvider';
import { fromApiToClient } from '../../../utils/dateFilters';
import { AutocompleteSelect } from '../../../shared/AutocompleteSelect/AutocompleteSelect';
import {
  hasPermissions,
  usePermissions,
} from '../../../hooks/usePermissionsAllowed';
import api from '../../../api/api';

export const BoxForm = ({ box, onFinish }) => {
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(box ? box.isActive : true);
  const [selectedAffiliate, setSelectedAffiliate] = useState();
  const [selectedBuyer, setSelectedBuyer] = useState();
  const [isRedirectAllowed, setIsRedirectAllowed] = useState(
    box ? box.isRedirectAllowed : true
  );
  const [isAllowDuplicates, setIsAllowDuplicates] = useState(
    box ? box.isAllowDuplicates : false
  );
  const role = usePermissions();
  const user = useContext(CurrentUserContext);
  const [isCurrentUserCantModify, setIsCurrentUserCantModify] = useState(false);
  const affiliatesTeam = [
    'AFFILIATE',
    'AFFILIATE_TEAM_LEAD',
    'AFFILIATE_SUPPORT',
  ];
  const fullstackTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  useEffect(() => {
    if (!box) form.setFieldValue('isActive', true);
    if (!box) form.setFieldValue('isRedirectAllowed', false);
  }, []);

  useEffect(() => {
    if (box) {
      form.setFieldsValue(box);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(box.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(box.updatedAt, user.timezone)
      );
      setIsActive(box.isActive);
      setIsRedirectAllowed(box.isRedirectAllowed);
      if (box.affiliate) {
        setSelectedAffiliate({
          value: box.affiliate.id,
          label: box.affiliate.nickname,
          key: box.affiliate.id,
        });
        form.setFieldValue('affiliate', {
          value: box.affiliate.id,
          label: box.affiliate.nickname,
          key: box.affiliate.id,
        });
      }
      if (box.buyer) {
        setSelectedBuyer({
          value: box.buyer.id,
          label: box.buyer.name,
          key: box.buyer.id,
        });
        form.setFieldValue('buyer', {
          value: box.buyer.id,
          label: box.buyer.name,
          key: box.buyer.id,
        });
      }
      if (box['isCurrentUserCantModify']) setIsCurrentUserCantModify(true);
    }
  }, [box]);

  useEffect(() => {
    if (!box) {
      setSelectedAffiliate({
        value: user.id,
        label: user.nickname,
        key: user.id,
      });
      form.setFieldValue('affiliate', {
        value: user.id,
        label: user.nickname,
        key: user.id,
      });
    }
  }, [user]);

  const onActiveChange = (e) => {
    setIsActive(e.target.checked);
  };

  const fetchAffiliates = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:AFFILIATE&filter.role=$or:$eq:FULLSTACK&filter.nickname=$ilike:${name}${idFilter}`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: `#${u.id} ${u.name}`,
        value: u.id,
      }));
  };

  const initialFetchAffiliates = async (name) => {
    const res = await api.get(
      `/user?limit=10&filter.isActive=$eq:true&filter.role=$eq:AFFILIATE&filter.role=$or:$eq:FULLSTACK`
    );
    if (res.length !== 0)
      return res.data.data.map((u) => ({
        label: `#${u.id} ${u.name}`,
        value: u.id,
      }));
  };

  const fetchBuyers = async (name) => {
    const idFilter =
      !isNaN(name) && name?.length ? `&filter.id=$or:$eq:${name}` : '';
    const res = await api.get(
      `/buyer?limit=10&filter.isActive=$eq:true&filter.name=$ilike:${name}${idFilter}`
    );
    if (res.length !== 0)
      return res.data.data.map((b) => ({
        label: `#${b.id} ${b.name}`,
        value: b.id,
      }));
  };

  const initialFetchBuyers = async () => {
    const res = await api.get(`/buyer?limit=10&filter.isActive=$eq:true`);
    if (res.length !== 0)
      return res.data.data.map((b) => ({
        label: `#${b.id} ${b.name}`,
        value: b.id,
      }));
  };

  const onAllowDuplicatesChange = (e) => {
    setIsAllowDuplicates(e.target.checked);
  };

  const onRedirectAllowedChange = (e) => {
    setIsRedirectAllowed(e.target.checked);
  };

  return (
    <Form
      form={form}
      onFinish={() => {
        onFinish({
          name: form.getFieldValue('name'),
          affiliateId: selectedAffiliate?.value || undefined,
          buyerId: selectedBuyer?.value || undefined,
          isAllowDuplicates: isAllowDuplicates,
          createdBy: user?.id,
          isActive,
          isRedirectAllowed,
          createdAt: undefined,
          updatedAt: undefined,
        });
      }}
      labelCol={{ span: 11 }}
      wrapperCol={{ span: 14 }}
    >
      <Form.Item
        name="name"
        label="Назва"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input
          disabled={
            !hasPermissions(role, affiliatesTeam.concat(fullstackTeam)) ||
            isCurrentUserCantModify
          }
          placeholder="Назва"
          rootClassName={styles.input}
        />
      </Form.Item>
      <Form.Item
        name="affiliate"
        label="Аффілейт"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <AutocompleteSelect
          className={styles.input}
          value={selectedAffiliate}
          placeholder="Аффілейт"
          initialFetch={initialFetchAffiliates}
          fetch={fetchAffiliates}
          onChange={setSelectedAffiliate}
          disabled={
            !hasPermissions(role, [
              'AFFILIATE_TEAM_LEAD',
              'AFFILIATE_SUPPORT',
              'FULLSTACK_TEAM_LEAD',
              'FULLSTACK_SUPPORT',
            ])
          }
        />
      </Form.Item>
      <Form.Item
        name="buyer"
        label="Баєр"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <AutocompleteSelect
          className={styles.input}
          value={selectedBuyer}
          placeholder="Баєр"
          initialFetch={initialFetchBuyers}
          fetch={fetchBuyers}
          onChange={setSelectedBuyer}
          disabled={
            !hasPermissions(role, affiliatesTeam.concat(fullstackTeam)) ||
            isCurrentUserCantModify
          }
        />
      </Form.Item>
      {box && (
        <Form.Item name="createdAt" label="Створено">
          <Input disabled placeholder="Створено" rootClassName={styles.input} />
        </Form.Item>
      )}
      {box && (
        <Form.Item name="updatedAt" label="Оновлено">
          <Input disabled placeholder="Оновлено" rootClassName={styles.input} />
        </Form.Item>
      )}
      <Form.Item
        name="isActive"
        label="Активний"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Checkbox
          onClick={onActiveChange}
          defaultChecked={box ? box.isActive : true}
          rootClassName={styles.input}
          disabled={
            !hasPermissions(role, affiliatesTeam.concat(fullstackTeam)) ||
            isCurrentUserCantModify
          }
        />
      </Form.Item>

      <Form.Item name="isAllowDuplicate" label="Дозволити дублікати">
        <Checkbox
          onClick={onAllowDuplicatesChange}
          defaultChecked={box ? box.isAllowDuplicates : true}
          rootClassName={styles.input}
          disabled={!hasPermissions(role, affiliatesTeam.concat(fullstackTeam))}
        />
      </Form.Item>

      <Form.Item
        name="isRedirectAllowed"
        label="Перенаправляти ліди на інше замовлення в разі дубля або помилки"
      >
        <Checkbox
          onClick={onRedirectAllowedChange}
          defaultChecked={box ? box.isRedirectAllowed : true}
          rootClassName={styles.input}
          disabled={!hasPermissions(role, affiliatesTeam.concat(fullstackTeam))}
        />
      </Form.Item>

      {hasPermissions(role, affiliatesTeam.concat(fullstackTeam)) && (
        <Form.Item className="flex justify-center">
          <Button type="primary" htmlType="submit">
            Зберегти
          </Button>
        </Form.Item>
      )}
    </Form>
  );
};
