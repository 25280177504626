import { Divider, Select, Space, Switch } from 'antd';
import CountryList from 'country-list-with-dial-code-and-flag';
import { useEffect, useState } from 'react';

const { Option } = Select;

export const CountrySelectFilter = ({ values, onChange, mode, ...props }) => {
  const countries = CountryList.getAll();
  const [treeLine, setTreeLine] = useState([]);
  const [isSelected, setIsSelected] = useState(true);
  return (
    <Select
      mode={mode}
      dropdownMatchSelectWidth={false}
      placeholder="Країни"
      onChange={(e) => {
        setTreeLine(e);
        onChange({ data: e, isSelected: isSelected });
      }}
      optionLabelProp="label"
      defaultValue={values}
      showSearch
      filterOption={(inputValue, option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()) ||
        option.value.toLowerCase().includes(inputValue.toLowerCase())
      }
      {...props}
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider
            style={{
              margin: '8px 0',
            }}
          />
          <Space
            style={{
              padding: '0 8px 4px',
            }}
          >
            <Switch
              defaultChecked={isSelected}
              onChange={(e) => {
                setIsSelected(e);
                onChange({ data: treeLine, isSelected: e });
              }}
              checkedChildren="Включно"
              unCheckedChildren="Виключно"
            />
          </Space>
        </>
      )}
    >
      {countries.map((c, index) => (
        <Option
          style={{ maxWidth: '200px' }}
          key={index}
          value={c.code}
          label={c.name}
        >
          <div>
            <span role="img" aria-label={c.name}>
              {c.flag}&nbsp;
            </span>
            {c.name}&nbsp;({c.code.trim()})
          </div>
        </Option>
      ))}
    </Select>
  );
};
