import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css';
import { Button, Form, Input } from 'antd';
import logoWhite from '../../logo_hell_white.webp';
import api from '../../api/api';

export const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);

  const onFinish = () => {
    api
      .post('/auth/login', {
        email: form.getFieldValue('email'),
        password: form.getFieldValue('password'),
      })
      .then((res) => {
        localStorage.setItem('access_token', res.data.access_token);
        navigate('/leads');
      })
      .catch((res) => {
        console.error(res);
        if (res.response.status === 401) {
          setIsError(true);
        }
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.error('Failed:', errorInfo);
  };

  return (
    <div className={styles.body}>
      <div className={styles.formContainer}>
        <div className={styles.logoContainer}>
          <img src={logoWhite} alt="" className={styles.logo} />
        </div>
        <Form
          form={form}
          name="basic"
          labelCol={{
            span: 8,
          }}
          wrapperCol={{
            span: 16,
          }}
          style={{
            maxWidth: 600,
          }}
          initialValues={{
            remember: true,
          }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                required: true,
                type: 'email',
                message: 'Будь ласка, введіть свій email',
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Пароль"
            name="password"
            rules={[
              {
                required: true,
                message: 'Будь ласка, введіть свій пароль',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
          {isError && (
            <Form.Item className={styles.errorContainer}>
              <div className={styles.error}>Невірний Email або пароль</div>
            </Form.Item>
          )}
          <Form.Item
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Button type="primary" htmlType="submit">
              Увійти
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};
