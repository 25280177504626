import React, { useContext, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

import { AppTable } from '../../../shared/Table/AppTable';
import api from '../../../api/api';
import { inputToFilter } from '../../../utils/inputToFilter';
import { Link, useSearchParams } from 'react-router-dom';
import { fromApiToClient } from '../../../utils/dateFilters';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';
import { CurrentUserContext } from '../../../providers/AuthProvider';
import { PermissionalLink } from '../../../shared/PermissionalLink/PermissionalLink';

export const Users = (props) => {
  const [searchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filterValues, setFilterValues] = useState({});
  const currentUser = useContext(CurrentUserContext);
  usePermissionsAllowed([
    'AFFILIATE_TEAM_LEAD',
    'AFFILIATE_SUPPORT',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
    'BIZDEV_TEAM_LEAD',
    'BIZDEV_SUPPORT',
    'ANALYST',
    'SUPER_ADMIN',
  ]);
  const url = searchParams.get('filters');
  const querystr = JSON.parse(url) ? JSON.parse(url) : null;

  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (currentUser) {
      setColumns([
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          show: true,
          render: (id) => (
            <PermissionalLink
              label={id}
              url={`/users/${id}`}
              permissions={[
                'FULLSTACK_TEAM_LEAD',
                'AFFILIATE_TEAM_LEAD',
                'BIZDEV_TEAM_LEAD',
                'ANALYST',
              ]}
            />
          ),
        },
        {
          title: "Ім'я",
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Нік',
          dataIndex: 'nickname',
          key: 'nickname',
        },
        {
          title: 'Email',
          dataIndex: 'email',
          key: 'email',
        },
        {
          title: 'Telegram',
          dataIndex: 'telegram',
          key: 'telegram',
        },
        {
          title: 'Роль',
          dataIndex: 'role',
          key: 'role',
        },
        {
          title: 'Стан',
          dataIndex: 'isActive',
          key: 'isActive',
          render: (date) => (date ? 'Активний' : 'Не активний'),
        },
        {
          title: 'Створено',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (date) => fromApiToClient(date, currentUser.timezone),
        },
        {
          title: 'Оновлено',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          render: (date) => fromApiToClient(date, currentUser.timezone),
        },
        {
          title: 'api-key',
          dataIndex: 'apiKey',
          key: 'apiKey',
          hide: currentUser
            ? currentUser.role !== 'SUPER_ADMIN'
              ? true
              : false
            : true,
        },
      ]);
    }
  }, [currentUser]);
  useEffect(() => {
    let res = '';
    if (!url) {
      res = 'filter.isActive=$and:$eq:true&';
    }
    for (let i in querystr) {
      res += `filter.${querystr[i][1]}=$and:${querystr[i][2]}&`;
    }
    api.get('/user/all-users?' + res + 'page=1&limit=10').then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
    });
  }, []);

  useDidUpdateEffect(() => {
    const url = inputToFilter(filterValues, currentPage);
    api.get(`/user/all-users?${url}`).then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
      window.scrollTo(0, 0);
    });
  }, [filterValues, currentPage]);

  const onFiltersChange = (name, value) => {
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  };
  const onFiltersSet = (filters) => {
    let object = {};
    for (let i in filters) {
      object[filters[i][1]] = filters[i][2];
    }

    setFilterValues({
      ...object,
    });
  };
  const onTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  return (
    <div>
      <AppTable
        filters={GetFilters({ onFiltersChange, onFiltersSet, querystr })}
        columns={columns}
        pagination={{
          total,
          current: currentPage,
          pageSize: 10,
        }}
        onChange={onTableChange}
        onColumnsChange={setColumns}
        data={data}
        dataPrefix="users"
        showAddButton
        createUrl={'/users/create'}
        querystr={querystr}
      />
    </div>
  );
};

const GetFilters = ({ onFiltersChange, onFiltersSet, querystr }) => {
  const [, setSearchParams] = useSearchParams();
  useEffect(() => {
    if (querystr) {
      onFiltersSet(querystr);
    }
  }, []);

  return [
    {
      label: 'ID',
      type: 'number',
      defaultValue: querystr?.hasOwnProperty('id') ? querystr.id[0] : '',
      onChange: debounce((e) => {
        onFiltersChange('id', `$eq:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            id: [e.target.value, 'id', `$eq:${e.target.value}`],
          }),
        });
      }, 300),
      style: {
        width: '80px',
      },
    },
    {
      label: "Ім'я",
      defaultValue: querystr?.hasOwnProperty('name') ? querystr.name[0] : '',
      onChange: debounce((e) => {
        onFiltersChange('name', `$ilike:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            name: [e.target.value, 'name', `$ilike:${e.target.value}`],
          }),
        });
      }, 300),
    },
    {
      label: 'Нік',
      defaultValue: querystr?.hasOwnProperty('nickname')
        ? querystr.nickname[0]
        : '',
      onChange: debounce((e) => {
        onFiltersChange('nickname', `$ilike:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            nickname: [e.target.value, 'nickname', `$ilike:${e.target.value}`],
          }),
        });
      }, 300),
    },
    {
      label: 'Телеграм',
      defaultValue: querystr?.hasOwnProperty('telegram')
        ? querystr.telegram[0]
        : '',
      onChange: debounce((e) => {
        onFiltersChange('telegram', `$ilike:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            telegram: [e.target.value, 'telegram', `$ilike:${e.target.value}`],
          }),
        });
      }, 300),
    },
    {
      label: 'Email',
      defaultValue: querystr?.hasOwnProperty('email') ? querystr.email[0] : '',
      onChange: debounce((e) => {
        onFiltersChange('email', `$ilike:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            email: [e.target.value, 'email', `$ilike:${e.target.value}`],
          }),
        });
      }, 300),
    },
    {
      label: 'Стан',
      type: 'checkbox',
      defaultChecked: querystr?.hasOwnProperty('isActive')
        ? querystr.isActive[0]
        : true,
      onChange: (e) => {
        if (e.target.checked) {
          onFiltersChange('isActive', `$eq:${e.target.checked}`);
        } else {
          onFiltersChange('isActive', `$ilike:`);
        }
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            isActive: [
              e.target.checked,
              'isActive',
              e.target.checked ? `$eq:${e.target.checked}` : '$ilike:',
            ],
          }),
        });
      },
    },
  ];
};
