export const inputToFilter = (formValues, page, pageSize) => {
  let res = '';
  for (let i in formValues) {
    if (getValueOnly(formValues[i]).length) {
      res += `filter.${i}=$and:${formValues[i]}&`;
    }
  }
  return `${res}limit=${pageSize || 10}&page=${page}`;
};

export const inputToFilterExport = (formValues) => {
  let res = '';
  for (let i in formValues) {
    if (getValueOnly(formValues[i]).length) {
      res += `filter.${i}=$and:${formValues[i]}&`;
    }
  }
  return res;
};

const getValueOnly = (value) => {
  return value.substr(value.indexOf(':') + 1);
};

export const sorterValue = (value) => {
  if (value?.order) {
    if (value?.order === 'descend') {
      return `&sortBy=${value.key}:DESC`;
    } else {
      return `&sortBy=${value.key}:ASC`;
    }
  }
  return '';
};
