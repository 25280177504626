import React from 'react';
import { Collapse } from 'antd';
import { PermissionalLink } from '../PermissionalLink/PermissionalLink';

const { Panel } = Collapse;

export const ExtendableTableCell = ({ data }) => {
  if (data.length <= 2) {
    return data.join(', ');
  }

  return (
    <Collapse size="small" ghost>
      <Panel header={`${data[0]}, ${data[1]}`} key="1">
        <p>{data.join(', ')}</p>
      </Panel>
    </Collapse>
  );
};

export const ExtendableTextTableCell = ({
  data,
  stringLength = 15,
  maxFullStringLength = 0,
}) => {
  if (data && data.length) {
    if (!maxFullStringLength || data.length > maxFullStringLength) {
      let sliced = data.slice(0, stringLength);
      if (sliced.length < data.length) {
        sliced += '...';
      }
      return (
        <Collapse size="small" ghost>
          <Panel header={`${sliced}`} key="1">
            <p>{data}</p>
          </Panel>
        </Collapse>
      );
    }
    return <>{data}</>;
  }
  return '';
};

export const ExtendableTextTableCellWithModal = ({
  data,
  setIsOpen,
  setSelectedData,
  stringLength,
}) => {
  if (data) {
    let sliced = data.slice(0, stringLength);
    if (stringLength < data.length) {
      sliced += '...';

      return (
        <Collapse size="small" ghost>
          <Panel header={`${sliced}}`} key="1">
            <div
              className="cursor-pointer"
              onClick={() => {
                setIsOpen(true);
                setSelectedData(data);
              }}
            >
              <p>{data}</p>
            </div>
          </Panel>
        </Collapse>
      );
    }
    return (
      <div
        className="cursor-pointer"
        onClick={() => {
          setIsOpen(true);
          setSelectedData(data);
        }}
      >
        <p>{data}</p>
      </div>
    );
  }
  return '';
};

export const ExtendableLinkTableCell = ({ data, count = 2 }) => {
  if (data.length <= count) {
    return data.map((d, index) => (
      <PermissionalLink
        label={`${d.label}${index !== data.length - 1 ? ', ' : ''}`}
        url={d.url}
        permissions={d.permissions}
      />
    ));
  }

  return (
    <Collapse size="small" ghost>
      <Panel header={`${data[0].label}, ${data[1].label}`} key="1">
        <p>
          {data.map((d, index) => (
            <PermissionalLink
              label={`${d.label}${index !== data.length - 1 ? ', ' : ''}`}
              url={d.url}
              permissions={d.permissions}
            />
          ))}
        </p>
      </Panel>
    </Collapse>
  );
};

export const ExtendableTableCellText = ({ data, count = 2 }) => {
  let info = `${JSON.parse(data).email}`;
  return (
    <Collapse size="small" ghost>
      <Panel header={`info lead: ${info}`} key="1">
        <p>{data}</p>
      </Panel>
    </Collapse>
  );
};
