import React, { useContext, useEffect, useState } from 'react';
import { Button, Collapse, Form, List, Modal, Popconfirm, Tooltip } from 'antd';
import { PermissionalLink } from '../PermissionalLink/PermissionalLink';
import {
  AFFILIATE_TEAM_LEAD,
  FULLSTACK_TEAM_LEAD,
  BIZDEV_TEAM_LEAD,
  SUPER_ADMIN,
} from '../../utils/roles';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import api from '../../api/api';
import styles from './Shared.module.css';
import { AddUserModal } from './AddUserModal';
import { useLocation } from 'react-router-dom';
import { CurrentUserContext } from '../../providers/AuthProvider';

const onDelete = (userId, entityId, location) => {
  if (location == 'buyers')
    api.delete(`/shared/buyer/${entityId}/${userId}`).then(() => {
      // eslint-disable-next-line no-restricted-globals
      window.location.reload();
    });
  else if (location == 'customers')
    api.delete(`/shared/customer/${entityId}/${userId}`).then(() => {
      // eslint-disable-next-line no-restricted-globals
      window.location.reload();
    });
};

const MinusBtn = ({ userId, entityId, location }) => {
  return (
    <div className="flex justify-between">
      <div
        className="flex items-center"
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        <Popconfirm
          title="Забрати доступ"
          description="Ви впевнені, що хочете забрати доступ у цього користувача?"
          onConfirm={() => onDelete(userId, entityId, location)}
          okText="Так"
          cancelText="Ні"
        >
          <Button
            className={styles.minusBtn}
            type="text"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <MinusOutlined />
          </Button>
        </Popconfirm>
      </div>
    </div>
  );
};

const PlusBtn = ({ openModal }) => {
  return (
    <div className={styles.title}>
      <div
        className="flex items-center"
        style={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
        }}
      >
        <Tooltip title={'Додати юзера'}>
          <Button className={styles.plusBtn} type="text" onClick={openModal}>
            <PlusOutlined />
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export const SharedCell = ({ id, users, buyerUserId }) => {
  let location = useLocation().pathname.replace('/', '');
  const user = useContext(CurrentUserContext);
  const usersArr = users.map((u) => u.id);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleSuccess = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  if (usersArr.includes(user.id)) return <div>Розшарено на вас</div>;

  let notInIds = users.length ? usersArr : [];
  notInIds.push(buyerUserId);

  if (users.length == 0)
    return (
      <div className={styles.plusDiv}>
        <i style={{ paddingTop: 6 }}>(не розшарено)</i>
        <PlusBtn entityId={id} openModal={showModal} />
        <AddUserModal
          entityId={id}
          location={location}
          notInIds={notInIds}
          isModalOpen={isModalOpen}
          onSuccess={handleSuccess}
          handleCancel={handleCancel}
        />
      </div>
    );
  return (
    <>
      <PlusBtn entityId={id} openModal={showModal} />
      <AddUserModal
        entityId={id}
        location={location}
        notInIds={notInIds}
        isModalOpen={isModalOpen}
        onSuccess={handleSuccess}
        handleCancel={handleCancel}
      />
      <List
        size="small"
        dataSource={users}
        renderItem={(user) => (
          <List.Item className={styles.listItem}>
            <PermissionalLink
              label={`#${user.id} ${user.nickname}`}
              url={`/users/${user.id}`}
              permissions={[
                SUPER_ADMIN,
                AFFILIATE_TEAM_LEAD,
                BIZDEV_TEAM_LEAD,
                FULLSTACK_TEAM_LEAD,
              ]}
            />
            <MinusBtn userId={user.id} entityId={id} location={location} />
          </List.Item>
        )}
      />
    </>
  );
};
