export const translateStatus = (status) => {
  switch (status) {
    case 'error':
      return 'Помилка відправки';
    case 'pending':
      return 'В обробці';
    case 'notDistributed':
      return 'Новий call статус';
    case 'unsigned':
      return 'Нерозподілений';
    case 'rejected':
      return 'Відхилений';
    case 'approved':
      return 'Підтверджений';
    case 'fraud':
      return 'FRAUD';
    case 'all':
      return 'Всі';
    case 'test':
      return 'Тест';
    default:
      return '-';
  }
};

export const translateType = (status) => {
  switch (status) {
    case 'resend':
      return 'Перевідправка';
    case 'export':
      return 'Експорт';
    case 'import':
      return 'Імпорт';
    case 'importFile':
      return 'Імпорт з файла';
    case 'purchase':
      return 'Покупка';
    case 'inject-bath':
      return 'Масовий інжект';
    default:
      return '-';
  }
};

export const translateStatusDetail = (status) => {
  switch (status) {
    case 'success':
      return 'Успішний';
    case 'partiallySuccessful':
      return 'Частично успішний';
    case 'error':
      return 'Помилка';
    case 'deleted':
      return 'Зупинений';
    case 'ongoing':
      return 'В обробці';
    default:
      return '-';
  }
};

export const hasSystemStatus = (status) => {
  return (
    status === 'error' || status === 'notDistributed' || status === 'unsigned'
  );
};

export const getColorByStatus = (status) => {
  switch (status) {
    case 'error':
      return '#cf1322';
    case 'pending':
      return '#ff9900';
    case 'notDistributed':
      return '#1d39c4';
    case 'unsigned':
      return '#531dab';
    case 'rejected':
      return '#d46b08';
    case 'approved':
      return '#389e0d';
    case 'all':
      return 'rgb(217, 217, 217)';
    case 'test':
      return 'rgb(255,2,250)';
    default:
      return '-';
  }
};
