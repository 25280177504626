import React, { useContext, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

import { AppTable } from '../../../shared/Table/AppTable';
import api from '../../../api/api';
import { inputToFilter } from '../../../utils/inputToFilter';
import { Link, useSearchParams } from 'react-router-dom';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';
import { fromApiToClient } from '../../../utils/dateFilters';
import { CurrentUserContext } from '../../../providers/AuthProvider';

export const Permissions = () => {
  const [searchParams] = useSearchParams();

  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [countPage, setCountPage] = useState(10);
  usePermissionsAllowed(['SUPER_ADMIN']);
  const url = searchParams.get('filters');
  const querystr = JSON.parse(url) ? JSON.parse(url) : null;
  const [filterValues, setFilterValues] = useState({
    isActive: !!querystr?.length ? '$ilike:' : '$eq:true',
  });
  const user = useContext(CurrentUserContext);
  const [columns, setColumns] = useState([]);
  useEffect(() => {
    if (user) {
      setColumns([
        {
          title: 'ID',
          dataIndex: 'id',
          key: 'id',
          show: true,
          render: (id) => <Link to={`/permissions/${id}`}>{id}</Link>,
        },
        {
          title: 'Назва',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'Стан',
          dataIndex: 'isActive',
          key: 'isActive',
          render: (date) => (date ? 'Активний' : 'Не активний'),
        },
        {
          title: 'Створено',
          dataIndex: 'createdAt',
          key: 'createdAt',
          render: (date) => fromApiToClient(date, user.timezone),
        },
        {
          title: 'Оновлено',
          dataIndex: 'updatedAt',
          key: 'updatedAt',
          render: (date) => fromApiToClient(date, user.timezone),
        },
      ]);
    }
  }, [user]);
  useEffect(() => {
    let res = '';
    if (!url) {
      res = 'filter.isActive=$and:$eq:true&';
    }
    for (let i in querystr) {
      res += `filter.${querystr[i][1]}=$and:${querystr[i][2]}&`;
    }
    api.get('/permissions?' + res + 'page=1&limit=10').then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
    });
  }, []);

  useDidUpdateEffect(() => {
    const url = inputToFilter(filterValues, currentPage, countPage);
    api.get(`/permissions?${url}`).then((res) => {
      setData(res.data.data);
      setTotal(res.data.meta.totalItems);
      window.scrollTo(0, 0);
    });
  }, [filterValues, currentPage, countPage]);
  const onFiltersChange = (name, value) => {
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  };

  const onFiltersSet = (filters) => {
    let object = {};
    for (let i in filters) {
      object[filters[i][1]] = filters[i][2];
    }
    setFilterValues({
      ...object,
    });
  };
  const onTableChange = (pagination) => {
    setCountPage(pagination.pageSize);
    setCurrentPage(pagination.current);
  };

  return (
    <div>
      <AppTable
        filters={GetFilters({ onFiltersChange, onFiltersSet, querystr })}
        columns={columns}
        pagination={{
          total,
          current: currentPage,
          pageSize: countPage,
          showSizeChanger: true,
        }}
        onChange={onTableChange}
        onColumnsChange={setColumns}
        data={data}
        dataPrefix="permissions"
        showAddButton
        createUrl={'/permissions/create'}
        querystr={querystr}
      />
    </div>
  );
};

const GetFilters = ({ onFiltersChange, onFiltersSet, querystr }) => {
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (querystr) {
      onFiltersSet(querystr);
    }
  }, []);

  return [
    {
      label: 'ID',
      type: 'number',
      defaultValue: querystr?.hasOwnProperty('id') ? querystr.id[0] : '',
      onChange: debounce((e) => {
        onFiltersChange('id', `$eq:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            id: [e.target.value, 'id', `$eq:${e.target.value}`],
          }),
        });
      }, 300),
      style: {
        width: '80px',
      },
    },
    {
      label: 'Назва',
      defaultValue: querystr?.hasOwnProperty('name') ? querystr.name[0] : '',
      onChange: debounce((e) => {
        onFiltersChange('name', `$ilike:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            name: [e.target.value, 'name', `$ilike:${e.target.value}`],
          }),
        });
      }, 300),
    },
    {
      label: 'Стан',
      type: 'checkbox',
      defaultChecked: querystr?.hasOwnProperty('isActive')
        ? querystr.isActive[0]
        : true,
      onChange: (e) => {
        if (e.target.checked) {
          onFiltersChange('isActive', `$eq:${e.target.checked}`);
        } else {
          onFiltersChange('isActive', `$ilike:`);
        }
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            isActive: [
              e.target.checked,
              'isActive',
              e.target.checked ? `$eq:${e.target.checked}` : '$ilike:',
            ],
          }),
        });
      },
    },
  ];
};
