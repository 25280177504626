import { useNavigate } from 'react-router-dom';
import api from '../../../api/api';
import { App } from 'antd';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { CustomersForm } from '../CustomersForm/CustomersForm';

export const CreateCustomer = () => {
  const { message } = App.useApp();
  const navigate = useNavigate();
  const bizdevsTeam = ['BIZDEV', 'BIZDEV_TEAM_LEAD', 'BIZDEV_SUPPORT'];
  const fullstackTeam = [
    'FULLSTACK',
    'FULLSTACK_TEAM_LEAD',
    'FULLSTACK_SUPPORT',
  ];
  usePermissionsAllowed(bizdevsTeam.concat(fullstackTeam));

  const onFinish = (customer) => {
    api
      .post('/customer', {
        ...customer,
      })
      .then((_) => {
        navigate('/customers');
        message.success('Користувача додано');
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  return (
    <>
      <CustomersForm onFinish={onFinish} />
    </>
  );
};
