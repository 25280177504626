import { Divider, Input, Space, Select, Spin, Button, Switch, Tag } from 'antd';
import debounce from 'lodash/debounce';
import { useMemo, useRef, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { getColorByStatus } from '../../../utils/translateStatus';
import { Option } from 'antd/es/mentions';
function DebounceSelect({
  fetchOptions,
  debounceTimeout = 400,
  initialFetchOptions,
  ...props
}) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const initialLoad = () => {
    fetchRef.current += 1;
    const fetchId = fetchRef.current;
    setOptions([]);
    setFetching(true);
    initialFetchOptions().then((newOptions) => {
      if (fetchId !== fetchRef.current) {
        // for fetch callback order
        return;
      }
      setOptions(newOptions);
      setFetching(false);
    });
  };

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      showSearch
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      onFocus={initialLoad}
      // onBlur={() => setOptions([])}
      dropdownMatchSelectWidth={false}
    >
      {options.map((e) => (
        <Option style={{ maxWidth: '200px' }} value={e.value}>
          {e.label}
        </Option>
      ))}
    </Select>
  );
}

export const AutocompleteSelectFilter = ({
  fetch,
  initialFetch,
  placeholder,
  value,
  onChange,
  ...props
}) => {
  const [treeLine, setTreeLine] = useState([]);
  const [isSelected, setIsSelected] = useState(true);
  return (
    <DebounceSelect
      value={value}
      placeholder={placeholder}
      fetchOptions={fetch}
      initialFetchOptions={initialFetch}
      onChange={(e) => {
        setTreeLine(e);
        onChange({ data: e, isSelected: isSelected });
      }}
      {...props}
      dropdownRender={(menu) => {
        return (
          <>
            {menu}
            <Divider
              style={{
                margin: '8px 0',
              }}
            />
            <Space
              style={{
                padding: '0 8px 4px',
              }}
            >
              <Switch
                defaultChecked={isSelected}
                onChange={(e) => {
                  setIsSelected(e);
                  onChange({ data: treeLine, isSelected: e });
                }}
                checkedChildren="Включно"
                unCheckedChildren="Виключно"
              />
            </Space>
          </>
        );
      }}
    />
  );
};
