import { Button, Checkbox, Form, Input } from 'antd';
import styles from './RegwayForm.module.css';
import React, { useContext, useEffect, useState } from 'react';
import { CurrentUserContext } from '../../../providers/AuthProvider';
import { fromApiToClient } from '../../../utils/dateFilters';

export const RegwayForm = ({ regway, onFinish }) => {
  const [form] = Form.useForm();
  const [isActive, setIsActive] = useState(regway ? regway.isActive : true);

  const user = useContext(CurrentUserContext);
  useEffect(() => {
    if (!regway) form.setFieldValue('isActive', true);
  }, []);

  useEffect(() => {
    if (regway && user) {
      form.setFieldsValue(regway);
      form.setFieldValue(
        'createdAt',
        fromApiToClient(regway.createdAt, user.timezone)
      );
      form.setFieldValue(
        'updatedAt',
        fromApiToClient(regway.updatedAt, user.timezone)
      );
      setIsActive(regway.isActive);
    }
  }, [regway, user]);

  const onActiveChange = (e) => {
    setIsActive(e.target.checked);
  };

  return (
    <Form
      form={form}
      onFinish={() => {
        onFinish({
          ...form.getFieldsValue(),
          userId: user?.id,
          isActive,
        });
      }}
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
    >
      <Form.Item
        name="name"
        label="Назва"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Назва" rootClassName={styles.input} />
      </Form.Item>
      <Form.Item
        name="login"
        label="Login"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Login" rootClassName={styles.input} />
      </Form.Item>
      <Form.Item
        name="password"
        label="Password"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Password" rootClassName={styles.input} />
      </Form.Item>
      <Form.Item
        name="rId"
        label="Regway ID"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Regway ID" rootClassName={styles.input} />
      </Form.Item>
      <Form.Item
        name="rKey"
        label="Regway Key"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Regway Key" rootClassName={styles.input} />
      </Form.Item>

      <Form.Item
        name="rUserId"
        label="Regway User ID"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Regway User ID" rootClassName={styles.input} />
      </Form.Item>

      <Form.Item
        name="rContactId"
        label="Regway Contact ID"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Input placeholder="Regway Contact ID" rootClassName={styles.input} />
      </Form.Item>

      {regway && (
        <Form.Item name="createdAt" label="Створено">
          <Input disabled placeholder="Створено" rootClassName={styles.input} />
        </Form.Item>
      )}
      {regway && (
        <Form.Item name="updatedAt" label="Оновлено">
          <Input disabled placeholder="Оновлено" rootClassName={styles.input} />
        </Form.Item>
      )}
      <Form.Item
        name="isActive"
        label="Активний"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Checkbox
          onClick={onActiveChange}
          defaultChecked={regway ? regway.isActive : true}
          rootClassName={styles.input}
        />
      </Form.Item>

      <Form.Item wrapperCol={{ offset: 11 }}>
        <Button type="primary" htmlType="submit">
          Зберегти
        </Button>
      </Form.Item>
    </Form>
  );
};
