import { Button, Modal, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { DeleteOutlined } from '@ant-design/icons';

export const DomainPurchaseConfirmModal = ({
  priceRes,
  isModalOpen,
  handleCancel,
  onSuccess,
  isLoading,
  isFixDomains,
}) => {
  const [selectedDomains, setSelectedDomains] = useState([]);
  const [unableToPurchase, setUnableToPurchase] = useState([]);

  useEffect(() => {
    setSelectedDomains(
      isFixDomains ? priceRes : priceRes?.filter((p) => p.price)
    );
    setUnableToPurchase(priceRes?.filter((p) => !p.price));
  }, [priceRes]);

  const columns = [
    {
      title: 'Домен',
      dataIndex: 'domain',
      key: 'domain',
    },
    {
      title: 'Ціна',
      dataIndex: 'price',
      key: 'price',
    },
    {
      key: 'action',
      dataIndex: 'action',
      render: (_, obj) => (
        <DeleteOutlined
          style={{ color: 'red' }}
          onClick={() =>
            setSelectedDomains(
              selectedDomains.filter((d) => d.domain !== obj.domain)
            )
          }
        />
      ),
    },
  ];

  return (
    <Modal
      title="Покупка доменів"
      open={isModalOpen}
      footer={null}
      onCancel={handleCancel}
    >
      {!!unableToPurchase?.length && (
        <p>
          Наступні домени {isFixDomains ? 'будуть виправлені' : 'недоступні'}:
          <b>{unableToPurchase?.map((d) => d.domain).join(', ')}</b>
        </p>
      )}
      <Table
        columns={columns}
        pagination={false}
        scroll={{ y: '600px' }}
        dataSource={selectedDomains}
      />
      <div className="flex justify-center mt-10">
        <Button
          loading={isLoading}
          type="primary"
          onClick={() => onSuccess(selectedDomains)}
        >
          Купити
        </Button>
      </div>
    </Modal>
  );
};
