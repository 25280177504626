import React, { useContext, useEffect } from 'react';
import {
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  UnorderedListOutlined,
  InboxOutlined,
  AuditOutlined,
  ApiOutlined,
  UserOutlined,
  LogoutOutlined,
  FileTextOutlined,
  GroupOutlined,
  ThunderboltOutlined,
  DatabaseOutlined,
  BellOutlined,
  PieChartOutlined,
  FlagOutlined,
  HistoryOutlined,
  LockOutlined,
  SolutionOutlined,
} from '@ant-design/icons';
import {
  Layout,
  Menu,
  Dropdown,
  Badge,
  Drawer,
  Switch,
  ConfigProvider,
  theme,
} from 'antd';
import { useState } from 'react';
import styles from './AppLayout.module.css';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import logoWhite from '../../../logo_white.webp';
import { CurrentUserContext } from '../../../providers/AuthProvider';
import { NightIcon } from '../../../resources/icons/NightIcon';
import { DayIcon } from '../../../resources/icons/DayIcon';
import { ThemeContext } from '../../../providers/ThemeProvider';
import { NotificationItem } from '../../../shared/Notification/NotificationItem';
import api from '../../../api/api';
import { socket } from '../../../api/socket';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';
import { ChangeProfileModal } from './ChangeProfileModal';

const { Header, Content, Sider } = Layout;

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const Logo = ({ collapsed }) => {
  if (!collapsed) {
    return <img src={logoWhite} alt="" className={styles.icon} />;
  }
  return '';
};

const AppLayout = () => {
  const [menuItems, setMenuItems] = useState([]);
  const { isDarkTheme, setIsDarkTheme } = useContext(ThemeContext);
  const { defaultAlgorithm, darkAlgorithm } = theme;

  const user = useContext(CurrentUserContext);

  useEffect(() => {
    if (user?.role) {
      if (['SUPER_ADMIN'].includes(user.role)) {
        setMenuItems([
          getItem(
            'Замовлення',
            'g1',
            null,
            [
              getItem(
                <Link to="/leads">Ліди</Link>,
                '/leads',
                <UnorderedListOutlined />
              ),
              getItem(
                <Link to="/orders">Замовлення</Link>,
                '/orders',
                <AuditOutlined />
              ),
              getItem(
                <Link to="/customers">Замовники</Link>,
                '/customers',
                <UserOutlined />
              ),
              getItem(
                <Link to="/integrations">Інтеграції</Link>,
                '/integrations',
                <ApiOutlined />
              ),
              getItem(
                <Link to="/buyers">Баєри</Link>,
                '/buyers',
                <InboxOutlined />
              ),
              getItem(
                <Link to="/boxes">Бокси</Link>,
                '/boxes',
                <InboxOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Замовлення - додаткове',
            'g5',
            null,
            [
              getItem(
                <Link to="/country-box">Country Box</Link>,
                '/country-box',
                <FlagOutlined />
              ),
              getItem(
                <Link to="/call-status-categories">Call статуси</Link>,
                '/call-status-categories',
                <GroupOutlined />
              ),
              getItem(
                <Link to="/call-status-groups">Групи call статусів</Link>,
                '/call-status-groups',
                <GroupOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Звіти',
            'g4',
            null,
            [
              getItem(
                <Link to="/report">Загальний звіт</Link>,
                '/report',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
              getItem(
                <Link to="/designer-reports">Конструктор звітів</Link>,
                '/designer-reports',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
            ],
            'group'
          ),
          getItem(
            'Менеджмент',
            'g2',
            null,
            [
              getItem(
                <Link to="/permissions">Права</Link>,
                '/permissions',
                <LockOutlined />
              ),
              getItem(
                <Link to="/users">Користувачі</Link>,
                'users',
                <UserOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Адміністрування',
            'g3',
            null,
            [
              getItem(
                <Link to="/logs">Логи лідів</Link>,
                '/logs',
                <FileTextOutlined />
              ),
              getItem(
                <Link to="/entity-logs">Логи сутностей</Link>,
                '/entity-logs',
                <DatabaseOutlined />
              ),
              getItem(
                <Link to="/inject-form">Інжект форма</Link>,
                '/inject-form',
                <ThunderboltOutlined />
              ),
            ],
            'group'
          ),
        ]);
      }
      if (['FULLSTACK'].includes(user.role)) {
        setMenuItems([
          getItem(
            'Замовлення',
            'g1',
            null,
            [
              getItem(
                <Link to="/leads">Ліди</Link>,
                '/leads',
                <UnorderedListOutlined />
              ),
              getItem(
                <Link to="/orders">Замовлення</Link>,
                '/orders',
                <AuditOutlined />
              ),
              getItem(
                <Link to="/customers">Замовники</Link>,
                '/customers',
                <UserOutlined />
              ),
              getItem(
                <Link to="/buyers">Баєри</Link>,
                '/buyers',
                <InboxOutlined />
              ),
              getItem(
                <Link to="/boxes">Бокси</Link>,
                '/boxes',
                <InboxOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Замовлення - додаткове',
            'g5',
            null,
            [
              getItem(
                <Link to="/country-box">Country Box</Link>,
                '/country-box',
                <FlagOutlined />
              ),
              getItem(
                <Link to="/call-status-categories">Call статуси</Link>,
                '/call-status-categories',
                <GroupOutlined />
              ),
              getItem(
                <Link to="/call-status-groups">Групи call статусів</Link>,
                '/call-status-groups',
                <GroupOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Звіти',
            'g4',
            null,
            [
              getItem(
                <Link to="/report">Загальний звіт</Link>,
                '/report',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
              getItem(
                <Link to="/designer-reports">Конструктор звітів</Link>,
                '/designer-reports',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
            ],
            'group'
          ),
        ]);
      }
      if (['FULLSTACK_TEAM_LEAD'].includes(user.role)) {
        setMenuItems([
          getItem(
            'Замовлення',
            'g1',
            null,
            [
              getItem(
                <Link to="/leads">Ліди</Link>,
                '/leads',
                <UnorderedListOutlined />
              ),
              getItem(
                <Link to="/orders">Замовлення</Link>,
                '/orders',
                <AuditOutlined />
              ),
              getItem(
                <Link to="/customers">Замовники</Link>,
                '/customers',
                <UserOutlined />
              ),
              getItem(
                <Link to="/buyers">Баєри</Link>,
                '/buyers',
                <InboxOutlined />
              ),
              getItem(
                <Link to="/boxes">Бокси</Link>,
                '/boxes',
                <InboxOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Замовлення - додаткове',
            'g5',
            null,
            [
              getItem(
                <Link to="/country-box">Country Box</Link>,
                '/country-box',
                <FlagOutlined />
              ),
              getItem(
                <Link to="/call-status-categories">Call статуси</Link>,
                '/call-status-categories',
                <GroupOutlined />
              ),
              getItem(
                <Link to="/call-status-groups">Групи call статусів</Link>,
                '/call-status-groups',
                <GroupOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Звіти',
            'g4',
            null,
            [
              getItem(
                <Link to="/report">Загальний звіт</Link>,
                '/report',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
              getItem(
                <Link to="/designer-reports">Конструктор звітів</Link>,
                '/designer-reports',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
            ],
            'group'
          ),
          getItem(
            'Менеджмент',
            'g2',
            null,
            [
              getItem(
                <Link to="/users">Користувачі</Link>,
                'users',
                <UserOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Адміністрування',
            'g3',
            null,
            [
              getItem(
                <Link to="/inject-form">Інжект форма</Link>,
                'inject-form',
                <ThunderboltOutlined />
              ),
            ],
            'group'
          ),
        ]);
      }
      if (['FULLSTACK_SUPPORT'].includes(user.role)) {
        setMenuItems([
          getItem(
            'Замовлення',
            'g1',
            null,
            [
              getItem(
                <Link to="/leads">Ліди</Link>,
                '/leads',
                <UnorderedListOutlined />
              ),
              getItem(
                <Link to="/orders">Замовлення</Link>,
                '/orders',
                <AuditOutlined />
              ),
              getItem(
                <Link to="/customers">Замовники</Link>,
                '/customers',
                <UserOutlined />
              ),
              getItem(
                <Link to="/buyers">Баєри</Link>,
                '/buyers',
                <InboxOutlined />
              ),
              getItem(
                <Link to="/boxes">Бокси</Link>,
                '/boxes',
                <InboxOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Замовлення - додаткове',
            'g5',
            null,
            [
              getItem(
                <Link to="/country-box">Country Box</Link>,
                '/country-box',
                <FlagOutlined />
              ),
              getItem(
                <Link to="/call-status-categories">Call статуси</Link>,
                '/call-status-categories',
                <GroupOutlined />
              ),
              getItem(
                <Link to="/call-status-groups">Групи call статусів</Link>,
                '/call-status-groups',
                <GroupOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Звіти',
            'g4',
            null,
            [
              getItem(
                <Link to="/report">Загальний звіт</Link>,
                '/report',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
              getItem(
                <Link to="/designer-reports">Конструктор звітів</Link>,
                '/designer-reports',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
            ],
            'group'
          ),
          getItem(
            'Менеджмент',
            'g2',
            null,
            [
              getItem(
                <Link to="/users">Користувачі</Link>,
                'users',
                <UserOutlined />
              ),
            ],
            'group'
          ),
        ]);
      }
      if (['BIZDEV'].includes(user.role)) {
        setMenuItems([
          getItem(
            'Замовлення',
            'g1',
            null,
            [
              getItem(
                <Link to="/leads">Ліди</Link>,
                '/leads',
                <UnorderedListOutlined />
              ),
              getItem(
                <Link to="/orders">Замовлення</Link>,
                '/orders',
                <AuditOutlined />
              ),
              getItem(
                <Link to="/customers">Замовники</Link>,
                '/customers',
                <UserOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Звіти',
            'g4',
            null,
            [
              getItem(
                <Link to="/report">Загальний звіт</Link>,
                '/report',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
              getItem(
                <Link to="/designer-reports">Конструктор звітів</Link>,
                '/designer-reports',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
            ],
            'group'
          ),
          getItem(
            'Замовлення - додаткове',
            'g5',
            null,
            [
              getItem(
                <Link to="/country-box">Country Box</Link>,
                '/country-box',
                <FlagOutlined />
              ),
            ],
            'group'
          ),
        ]);
      }
      if (['BIZDEV_SUPPORT'].includes(user.role)) {
        setMenuItems([
          getItem(
            'Замовлення',
            'g1',
            null,
            [
              getItem(
                <Link to="/leads">Ліди</Link>,
                '/leads',
                <UnorderedListOutlined />
              ),
              getItem(
                <Link to="/orders">Замовлення</Link>,
                '/orders',
                <AuditOutlined />
              ),
              getItem(
                <Link to="/customers">Замовники</Link>,
                '/customers',
                <UserOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Замовлення - додаткове',
            'g5',
            null,
            [
              getItem(
                <Link to="/country-box">Country Box</Link>,
                '/country-box',
                <FlagOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Звіти',
            'g4',
            null,
            [
              getItem(
                <Link to="/report">Загальний звіт</Link>,
                '/report',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
              getItem(
                <Link to="/designer-reports">Конструктор звітів</Link>,
                '/designer-reports',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
            ],
            'group'
          ),
          getItem(
            'Менеджмент',
            'g2',
            null,
            [
              getItem(
                <Link to="/users">Користувачі</Link>,
                'users',
                <UserOutlined />
              ),
            ],
            'group'
          ),
        ]);
      }
      if (['BIZDEV_SUPPORT'].includes(user.role)) {
        setMenuItems([
          getItem(
            'Замовлення',
            'g1',
            null,
            [
              getItem(
                <Link to="/leads">Ліди</Link>,
                '/leads',
                <UnorderedListOutlined />
              ),
              getItem(
                <Link to="/orders">Замовлення</Link>,
                '/orders',
                <AuditOutlined />
              ),
              getItem(
                <Link to="/customers">Замовники</Link>,
                '/customers',
                <UserOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Замовлення - додаткове',
            'g5',
            null,
            [
              getItem(
                <Link to="/country-box">Country Box</Link>,
                '/country-box',
                <FlagOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Звіти',
            'g4',
            null,
            [
              getItem(
                <Link to="/report">Загальний звіт</Link>,
                '/report',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
              getItem(
                <Link to="/designer-reports">Конструктор звітів</Link>,
                '/designer-reports',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
            ],
            'group'
          ),
          getItem(
            'Менеджмент',
            'g2',
            null,
            [
              getItem(
                <Link to="/users">Користувачі</Link>,
                'users',
                <UserOutlined />
              ),
            ],
            'group'
          ),
        ]);
      }
      if (['BIZDEV_TEAM_LEAD'].includes(user.role)) {
        setMenuItems([
          getItem(
            'Замовлення',
            'g1',
            null,
            [
              getItem(
                <Link to="/leads">Ліди</Link>,
                '/leads',
                <UnorderedListOutlined />
              ),
              getItem(
                <Link to="/orders">Замовлення</Link>,
                '/orders',
                <AuditOutlined />
              ),
              getItem(
                <Link to="/customers">Замовники</Link>,
                '/customers',
                <UserOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Замовлення - додаткове',
            'g5',
            null,
            [
              getItem(
                <Link to="/country-box">Country Box</Link>,
                '/country-box',
                <FlagOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Звіти',
            'g4',
            null,
            [
              getItem(
                <Link to="/report">Загальний звіт</Link>,
                '/report',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
              getItem(
                <Link to="/designer-reports">Конструктор звітів</Link>,
                '/designer-reports',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
            ],
            'group'
          ),
          getItem(
            'Менеджмент',
            'g2',
            null,
            [
              getItem(
                <Link to="/users">Користувачі</Link>,
                'users',
                <UserOutlined />
              ),
            ],
            'group'
          ),
        ]);
      }
      if (['AFFILIATE'].includes(user.role)) {
        setMenuItems([
          getItem(
            'Замовлення',
            'g1',
            null,
            [
              getItem(
                <Link to="/leads">Ліди</Link>,
                '/leads',
                <UnorderedListOutlined />
              ),
              getItem(
                <Link to="/orders">Замовлення</Link>,
                '/orders',
                <AuditOutlined />
              ),
              getItem(
                <Link to="/buyers">Баєри</Link>,
                '/buyers',
                <InboxOutlined />
              ),
              getItem(
                <Link to="/boxes">Бокси</Link>,
                '/boxes',
                <InboxOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Замовлення - додаткове',
            'g5',
            null,
            [
              getItem(
                <Link to="/call-status-categories">Call статуси</Link>,
                '/call-status-categories',
                <GroupOutlined />
              ),
              getItem(
                <Link to="/call-status-groups">Групи call статусів</Link>,
                '/call-status-groups',
                <GroupOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Звіти',
            'g4',
            null,
            [
              getItem(
                <Link to="/report">Загальний звіт</Link>,
                '/report',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
              getItem(
                <Link to="/designer-reports">Конструктор звітів</Link>,
                '/designer-reports',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
            ],
            'group'
          ),
        ]);
      }
      if (['AFFILIATE_TEAM_LEAD'].includes(user.role)) {
        setMenuItems([
          getItem(
            'Замовлення',
            'g1',
            null,
            [
              getItem(
                <Link to="/leads">Ліди</Link>,
                '/leads',
                <UnorderedListOutlined />
              ),
              getItem(
                <Link to="/orders">Замовлення</Link>,
                '/orders',
                <AuditOutlined />
              ),
              getItem(
                <Link to="/buyers">Баєри</Link>,
                '/buyers',
                <InboxOutlined />
              ),
              getItem(
                <Link to="/boxes">Бокси</Link>,
                '/boxes',
                <InboxOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Замовлення - додаткове',
            'g5',
            null,
            [
              getItem(
                <Link to="/call-status-categories">Call статуси</Link>,
                '/call-status-categories',
                <GroupOutlined />
              ),
              getItem(
                <Link to="/call-status-groups">Групи call статусів</Link>,
                '/call-status-groups',
                <GroupOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Звіти',
            'g4',
            null,
            [
              getItem(
                <Link to="/report">Загальний звіт</Link>,
                '/report',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
              getItem(
                <Link to="/designer-reports">Конструктор звітів</Link>,
                '/designer-reports',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
            ],
            'group'
          ),
          getItem(
            'Менеджмент',
            'g2',
            null,
            [
              getItem(
                <Link to="/users">Користувачі</Link>,
                'users',
                <UserOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Адміністрування',
            'g3',
            null,
            [
              getItem(
                <Link to="/inject-form">Інжект форма</Link>,
                '/inject-form',
                <ThunderboltOutlined />
              ),
            ],
            'group'
          ),
        ]);
      }
      if (['AFFILIATE_SUPPORT'].includes(user.role)) {
        setMenuItems([
          getItem(
            'Замовлення',
            'g1',
            null,
            [
              getItem(
                <Link to="/leads">Ліди</Link>,
                '/leads',
                <UnorderedListOutlined />
              ),
              getItem(
                <Link to="/orders">Замовлення</Link>,
                '/orders',
                <AuditOutlined />
              ),
              getItem(
                <Link to="/buyers">Баєри</Link>,
                '/buyers',
                <InboxOutlined />
              ),
              getItem(
                <Link to="/boxes">Бокси</Link>,
                '/boxes',
                <InboxOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Замовлення - додаткове',
            'g5',
            null,
            [
              getItem(
                <Link to="/call-status-categories">Call статуси</Link>,
                '/call-status-categories',
                <GroupOutlined />
              ),
              getItem(
                <Link to="/call-status-groups">Групи call статусів</Link>,
                '/call-status-groups',
                <GroupOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Звіти',
            'g4',
            null,
            [
              getItem(
                <Link to="/report">Загальний звіт</Link>,
                '/report',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
              getItem(
                <Link to="/designer-reports">Конструктор звітів</Link>,
                '/designer-reports',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
            ],
            'group'
          ),
          getItem(
            'Менеджмент',
            'g2',
            null,
            [
              getItem(
                <Link to="/users">Користувачі</Link>,
                'users',
                <UserOutlined />
              ),
            ],
            'group'
          ),
        ]);
      }
      if (user.role === 'ANALYST') {
        setMenuItems([
          getItem(
            'Замовлення',
            'g1',
            null,
            [
              getItem(
                <Link to="/leads">Ліди</Link>,
                '/leads',
                <UnorderedListOutlined />
              ),
              getItem(
                <Link to="/orders">Замовлення</Link>,
                '/orders',
                <AuditOutlined />
              ),
              getItem(
                <Link to="/customers">Замовники</Link>,
                '/customers',
                <UserOutlined />
              ),
              getItem(
                <Link to="/integrations">Інтеграції</Link>,
                '/integrations',
                <ApiOutlined />
              ),
              getItem(
                <Link to="/buyers">Баєри</Link>,
                '/buyers',
                <InboxOutlined />
              ),
              getItem(
                <Link to="/boxes">Бокси</Link>,
                '/boxes',
                <InboxOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Замовлення - додаткове',
            'g5',
            null,
            [
              getItem(
                <Link to="/country-box">Country Box</Link>,
                '/country-box',
                <FlagOutlined />
              ),
              getItem(
                <Link to="/call-status-categories">Call статуси</Link>,
                '/call-status-categories',
                <GroupOutlined />
              ),
              getItem(
                <Link to="/call-status-groups">Групи call статусів</Link>,
                '/call-status-groups',
                <GroupOutlined />
              ),
            ],
            'group'
          ),
          getItem(
            'Звіти',
            'g4',
            null,
            [
              getItem(
                <Link to="/report">Загальний звіт</Link>,
                '/report',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
              getItem(
                <Link to="/designer-reports">Конструктор звітів</Link>,
                '/designer-reports',
                <PieChartOutlined style={{ fontSize: 14 }} />
              ),
            ],
            'group'
          ),
        ]);
      }
    }
  }, [user]);

  const { pathname } = useLocation();
  const [collapsed, setCollapsed] = useState(false);
  let label;

  const [title, setTitle] = useState(label);
  const [url, setUrl] = useState(label);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();
  const [isModalOpenProfile, setIsModalOpenProfile] = useState(false);

  useEffect(() => {
    if (menuItems.length) {
      menuItems.forEach((i) => {
        if (i) {
          const item = i.children.find((ii) => pathname.includes(ii.key));
          if (item?.label) {
            label = item.label;
            setUrl(item.key);
          }
        }
      });
      if (label) setTitle(label.props.children);
      if (pathname === '/') {
        navigate('/leads');
        setTitle('Ліди');
      }
    }
  }, [pathname, menuItems]);

  useDidUpdateEffect(() => {
    fetchNotifications();
    socket.on('notification-created', (res) => {
      if (res.find((n) => n.user_id === user.id)) {
        setNotifications(res);
      }
    });
  }, [user]);

  const fetchNotifications = () => {
    api.get('/notification').then((res) => setNotifications(res.data));
  };

  const handleLogout = () => {
    localStorage.removeItem('access_token');
    navigate('/login');
  };

  const handleModalProfileCancel = () => {
    setIsModalOpenProfile(false);
  };
  const onResendUpdateSuccess = () => {
    setIsModalOpenProfile(false);
  };

  const profileItems = [
    {
      key: '1',
      label: (
        <div
          className={styles.profileItem}
          onClick={() => setIsModalOpenProfile(true)}
        >
          <SolutionOutlined />
          <div className={styles.profileItemText}>Профіль</div>
        </div>
      ),
    },
    {
      key: '2',
      label: (
        <div className={styles.profileItem} onClick={handleLogout}>
          <LogoutOutlined />
          <div className={styles.profileItemText}>Logout</div>
        </div>
      ),
    },
  ];

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkTheme ? darkAlgorithm : defaultAlgorithm,
        token: {
          colorBgBase: isDarkTheme && '#14171e',
          colorBgContainer: isDarkTheme ? '#000d1a00' : '#fafafa00',
        },
      }}
    >
      <Layout className={styles.layout}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
          trigger={null}
        >
          <div className={styles.iconContainer}>
            <div>
              <Logo collapsed={collapsed} />
            </div>
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: `trigger ${styles.collapseButton}`,
                onClick: () => setCollapsed(!collapsed),
              }
            )}
          </div>
          <Menu
            theme="dark"
            selectedKeys={[url]}
            onClick={({ key }) => {
              menuItems.forEach((i) => {
                if (i) {
                  const label = i.children.find((ii) => ii.key === key)?.label;
                  if (label) setTitle(label.props.children);
                }
              });
            }}
            defaultSelectedKeys={[url]}
            mode="inline"
            items={menuItems}
          />
        </Sider>
        <Layout className={styles.layout}>
          <Header
            className={styles.header}
            style={{
              background: isDarkTheme
                ? 'linear-gradient(180deg, rgba(0,21,41,1) 30%, rgba(20, 23, 30, 1))'
                : 'white',
              color: !isDarkTheme && 'black',
            }}
          >
            <div className={styles.title}>{title}</div>
            <div className="flex items-center">
              <div className="mr-8">
                <Switch
                  defaultChecked={isDarkTheme}
                  checkedChildren={<NightIcon />}
                  unCheckedChildren={<DayIcon />}
                  onChange={(e) => {
                    setIsDarkTheme(e);
                    localStorage.setItem('isDarkTheme', e);
                  }}
                />
              </div>
              {user && (
                <div className="mr-8 text-lg">
                  ID: {user.id} | {user.nickname}
                </div>
              )}
              <div className={styles.profile}>
                <Link
                  style={{ color: isDarkTheme ? '#fff' : '#000' }}
                  to="/tasks"
                >
                  <HistoryOutlined />
                </Link>
              </div>
              <Dropdown
                menu={{
                  items: profileItems,
                }}
                placement="bottom"
                trigger={['click']}
              >
                <div className={styles.profile}>
                  <UserOutlined />
                </div>
              </Dropdown>
              <div>
                <Badge
                  style={{ color: '#fff' }}
                  className={styles.profile}
                  count={notifications.length}
                >
                  <BellOutlined onClick={() => setIsDrawerOpen(true)} />
                </Badge>
                <Drawer
                  title="Нотифікації"
                  placement="right"
                  open={isDrawerOpen}
                  onClose={() => setIsDrawerOpen(false)}
                >
                  {!!notifications.length || 'Немає непрочитаних нотифікацій'}
                  {notifications.map((n) => {
                    return (
                      <NotificationItem
                        notification={n}
                        refetch={fetchNotifications}
                        close={() => setIsDrawerOpen(false)}
                      />
                    );
                  })}
                </Drawer>
              </div>
            </div>
          </Header>
          <ChangeProfileModal
            isModalOpen={isModalOpenProfile}
            handleCancel={handleModalProfileCancel}
            onSuccess={onResendUpdateSuccess}
          />
          <Content className={styles.contentContainer}>
            <div className={styles.content}>
              <Outlet />
            </div>
          </Content>
        </Layout>
      </Layout>
    </ConfigProvider>
  );
};

export default AppLayout;
