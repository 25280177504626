import { App, Button, Checkbox, Form, Modal, Select } from 'antd';
import React, { useContext, useState } from 'react';
import api from '../../../api/api';
import { hasSystemStatus } from '../../../utils/translateStatus';
import { statusOptions } from '../../../utils/statusOptions';
import { usePermissions } from '../../../hooks/usePermissionsAllowed';

export const ChangeStatusModal = ({
  leads,
  isModalOpen,
  handleCancel,
  onSuccess,
}) => {
  const role = usePermissions();
  const [form] = Form.useForm();
  const { message } = App.useApp();
  const [status, setStatus] = useState('');
  const [isStatusLocked, setIsStatusLocked] = useState(true);

  const onStatusUpdate = () => {
    api
      .put(
        '/lead/status',
        leads
          .filter((l) => {
            if (
              [
                'SUPER_ADMIN',
                'AFFILIATE_TEAM_LEAD',
                'FULLSTACK_TEAM_LEAD',
              ].includes(role)
            ) {
              return true;
            } else {
              return !hasSystemStatus(l?.status);
            }
          })

          .map((l) => ({
            id: l.id,
            status,
            isStatusLocked,
          }))
      )
      .then((_) => {
        message.success('Статуси змінено');
        onSuccess();
      })
      .catch((e) => message.error(e.response.data.message));
  };

  const onStatusChange = (value) => {
    setStatus(value);
  };

  const onLockClick = (e) => {
    setIsStatusLocked(e.target.checked);
  };

  const wrongStatusLeads = leads.filter((l) => {
    if (
      ['SUPER_ADMIN', 'AFFILIATE_TEAM_LEAD', 'FULLSTACK_TEAM_LEAD'].includes(
        role
      )
    )
      return false;
    return hasSystemStatus(l?.status);
  });

  return (
    <Modal
      title="Змінити статус лідів"
      open={isModalOpen}
      footer={null}
      onCancel={handleCancel}
    >
      {!!wrongStatusLeads.length && (
        <div>
          Наступні ліди не будуть оновлені, оскільки знаходяться в системному
          статусі: <b>{wrongStatusLeads.map((l) => l.email).join(', ')}</b>
        </div>
      )}
      {wrongStatusLeads.length !== leads.length && (
        <Form
          wrapperCol={{ span: 12, offset: 1 }}
          labelCol={{ span: 7, offset: 1 }}
          form={form}
          onFinish={onStatusUpdate}
        >
          <Form.Item
            label="Статус"
            name="status"
            style={{ marginTop: 15 }}
            rules={[{ required: true }]}
          >
            <Select
              onChange={onStatusChange}
              options={statusOptions.filter((s) => !hasSystemStatus(s.value))}
            />
          </Form.Item>
          <Form.Item
            wrapperCol={{ offset: 1 }}
            labelCol={{ offset: 8 }}
            name="isStatusLocked"
            label="Блокування статусу"
          >
            <Checkbox
              onClick={onLockClick}
              defaultChecked
              checked={isStatusLocked}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ offset: 11 }}>
            <Button type="primary" htmlType="submit">
              Змінити
            </Button>
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
