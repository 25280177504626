import React, { useContext, useEffect, useState } from 'react';
import debounce from 'lodash.debounce';

import { AppTable } from '../../../shared/Table/AppTable';
import api from '../../../api/api';
import { inputToFilter } from '../../../utils/inputToFilter';
import { useSearchParams } from 'react-router-dom';
import { usePermissionsAllowed } from '../../../hooks/usePermissionsAllowed';
import { useDidUpdateEffect } from '../../../hooks/useDidUpdateEffect';
import { message, Progress } from 'antd';
import { CurrentUserContext } from '../../../providers/AuthProvider';
import { groupBy } from '../../../utils/groupBy';
import { socket } from '../../../api/socket';

const ProgressMessage = ({ progress }) => {
  const grouped = groupBy(progress, 'sessionId');
  return (
    <>
      {Object.keys(grouped).map((sessionId) => {
        const total = grouped[sessionId].length;
        const done = grouped[sessionId].filter((d) => d.success).length;
        const perc = (100 * done) / total;
        const rounded = Math.ceil(perc);
        return (
          <span>
            <Progress
              percent={rounded}
              status={rounded === 100 ? 'success' : 'active'}
            />
          </span>
        );
      })}
    </>
  );
};

export const Domains = () => {
  const [searchParams] = useSearchParams();
  const [messageApi, contextHolder] = message.useMessage();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [filterValues, setFilterValues] = useState({});
  usePermissionsAllowed(['SUPPORT', 'TEAM_LEAD']);
  const url = searchParams.get('filters');
  const querystr = JSON.parse(url) ? JSON.parse(url) : {};

  const user = useContext(CurrentUserContext);
  const [columns, setColumns] = useState([
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: "Ім'я",
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ціна',
      dataIndex: 'price',
      key: 'price',
    },
    {
      title: 'Сервер',
      dataIndex: 'server',
      key: 'server',
      render: (server) => server.name,
    },
    {
      title: 'Regway',
      dataIndex: 'regway',
      key: 'regway',
      render: (regway) => regway.name,
    },
    {
      title: 'Cloudflare',
      dataIndex: 'cloudflare',
      key: 'cloudflare',
      render: (cloudflare) => cloudflare.name,
    },
    {
      title: 'Створено',
      dataIndex: 'createdAt',
      key: 'createdAt',
    },
  ]);

  const onProgressUpdate = (progress) => {
    const key = 'progress';
    if (!progress.length) {
      messageApi.destroy(key);
      return;
    }
    messageApi.open({
      content: <ProgressMessage progress={progress} />,
      key,
      duration: 0,
      style: {
        justifyContent: 'center',
        alignItems: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: '15px',
        padding: 0,
      },
    });
  };

  useEffect(() => {
    if (user) {
      socket.emit('get-progress', user.id);
    }
  }, [user]);

  useEffect(() => {
    socket.on('update-progress', (msg) => {
      onProgressUpdate(msg);
    });
  }, []);

  useEffect(() => {
    if (user) {
      const userFilter =
        user.role === 'SUPER_ADMIN' ? '' : `&filter.userId=${user?.id}`;
      let res = '';
      if (!url) {
        res = 'filter.isActive=$and:$eq:true&';
      }
      for (let i in querystr) {
        res += `filter.${querystr[i][1]}=$and:${querystr[i][2]}&`;
      }
      api
        .get('/domain-purchase/domain?' + res + 'page=1&limit=10' + userFilter)
        .then((res) => {
          setData(res.data.data);
          setTotal(res.data.meta.totalItems);
        });
    }
  }, [user]);

  useDidUpdateEffect(() => {
    const url = inputToFilter(filterValues, currentPage);
    if (user) {
      const userFilter =
        user.role === 'SUPER_ADMIN' ? '' : `&filter.userId=${user?.id}`;
      api.get(`/domain-purchase/domain?${url}${userFilter}`).then((res) => {
        setData(res.data.data);
        setTotal(res.data.meta.totalItems);
        window.scrollTo(0, 0);
      });
    }
  }, [filterValues, currentPage]);

  const onFiltersChange = (name, value) => {
    setFilterValues({
      ...filterValues,
      [name]: value,
    });
  };

  const onFiltersSet = (filters) => {
    let object = {};
    for (let i in filters) {
      object[filters[i][1]] = filters[i][2];
    }
    setFilterValues({
      ...object,
    });
  };
  const onTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  return (
    <div>
      {contextHolder}
      <AppTable
        filters={GetFilters({ onFiltersChange, onFiltersSet, querystr })}
        columns={columns}
        pagination={{
          total,
          current: currentPage,
          pageSize: 10,
        }}
        onChange={onTableChange}
        onColumnsChange={setColumns}
        data={data}
        dataPrefix="domain"
        querystr={querystr}
      />
    </div>
  );
};

const GetFilters = ({ onFiltersChange, onFiltersSet, querystr }) => {
  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (querystr) {
      onFiltersSet(querystr);
    }
  }, []);

  return [
    {
      label: 'ID',
      type: 'number',
      defaultValue: querystr?.hasOwnProperty('id') ? querystr.id[0] : '',
      onChange: debounce((e) => {
        onFiltersChange('id', `$eq:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            id: [e.target.value, 'id', `$eq:${e.target.value}`],
          }),
        });
      }, 300),
      style: {
        width: '80px',
      },
    },
    {
      label: "Ім'я",
      defaultValue: querystr?.hasOwnProperty('name') ? querystr.name[0] : '',
      onChange: debounce((e) => {
        onFiltersChange('name', `$ilike:${e.target.value}`);
        setSearchParams({
          filters: JSON.stringify({
            ...querystr,
            name: [e.target.value, 'name', `$ilike:${e.target.value}`],
          }),
        });
      }, 300),
    },
  ];
};
