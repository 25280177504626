import { App, Button, Card } from 'antd';
import React, { useState } from 'react';
import api from '../../api/api';
import { useNavigate } from 'react-router-dom';
import { fromApiToClient } from '../../utils/dateFilters';

export const NotificationItem = ({ notification, refetch, close }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { message } = App.useApp();
  const navigate = useNavigate();

  const onRead = () => {
    api
      .put(`/notification/${notification.id}`, { isRead: true })
      .then((_) => {
        setIsLoading(false);
        refetch();
      })
      .catch((e) => {
        message.error(e.response.data.message);
      });
  };

  return (
    <Card className="flex justify-between my-4">
      <div>
        {getTextByType(
          notification.type,
          notification.createdAt,
          JSON.parse(notification.data)
        )}
      </div>
      <div className="my-2">
        <Button type="primary" onClick={onRead} loading={isLoading}>
          Прочитано
        </Button>
        <Button
          type="link"
          onClick={() => {
            close();
            navigate(
              getLinkByType(notification.type, JSON.parse(notification.data))
            );
          }}
        >
          Перейти
        </Button>
      </div>
    </Card>
  );
};

const getTextByType = (type, createdAt, data) => {
  if (type === 'CLOUDFLARE_BANNED')
    return `${fromApiToClient(
      createdAt
    )}: Cloudflare аккаунт потрапив в тимчасовий бан.`;

  if (type === 'INJECT_DONE')
    return `${fromApiToClient(createdAt)}: Інджект ${
      data?.length
    } лідів завершено.`;

  if (type === 'RESEND_DONE')
    return `${fromApiToClient(createdAt)}: Перевідправка ${
      data?.length
    } лідів завершена.`;

  if (type === 'IMPORT_DONE')
    return `${fromApiToClient(createdAt)}: Імпорт лідів завершено.`;

  if (type === 'IMPORT_FAILED')
    return `${fromApiToClient(createdAt)}: Помилка при іморті ліда ${
      data.email
    }`;

  return 'Notification';
};

const getLinkByType = (type, data) => {
  if (type === 'CLOUDFLARE_BANNED') return 'domains';
  if (type === 'INJECT_DONE')
    return `/leads?filters={"id":[[${data.join(',')}],"id","$in:${data.join(
      ','
    )}"]}`;
  if (type === 'RESEND_DONE')
    return `/leads?filters={"id":[[${data.join(',')}],"id","$in:${data.join(
      ','
    )}"]}`;

  if (type === 'IMPORT_DONE') return `/leads`;

  if (type === 'IMPORT_FAILED')
    return `/leads?filters={"email":["${data.email}","email","$ilike:${data.email}"]}`;

  return '/';
};
